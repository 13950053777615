import React from 'react';
import withPageView from '../../modules/ui/hoc/withPageView.hoc';
import { playSound } from './mechanisms.utils';

import './MusicalImage.scss';

class MusicalButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.root = React.createRef();
    this.currentAudio = null;
    this.soundStates = {};
    this.state = {
      audioUrl: false
    };
  }

  componentWillUnmount() {
    if (this.currentAudio) {
      this.currentAudio.pause();
    }
  }

  onClick() {
    const { url, soundStates, currentAudio } = playSound(
      this.props.sound,
      this.soundStates,
      this.currentAudio
    );
    this.soundStates = soundStates;
    this.currentAudio = currentAudio;
    this.setState(() => ({
      audioUrl: url
    }));
    if (this.props.pageName) {
      this.props.pageView(this.props.pageName, this.props.spotId);
    }
  }

  render() {
    return (
      <div className="c-musical-button" style={this.props.style}>
        <button
          type="button"
          className="o-button"
          onClick={this.onClick.bind(this)}
        >
          {this.props.label || 'Lancer le son'}
        </button>
      </div>
    );
  }
}

export default withPageView(MusicalButton);
