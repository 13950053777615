import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_EVENT_BETA } from '../../../../../../share/constants/Badges.constants';

class BadgeEventBeta extends React.PureComponent {
  render() {
    return (
      <BadgeIcon color="#C0C0C0" label={BADGE_LABEL_EVENT_BETA} {...this.props}>
        β
      </BadgeIcon>
    );
  }
}

export default BadgeEventBeta;
