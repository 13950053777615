import React, { Fragment } from 'react';
import RichContent from '../spots/RichContent.container';
import withPageView from '../../modules/ui/hoc/withPageView.hoc';
import { getHasCorrectAnswer } from '../../../../utils/answer';

import './PasswordProtection.scss';

class PasswordProtection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: Boolean(localStorage.getItem('mechanism-password-' + props.id)),
      messageError: null
    };
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler(event) {
    event.preventDefault();
    const input = document.forms.password.protection;
    const answer = input.value;
    input.value = '';

    if (!getHasCorrectAnswer(this.props.password, answer)) {
      this.setState(() => ({
        messageError: this.props.error || 'Mauvais mot de passe.'
      }));

      return false;
    }

    localStorage.setItem('mechanism-password-' + this.props.id, 'true');
    this.setState(() => ({
      loaded: true,
      messageError: null
    }));
    document.querySelector('.c-page__scroll').scrollTo(0, 0);
    if (this.props.pageName) {
      this.props.pageView(this.props.pageName, this.props.spotId);
    }
  }

  render() {
    if (this.state.loaded) {
      return <RichContent content={this.props.content} />;
    }

    return (
      <Fragment>
        {this.props.placeholder && (
          <RichContent content={this.props.placeholder} />
        )}
        <form
          name="password"
          className="c-password-protection"
          onSubmit={this.submitHandler}
        >
          <label className="c-password-protection__fieldset">
            <span className="c-password-protection__label">
              {this.props.label}
            </span>
            <span className="c-password-protection__input-group">
              <input
                name="protection"
                type="text"
                className="c-password-protection__input"
              />
              <span className="c-password-protection__button">
                <button className="o-button o-button--tiny">OK</button>
              </span>
            </span>
            {this.state.messageError && (
              <span className="c-password-protection__message">
                {this.state.messageError}
              </span>
            )}
          </label>
        </form>
      </Fragment>
    );
  }
}

export default withPageView(PasswordProtection);
