import fetch from 'isomorphic-fetch';

const baseUrl =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';

export function fetchApi(path, params) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  const url = baseUrl + path;

  return fetch(url, options).then(response => {
    return response
      .json()
      .then(function(data) {
        return [data, response];
      })
      .catch(error => {
        console.error(error);
      });
  });
}
