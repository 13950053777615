import React from 'react';
import Modal from './Modal.container';
import { REVIEW_MODAL } from './Modal.constants';
import ThemeFeature from '../../theme/ThemeFeature.component';
import { ModalHoc } from './Modal.hoc';

import './ReviewModal.scss';

class ReviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reviewDone: false,
      like: false
    };
  }

  send() {
    const message = document.forms.dislike.message.value;
    const storyId = this.props.storyId;
    this.props.review(storyId, false, message);
    this.props.closeModal();
  }

  onLike() {
    const storyId = this.props.storyId;
    this.props.review(storyId, true);
    this.setState(() => {
      return {
        reviewDone: true,
        like: true
      };
    });
  }

  onDislike() {
    const storyId = this.props.storyId;
    this.props.review(storyId, false);
    this.setState(() => {
      return {
        reviewDone: true,
        like: false
      };
    });
  }

  renderContent() {
    if (!this.state.reviewDone) {
      return (
        <div className="c-review-modal__actions">
          <button
            className="c-review-modal__button"
            onClick={this.onLike.bind(this)}
          >
            <img src="/images/thumb-up.svg" alt="J'ai adoré" />
          </button>
          <button
            className="c-review-modal__button"
            onClick={this.onDislike.bind(this)}
          >
            <img src="/images/thumb-down.svg" alt="Je ne me suis pas amusé" />
          </button>
        </div>
      );
    }
    if (this.state.like) {
      return (
        <div>
          <p>
            Merci beaucoup pour votre réponse ! Nous sommes ravis que le jeu
            vous ait plu !
          </p>
          <ThemeFeature
            name="newsletter"
            render={() => (
              <p>
                Vous souhaitez être tenu informé des nouvelles aventures ?{' '}
                <a
                  className="c-review-modal__link"
                  href="https://zupple.fr#newsletter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscrivez-vous à notre newsletter !
                </a>
              </p>
            )}
          />
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Merci beaucoup pour votre réponse. Nous sommes désolés que le jeu ne
            vous ait pas plu. Si vous pouviez nous expliquer pourquoi en
            quelques mots cela nous aiderait beaucoup !
          </p>
          <form name="dislike">
            <textarea
              placeholder="Votre message"
              name="message"
              className="c-review-modal__message"
            />
            <div className="c-review-modal__send">
              <button
                type="button"
                className="o-button o-button--tiny"
                onClick={this.send.bind(this)}
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal modalId={REVIEW_MODAL} contentLabel="Qu'avez vous pensé du jeu ?">
        {this.renderContent()}
      </Modal>
    );
  }
}

export default ModalHoc(ReviewModal);
