import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_DATE_HALLOWEEN } from '../../../../../../share/constants/Badges.constants';

class BadgeDateHalloween extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#f27503"
        label={BADGE_LABEL_DATE_HALLOWEEN}
        {...this.props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 347 347"
          width="35"
          height="35"
        >
          <g fill="#f27503">
            <path d="M167.249 70.97s4.4-35.6 21.6-49.6 29.2-15.2 29.2-15.2l19.2 20.4s-49.6 15.2-52.8 61.2c-2.8 46.4-17.2-16.8-17.2-16.8z" />
            <path d="M339.249 144.97c14.8 45.2 5.6 99.2-24.8 140.8-11.6 16.4-25.6 29.2-40.4 37.6-14.8 8.8-30.4 13.2-46 13.2-8 0-16.4-1.2-24.4-4l-2.8-.8-2.4 1.6c-14.4 9.6-34 9.2-48.4-.8l-2.4-1.6-2.8.8c-8.8 3.2-17.6 4.8-26.8 4.8-31.2 0-62.8-18.8-86.4-51.2-30-41.6-39.6-95.6-24.8-140.8 18.8-55.2 60.8-67.2 92.8-67.2 25.2 0 45.2 7.2 45.6 7.2l2.8.8 2.4-1.6c14.4-10 34-10.4 48.4-.8l2.4 1.6 2.4-.8c6.4-2 22.8-6.4 42.8-6.4 32.4 0 74.4 12 92.8 67.6z" />
            <path d="M198.849 333.77l2.4-1.6c-1.2.8-2 1.2-3.2 2 .4 0 .8-.4.8-.4z" />
            <path d="M201.249 332.17l2.8.8c8 2.4 16 4 24.4 4 15.6 0 31.6-4.8 46-13.2 14.8-8.8 28.4-21.6 40.4-37.6 30-41.6 39.6-95.6 24.8-140.8-18.8-56-60.8-68-92.8-68-20 0-36.8 4.4-42.8 6.4l-2.8.8s47.2 16.8 70.4 52.4c27.2 41.6 42.8 148.8-70.4 195.2z" />
          </g>
          <g fill="white">
            <path d="M257.249 264.97l-13.6-14.4-44.4 29.2-26-21.6-26 21.6-44.4-29.2-14 14.4-44.4-68.4 44.4 28.4 14-14.4 44.4 29.2 26-21.6 26 21.6 44.4-29.2 13.6 14.4 44.8-28.4z" />
            <path d="M152.449 186.17s-18.4-32-36.8-32-36.8 32-36.8 32c0-31.2 16-55.6 36.8-55.6 20.8 0 36.8 24.4 36.8 55.6z" />
            <path d="M271.649 186.17s-18.4-32-36.8-32-36.8 32-36.8 32c0-31.2 16-55.6 36.8-55.6 20.8 0 36.8 24.4 36.8 55.6z" />
          </g>
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeDateHalloween;
