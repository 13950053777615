const initList = require('../../../utils/spots.utils').initList;

const ConfrerieAraignee = require('./spots/confrerie/sangDeLicorne/Araignee');
const ConfrerieVampire = require('./spots/confrerie/sangDeLicorne/Vampire');
const ConfrerieFantome = require('./spots/confrerie/sangDeLicorne/Fantome');
const ConfrerieSerpent = require('./spots/confrerie/sangDeLicorne/Serpent');
const ConfrerieFinal = require('./spots/confrerie/sangDeLicorne/Final');

const list = initList('spot');

list.add(ConfrerieAraignee);
list.add(ConfrerieVampire);
list.add(ConfrerieFantome);
list.add(ConfrerieSerpent);
list.add(ConfrerieFinal);

module.exports = list.getAll();
