import React from 'react';

import FooterMainNav from './FooterMainNav.container';
import FooterRiddleNav from './FooterRiddleNav.container';
import FooterSpotListNav from './FooterSpotListNav.container';

import './FooterNav.scss';

class FooterNav extends React.PureComponent {
  render() {
    if (this.props.isRiddle) {
      return <FooterRiddleNav spotId={this.props.spotId} />;
    }
    if (this.props.isSpotList) {
      return <FooterSpotListNav />;
    }
    if (this.props.isMainNav) {
      return <FooterMainNav />;
    }

    return null;
  }
}

export default FooterNav;
