import React from 'react';

import './HeaderLogo.css';

class HeaderLogo extends React.PureComponent {
  render() {
    return '';
  }
}

export default HeaderLogo;
