import React from 'react';
import BadgeIcon from '../Badge.component';
import WinterTrophyIcon from '../../../icons/WinterTrophyIcon.component';
import { BADGE_LABEL_ACHIEVEMENT_WINTER } from '../../../../../../share/constants/Badges.constants';

class BadgeAchievementWinter extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#C0C0C0"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #FFFE6F)"
        label={BADGE_LABEL_ACHIEVEMENT_WINTER}
        {...this.props}
      >
        <WinterTrophyIcon color="#C0C0C0" size="45" />
      </BadgeIcon>
    );
  }
}

export default BadgeAchievementWinter;
