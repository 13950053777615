import { connect } from 'react-redux';
import { prefillAnswer } from '../../modules/ui/Ui.actions';

const mapDispatchToProps = {
  prefillAnswer
};

const withPreFillAnswer = Component =>
  connect(
    null,
    mapDispatchToProps
  )(Component);

export default withPreFillAnswer;
