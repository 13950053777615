import { UPDATE_REACH_DISTANCE } from './Options.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  getStateFromLocalStorage,
  saveStateInLocalStorage,
  pick
} from '../../../../utils/basis';

const reducerName = 'options';

function saveState(state) {
  saveStateInLocalStorage(reducerName, pick(state, 'reachDistance'));
}

const OptionsReducer = options => {
  const emptyState = {
    reachDistance: options.reachDistance || 200
  };
  const initialState = getStateFromLocalStorage(reducerName, emptyState);

  return (state = initialState, action) => {
    let newState;

    switch (action.type) {
      case UPDATE_REACH_DISTANCE:
        newState = {
          ...state,
          reachDistance: action.distance
        };
        saveState(newState);

        return newState;

      case LOGOUT_USER: {
        saveState(emptyState);

        return emptyState;
      }

      default:
        return state;
    }
  };
};

export default OptionsReducer;
