import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_DATE_CHRISTMAS } from '../../../../../../share/constants/Badges.constants';

class BadgeDateChristmas extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#ed5d51"
        label={BADGE_LABEL_DATE_CHRISTMAS}
        {...this.props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="35"
          height="35"
        >
          <path
            fill="#ed5d51"
            d="M400.457 299.632l-59.324-58.111V88.998H87.135v202.421c0 38.213 8.417 58.639 25.568 78.403l108.149 105.731C244.822 499.064 276.726 512 310.649 512c33.931 0 65.82-12.936 89.808-36.437 23.988-23.491 37.2-54.735 37.2-87.961 0-33.236-13.213-64.481-37.2-87.97z"
          />
          <g fill="#ddd">
            <path d="M92.522 245.302c-1.822 0-3.598.158-5.387.274v45.843c0 38.213 8.417 58.639 25.568 78.403l24.045 23.509c22.733-14.3 37.843-39.221 37.843-67.641-.002-44.395-36.744-80.388-82.069-80.388z" />
            <path d="M292.836 380.884c-32.81 32.129-52.661 70.549-58.178 106.233C256.49 503.185 282.848 512 310.649 512c33.931 0 65.82-12.936 89.808-36.437 23.988-23.491 37.2-54.735 37.2-87.961 0-23.408-6.676-45.751-18.926-65.168-40.999.894-87.768 21.101-125.895 58.45z" />
          </g>
          <path
            fill="#ccc"
            d="M357.042 84.81c0 8.674-7.032 15.705-15.706 15.705H90.049c-8.674 0-15.706-7.031-15.706-15.705V15.705C74.342 7.031 81.375 0 90.049 0h251.288c8.674 0 15.706 7.031 15.706 15.705l-.001 69.105z"
          />
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeDateChristmas;
