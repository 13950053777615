import React from 'react';
import { Link } from 'react-router-dom';
import { to } from '../../../urls';
import classNames from 'classnames';
import SpotIcon from '../../icons/SpotIcon.component';
import { PAGE_NAME } from '../../spots/SpotList.component';

class FooterSpotListNav extends React.PureComponent {
  getLinkClasses(route) {
    return classNames('c-footer-nav__link', {
      'c-footer-nav__link--active': this.props.page === route
    });
  }

  render() {
    return (
      <div className="c-footer-nav">
        <ul className="c-footer-nav__list">
          <li className="c-footer-nav__item">
            <Link
              to={to('spotsToStart')}
              className={this.getLinkClasses(PAGE_NAME.TO_START)}
            >
              <SpotIcon />
              <div className="c-footer-nav__name">À commencer</div>
            </Link>
          </li>
          <li className="c-footer-nav__item">
            <Link
              to={to('spotsInProgress')}
              className={this.getLinkClasses(PAGE_NAME.IN_PROGRESS)}
            >
              <SpotIcon inProgress />
              <div className="c-footer-nav__name">En cours</div>
            </Link>
          </li>
          <li className="c-footer-nav__item">
            <Link
              to={to('spotsAnswered')}
              className={this.getLinkClasses(PAGE_NAME.ANSWERED)}
            >
              <SpotIcon completed />
              <div className="c-footer-nav__name">terminées</div>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default FooterSpotListNav;
