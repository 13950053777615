import React from 'react';

import colors from '~styles/variables/colors.scss';

class SpotIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 74 74"
        width={this.props.size || 74}
        height={this.props.size || 74}
        className={this.props.className}
        style={this.props.style}
      >
        {this.props.title && <title>{this.props.title}</title>}
        <circle cx="37" cy="37" r="30" fill={colors.alert} />
        {this.props.completed && (
          <path
            fill="none"
            stroke="white"
            strokeWidth="8"
            strokeLinecap="round"
            d="M22,38 L32,48 L52,26"
          />
        )}
        {this.props.inProgress && <circle cx="37" cy="37" r="8" fill="white" />}
      </svg>
    );
  }
}

export default SpotIcon;
