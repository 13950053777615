import React from 'react';
import { LeafletConsumer } from 'react-leaflet';
import { CachedTileLayer } from '@yaga/leaflet-cached-tile-layer';

// 7 days
const maxAge = 1000 * 60 * 60 * 24 * 7;

class MapCacheLayer extends React.PureComponent {
  render() {
    const { account, styles, token } = this.props;

    return (
      <LeafletConsumer>
        {context => {
          const url = `https://api.mapbox.com/styles/v1/${account}/${styles}/tiles/256/{z}/{x}/{y}?access_token=${token}`;
          new CachedTileLayer(url, { maxAge }).addTo(context.map);
        }}
      </LeafletConsumer>
    );
  }
}

export default MapCacheLayer;
