import React from 'react';
import Rank from './trophies/Rank.container';
import withPageView from '../modules/ui/hoc/withPageView.hoc';

class RankPage extends React.PureComponent {
  componentDidMount() {
    this.props.pageView('Classement');
  }

  render() {
    return <Rank />;
  }
}

export default withPageView(RankPage);
