import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Components
import WarningIcon from '../icons/WarningIcon.component';
import XpFlag from '../xp/XpFlag.component';
import CheckPictogram from '../pictos/CheckPictogram.component';
import CrossPictogram from '../pictos/CrossPictogram.component';
import CluePictogram from '../pictos/CluePictogram.component';
import DistancePictogram from '../pictos/DistancePictogram.component';
import DurationPictogram from '../pictos/DurationPictogram.component';
import SignalPictogram from '../pictos/SignalPictogram.component';
import StoryPictogram from '../pictos/StoryPictogram.component';
import TakeAwayPictogram from '../pictos/TakeAwayPictogram.component';
import ThemeComponent from '../theme/ThemeComponent.component';
import ThemeFeature from '../theme/ThemeFeature.component';
import ThemeLabel from '../theme/ThemeLabel.component';

// Functions
import { getDistanceLabel } from '../../modules/geolocation/Geolocation.functions';
import { displayDate, displayDuration } from '../../utils/date';
import { capitalize, typography } from '../../../../utils/basis';
import { hasFullAccess } from '../../modules/auth/Auth.functions';
import { to } from '../../urls';

// Datas
import stories from '~data/stories';
import universes from '~data/universes';
import stripeConf from '~data/stripe';

import './SpotPreview.scss';

class SpotPreview extends React.Component {
  getTopLineColor() {
    const spot = this.props.spot;
    if (spot.topLineColor) {
      return {
        background: spot.topLineColor
      };
    }

    if (!spot.storyId) {
      return {};
    }

    const story = stories[spot.storyId];
    const universe = universes[story.universeId];

    return {
      background: story.topLineColor || universe.topLineColor
    };
  }

  onHighlightSpot() {
    this.props.setHighlightedSpot(this.props.spot);
  }

  onActivate(product) {
    this.props.activateProduct(product.durationId);
  }

  renderPlayLink(label) {
    return (
      <Link to={to('spot', this.props.spot)} className="o-button">
        {label}
      </Link>
    );
  }

  renderAction() {
    const email = this.props.email;
    if (this.props.spot.status === 'teaser' && !this.props.userIsAdmin) {
      return (
        <div className="c-spot-preview__closer">{this.props.spot.teaser}</div>
      );
    }

    if (
      !hasFullAccess(this.props.user) &&
      !this.props.spot.isFree &&
      !this.props.isGameStarted
    ) {
      if (this.props.small) {
        return null;
      }

      if (this.props.pendingProduct) {
        const product = stripeConf.products[this.props.pendingProduct];

        return (
          <div className="c-spot-preview__closer">
            <button
              className="c-spot-preview__activate"
              onClick={this.onActivate.bind(this, product)}
            >
              Activez votre accès
            </button>{' '}
            pour accèder à &nbsp;
            <ThemeLabel name="spot.the" default="l'énigme" />.
          </div>
        );
      } else {
        return (
          <div className="c-spot-preview__closer">
            <Link
              to={{
                pathname: to('payment'),
                state: {
                  email: email,
                  fromApp: true
                }
              }}
              className="c-spot-preview__link"
            >
              {' '}
              Choisissez votre temps d'accès
            </Link>{' '}
            pour accèder à &nbsp;
            <ThemeLabel name="spot.the" default="l'énigme" />.
          </div>
        );
      }
    }
    const { spot, distance } = this.props;
    if (this.props.isGameAnswered || this.props.isGameBlocked) {
      return this.renderPlayLink('Revoir');
    }

    if (this.props.isGameStarted) {
      return this.renderPlayLink('Reprendre');
    }

    const maxDistance =
      this.props.reachDistance + (spot.overReachDistance || 0);
    if (this.props.userIsAdmin || distance <= maxDistance) {
      return this.renderPlayLink(`C'est parti !`);
    }

    if (this.props.small) {
      return null;
    }

    return (
      <div className="c-spot-preview__closer">
        Rapprochez-vous du lieu de{' '}
        <ThemeLabel name="spot.the" default="l'énigme" /> pour y accéder.
      </div>
    );
  }

  getWarningMessage(spot, isGameStarted, isGameAnswered, isGameBlocked) {
    if (isGameAnswered || isGameBlocked || !spot.warning) {
      return '';
    }
    const warnings =
      typeof spot.warning === 'string'
        ? { before: spot.warning }
        : spot.warning;

    return isGameStarted ? warnings.during : warnings.before;
  }

  renderWarning() {
    const { spot, isGameStarted, isGameAnswered, isGameBlocked } = this.props;

    const warning = this.getWarningMessage(
      spot,
      isGameStarted,
      isGameAnswered,
      isGameBlocked
    );
    if (!warning) {
      return '';
    }

    return (
      <div className="c-spot-preview__info c-spot-preview__warning">
        <WarningIcon className="c-spot-preview__warning-icon" />
        {warning}
      </div>
    );
  }

  renderAnswerDate() {
    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          {this.props.isGameAnswered ? (
            <CheckPictogram className="c-spot-preview__icon" />
          ) : (
            <CrossPictogram className="c-spot-preview__icon" />
          )}
        </div>
        {capitalize(displayDate(new Date(this.props.gameEndDate), true))}
      </div>
    );
  }
  renderAnswerClues() {
    if (!this.props.isGameStarted) {
      return null;
    }
    const clues = this.props.nextClueIndex;
    const plural = clues > 1 ? 's' : '';

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <CluePictogram className="c-spot-preview__icon" />
        </div>
        En {displayDuration(this.props.gameTime)}{' '}
        {clues > 0 ? `avec ${clues} indice${plural}` : `sans indice`}
      </div>
    );
  }

  renderAnsweredContent() {
    return (
      <div className="c-spot-preview__content">
        {this.renderDistance()}
        {this.renderAnswerDate()}
        {this.renderAnswerClues()}
        {this.renderStory()}
      </div>
    );
  }

  renderDistance() {
    const maxDistance =
      this.props.reachDistance + (this.props.spot.overReachDistance || 0);
    const isAway = this.props.distance > maxDistance;

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <DistancePictogram className="c-spot-preview__icon" />
        </div>
        <div>
          {isAway && getDistanceLabel(this.props.distance)}
          {!this.props.withMapLink && this.props.spot.placeName && (
            <Fragment>
              {isAway && ' - '}
              {this.props.spot.placeName}
            </Fragment>
          )}
          {this.props.withMapLink && (
            <Fragment>
              {isAway && ' - '}
              {this.props.isGeolocationApp && (
                <Link to={to('home')} onClick={this.onHighlightSpot.bind(this)}>
                  {this.props.spot.placeName || 'sur la carte'}
                </Link>
              )}
              {!this.props.isGeolocationApp && this.props.spot.placeName}
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  renderTimeInfo() {
    const { timeInfo } = this.props.spot;

    if (!timeInfo) {
      return null;
    }

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <DurationPictogram className="c-spot-preview__icon" />
        </div>
        <div>{timeInfo}</div>
      </div>
    );
  }

  renderLevel() {
    const { levelLabel } = this.props.spot.riddle;
    if (!levelLabel) {
      return null;
    }

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <SignalPictogram className="c-spot-preview__icon" />
        </div>
        <div>{levelLabel}</div>
      </div>
    );
  }

  renderStory() {
    const { spot } = this.props;
    if (!spot.storyId || spot.storyId === this.props.storyId) {
      return '';
    }

    const story = stories[spot.storyId];
    const universe = universes[story.universeId];

    return (
      <ThemeFeature
        name="stories"
        render={() => (
          <div className="c-spot-preview__info">
            <div className="c-spot-preview__icon-wrapper">
              <StoryPictogram className="c-spot-preview__icon" />
            </div>
            <ThemeFeature
              name="universes"
              render={() => (universe.shortTitle || universe.title) + ' - '}
            />
            {story.shortTitle || story.title}
            {spot.displayTag ? ' - ' + spot.displayTag : ''}
          </div>
        )}
      />
    );
  }

  renderTakeAway() {
    if (!this.props.spot.riddle.isTakeAway) {
      return '';
    }

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <TakeAwayPictogram className="c-spot-preview__icon" />
        </div>
        <ThemeLabel name="spot.singular" default="énigme" capitalize />
        &nbsp;
        <Link to={to('rules') + '#enigmes-a-emporter'}>à emporter</Link>
      </div>
    );
  }

  renderClues() {
    if (!this.props.spot.riddle.rate) {
      return null;
    }

    if (this.props.nextClueIndex) {
      return (
        <div className="c-spot-preview__info">
          <div className="c-spot-preview__icon-wrapper">
            <CluePictogram className="c-spot-preview__icon" />
          </div>
          {this.props.nextClueIndex} indice
          {this.props.nextClueIndex > 1 ? 's' : ''}
        </div>
      );
    }

    return (
      <div className="c-spot-preview__info">
        <div className="c-spot-preview__icon-wrapper">
          <CluePictogram className="c-spot-preview__icon" />
        </div>
        ~{this.props.spot.riddle.rate}% de réussite sans indice
      </div>
    );
  }

  renderAuthor() {
    const { spot } = this.props;
    let author = null;
    if (spot.storyId) {
      author = universes[stories[spot.storyId].universeId].author;
    } else if (spot.author) {
      author = spot.author;
    }
    if (!author) {
      return null;
    }

    return <div className="c-spot-preview__sub-title">{author}</div>;
  }

  renderCustom() {
    return <ThemeComponent name="SpotPreview" spot={this.props.spot} />;
  }

  renderContent() {
    if (this.props.spot.status === 'teaser' && !this.props.userIsAdmin) {
      return '';
    }
    return (
      <div className="c-spot-preview__content">
        {this.renderDistance()}
        {this.renderTimeInfo()}
        {this.renderLevel()}
        {this.renderStory()}
        {this.renderClues()}
        {this.renderTakeAway()}
        {this.renderCustom()}
      </div>
    );
  }

  render() {
    const { spot, isGameAnswered, isGameBlocked } = this.props;
    const classes = classNames('c-spot-preview', {
      'o-stamp': isGameAnswered || isGameBlocked,
      'o-stamp--blocked': !isGameAnswered && isGameBlocked
    });

    return (
      <div className={classes}>
        <div
          className="c-spot-preview__top-line"
          style={this.getTopLineColor()}
        />
        <ThemeFeature
          name="xp"
          render={() => (
            <div className="c-spot-preview__flag">
              <XpFlag
                isSpot
                xp={spot.xp}
                malus={this.props.malus}
                answered={this.props.isGameAnswered}
                blocked={this.props.isGameBlocked}
              />
            </div>
          )}
        />
        <div className="c-spot-preview__wrapper">
          <h1 className="c-spot-preview__title">
            {spot.displayId && (
              <span>
                {spot.displayId}
                {' - '}
              </span>
            )}
            {typography(spot.shortTitle || spot.title)}
          </h1>
          {this.renderAuthor()}
          {this.props.isGameAnswered || this.props.isGameBlocked
            ? this.renderAnsweredContent()
            : this.renderContent()}
          <div className="c-spot-preview__button">{this.renderAction()}</div>
          {this.renderWarning()}
        </div>
      </div>
    );
  }
}

export default SpotPreview;
