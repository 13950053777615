import React from 'react';

import './StatusBar.scss';
import { isRoute } from '../../../urls';
import {
  POSITION_UNAVAILABLE_ERROR,
  TIMEOUT_ERROR
} from '../../../modules/geolocation/Geolocation.constants';
import TargetIcon from '../../icons/TargetIcon.component';

class StatusBar extends React.PureComponent {
  constructor(props) {
    super(props);

    // Initial state
    const isOnLine = !('onLine' in navigator) || navigator.onLine;
    this.state = {
      isOnLine
    };

    // Update online state
    window.addEventListener('offline', () => {
      this.setState(() => ({
        isOnLine: false
      }));
    });
    window.addEventListener('online', () => {
      this.setState(() => ({
        isOnLine: true
      }));
    });
  }

  render() {
    if (!this.state.isOnLine) {
      return (
        <div className="c-status-bar">Vous n'êtes pas connecté à internet</div>
      );
    }

    // Sur la home, le message d'erreur peut déjà prendre toute la page
    if (!isRoute('home', this.props.location) || this.props.isMapDisplayed) {
      if (this.props.geolocationError === POSITION_UNAVAILABLE_ERROR) {
        return (
          <div className="c-status-bar">
            <TargetIcon className="c-status-bar__icon" disabled />
            <span className="c-status-bar__label">Votre GPS est désactivé</span>
          </div>
        );
      }

      if (this.props.geolocationError === TIMEOUT_ERROR) {
        return (
          <div className="c-status-bar">
            <TargetIcon className="c-status-bar__icon" disabled />
            <span className="c-status-bar__label">Position GPS perdue</span>
          </div>
        );
      }
    }

    return null;
  }
}

export default StatusBar;
