import { UPDATE_APP } from './App.constants';
import { fetchAndUpdate } from '../api/Api.actions';

export function updateApp(app) {
  return {
    type: UPDATE_APP,
    app
  };
}

export function fetchMessages() {
  return fetchAndUpdate('/api/app/getAllMessagesForUser');
}
