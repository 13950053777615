import { PLAY_STORY, STOP_STORY } from './Story.constants';
import { activeSpotsView } from '../map/Map.actions';
import { fetchAndUpdate } from '../api/Api.actions';

export function playStory(storyId) {
  return function(dispatch) {
    dispatch(activeSpotsView());
    dispatch({
      type: PLAY_STORY,
      storyId
    });
    dispatch(
      fetchAndUpdate('/api/story/start', {
        storyId
      })
    );
  };
}

export function stopStory(storyId) {
  return function(dispatch) {
    dispatch({
      type: STOP_STORY,
      storyId
    });
    dispatch(
      fetchAndUpdate('/api/story/stop', {
        storyId
      })
    );
  };
}
