import { connect } from 'react-redux';
import AutologinPage from './AutologinPage.component';
import { authenticate, resetUserData } from '../../modules/auth/Auth.actions';
import {
  getConnectionError,
  isConnected
} from '../../modules/auth/Auth.selectors';
import { getAppStatus } from '../../modules/app/App.selectors';
import { checkAppStatus } from '../../modules/ui/Ui.actions';

function mapStateToProps(state) {
  return {
    isConnected: isConnected(state),
    connectionError: getConnectionError(state),
    appStatus: getAppStatus(state)
  };
}

const mapDispatchToProps = {
  authenticate,
  resetUserData,
  checkAppStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutologinPage);
