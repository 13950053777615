import React from 'react';
import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { renderForEach } from '../../../../utils/render';

import './MixtCarousel.scss';

class MixtCarousel extends React.PureComponent {
  render() {
    return (
      <Carousel
        showArrows
        showStatus={false}
        showThumbs={false}
        className="c-mixt-carousel"
        showIndicators={false}
        swipeScrollTolerance={20}
      >
        {renderForEach(this.props.content, (content, index) => {
          const classes = classNames('c-mixt-carousel__content', {
            'c-mixt-carousel__content--middle': this.props.align === 'middle',
            'c-mixt-carousel__content--bottom': this.props.align === 'bottom'
          });

          return (
            <div
              className={classes}
              key={`content-${index}`}
              style={content.noBorder ? { border: 'none', padding: 0 } : {}}
            >
              <h2 className="c-mixt-carousel__title">{content.title}</h2>

              <div className="c-mixt-carousel__figure">
                <img
                  height={content.imageHeight || 'auto'}
                  width={content.imageWidth || 'auto'}
                  style={content.style}
                  src={content.image}
                  alt=""
                  className="c-mixt-carousel__image"
                />
                {content.watermark && (
                  <span className="c-mixt-carousel__watermark">
                    {content.watermark}
                  </span>
                )}
              </div>
              <ul className="c-mixt-carousel__list">
                {renderForEach(content.list, (item, itemIndex) => {
                  return <li key={`list-item-${itemIndex}`}>{item}</li>;
                })}
              </ul>
              <div className="c-mixt-carousel__text">{content.description}</div>
            </div>
          );
        })}
      </Carousel>
    );
  }
}

export default MixtCarousel;
