import React from 'react';

import colors from '~styles/variables/colors.scss';

class PlaceIcon extends React.PureComponent {
  render() {
    const prefix = this.props.prefix || 'icon';

    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.998%"
            x2="99.994%"
            y2="49.998%"
            id={`${prefix}-basilique`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.activeTo} offset="100%" />
          </linearGradient>
        </defs>
        <g
          fill="none"
          stroke="url(#icon-basilique)"
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
        >
          <path d="M3,19.5 H17 M3,20 v-14 M5,9 v-8 M4,2 h2 M7,20 v-14 M3,9 h4 M3,17 h4 M3,9 C3,8 3,7 4,6 C5,7 5,8 5,9 M5,9 C5,8 5,7 6,6 C7,7 7,8 7,9 M3,6 h4 M13,20 v-14 M15,9 v-8 M14,2 h2 M17,20 v-14 M13,9 h4 M13,17 h4 M13,9 C13,8 13,7 14,6 C15,7 15,8 15,9 M15,9 C15,8 15,7 16,6 C17,7 17,8 17,9 M13,6 h4 M7,18 h6 M9,20 v-7 M11,20 v-5 M7,15 C7,13 7,12 8,11 C9,12 9,13 9,15 C9,13 9,12 10,11 C11,12 11,13 11,15 C11,13 11,12 12,11 C13,12 13,13 13,15 M7,11 h6 M7,9 h6 l-3,-1 l-3,1 M10,8 C9.5,7 9.5,7 10,6 C10.5,7 10.5,7 10,8" />
        </g>
        <g fill="url(#icon-basilique)" stroke="none">
          <circle cx="5" cy="11" r="0.3" />
          <circle cx="5" cy="13" r="0.3" />
          <circle cx="5" cy="15" r="0.3" />
          <circle cx="15" cy="11" r="0.3" />
          <circle cx="15" cy="13" r="0.3" />
          <circle cx="15" cy="15" r="0.3" />
        </g>
      </svg>
    );
  }
}

export default PlaceIcon;
