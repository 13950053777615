import { connect } from 'react-redux';
import FooterMainNav from './FooterMainNav.component';
import { anyModalOpen } from '../../../modules/ui/Ui.selectors';
import { hasUnreadNotification } from '../../../modules/notification/Notification.selectors';

function mapStateToProps(state) {
  return {
    location: state.router.location,
    anyModalOpen: anyModalOpen(state),
    hasNotification: hasUnreadNotification(state)
  };
}

export default connect(mapStateToProps)(FooterMainNav);
