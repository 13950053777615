import { connect } from 'react-redux';
import About from './About.component';
import { getUser } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(About);
