export function isFree(user) {
  return !hasPass(user);
}

export function hasPass(user) {
  return Boolean(user.expirationDate);
}

export function hasExpiredPass(user) {
  return hasPass(user) && !hasActivePass(user);
}

export function hasActivePass(user) {
  return hasPass(user) && new Date(user.expirationDate) > new Date();
}

export function hasFullAccess(user) {
  return Boolean(user.isAdmin || user.isOurGuest || hasActivePass(user));
}
