import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_EVENT_ADVENTURE_13 } from '../../../../../../share/constants/Badges.constants';

class BadgeEventAdventure13 extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#64cdf4"
        label={BADGE_LABEL_EVENT_ADVENTURE_13}
        {...this.props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-30 -30 460 500"
          style={{ marginBottom: '-10%' }}
        >
          <g className="layer">
            <path
              fill="#fc95bb"
              d="M111.761 447.343a7.61 7.61 0 01-7.611-7.612c0-12.875 4.643-19.796 8.374-25.358 3.237-4.824 5.793-8.634 5.793-16.875 0-8.242-2.556-12.053-5.793-16.876-3.73-5.562-8.374-12.483-8.374-25.358 0-12.874 4.643-19.794 8.374-25.355 3.237-4.824 5.793-8.633 5.793-16.873a7.611 7.611 0 1115.222 0c0 12.874-4.643 19.794-8.374 25.355-3.237 4.824-5.793 8.633-5.793 16.873 0 8.242 2.556 12.053 5.793 16.876 3.73 5.562 8.374 12.483 8.374 25.358 0 12.874-4.643 19.796-8.374 25.357-3.237 4.824-5.793 8.634-5.793 16.876a7.61 7.61 0 01-7.611 7.612z"
            />
            <path
              fill="#f8c52d"
              d="M139.432 321.534h-29.554c-6.016 0-10.577-5.428-9.539-11.354l6.729-38.444h35.175l6.729 38.444c1.037 5.926-3.524 11.354-9.54 11.354z"
            />
            <path
              fill="#d6a826"
              d="M139.435 321.537h-24.464c6.012 0 10.57-5.426 9.539-11.358l-6.73-38.448h24.464l6.73 38.448c1.031 5.932-3.526 11.358-9.539 11.358z"
            />
            <path
              fill="#fee265"
              d="M224.827 139.242c0 74.483-44.848 146.484-100.171 146.484S24.484 213.725 24.484 139.242 69.332 16 124.655 16s100.172 48.759 100.172 123.242z"
            />
            <path
              fill="#fdd330"
              d="M224.831 139.237c0 74.492-44.855 146.488-100.179 146.488-8.114 0-15.996-1.546-23.554-4.426 51.979-5.881 93.004-74.836 93.004-146.084 0-61.841-30.921-105.948-73.088-119.135 1.203-.06 2.415-.08 3.638-.08 55.324 0 100.179 48.755 100.179 123.237z"
            />
            <text
              x="124"
              y="211"
              fill="#fff"
              strokeWidth="0"
              fontFamily="sans-serif"
              fontSize="200"
              textAnchor="middle"
            >
              1
            </text>
            <g>
              <path
                fill="#fdd330"
                d="M275.529 517a7.61 7.61 0 01-7.611-7.612c0-7.226-2.145-10.422-5.112-14.845-3.428-5.109-7.694-11.467-7.694-23.327 0-11.861 4.266-18.22 7.694-23.33 2.967-4.424 5.112-7.62 5.112-14.848 0-7.227-2.145-10.424-5.112-14.847-3.428-5.11-7.694-11.469-7.694-23.33 0-11.86 4.266-18.218 7.694-23.327 2.967-4.423 5.112-7.619 5.112-14.845 0-4.204 3.407-7.611 7.611-7.611s7.612 3.407 7.612 7.611c0 11.86-4.266 18.218-7.694 23.327-2.967 4.423-5.112 7.619-5.112 14.845 0 7.228 2.145 10.424 5.112 14.849 3.428 5.109 7.694 11.468 7.694 23.328 0 11.861-4.266 18.22-7.694 23.33-2.967 4.424-5.112 7.62-5.112 14.848 0 7.226 2.145 10.422 5.112 14.845 3.428 5.109 7.694 11.467 7.694 23.327a7.611 7.611 0 01-7.612 7.612z"
              />
              <path
                fill="#1785f8"
                d="M289.122 367.929h-29.554c-6.016 0-10.577-5.428-9.539-11.354l6.729-38.444h35.175l6.729 38.444c1.036 5.927-3.524 11.354-9.54 11.354z"
              />
              <path
                fill="#1576f7"
                d="M290.121 366.93h-24.464c6.012 0 10.58-5.426 9.539-11.358l-6.73-38.438h24.464l6.73 38.438c1.041 5.931-3.527 11.358-9.539 11.358z"
              />
              <path
                fill="#31a7fb"
                d="M375.516 185.637c0 74.483-44.848 146.484-100.171 146.484S175.173 260.12 175.173 185.637 220.022 62.395 275.345 62.395s100.171 48.759 100.171 123.242z"
              />
              <path
                fill="#1c96f9"
                d="M374.516 185.64c0 74.482-44.845 146.478-100.168 146.478-10.125 0-19.896-2.415-29.112-6.811 54.283-1.93 97.905-72.987 97.905-146.448 0-58.092-27.283-100.542-65.539-116.396 53.818 1.859 96.914 50.16 96.914 123.177z"
              />
              <text
                x="275"
                y="258.25"
                fill="#fff"
                strokeWidth="0"
                fontFamily="sans-serif"
                fontSize="200"
                textAnchor="middle"
              >
                3
              </text>
            </g>
          </g>
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeEventAdventure13;
