import React from 'react';
import BadgeIcon from '../Badge.component';
import TypewriterIcon from '../../../icons/TypewriterIcon.component';
import { BADGE_LABEL_STORY_RESISTANCE } from '../../../../../../share/constants/Badges.constants';

class BadgeStoryResistance extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#04a891"
        label={BADGE_LABEL_STORY_RESISTANCE}
        {...this.props}
      >
        <TypewriterIcon className="c-resistance-answer__typewriter" />
      </BadgeIcon>
    );
  }
}

export default BadgeStoryResistance;
