import { UPDATE_BADGES } from './Badge.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';
import { createIndex } from '../../../../utils/basis';

const initialState = {
  all: {},
  byUser: {}
};

const BadgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BADGES:
      const all = {
        ...state.all,
        ...action.badges
      };
      const byUser = createIndex(Object.values(all), '_id', 'userId');

      return {
        ...state,
        all,
        byUser
      };

    case LOGOUT_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default BadgeReducer;
