const { cleanString } = require('./basis');

const WRONG_ORDER = 'wrongOrder';
const WRONG_PREFIX = 'wrongPrefix';
const WRONG_N_CHARACTERS = 'wrongCharacters';
const GOOD_N_STARTING_CHARACTERS = 'goodStart';
const GOOD_N_ENDING_CHARACTERS = 'goodEnd';
const LENGTH = 'length';

const OPERATOR_EQUAL = '=';
const OPERATOR_NOT = '!';
const OPERATOR_MIN = 'min';
const OPERATOR_MAX = 'max';

function compare(a, b, operator) {
  if (operator === OPERATOR_MIN) {
    return a >= b;
  }
  if (operator === OPERATOR_MAX) {
    return a <= b;
  }
  if (operator === OPERATOR_NOT) {
    return a !== b;
  }

  return a === b;
}

function getCleanedCorrectAnswerArray(answer) {
  if (Array.isArray(answer)) {
    return answer.map(item => cleanString(item));
  }

  const cleanCorrectAnswerArray = [];
  cleanCorrectAnswerArray.push(cleanString(answer));

  return cleanCorrectAnswerArray;
}

function mapRemovePrefix(cleanAnswerArray) {
  return cleanAnswerArray.map(removePrefix);
}

function removePrefix(cleanAnswer) {
  return splitPrefix(cleanAnswer).value || '';
}

function getHasCorrectSpotAnswer(spot, answer) {
  return getHasCorrectAnswer(spot.answer, answer);
}

function getHasCorrectAnswer(rightAnswer, answer) {
  const cleanAnswer = cleanString(answer);
  const cleanCorrectAnswerArray = getCleanedCorrectAnswerArray(rightAnswer);

  return cleanCorrectAnswerArray.includes(cleanAnswer);
}

function isWrongOrder(spot, cleanedAnswer) {
  const answer = removePrefix(cleanedAnswer);
  const cleanedCorrectAnswerList = mapRemovePrefix(
    getCleanedCorrectAnswerArray(spot.answer)
  );

  return cleanedCorrectAnswerList.some(cleanedCorrectAnswer => {
    if (answer.length !== cleanedCorrectAnswer.length) {
      return false;
    }

    return (
      Array.from(answer)
        .sort()
        .join('') ===
      Array.from(cleanedCorrectAnswer)
        .sort()
        .join('')
    );
  });
}

function splitPrefix(answer) {
  const split = answer.split(/:(.*)/s).filter(Boolean);
  return {
    prefix: split.length === 2 ? split[0] : null,
    value: split.length === 2 ? split[1] : split[0]
  };
}

function isWrongPrefix(spot, cleanedAnswer, prefixFilter = null) {
  const splitAnswer = splitPrefix(cleanedAnswer);
  if (!splitAnswer.prefix) {
    return false;
  }

  return getCleanedCorrectAnswerArray(spot.answer).some(cleanedCorrectAnswer => {
    const splitCorrectAnswer = splitPrefix(cleanedCorrectAnswer);

    return (
      splitAnswer.prefix !== splitCorrectAnswer.prefix &&
      splitAnswer.value === splitCorrectAnswer.value &&
      (!prefixFilter || splitAnswer.prefix === prefixFilter)
    );
  });
}

function areStartingCharactersGood(spot, cleanedAnswer, length) {
  const cleanedCorrectAnswerList = mapRemovePrefix(
    getCleanedCorrectAnswerArray(spot.answer)
  );
  const match = removePrefix(cleanedAnswer).substr(0, length);

  return cleanedCorrectAnswerList.some(cleanedCorrectAnswer =>
    cleanedCorrectAnswer.startsWith(match)
  );
}

function areEndingCharactersGood(spot, cleanedAnswer, length) {
  const cleanedCorrectAnswerList = mapRemovePrefix(
    getCleanedCorrectAnswerArray(spot.answer)
  );
  const match = removePrefix(cleanedAnswer).substr(-length);

  return cleanedCorrectAnswerList.some(cleanedCorrectAnswer =>
    cleanedCorrectAnswer.endsWith(match)
  );
}

function areCharactersWrong(spot, cleanedAnswer, length, operator) {
  const cleanedCorrectAnswerList = mapRemovePrefix(
    getCleanedCorrectAnswerArray(spot.answer)
  );
  const loop = operator === OPERATOR_MIN ? 'every' : 'some';

  return cleanedCorrectAnswerList[loop](cleanedCorrectAnswer => {
    const reducedAnswer = Array.from(cleanedAnswer);
    const reducedCorrectAnswer = Array.from(cleanedCorrectAnswer);
    let index = 0;
    while (index < reducedCorrectAnswer.length) {
      const letter = reducedCorrectAnswer[index];
      const pos = reducedAnswer.indexOf(letter);
      if (pos === -1) {
        index++;
        continue;
      }

      reducedAnswer.splice(pos, 1);
      reducedCorrectAnswer.splice(index, 1);
    }
    const distance = Math.max(
      reducedAnswer.length,
      reducedCorrectAnswer.length
    );

    return compare(distance, length, operator);
  });
}

function isAnswerLengthValid(cleanedAnswer, length, operator) {
  return compare(removePrefix(cleanedAnswer).length, length, operator);
}

function destructCheerAuto(name) {
  const cheer = {
    name,
    operator: OPERATOR_EQUAL
  };

  // Extract parameter
  let matches;
  matches = /([^(]+)\(([^)]*)\)$/.exec(cheer.name);
  if (matches) {
    cheer.parameters = matches[2].split(',');
    cheer.length = parseInt(matches[2], 10) || null;
    cheer.name = matches[1];
  }

  // Extract operator
  matches = /^([^(]+)-(.*)$/.exec(cheer.name);
  if (matches) {
    cheer.name = matches[2];
    if (matches[1] === OPERATOR_MIN || matches[1] === OPERATOR_MAX) {
      cheer.operator = matches[1];
    }
  } else if (cheer.name[0] === '!') {
    cheer.name = cheer.name.slice(1);
    cheer.operator = OPERATOR_NOT;
  }

  return cheer;
}

function getCheerMessage(spot, answer) {
  if (!spot.dialog) {
    return null;
  }

  // Manual Cheers
  let cheerPattern;
  const cleanedAnswer = cleanString(answer);
  if (spot.dialog.cheers) {
    cheerPattern = Object.keys(spot.dialog.cheers).find(cheerItem =>
      new RegExp(cheerItem, 'i').test(cleanedAnswer)
    );
    if (cheerPattern) {
      return spot.dialog.cheers[cheerPattern];
    }
  }

  // Automatic Cheers
  if (spot.dialog.cheersAuto) {
    cheerPattern = Object.keys(spot.dialog.cheersAuto).find(cheerItem => {
      const condition = destructCheerAuto(cheerItem);

      // Correct answer, but wrong prefix
      if (cheerItem === WRONG_PREFIX) {
        return isWrongPrefix(spot, cleanedAnswer);
      }
      if (condition.name === WRONG_PREFIX) {
        return isWrongPrefix(spot, cleanedAnswer, condition.parameters[0]);
      }

      // All characters OK but wrong order
      if (cheerItem === WRONG_ORDER) {
        return isWrongOrder(spot, cleanedAnswer);
      }

      // First characters OK
      if (condition.name === GOOD_N_STARTING_CHARACTERS) {
        return areStartingCharactersGood(spot, cleanedAnswer, condition.length);
      }

      // Last characters OK
      if (condition.name === GOOD_N_ENDING_CHARACTERS) {
        return areEndingCharactersGood(spot, cleanedAnswer, condition.length);
      }

      // Almost all characters OK
      if (condition.name === WRONG_N_CHARACTERS) {
        return areCharactersWrong(
          spot,
          cleanedAnswer,
          condition.length,
          condition.operator
        );
      }

      if (condition.name === LENGTH) {
        return isAnswerLengthValid(
          cleanedAnswer,
          condition.length,
          condition.operator
        );
      }

      console.error('unknown auto cheer: ' + cheerItem);
      return null;
    });
    if (cheerPattern) {
      return spot.dialog.cheersAuto[cheerPattern];
    }
  }

  return null;
}

module.exports = {
  WRONG_ORDER,
  WRONG_PREFIX,
  WRONG_N_CHARACTERS,
  GOOD_N_STARTING_CHARACTERS,
  GOOD_N_ENDING_CHARACTERS,
  areCharactersWrong,
  areEndingCharactersGood,
  areStartingCharactersGood,
  destructCheerAuto,
  getCheerMessage,
  getHasCorrectSpotAnswer,
  getHasCorrectAnswer,
  isWrongOrder,
  isWrongPrefix,
  splitPrefix
};
