import { connect } from 'react-redux';
import FooterSpotListNav from './FooterSpotListNav.component';
import { countGameByStatus } from '../../../modules/game/Game.selectors';
import { isRoute } from '../../../urls';
import { PAGE_NAME } from '../../spots/SpotList.component';

function mapStateToProps(state) {
  const location = state.router.location;
  const { gameToStartCount, gameInProgressCount } = countGameByStatus(state);

  let page;
  if (isRoute('spotsToStart', location)) {
    page = PAGE_NAME.TO_START;
  } else if (isRoute('spotsInProgress', location)) {
    page = PAGE_NAME.IN_PROGRESS;
  } else if (isRoute('spotsAnswered', location)) {
    page = PAGE_NAME.ANSWERED;
  } else if (gameInProgressCount > 0) {
    page = PAGE_NAME.IN_PROGRESS;
  } else if (gameToStartCount > 0) {
    page = PAGE_NAME.TO_START;
  } else {
    page = PAGE_NAME.ANSWERED;
  }

  return {
    page
  };
}

export default connect(mapStateToProps)(FooterSpotListNav);
