import React from 'react';
import { AppContext } from '../../App';

class ThemeComponent extends React.PureComponent {
  render() {
    const { name, proxyParams, ...props } = this.props;

    return (
      <AppContext.Consumer>
        {({ components }) => {
          let Component = components[name];
          if (!Component) {
            return null;
          }
          if (typeof Component.componentProxy === 'function') {
            Component = Component.componentProxy(proxyParams);
          }
          if (!Component) {
            return null;
          }

          return <Component {...props} />;
        }}
      </AppContext.Consumer>
    );
  }
}

export default ThemeComponent;
