import React from 'react';

class AutumnIcon extends React.PureComponent {
  render() {
    return (
      <svg
        style={{ transform: 'rotate(180deg)' }}
        viewBox="0 0 32 32"
        width={this.props.size || 30}
        height={this.props.size || 30}
        className={this.props.className}
      >
        <path
          fill={this.props.color || 'currentColor'}
          d="M28.722 2c-5.267.033-18.075.917-23.246 8.38C2.79 14.26 2.56 19.295 4.79 25.347c.017.047.052.078.075.12-.797 1.974-1.106 3.287-1.114 3.324-.222 1.266 1.639 1.665 1.956.42.02-.102.523-.9 1.254-2.995l.012.004C8.938 20.43 9.86 16.018 14.805 11.45a1.073 1.073 0 0 1 .75-.294 1.073 1.073 0 0 1 .706 1.872c-4.458 4.117-5.243 7.88-7.156 13.573.366.038.673.077 1.182.118.703.054 1.409.08 2.114.08 3.382 0 7.326-.661 10.057-3.192 2.17-2.011 3.27-4.906 3.27-8.605 0-9.1 3.43-11.098 3.447-11.107A1.002 1.002 0 0 0 28.728 2h-.006z"
        />
      </svg>
    );
  }
}

export default AutumnIcon;
