import React from 'react';
import BadgeIcon from '../Badge.component';
import SummerTrophyIcon from '../../../icons/SummerTrophyIcon.component';
import { BADGE_LABEL_ACHIEVEMENT_SUMMER } from '../../../../../../share/constants/Badges.constants';

class BadgeAchievementSummer extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#F9D71C"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #F9D71C)"
        label={BADGE_LABEL_ACHIEVEMENT_SUMMER}
        {...this.props}
      >
        <SummerTrophyIcon color="#F9D71C" size="45" />
      </BadgeIcon>
    );
  }
}

export default BadgeAchievementSummer;
