import React from 'react';
import Modal from './Modal.container';
import RichContent from '../../spots/RichContent.container';
import { INFO_MODAL } from './Modal.constants';
import { KIDS_INFO_TYPE } from '../../../constants/spot.constants';
import withPageView from '../../../modules/ui/hoc/withPageView.hoc';

import spots from '~data/spots';
import { typography } from '../../../../../utils/basis';
import StructuredText from '../../../../../share/components/StructuredText.component';
import { IMAGE } from '../../../constants/contentType.constants';
import ThemeComponent from '../../theme/ThemeComponent.component';

class InfoModal extends React.PureComponent {
  onOpen() {
    const spot = spots[this.props.spotId];
    const pageName =
      spot.infoType === KIDS_INFO_TYPE ? 'Zupple Kids' : 'Infos culturelles';
    this.props.pageView(pageName, this.props.spotId);
  }

  render() {
    const spot = spots[this.props.spotId];

    return (
      <Modal
        modalId={INFO_MODAL}
        afterOpenModal={this.onOpen.bind(this)}
        contentLabel={
          (spot.info && spot.info.name) || spot.infoName || spot.placeName
        }
        icon="info"
      >
        {spot.info && (
          <>
            {spot.info.question && (
              <strong>{typography(spot.info.question)}</strong>
            )}
            {spot.info.image && (
              <StructuredText content={[{ type: IMAGE, ...spot.info.image }]} />
            )}
            <RichContent content={spot.info.content} />
          </>
        )}
        {!spot.info && <RichContent content={spot.place} />}
        <ThemeComponent name="InfoModalFooter" />
      </Modal>
    );
  }
}

export default withPageView(InfoModal);
