import React from 'react';

class HistoryIcon extends React.PureComponent {
  render() {
    const size = this.props.size || 24;

    return (
      <svg
        viewBox="3 3 44 44"
        width={size}
        height={size}
        className={this.props.className}
      >
        <path
          d="M25.99 6C16.04 6 8 14.06 8 24H2l7.79 7.79.14.29L18 24h-6c0-7.73 6.27-14 14-14s14 6.27 14 14-6.27 14-14 14c-3.87 0-7.36-1.58-9.89-4.11l-2.83 2.83C16.53 39.98 21.02 42 25.99 42 35.94 42 44 33.94 44 24S35.94 6 25.99 6z"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default HistoryIcon;
