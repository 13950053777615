import React from 'react';
import Modal from './Modal.container';
import Answer from '../../answer/Answer.container';
import { ANSWER_MODAL, AWARD_MODAL } from './Modal.constants';

class AnswerModal extends React.PureComponent {
  spot = null;
  malus = null;
  hasCorrectAnswer = false;
  tryCount = 0;

  onClose() {
    if (!this.spot) {
      return null;
    }

    const isGameBlocked = this.spot.maxTry && this.tryCount >= this.spot.maxTry;
    if ((this.spot && this.hasCorrectAnswer) || isGameBlocked) {
      this.props.openModal(AWARD_MODAL, {
        spot: this.spot,
        xp: this.spot.xp - this.malus,
        isGameAnswered: this.hasCorrectAnswer,
        isGameBlocked
      });
    }
  }

  setAnswer({ spot, malus, hasCorrectAnswer, tryCount }) {
    this.spot = spot;
    this.malus = malus;
    this.hasCorrectAnswer = hasCorrectAnswer;
    this.tryCount = tryCount;
  }

  render() {
    return (
      <Modal
        modalId={ANSWER_MODAL}
        contentLabel="Proposer une réponse"
        className="modal-answer"
        onClose={this.onClose.bind(this)}
        noDecorations
      >
        {(isOpen, closeModal) => (
          <Answer
            isOpen={isOpen}
            closeModal={closeModal}
            setAnswer={this.setAnswer.bind(this)}
          />
        )}
      </Modal>
    );
  }
}

export default AnswerModal;
