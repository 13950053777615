import React from 'react';
import PropTypes from 'prop-types';
import {
  findElementAt,
  getClickCoordinates
} from '../../../../common/play/components/mechanisms/mechanisms.utils';
import withPreFillAnswer from '../../../../common/play/components/mechanisms/withPrefillAnswer.hoc';

import './TimeMachine.css';

const switchesPosition = [
  { id: 0, x: 75, y: 580, width: 73, height: 92 },
  { id: 1, x: 583, y: 580, width: 73, height: 92 },
  { id: 2, x: 75, y: 677, width: 73, height: 92 },
  { id: 3, x: 583, y: 677, width: 73, height: 92 },
  { id: 4, x: 75, y: 774, width: 73, height: 92 },
  { id: 5, x: 583, y: 774, width: 73, height: 92 }
];

const slidersPosition = [
  { id: 0, value: 1, x: 140, y: 566, width: 51, height: 60 },
  { id: 0, value: 2, x: 191, y: 566, width: 51, height: 60 },
  { id: 0, value: 3, x: 242, y: 566, width: 51, height: 60 },
  { id: 0, value: 4, x: 293, y: 566, width: 51, height: 60 },
  { id: 0, value: 5, x: 344, y: 566, width: 51, height: 60 },
  { id: 0, value: 6, x: 395, y: 566, width: 51, height: 60 },
  { id: 0, value: 7, x: 446, y: 566, width: 51, height: 60 },
  { id: 0, value: 8, x: 497, y: 566, width: 51, height: 60 },
  { id: 0, value: 9, x: 548, y: 566, width: 51, height: 60 },
  { id: 1, value: 1, x: 140, y: 663, width: 51, height: 60 },
  { id: 1, value: 2, x: 191, y: 663, width: 51, height: 60 },
  { id: 1, value: 3, x: 242, y: 663, width: 51, height: 60 },
  { id: 1, value: 4, x: 293, y: 663, width: 51, height: 60 },
  { id: 1, value: 5, x: 344, y: 663, width: 51, height: 60 },
  { id: 1, value: 6, x: 395, y: 663, width: 51, height: 60 },
  { id: 1, value: 7, x: 446, y: 663, width: 51, height: 60 },
  { id: 1, value: 8, x: 497, y: 663, width: 51, height: 60 },
  { id: 1, value: 9, x: 548, y: 663, width: 51, height: 60 },
  { id: 2, value: 1, x: 140, y: 759, width: 51, height: 60 },
  { id: 2, value: 2, x: 191, y: 759, width: 51, height: 60 },
  { id: 2, value: 3, x: 242, y: 759, width: 51, height: 60 },
  { id: 2, value: 4, x: 293, y: 759, width: 51, height: 60 },
  { id: 2, value: 5, x: 344, y: 759, width: 51, height: 60 },
  { id: 2, value: 6, x: 395, y: 759, width: 51, height: 60 },
  { id: 2, value: 7, x: 446, y: 759, width: 51, height: 60 },
  { id: 2, value: 8, x: 497, y: 759, width: 51, height: 60 },
  { id: 2, value: 9, x: 548, y: 759, width: 51, height: 60 },
  { id: 3, value: 1, x: 140, y: 856, width: 51, height: 60 },
  { id: 3, value: 2, x: 191, y: 856, width: 51, height: 60 },
  { id: 3, value: 3, x: 242, y: 856, width: 51, height: 60 },
  { id: 3, value: 4, x: 293, y: 856, width: 51, height: 60 },
  { id: 3, value: 5, x: 344, y: 856, width: 51, height: 60 },
  { id: 3, value: 6, x: 395, y: 856, width: 51, height: 60 },
  { id: 3, value: 7, x: 446, y: 856, width: 51, height: 60 },
  { id: 3, value: 8, x: 497, y: 856, width: 51, height: 60 },
  { id: 3, value: 9, x: 548, y: 856, width: 51, height: 60 }
];
const buttonsPosition = [
  { id: 0, value: 'haut', x: 564, y: 98, width: 71, height: 77 },
  { id: 1, value: 'milieu', x: 564, y: 274, width: 71, height: 77 },
  { id: 2, value: 'bas', x: 564, y: 449, width: 71, height: 77 }
];
const cransPosition = [
  { id: 0, x: 91, y: 158, width: 123, height: 123 },
  { id: 1, x: 244, y: 158, width: 123, height: 123 },
  { id: 2, x: 396, y: 158, width: 123, height: 123 }
];
const dotsPosition = [
  { id: 0, x: 183, y: i => 314 + 38 * i, width: 10, height: 91 },
  { id: 1, x: 334, y: i => 314 + 38 * i, width: 10, height: 91 },
  { id: 2, x: 487, y: i => 314 + 38 * i, width: 10, height: 91 }
];

class TimeMachine extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.state = {
      loaded: false,
      switchList: [0, 0, 0, 0, 0, 0],
      sliderList: [5, 5, 5, 5],
      lastButtonId: null,
      cranList: [0, 0, 0]
    };
    this.buttonSequence = [];
    this.currentAudio = null;
    this.images = {};
    this.loadedImagesCount = 0;
    this.currentTouchSliderId = null;
    this.addImage('canvas', 733, 989);
    this.addImage('switchOn', 78, 105);
    this.addImage('switchOff', 78, 105);
    this.addImage('wagon', 69, 31);
    this.addImage('buttonOn', 71, 77);
    this.addImage('buttonOff', 71, 77);
    this.addImage('cran0', 123, 123);
    this.addImage('cran1', 123, 123);
    this.addImage('cran2', 123, 123);
    this.addImage('cran3', 123, 123);
    this.addImage('dots', 10, 91);
    this.clickHandler = this.clickHandler.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    if (this.props.enable.switches) {
      setTimeout(() => this.shareSwitchAnswer(), 0);
    }
    if (this.props.enable.sliders) {
      setTimeout(() => this.shareSliderAnswer(), 0);
    }
    if (this.props.enable.crans) {
      setTimeout(() => this.shareCranAnswer(), 0);
    }
  }

  addImage(name, width, height) {
    const image = new Image(width, height);
    image.onload = this.loadHandler.bind(this);
    image.width = width;
    image.height = height;
    image.src = '/images/time-machine/' + name + '.png';
    this.images[name] = image;
  }

  loadHandler() {
    this.loadedImagesCount++;
    if (this.loadedImagesCount === Object.keys(this.images).length) {
      this.setState(() => ({
        loaded: true
      }));
    }
  }

  drawImage(name, dx, dy) {
    const ctx = this.root.current.getContext('2d');
    ctx.drawImage(
      this.images[name],
      dx,
      dy,
      this.images[name].width,
      this.images[name].height
    );
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.clickHandler);
    this.root.current.addEventListener('touchstart', this.onTouchStart);
    this.root.current.addEventListener('touchmove', this.onTouchMove);
    this.root.current.addEventListener('touchend', this.onTouchEnd);
  }

  componentWillUnmount() {
    this.root.current.removeEventListener('click', this.clickHandler);
    this.root.current.removeEventListener('touchstart', this.onTouchStart);
    this.root.current.removeEventListener('touchmove', this.onTouchMove);
    this.root.current.removeEventListener('touchend', this.onTouchEnd);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAnswerModalOpen) {
      this.buttonSequence = [];
    }
    if (!this.state.loaded) {
      return false;
    }
    const canvas = this.root.current;
    canvas.width = this.images.canvas.naturalWidth;
    canvas.height = this.images.canvas.naturalHeight;

    this.drawImage('canvas', 0, 0);
    switchesPosition.forEach(position => {
      const state = this.state.switchList[position.id]
        ? 'switchOn'
        : 'switchOff';
      this.drawImage(state, position.x, position.y);
    });
    [0, 1, 2, 3].forEach(id => {
      const position = slidersPosition.find(
        sliderItem =>
          sliderItem.id === id && sliderItem.value === this.state.sliderList[id]
      );
      if (!position) {
        return false;
      }
      this.drawImage('wagon', position.x - 10, position.y);
    });
    buttonsPosition.forEach(position => {
      const state =
        this.state.lastButtonId === position.id ? 'buttonOn' : 'buttonOff';
      this.drawImage(state, position.x, position.y);
    });
    cransPosition.forEach(position => {
      const state = this.state.cranList[position.id];
      this.drawImage(`cran${state}`, position.x, position.y);
    });
    dotsPosition.forEach(position => {
      const y = position.y(this.state.cranList[position.id]);
      this.drawImage(`dots`, position.x, y);
    });
  }

  getEventSwitch(event) {
    if (!this.props.enable.switches) {
      return false;
    }
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.images.canvas.width
    );
    return findElementAt(switchesPosition, x, y);
  }

  getEventSlider(event) {
    if (!this.props.enable.sliders) {
      return false;
    }
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.images.canvas.width
    );

    return findElementAt(slidersPosition, x, y);
  }

  getEventButton(event) {
    if (!this.props.enable.buttons) {
      return false;
    }
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.images.canvas.width
    );

    return findElementAt(buttonsPosition, x, y);
  }

  getEventCran(event) {
    if (!this.props.enable.crans) {
      return false;
    }
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.images.canvas.width
    );

    return findElementAt(cransPosition, x, y);
  }

  clickHandler(event) {
    const currentSwitch = this.getEventSwitch(event);
    if (currentSwitch) {
      this.toggleSwitch(currentSwitch.id);
    }

    const currentSlider = this.getEventSlider(event);
    if (currentSlider) {
      this.moveSlider(currentSlider.id, currentSlider.value);
    }

    const currentButton = this.getEventButton(event);
    if (currentButton) {
      this.clickButton(currentButton.id);
    }

    const currentCran = this.getEventCran(event);
    if (currentCran) {
      this.moveCran(currentCran.id);
    }
  }

  onTouchStart(event) {
    const currentSlider = this.getEventSlider(event);
    if (currentSlider) {
      this.currentTouchSliderId = currentSlider.id;
    }
  }

  onTouchMove(event) {
    if (this.currentTouchSliderId === null) {
      return false;
    }
    const currentSlider = this.getEventSlider(event);
    if (currentSlider && this.currentTouchSliderId === currentSlider.id) {
      this.moveSlider(currentSlider.id, currentSlider.value);
    }
  }

  onTouchEnd() {
    this.currentTouchSliderId = null;
  }

  toggleSwitch(id) {
    this.setState(state => {
      const switchList = Array.from(state.switchList);
      switchList[id] = switchList[id] ? 0 : 1;
      this.shareSwitchAnswer(switchList);

      // Sound
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.currentAudio = new Audio('/mp3/switch.mp3');
      this.currentAudio.play();

      return {
        switchList
      };
    });
  }

  moveSlider(id, value) {
    this.setState(state => {
      const sliderList = Array.from(state.sliderList);
      if (sliderList[id] === value) {
        return false;
      }
      sliderList[id] = value;
      this.shareSliderAnswer(sliderList);

      // Sound
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.currentAudio = new Audio('/mp3/slider.mp3');
      this.currentAudio.play();

      return {
        sliderList
      };
    });
  }

  clickButton(id) {
    // Wa cannot press a pressed button
    if (this.state.lastButtonId === id) {
      return false;
    }

    // Update sequence
    this.buttonSequence.push(buttonsPosition[id].value);
    this.buttonSequence = this.buttonSequence.slice(-4);
    this.shareButtonAnswer();
    this.setState(() => ({
      lastButtonId: id
    }));

    // Pop after 300ms
    setTimeout(() => {
      this.setState(() => ({
        lastButtonId: null
      }));
    }, 800);

    // Sound
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio = null;
    }
    this.currentAudio = new Audio('/mp3/button.mp3');
    this.currentAudio.play();
  }

  moveCran(id) {
    this.setState(state => {
      const cranList = Array.from(state.cranList);
      cranList[id] = (cranList[id] + 1) % 4;
      this.shareCranAnswer(cranList);

      // Sound
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.currentAudio = new Audio('/mp3/cran.mp3');
      this.currentAudio.play();

      return {
        cranList
      };
    });
  }

  shareSwitchAnswer(state = this.state.switchList) {
    state = state.map(switchItem => (switchItem ? 'haut' : 'bas  '));
    const answer = `${state[0]}     ${state[1]}
${state[2]}     ${state[3]}
${state[4]}     ${state[5]}`;
    this.props.prefillAnswer(answer);
  }

  shareSliderAnswer(state = this.state.sliderList) {
    this.props.prefillAnswer(state.join(''));
  }

  shareButtonAnswer(state = this.buttonSequence) {
    this.props.prefillAnswer(state.join(' '));
  }

  shareCranAnswer(state = this.state.cranList) {
    const values = [[3, 2, 6, 8], [0, 4, 7, 1], [2, 6, 8, 9]];
    this.props.prefillAnswer(
      state.map((id, index) => values[index][id]).join('')
    );
  }

  render() {
    return (
      <div className="c-time-machine">
        <canvas className="c-time-machine__canvas" ref={this.root} />
      </div>
    );
  }
}

TimeMachine.propTypes = {
  props: PropTypes.shape({
    isAnswerModalOpen: PropTypes.bool,
    enable: PropTypes.shape({
      switches: PropTypes.bool,
      sliders: PropTypes.bool,
      buttons: PropTypes.bool,
      crans: PropTypes.bool
    }),
    prefillAnswer: PropTypes.func
  })
};

export default withPreFillAnswer(TimeMachine);
