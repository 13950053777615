import React from 'react';

class WaitIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 47 47"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <path
          d="M22.432 22.429a1.067 1.067 0 1 0 2.136 0 5.321 5.321 0 0 1 1.251-3.421c1.793-2.126 3.137-4.431 3.988-6.851a1.069 1.069 0 0 0-1.007-1.423H18.199a1.069 1.069 0 0 0-1.008 1.423c.853 2.42 2.195 4.725 3.988 6.851a5.32 5.32 0 0 1 1.253 3.421zM24.568 26.71a1.068 1.068 0 0 0-2.136 0c-.001 1.542-.922 3.067-2.593 4.304-3.574 2.639-6.249 5.506-7.951 8.52a1.06 1.06 0 0 0 .009 1.062c.19.329.542.53.922.53h21.364a1.067 1.067 0 0 0 .93-1.592c-1.701-3.014-4.377-5.881-7.95-8.52-1.673-1.237-2.594-2.762-2.595-4.304z"
          fill={this.props.color || 'currentColor'}
        />
        <path
          d="M42.192 42.729h-.639c-.734-8.313-5.602-14.695-9.386-19.228 3.785-4.532 8.651-10.915 9.388-19.228h.639a2.136 2.136 0 0 0 0-4.273H4.807a2.136 2.136 0 0 0 0 4.273h.639c.735 8.314 5.601 14.697 9.386 19.228-3.784 4.532-8.651 10.914-9.387 19.228h-.638a2.137 2.137 0 0 0 0 4.273H42.194a2.137 2.137 0 1 0-.002-4.273zm-32.464 0c.803-7.511 5.686-13.295 9.335-17.617l.195-.231a2.134 2.134 0 0 0 0-2.755l-.194-.23c-3.648-4.323-8.533-10.107-9.336-17.619h27.544c-.803 7.512-5.688 13.296-9.336 17.619l-.193.23a2.134 2.134 0 0 0 0 2.755l.195.231c3.648 4.322 8.531 10.106 9.334 17.617H9.728z"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default WaitIcon;
