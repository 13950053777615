import React from 'react';
import { AppContext } from '../../App';

function withThemeFeatures(Component) {
  return function withThemeFeaturesHoc(props) {
    return (
      <AppContext.Consumer>
        {({ features }) => <Component {...props} themeFeatures={features} />}
      </AppContext.Consumer>
    );
  };
}

export default withThemeFeatures;
