import React from 'react';

class AnswerIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 24 24"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <path
          d="M15.6568542,12 L8.58578644,4.92893219 C7.80473785,4.1478836 7.80473785,2.88155365 8.58578644,2.10050506 C9.36683502,1.31945648 10.633165,1.31945648 11.4142136,2.10050506 L19.8994949,10.5857864 C20.6805435,11.366835 20.6805435,12.633165 19.8994949,13.4142136 L11.4142136,21.8994949 C10.633165,22.6805435 9.36683502,22.6805435 8.58578644,21.8994949 C7.80473785,21.1184464 7.80473785,19.8521164 8.58578644,19.0710678 L15.6568542,12 Z"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default AnswerIcon;
