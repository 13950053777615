import { connect } from 'react-redux';
import AboutPage from './AboutPage.component';
import { getIsAppUpToDate } from '../modules/app/App.selectors';

function mapStateToProps(state) {
  return {
    isAppUpToDate: getIsAppUpToDate(state)
  };
}

export default connect(mapStateToProps)(AboutPage);
