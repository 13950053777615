import React from 'react';
import { AppContext } from '../../App';

function withThemeData(Component) {
  return function withThemeDataHoc(props) {
    return (
      <AppContext.Consumer>
        {({ data }) => <Component {...props} themeData={data} />}
      </AppContext.Consumer>
    );
  };
}

export default withThemeData;
