import React from 'react';
import BadgeIcon from '../Badge.component';
import SummerIcon from '../../../icons/SummerIcon.component';
import { BADGE_LABEL_SEASON_SUMMER } from '../../../../../../share/constants/Badges.constants';

class BadgeSeasonSummer extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#F9D71C"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #F9D71C)"
        label={BADGE_LABEL_SEASON_SUMMER}
        {...this.props}
      >
        <SummerIcon color="#F9D71C" />
      </BadgeIcon>
    );
  }
}

export default BadgeSeasonSummer;
