import { UPDATE_REACH_DISTANCE } from './Options.constants';
import { getReachDistance } from './Options.selectors';

export function updateReachDistance(distance) {
  return function(dispatch, getState) {
    if (distance < getReachDistance(getState())) {
      return null;
    }

    dispatch({
      type: UPDATE_REACH_DISTANCE,
      distance
    });
  };
}
