export const getPlayerPosition = state => state.geolocation.position;

export const hasPlayerPosition = state =>
  state.geolocation.position.latitude !== 0;

export const getPlayerOrientation = state => {
  const orientation = state.geolocation.orientation;
  if (orientation.isAbsoluteSensor) {
    return orientation.relative;
  }

  return orientation.validatedNorth + orientation.relative;
};

export const hasPlayerOrientation = state => {
  const orientation = state.geolocation.orientation;
  if (orientation.isAbsoluteSensor) {
    return true;
  }
  return orientation.validatedNorth !== null && orientation.relative !== null;
};

export const getRelativeOrientation = state =>
  state.geolocation.orientation.relative;

export const getGeolocationError = state => state.geolocation.error;

export const getGeolocationPermission = state => state.geolocation.permission;

export const getGeolocationStatus = state => state.geolocation.status;

export const getIsGeolocationApp = state => state.geolocation.isGeolocationApp;
