import React from 'react';
import { Link } from 'react-router-dom';
import { to } from '../../../urls';
import { diffPropValue, typography } from '../../../../../utils/basis';
import { history } from '../../../modules/createStore';

import Modal from './Modal.container';
import { STORY_INTRO_MODAL } from './Modal.constants';
import { ModalHoc } from './Modal.hoc';
import WarningIcon from '../../icons/WarningIcon.component';
import ThemeLabel from '../../theme/ThemeLabel.component';
import RichContent from '../../spots/RichContent.container';
import { isStoryTeaser } from '../../../modules/story/Story.functions';

import stories from '~data/stories';

class StoryIntroModal extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'onClose')) {
      this.onCloseHandler = this.props.onClose;
    }
  }

  onClick() {
    this.props.playStory(this.props.storyId);
    history.push('/');
  }

  renderWarning() {
    const story = stories[this.props.storyId];

    if (story.warning && !this.props.isCompleted) {
      return (
        <div className="c-modal-story__warning">
          <WarningIcon color="#FC388C" />
          <div>{typography(story.warning)}</div>
        </div>
      );
    }
  }

  renderButton() {
    const { storyId, user, isConnected, isCompleted } = this.props;
    const story = stories[storyId];

    if (!isConnected) {
      return (
        <Link to={to('subscribe')} className="o-button c-modal-story__button">
          S'inscrire !
        </Link>
      );
    } else if (isStoryTeaser(story, user)) {
      return (
        <Link to={to('stories')} className="o-button c-modal-story__button">
          Voir les autres aventures
        </Link>
      );
    } else if (!isCompleted) {
      return (
        <button
          onClick={this.onClick.bind(this)}
          className="o-button c-modal-story__button"
        >
          Voir <ThemeLabel name="spot.thePlural" default="les énigmes" />
        </button>
      );
    } else {
      return (
        <button
          onClick={this.onClick.bind(this)}
          className="o-button c-modal-story__button"
        >
          Revoir <ThemeLabel name="spot.thePlural" default="les énigmes" />
        </button>
      );
    }
  }

  onClose() {
    if (this.onCloseHandler) {
      this.onCloseHandler();
    }
  }

  render() {
    const story = stories[this.props.storyId];
    if (!story) {
      return '';
    }

    return (
      <Modal
        modalId={STORY_INTRO_MODAL}
        universeId={story.universeId}
        story={story}
        className={
          this.props.isCompleted ? 'c-modal-story-completed' : 'c-modal-story'
        }
        distance={this.props.distance}
        showStoryPreview={true}
        renderFooter={() => this.renderButton()}
        title={story.title}
        hideCross={this.props.hideCross}
        onClose={this.onClose.bind(this)}
      >
        <img src={story.logo} className="c-modal-story__image" alt="" />
        <div className="c-modal-story__pitch">
          <RichContent content={story.pitch} />
        </div>
        {this.renderWarning()}
      </Modal>
    );
  }
}

export default ModalHoc(StoryIntroModal);
