import React, { Fragment } from 'react';
import { encrypt } from '../../../../utils/basis';
import { displayHourMinuteDate } from '../../utils/date';
import { fetchApi } from '../../modules/api/Api.functions';
import version from '../../../../version';
import stripeConf from '~data/stripe';
import { Link } from 'react-router-dom';
import { to } from '../../urls';

import './AccountEdit.scss';

class AccountEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      badPassword: true,
      badPasswordMessage: false,
      updateError: false,
      updating: false,
      updateDone: false,
      passwordValue: '',
      passwordConfirmValue: ''
    };
  }

  onClick(product) {
    this.props.activateProduct(product.durationId);
  }

  updatePassword(e) {
    const userId = this.props.userId;
    e.preventDefault();
    if (!this.isGoodPassword()) {
      this.setState(() => {
        return {
          badPasswordMessage: true,
          badPassword: true
        };
      });
      return false;
    }
    this.setState(() => {
      return {
        updating: true
      };
    });
    const password = encrypt(document.forms.passwordEdit.password.value);
    fetchApi('/api/user/password/update', {
      userId,
      password,
      version,
      token: this.props.token
    })
      .then(([{ result }]) => {
        if (result.error) {
          this.setState(() => {
            return {
              updateError: true,
              updating: false
            };
          });
          return false;
        } else {
          this.setState(() => {
            return {
              updateError: false,
              updating: false,
              updateDone: true,
              passwordValue: '',
              passwordConfirmValue: ''
            };
          });
          return true;
        }
      })
      .catch(e => {
        this.setState(() => {
          return {
            updateError: true,
            updating: false
          };
        });
      });
    return true;
  }

  isGoodPassword() {
    this.setState(() => {
      return {
        badPasswordMessage: false,
        passwordValue: document.forms.passwordEdit.password.value,
        passwordConfirmValue: document.forms.passwordEdit.confirmPassword.value
      };
    });
    if (
      document.forms.passwordEdit.password.value ===
      document.forms.passwordEdit.confirmPassword.value
    ) {
      this.setState(() => {
        return {
          badPassword: false
        };
      });
      return true;
    } else {
      this.setState(() => {
        return {
          badPassword: true
        };
      });
      return false;
    }
  }

  renderExpirationDate() {
    const email = this.props.email;
    if (this.props.isAdmin || this.props.isOurGuest) {
      return <div className="c-account-edit__sub-title">Accès illimité</div>;
    }
    if (this.props.isFree) {
      if (this.props.pendingProduct) {
        const product = stripeConf.products[this.props.pendingProduct];

        return (
          <div>
            <button
              className="o-button"
              onClick={this.onClick.bind(this, product)}
            >
              Activer mon accès {product.duration} {product.durationUnit}
            </button>
          </div>
        );
      }
      return (
        <div>
          <Link
            to={{
              pathname: to('payment'),
              state: {
                email: email,
                fromApp: true
              }
            }}
          >
            <button className="o-button">Choisir mon temps d'accès</button>
          </Link>
        </div>
      );
    }
    if (this.props.isExpired) {
      if (this.props.pendingProduct) {
        const product = stripeConf.products[this.props.pendingProduct];

        return (
          <Fragment>
            <div className="c-account-edit__sub-title">
              Accès expiré depuis le :{' '}
              {displayHourMinuteDate(new Date(this.props.expirationDate))}
            </div>
            <div>
              <button
                className="o-button"
                onClick={this.onClick.bind(this, product)}
              >
                Activer mon accès {product.duration} {product.durationUnit}
              </button>
            </div>
          </Fragment>
        );
      }
      return (
        <Fragment>
          <div className="c-account-edit__sub-title">
            Accès expiré depuis le :{' '}
            {displayHourMinuteDate(new Date(this.props.expirationDate))}
          </div>
          <div>
            <Link
              to={{
                pathname: to('payment'),
                state: {
                  email: email,
                  fromApp: true
                }
              }}
            >
              <button className="o-button">
                Choisir mon nouveau temps d'accès
              </button>
            </Link>
          </div>
        </Fragment>
      );
    }
    if (
      this.props.expirationDate === null ||
      this.props.expirationDate === 'undefined'
    ) {
      return null;
    }
    return (
      <div className="c-account-edit__sub-title">
        Accès disponible jusqu'au :{' '}
        {displayHourMinuteDate(new Date(this.props.expirationDate))}
      </div>
    );
  }

  render() {
    return (
      <div className="c-account-edit">
        <h1 className="c-account-edit__title">{this.props.firstName}</h1>
        {this.renderExpirationDate()}
        <h2 className="c-account-edit__chapter">Modifier mon mot de passe</h2>
        <form name="passwordEdit">
          {this.state.updateError && (
            <div className="c-account-edit__error">
              Erreur lors de la modification du mot de passe.
            </div>
          )}
          {this.state.badPasswordMessage && (
            <div className="c-account-edit__error">
              Les mots de passe ne correspondent pas.
            </div>
          )}
          {this.state.updateDone && (
            <div className="c-account-edit__error">
              Votre mot de passe a bien été modifié.
            </div>
          )}
          <input
            className="c-account-edit__input"
            name="password"
            type="password"
            placeholder="Nouveau mot de passe"
            autoComplete="new-password"
            value={this.state.passwordValue}
            onChange={this.isGoodPassword.bind(this)}
          />
          <input
            className="c-account-edit__input"
            name="confirmPassword"
            type="password"
            placeholder="Confirmation de mot de passe"
            value={this.state.passwordConfirmValue}
            onChange={this.isGoodPassword.bind(this)}
            autoComplete="new-password"
          />
          <button
            className={
              this.state.badPassword
                ? 'c-account-edit__button-disabled o-button'
                : 'o-button'
            }
            onClick={this.updatePassword.bind(this)}
            type="submit"
          >
            Valider
          </button>
        </form>
      </div>
    );
  }
}

export default AccountEdit;
