import { connect } from 'react-redux';
import AnswerModal from './AnswerModal.component';
import { openModal } from '../../../modules/ui/Ui.actions';

const mapDispatchToProps = {
  openModal
};

export default connect(
  null,
  mapDispatchToProps
)(AnswerModal);
