import React from 'react';

import color from '~styles/variables/colors.scss';
import './Rule.scss';

class RankRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="classement">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Devenir le meilleur</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <div
                className="o-laurel"
                style={{
                  color: color.main
                }}
              >
                1
              </div>
            </div>
            <div className="c-rule__text">
              Cumulez des XP à chaque énigme résolue, et vous deviendrez
              peut-être le meilleur chasseur d'énigmes&nbsp;!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RankRule;
