import global from '~data/global.js';

export default function urlsCreator(urls) {
  function defaultConverter(...params) {
    return params;
  }

  function getUrl(name) {
    let url = urls[name];
    if (!url) {
      throw new Error('Url not found for: ' + name);
    }
    if (typeof url === 'string') {
      url = {
        path: url
      };
    }

    return {
      converter: defaultConverter,
      ...url,
      name
    };
  }

  function getPath(name) {
    return getUrl(name).path;
  }

  function getRegExp(name) {
    return new RegExp(
      '^' + getPath(name).replace(/:[a-zA-Z]+/g, '([^/]*)') + '$'
    );
  }

  function isRoute(name, location) {
    return getRegExp(name).test(location.pathname);
  }

  function someRoute(names, location) {
    return names.some(name => isRoute(name, location));
  }

  function to(name, ...params) {
    const converter = getUrl(name).converter;
    params = converter.apply(null, params);

    return Object.keys(params).reduce(
      (url, param) => url.replace(new RegExp(`:${param}`), params[param]),
      getPath(name)
    );
  }

  function absoluteTo(name, ...params) {
    const relativeTo = to(name, ...params);
    return global.domain + relativeTo;
  }

  function getRoute(location) {
    let route = null;

    Object.keys(urls).find(urlName => {
      if (isRoute(urlName, location)) {
        route = urlName;
        return true;
      }

      return false;
    });

    return route;
  }

  function getRouteParams(location) {
    const name = getRoute(location);
    if (!name) {
      return {};
    }
    const params = getRegExp(name)
      .exec(location.pathname)
      .slice(1);

    return params.join('/');
  }

  return {
    getPath,
    isRoute,
    someRoute,
    to,
    getRouteParams,
    absoluteTo
  };
}
