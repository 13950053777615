import { UPDATE_RANKS } from './Rank.constants';
import { groupBy, objectMap } from '../../../../utils/basis';

const initialState = {
  all: {},
  byRank: {},
  topValues: []
};

const RankReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RANKS:
      const all = {
        ...state.all,
        ...action.ranks
      };
      const byRank = objectMap(groupBy(action.ranks, 'rank'), list => {
        return list.map(item => item.index);
      });
      const topValues = Object.keys(byRank)
        .map(item => parseInt(item, 10))
        .sort((a, b) => a - b)
        .slice(0, 4);

      return {
        ...state,
        all,
        byRank,
        topValues
      };

    default:
      return state;
  }
};

export default RankReducer;
