import React from 'react';
import { findElementAt, getClickCoordinates } from './mechanisms.utils';

import './ZoomableImage.scss';

function minmax(min, value, max) {
  return value > max ? max : value < min ? min : value;
}

class ZoomableImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      sourceX: 0,
      sourceY: 0,
      zoom: 1
    };
    this.root = React.createRef();
    this.image = new Image(props.width, props.height);
    this.image.onload = this.loadHandler.bind(this);
    this.image.src = props.content;
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.eventHandler);
  }

  componentWillUnmount() {
    this.root.current.removeEventListener('click', this.eventHandler);
  }

  componentDidUpdate() {
    if (this.state.loaded) {
      const canvas = this.root.current;
      canvas.width = this.image.naturalWidth;
      canvas.height = this.image.naturalHeight;
      const ctx = this.root.current.getContext('2d');
      ctx.drawImage(
        this.image,
        this.state.sourceX,
        this.state.sourceY,
        this.getScreenWidth(),
        this.getScreenHeight(),
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
  }

  loadHandler() {
    this.setState(() => ({
      loaded: true
    }));
  }

  getScreenWidth(zoom = this.state.zoom) {
    return this.props.width / Math.pow(2, zoom - 1);
  }

  getScreenHeight(zoom = this.state.zoom) {
    return this.props.height / Math.pow(2, zoom - 1);
  }

  eventHandler(event) {
    const canvas = this.root.current;
    const screenX = this.getScreenWidth();
    const screenY = this.getScreenHeight();
    const { x, y } = getClickCoordinates(event, canvas, screenX);
    const { zoom, sourceX, sourceY } = this.state;
    if (zoom >= this.props.maxZoom) {
      this.setState(() => ({
        sourceX: 0,
        sourceY: 0,
        zoom: 1
      }));

      if (this.props.onSelection) {
        const element = findElementAt(
          this.props.elements,
          sourceX + x,
          sourceY + y
        );
        this.props.onSelection(sourceX + x, sourceY + y, element);
      }

      return true;
    }

    const centerX = sourceX + screenX / 2;
    const centerY = sourceY + screenY / 2;
    const newCenterX = (1.5 * (sourceX + x) + centerX) / 2.5;
    const newCenterY = (1.5 * (sourceY + y) + centerY) / 2.5;
    this.redraw(newCenterX, newCenterY, zoom + 1);
  }

  onUnZoom(event) {
    if (event) {
      event.preventDefault();
    }
    if (this.state.zoom <= 1) {
      return false;
    }
    const screenWidth = this.getScreenWidth();
    const screenHeight = this.getScreenHeight();
    const centerX = this.state.sourceX + screenWidth / 2;
    const centerY = this.state.sourceY + screenHeight / 2;
    this.redraw(centerX, centerY, this.state.zoom - 1);
  }

  redraw(centerX, centerY, zoom) {
    const nextScreenWidth = this.getScreenWidth(zoom);
    const nextScreenHeight = this.getScreenHeight(zoom);
    const x = centerX - nextScreenWidth / 2;
    const y = centerY - nextScreenHeight / 2;
    const maxX = this.props.width - nextScreenWidth;
    const maxY = this.props.height - nextScreenHeight;
    this.setState(() => ({
      sourceX: minmax(0, x, maxX),
      sourceY: minmax(0, y, maxY),
      zoom
    }));
  }

  render() {
    return (
      <div className="c-zoomable-image">
        <canvas className="c-zoomable-image__canvas" ref={this.root} />
        {this.props.children &&
          this.props.children({
            zoom: this.state.zoom,
            onUnZoom: this.onUnZoom.bind(this)
          })}
        {(this.props.download || this.props.unzoom) && (
          <div className="c-zoomable-image__action">
            {this.props.download && this.state.zoom === 1 && (
              <>
                <em style={{ color: '#999', fontSize: '12px' }}>
                  Vous pouvez cliquer pour zoomer, ou{' '}
                  <a
                    className="c-zoomable-image__download-link"
                    href={this.props.content}
                    download
                  >
                    téléchargez l'image.
                  </a>
                </em>
              </>
            )}
            {this.props.unzoom && this.state.zoom > 1 && (
              <button
                className="c-zoomable-image__unzoom-link"
                onClick={this.onUnZoom.bind(this)}
              >
                Retour
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ZoomableImage;
