import { connect } from 'react-redux';
import SpotPreview from './SpotPreview.component';
import {
  isGameStarted,
  getMalus,
  isGameAnswered,
  getGameEndDate,
  getNextClueIndex,
  getGameTimeBySpotId,
  isGameBlocked
} from '../../modules/game/Game.selectors';
import {
  isAdmin,
  isOurGuest,
  isFree,
  isExpired,
  getPendingProduct,
  getEmail,
  getUser
} from '../../modules/auth/Auth.selectors';
import { getReachDistance } from '../../modules/options/Options.selectors';
import { getCurrentStoryId } from '../../modules/story/Story.selectors';
import { setHighlightedSpot } from '../../modules/map/Map.actions';
import { activateProduct } from '../../modules/auth/Auth.actions';
import { getIsGeolocationApp } from '../../modules/geolocation/Geolocation.selectors';

function mapStateToProps(state, ownProps) {
  const spotId = ownProps.spot.id;

  return {
    isGeolocationApp: getIsGeolocationApp(state),
    isGameStarted: isGameStarted(state, spotId),
    isGameAnswered: isGameAnswered(state, spotId),
    isGameBlocked: isGameBlocked(state, spotId),
    nextClueIndex: getNextClueIndex(state, spotId),
    gameEndDate: getGameEndDate(state, spotId),
    gameTime: getGameTimeBySpotId(state, spotId),
    malus: getMalus(state, spotId),
    userIsAdmin: isAdmin(state),
    reachDistance: getReachDistance(state),
    storyId: getCurrentStoryId(state),
    isFree: isFree(state),
    isOurGuest: isOurGuest(state),
    isExpired: isExpired(state),
    pendingProduct: getPendingProduct(state),
    email: getEmail(state),
    user: getUser(state)
  };
}

const mapDispatchToProps = {
  setHighlightedSpot,
  activateProduct
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpotPreview);
