import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_XP_500 } from '../../../../../../share/constants/Badges.constants';

class BadgeXP500 extends React.PureComponent {
  render() {
    return (
      <BadgeIcon color="#cc6633" label={BADGE_LABEL_XP_500} {...this.props}>
        <div style={{ fontSize: '15px', lineHeight: '18px' }}>500</div>
        <div style={{ fontSize: '10px', lineHeight: '10px' }}> XP</div>
      </BadgeIcon>
    );
  }
}

export default BadgeXP500;
