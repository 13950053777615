import React from 'react';
import { renderFor } from '../../../../utils/render';
import { getInputDefaultValue, updateValue } from './localStorage.utils';

import './HiddenWordsGrid.scss';

class HiddenWordsGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.isFirstCellWasChecked = false;
  }

  componentDidMount() {
    this.hideBlackCell();
    this.initToggle();
  }

  componentDidUpdate() {
    this.hideBlackCell();
  }

  initToggle() {
    Array.from(
      this.root.current.querySelectorAll('.c-hidden-words-grid__cell')
    ).forEach(cell => {
      if (getInputDefaultValue(this.props.mechanismId, cell.id) === '1') {
        this.toggleCell(cell);
      }
    });
  }

  hideBlackCell() {
    if (this.props.hidden && this.root.current) {
      this.props.hidden.forEach(item => {
        let element = this.root.current.querySelector(
          '#cell-' + item[0] + '-' + item[1]
        );
        if (!element) {
          return false;
        }
        element.classList.add('c-hidden-words-grid__cell--hidden-always');
      });
    }
  }

  onTouchStart(event) {
    const cell = this.getCellFromElement(event.target);
    if (!cell) {
      return false;
    }
    this.isFirstCellWasChecked = this.getIsChecked(cell);
    this.toggleCell(cell);
  }

  onTouchMove(event) {
    const coords = event.touches[0];
    const element = document.elementFromPoint(coords.pageX, coords.pageY);

    // Only trigger cell toggling on letter to allow diagonal selection
    if (!element.classList.contains('c-hidden-words-grid__letter')) {
      return null;
    }
    const cell = element.parentNode;

    // Avoid multiple events and avoid to alternate check / uncheck between cells
    if (this.getIsTouchTriggerAToggle(cell)) {
      this.toggleCell(cell);
    }
  }

  onTouchEnd(event) {
    // Avoid click event emulation
    event.preventDefault();
  }

  onClick(event) {
    const cell = this.getCellFromElement(event.target);
    if (!cell) {
      return false;
    }
    this.toggleCell(cell);
  }

  getIsCell(element) {
    return element.classList.contains('c-hidden-words-grid__cell');
  }

  getCellFromElement(element) {
    if (this.getIsCell(element)) {
      return element;
    }
    if (this.getIsCell(element.parentNode)) {
      return element.parentNode;
    }

    return false;
  }

  getIsChecked(cell) {
    return cell.classList.contains('c-hidden-words-grid__cell--hidden');
  }

  getIsTouchTriggerAToggle(cell) {
    return this.getIsChecked(cell) === this.isFirstCellWasChecked;
  }

  toggleCell(cell) {
    if (this.getIsChecked(cell)) {
      cell.classList.remove('c-hidden-words-grid__cell--hidden');
      updateValue(this.props.mechanismId, cell.id, 0);
      if (this.props.hiddenStyles) {
        cell.removeAttribute('style');
      }
    } else {
      cell.classList.add('c-hidden-words-grid__cell--hidden');
      updateValue(this.props.mechanismId, cell.id, 1);
      if (this.props.hiddenStyles) {
        cell.setAttribute('style', this.props.hiddenStyles);
      }
    }
  }

  renderCell(lineIndex, cellIndex) {
    const charIndex = lineIndex * this.props.width + cellIndex;

    return (
      <td
        className="c-hidden-words-grid__cell"
        onClick={this.onClick.bind(this)}
        key={`cell-${charIndex}`}
        id={`cell-${lineIndex}-${cellIndex}`}
      >
        <span className="c-hidden-words-grid__letter">
          {this.props.content.charAt(charIndex)}
        </span>
      </td>
    );
  }

  renderLine(index) {
    return (
      <tr key={`line-${index}`}>
        {renderFor(this.props.width, cellIndex =>
          this.renderCell(index, cellIndex)
        )}
      </tr>
    );
  }

  render() {
    return (
      <table
        ref={this.root}
        className="c-hidden-words-grid"
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchMove={this.onTouchMove.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
      >
        <tbody>
          {renderFor(this.props.height, index => this.renderLine(index))}
        </tbody>
      </table>
    );
  }
}

export default HiddenWordsGrid;
