import React from 'react';

class ForGroups extends React.PureComponent {
  render() {
    if (!this.props.isInOneGroup) {
      return null;
    }

    return this.props.children();
  }
}

export default ForGroups;
