import { connect } from 'react-redux';
import AccountEdit from './AccountEdit.component';
import {
  getUserId,
  getAuthToken,
  getFirstName,
  isFree,
  isExpired,
  getPendingProduct,
  isAdmin,
  isOurGuest,
  getExpirationDate,
  getEmail
} from '../../modules/auth/Auth.selectors';
import { activateProduct } from '../../modules/auth/Auth.actions';

function mapStateToProps(state) {
  return {
    userId: getUserId(state),
    token: getAuthToken(state),
    firstName: getFirstName(state),
    isFree: isFree(state),
    isAdmin: isAdmin(state),
    pendingProduct: getPendingProduct(state),
    email: getEmail(state),
    isOurGuest: isOurGuest(state),
    isExpired: isExpired(state),
    expirationDate: getExpirationDate(state)
  };
}

const mapDispatchToProps = {
  activateProduct
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountEdit);
