import { NOTIFICATION_UNREAD_STATUS } from './Notification.constants';

export function getAllNotifications(state) {
  return state.notification.all;
}

export function hasUnreadNotification(state) {
  return !!state.notification.byStatus[NOTIFICATION_UNREAD_STATUS].length;
}

export function getLastUnreadNotificationIdByType(state, type) {
  return state.notification.lastByType[type];
}

export function getLastUnreadNotificationByType(state, type) {
  const id = getLastUnreadNotificationIdByType(state, type);

  return id && getNotificationById(state, id);
}

export function getNotificationById(state, notifId) {
  return state.notification.all[notifId];
}
