import React from 'react';

class WarningIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 24 24"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <g
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="2"
          strokeLinejoin="round"
        >
          <path d="M1,21 L12,3 L23,21z" />
          <path d="M12,9 L12,15" />
        </g>
        <circle
          cx="12"
          cy="17"
          r="1"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default WarningIcon;
