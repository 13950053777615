import urlsCreator from './utils/urlsCreator.utils';

const urls = {
  home: '/',
  spot: {
    path: '/enigme/:id/',
    converter: spot => ({ id: spot.id })
  },
  spotsToStart: '/enigmes/à-faire/',
  spotsInProgress: '/enigmes/en-cours/',
  spotsAnswered: '/enigmes/finies/',
  stories: '/parcours/',
  storyIntro: {
    path: '/parcours/:id',
    converter: story => ({ id: story.id })
  },
  trophies: '/trophées/',
  messagesList: '/messages/',
  messageDetail: {
    path: '/message/:id',
    converter: message => ({ id: message._id })
  },
  account: '/mon-compte/',
  rank: '/classement/',
  accountEdit: '/edition-compte/',
  rules: '/regles/',
  about: '/a-propos/',
  subscribe: '/inscription/',
  subscribeSuccess: '/bienvenue/',
  payment: '/choix-acces',
  autologin: '/autologin'
};

export const {
  getPath,
  isRoute,
  someRoute,
  to,
  getRouteParams,
  absoluteTo
} = urlsCreator(urls);
