import React from 'react';

import colors from '~styles/variables/colors.scss';

class TakeAwayIcon extends React.PureComponent {
  render() {
    const prefix = this.props.prefix || 'icon';

    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.998%"
            x2="99.994%"
            y2="49.998%"
            id={`${prefix}-take-away`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.activeTo} offset="100%" />
          </linearGradient>
        </defs>
        <g
          fill="none"
          stroke={`url(#${prefix}-take-away)`}
          strokeWidth="1"
          strokeLinejoin="round"
        >
          <path d="M3,18 L17,18 L17,11 C17,6 15,3 10,3 C5,3 3,6 3,11z M6,18 L14,18 L14,15 C14,12 14,7 10,7 C6,7 6,12 6,15z M8,14 h4 M4,0 v6 v-2 h1 v-4 h1 v4 M16,0 v6 v-2 h-1 v-4 h-1 v4" />
        </g>
      </svg>
    );
  }
}

export default TakeAwayIcon;
