import React from 'react';
import Modal from './Modal.container';
import ThemeLabel from '../../theme/ThemeLabel.component';
import RichContent from '../../spots/RichContent.container';
import { SPOT_INTRO_MODAL } from './Modal.constants';
import stories from '~data/stories';
import { diffPropValue } from '../../../../../utils/basis';

class SpotIntroModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'showStoryModal')) {
      this.setState(() => ({
        step: this.props.spot.popup && !this.props.showStoryModal ? 1 : 0
      }));
    }
  }

  handleButtonClick() {
    if (this.props.spot.popup && this.state.step === 0) {
      this.setState(() => ({
        step: 1
      }));

      return false;
    }

    this.props.closeModal();
  }

  renderButton() {
    return (
      <button onClick={this.handleButtonClick} className="o-button">
        Voir <ThemeLabel name="spot.the" default="l'énigme" />
      </button>
    );
  }

  renderStoryModal() {
    const { spot, storyMode } = this.props;
    const story = stories[spot.storyId];

    if (!story) {
      return '';
    }

    return (
      <Modal
        modalId={SPOT_INTRO_MODAL}
        universeId={story.universeId}
        className="c-modal-spot"
        title={spot.title}
        renderFooter={() => this.renderButton()}
        hideCross={true}
      >
        {spot.introImage && (
          <img src={spot.introImage} className="c-modal-spot__image" alt="" />
        )}
        {storyMode && spot.item && spot.item.description && (
          <div className="c-modal-spot__pitch">{spot.item.description}</div>
        )}
        {!storyMode && (
          <div className="c-modal-spot__pitch">
            <RichContent content={story.pitch} />
          </div>
        )}
      </Modal>
    );
  }

  renderCustomModal() {
    const { spot } = this.props;
    const story = stories[spot.storyId];

    return (
      <Modal
        modalId={SPOT_INTRO_MODAL}
        universeId={story.universeId}
        className="c-modal-spot"
        title={spot.popup.title}
        renderFooter={() => this.renderButton()}
        hideCross={true}
      >
        <div className="c-modal-spot__pitch">
          <RichContent content={spot.popup.content} />
        </div>
      </Modal>
    );
  }

  render() {
    if (this.state.step === 1) {
      return this.renderCustomModal();
    }

    return this.renderStoryModal();
  }
}

export default SpotIntroModal;
