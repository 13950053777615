import { deepGet } from '../../../../utils/basis';

export const getUserId = state => state.auth.currentUserId;

export const getUser = state => state.auth.all[getUserId(state)];

export const getFirstName = state => deepGet(getUser(state), 'firstName');

export const getEmail = state => deepGet(getUser(state), 'email');

export const getExpirationDate = state =>
  deepGet(getUser(state), 'expirationDate');

export const getUserRank = state => deepGet(getUser(state), 'rank');

export const isConnected = state => Boolean(getUserId(state));

export const isFree = state => deepGet(getUser(state), 'isFree');

export const getUserCustomData = state => deepGet(getUser(state), 'customData');

export const isUserInOneGroup = (state, groups) => {
  if (!groups || !groups.length) {
    return true;
  }

  return deepGet(getUser(state), 'groups').some(group =>
    groups.includes(group)
  );
};

export const isUserInGroup = (state, group) =>
  deepGet(getUser(state), 'groups').includes(group);

export const isOurGuest = state => deepGet(getUser(state), 'isOurGuest');

export const isExpired = state => {
  const expirationDate = getExpirationDate(state);
  if (
    expirationDate !== null &&
    expirationDate !== 'undefined' &&
    new Date(expirationDate) < new Date()
  ) {
    return true;
  }
  return false;
};

export const isAdmin = state => deepGet(getUser(state), 'isAdmin');

export const isBeta = state => deepGet(getUser(state), 'isBeta');

export const getXp = state => deepGet(getUser(state), 'xp');

export const getAuthToken = state =>
  deepGet(getUser(state), 'lastConnection', '_id');

export const getConnectionError = state => state.auth.connectionError;

export const getPendingProduct = state =>
  deepGet(getUser(state), 'pendingProduct');
