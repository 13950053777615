import React from 'react';
import BadgeProxy from '../trophies/badges/BadgeProxy.component';

import './Rule.scss';
import { BADGE_TYPE_XP } from '../../../../share/constants/Badges.constants';

class BadgeRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="enigmes-a-emporter">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Découvrir tous les badges</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <BadgeProxy active badge={{ type: BADGE_TYPE_XP, value: 500 }} />
            </div>
            <div className="c-rule__text">
              De nombreux badges sont à gagner&nbsp;! Certains sont visibles,
              comme atteindre 500 XP, mais certains sont à découvrir comme… Et
              non, ce ne sera pas aussi facile.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BadgeRule;
