import { connect } from 'react-redux';
import Header from './Header.component';
import { getFirstName, getXp } from '../../../modules/auth/Auth.selectors';
import { isRoute, someRoute } from '../../../urls';
import { countGameByStatus } from '../../../modules/game/Game.selectors';
import {
  getLastHistoryEntry,
  isFullPage
} from '../../../modules/ui/Ui.selectors';
import { goBack, openModal } from '../../../modules/ui/Ui.actions';
import { disconnect } from '../../../modules/auth/Auth.actions';
import { getIsGeolocationApp } from '../../../modules/geolocation/Geolocation.selectors';
import { getIsSingleStory } from '../../../modules/story/Story.selectors';

function mapStateToProps(state) {
  const {
    storyId,
    gameToStartCount,
    gameInProgressCount,
    gameAnsweredCount
  } = countGameByStatus(state);
  const isSpotList = someRoute(
    ['spotsToStart', 'spotsInProgress', 'spotsAnswered'],
    state.router.location
  );

  return {
    isSingleStory: getIsSingleStory(state),
    isGeolocationApp: getIsGeolocationApp(state),
    isSpotList,
    firstName: getFirstName(state),
    isHome: isRoute('home', state.router.location),
    xp: getXp(state),
    storyId,
    gameToStartCount,
    gameInProgressCount,
    gameAnsweredCount,
    isFullPage: isFullPage(state),
    lastPage: getLastHistoryEntry(state)
  };
}

const mapDispatchToProps = {
  goBack,
  openModal,
  disconnect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
