export const UPDATE_LAST_VERSION_AVAILABLE =
  'app/UPDATE_LAST_VERSION_AVAILABLE';

export const UPDATE_APP = 'app/UPDATE_APP';

export const UPDATE_ALL_MESSAGES = 'app/UPDATE_ALL_MESSAGES';

export const CLOSED_BEFORE_APP_STATUS = 'CLOSED_BEFORE';

export const RUNNING_APP_STATUS = 'RUNNING';

export const CLOSED_AFTER_APP_STATUS = 'CLOSED_AFTER';
