import spots from '~data/spots';
import { fetchAndUpdate } from '../api/Api.actions';
import { BLOCK_GAME } from './Game.constants';

function updateGamesStatus(udpate, dispatch) {
  Object.values(udpate.games || {}).forEach(function(game) {
    if (
      !spots[game.spotId] ||
      !spots[game.spotId].maxTime ||
      !game.startDate ||
      game.isAnswered ||
      game.isBlocked
    ) {
      return false;
    }

    const maxTime = spots[game.spotId].maxTime;
    const startDate = new Date(game.startDate);
    startDate.setSeconds(startDate.getSeconds() + maxTime);
    const remainingTime = startDate - new Date();
    if (remainingTime <= 0) {
      udpate.games[game._id].isBlocked = true;
      udpate.games[game._id].endDate = startDate;
    } else {
      setTimeout(() => {
        dispatch({
          type: BLOCK_GAME,
          gameId: game._id,
          endDate: startDate
        });
      }, remainingTime);
    }
  });

  return udpate;
}

export function getAllGames() {
  return fetchAndUpdate('/api/game/all', {}, updateGamesStatus);
}

export function findGame(spotId) {
  return fetchAndUpdate('/api/game/find', { spotId }, updateGamesStatus);
}

export function takeClue(spotId) {
  return fetchAndUpdate('/api/game/clue', { spotId });
}

export function proposeAnswer(spotId, answer, malus) {
  return fetchAndUpdate('/api/game/answer', {
    spotId,
    answer,
    malus
  });
}
