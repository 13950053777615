import version from '../../../../version';

export const getLastVersionAvailable = state => state.app.lastVersionAvailable;

export const getIsAppUpToDate = state =>
  version >= getLastVersionAvailable(state);

export const getAppStatus = state => {
  return state.app.status;
};

export const getIsBadgePage = state =>
  state.router.location.pathname === '/trophées/';

export const getIsRankPage = state =>
  state.router.location.pathname === '/classement/';

export const getNextMessage = state => {
  if (state.app.allMessages.length === 0) {
    return null;
  }
  return state.app.allMessages[0];
};

export const getAllMessages = state => {
  return state.app.allMessages;
};
