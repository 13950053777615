import React from 'react';

function match(value, element) {
  if (element.props.default === true) {
    return true;
  }
  if (typeof value === 'undefined') {
    value = true;
  }
  if (typeof element.props.value === 'function') {
    return element.props.value(value);
  }
  if (Array.isArray(element.props.values)) {
    return element.props.values.includes(value);
  }

  return element.props.value === value;
}

export class Switch extends React.PureComponent {
  render() {
    let child = null;
    React.Children.forEach(this.props.children, element => {
      if (!child && match(this.props.value, element)) {
        child = element;
      }
    });

    return child;
  }
}

export class Case extends React.PureComponent {
  render() {
    return this.props.children;
  }
}
