import React from 'react';
import About from './about/About.container';
import withPageView from '../modules/ui/hoc/withPageView.hoc';

class AboutPage extends React.PureComponent {
  componentDidMount() {
    this.props.pageView('A propos');
  }

  render() {
    return <About isAppUpToDate={this.props.isAppUpToDate} />;
  }
}

export default withPageView(AboutPage);
