// Actions
import { push } from 'connected-react-router';
import { getAllGames } from '../game/Game.actions';
import { fetchAndUpdate } from '../api/Api.actions';
import { updateReachDistance } from '../options/Options.actions';
import { fetchBadges } from '../badge/Badge.actions';
import { fetchNotifications } from '../notification/Notification.actions';
import { updateApp } from '../app/App.actions';

// Functions
import { fetchApi } from '../api/Api.functions';
import {
  isDefined,
  getDeviceOs,
  getDeviceBrowser,
  getIsPwa
} from '../../../../utils/basis';
import { to } from '../../urls';

// Data
import version from '../../../../version';
import {
  CONNECT_USER,
  LOGOUT_USER,
  UPDATE_CONNECTION_ERROR,
  CLEAR_CONNECTION_ERROR
} from './Auth.constants';

export function authenticate(email, password) {
  const os = getDeviceOs();
  const browser = getDeviceBrowser();
  const isPwa = getIsPwa();

  return function(dispatch) {
    dispatch({
      type: CLEAR_CONNECTION_ERROR
    });
    fetchApi('/api/user/connect', {
      email,
      password,
      version,
      os,
      browser,
      pwa: Number(isPwa)
    })
      .then(function([{ user, error, app }]) {
        if (app) {
          dispatch(updateApp(app));
        }
        if (error) {
          dispatch({
            type: UPDATE_CONNECTION_ERROR,
            error
          });

          return false;
        }
        if (user) {
          if (!isDefined(user.xp)) {
            user.xp = 0;
          }
          dispatch({
            type: CONNECT_USER,
            user
          });
          if (user.reachDistance) {
            dispatch(updateReachDistance(user.reachDistance));
          }

          dispatch(fetchConnectedData());
        }
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: UPDATE_CONNECTION_ERROR,
          error: `Une erreur s'est produite`
        });
      });
  };
}

export function disconnect() {
  return function(dispatch) {
    dispatch({
      type: LOGOUT_USER
    });
    dispatch(push(to('home')));
  };
}

export function resetUserData() {
  return function(dispatch) {
    dispatch(updateUser());
    dispatch(fetchConnectedData());
  };
}

function fetchConnectedData() {
  return function(dispatch) {
    dispatch(getAllGames());
    dispatch(fetchBadges());
    dispatch(fetchNotifications());
  };
}

export function updateUser() {
  return fetchAndUpdate('/api/user/get');
}

export function activateProduct(duration) {
  return fetchAndUpdate('/api/user/activate', { duration });
}
