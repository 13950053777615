import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_XP_1000 } from '../../../../../../share/constants/Badges.constants';

class BadgeXP1000 extends React.PureComponent {
  render() {
    return (
      <BadgeIcon color="#cc6633" label={BADGE_LABEL_XP_1000} {...this.props}>
        <div style={{ fontSize: '15px', lineHeight: '18px' }}>1000</div>
        <div style={{ fontSize: '10px', lineHeight: '10px' }}> XP</div>
      </BadgeIcon>
    );
  }
}

export default BadgeXP1000;
