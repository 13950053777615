import React from 'react';

import './Page.scss';
import classNames from 'classnames';

class Page extends React.PureComponent {
  render() {
    return (
      <div className="c-page">
        <div className="c-page__scroll">
          <div
            className={classNames('c-page__container', this.props.className)}
          >
            <div className="c-page__content">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
