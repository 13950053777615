import React from 'react';

import './Error.scss';

class Error extends React.Component {
  render() {
    return (
      <div className="c-error">
        <div className="c-error__content">{this.props.children}</div>
      </div>
    );
  }
}

export default Error;
