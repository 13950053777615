import React from 'react';
import SignalPictogram from '../pictos/SignalPictogram.component';
import colors from '~styles/variables/colors.scss';

import './Rule.scss';

class SignalRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="prevenez-nous">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Prévenez-nous</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <SignalPictogram size="60" color={colors.alert} />
            </div>
            <div className="c-rule__text">
              La ville évolue sans cesse ! Par exemple, il est possible que
              certaines énigmes soient perturbées par des travaux. Si vous
              constatez un problème, dites-le-nous&nbsp;!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignalRule;
