import {
  LOGOUT_USER,
  UPDATE_CONNECTION_ERROR,
  CLEAR_CONNECTION_ERROR,
  CONNECT_USER,
  UPDATE_USERS
} from './Auth.constants';
import {
  getStateFromLocalStorage,
  saveStateInLocalStorage
} from '../../../../utils/basis';

const reducerName = 'auth';
const emptyState = {
  all: {},
  currentUserId: null,
  connectionError: null
};
const initialState = getStateFromLocalStorage(reducerName, emptyState);

function saveState(state) {
  saveStateInLocalStorage(reducerName, {
    all: state.all,
    currentUserId: state.currentUserId
  });
}

const AuthReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case CONNECT_USER:
      newState = {
        ...state,
        all: {
          ...state.all,
          [action.user._id]: action.user
        },
        currentUserId: action.user._id,
        connectionError: null
      };
      saveState(newState);

      return newState;

    case UPDATE_USERS:
      newState = {
        ...state,
        all: {
          ...state.all,
          ...action.users
        }
      };
      saveState(newState);

      return newState;

    case UPDATE_CONNECTION_ERROR:
      return {
        ...state,
        connectionError: {
          ...action.error,
          label: action.error.label || action.error.name
        }
      };

    case CLEAR_CONNECTION_ERROR:
      return {
        ...state,
        connectionError: null
      };

    case LOGOUT_USER: {
      saveState(emptyState);

      return emptyState;
    }

    default:
      return state;
  }
};

export default AuthReducer;
