export const UPDATE_ERROR = 'geolocation/UPDATE_ERROR';

export const UPDATE_POSITION = 'geolocation/UPDATE_POSITION';

export const UPDATE_CURRENT_ORIENTATION =
  'geolocation/UPDATE_CURRENT_ORIENTATION';

export const UPDATE_RELATIVE_ORIENTATION =
  'geolocation/UPDATE_RELATIVE_ORIENTATION';

export const UPDATE_PERMISSION = 'geolocation/UPDATE_PERMISSION';

export const UPDATE_STATUS = 'geolocation/UPDATE_STATUS';

export const PERMISSION_DENIED_ERROR = 1;

export const POSITION_UNAVAILABLE_ERROR = 2;

export const TIMEOUT_ERROR = 3;

export const BROWSER_NOT_SUPPORTED_ERROR = 9;

export const PROMPT_PERMISSION = 'prompt';

export const GRANTED_PERMISSION = 'granted';

export const DENIED_PERMISSION = 'denied';

export const STARTED_STATUS = 'started';

export const WORKING_STATUS = 'working';

export const STOPPED_STATUS = 'stopped';
