import { connect } from 'react-redux';
import MessageBar from './MessageBar.component';
import { getNextMessage } from '../../../modules/app/App.selectors';

function mapStateToProps(state) {
  return {
    nextMessage: getNextMessage(state)
  };
}

export default connect(mapStateToProps)(MessageBar);
