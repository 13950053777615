import React from 'react';
import { Link } from 'react-router-dom';
import withPageView from '../../modules/ui/hoc/withPageView.hoc';
import { displayDate } from '../../utils/date';
import { renderForEach } from '../../../../utils/render';
import { to } from '../../urls';

import './MessagesList.scss';
import Page from '../Page.component';

class MessagesListPage extends React.PureComponent {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      readMessages: localStorage.getItem('app-readMessagesIds') || []
    };
  }

  componentDidMount() {
    this.props.fetchMessages();
  }

  render() {
    if (!this.props.allMessages || this.props.allMessages.length === 0) {
      return (
        <Page>
          <h1 className="c-spot__title">Mes Messages</h1>
          <p>
            Pas de messages actuellement. Vous retrouverez ici la liste des
            messages que vous aurez reçu.
          </p>
        </Page>
      );
    }

    const messages = this.props.allMessages;

    return (
      <Page>
        <h1 className="c-spot__title">Mes messages</h1>
        <div className="c-messages-list">
          {renderForEach(messages, message => (
            <Link
              to={to('messageDetail', message)}
              key={`message` + message._id}
            >
              <div
                className={`c-messages-list__item ${
                  this.state.readMessages.includes(message._id)
                    ? 'c-messages-list__item--read'
                    : ''
                }`}
              >
                <div className="c-messages-list__item-header">
                  <div className="c-messages-list__item-subject">
                    {message.subject}
                  </div>
                  <div className="c-messages-list__item-date">
                    {displayDate(new Date(message.date))}
                  </div>
                </div>
                <div className="c-messages-list__item-content">
                  {message.content}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Page>
    );
  }
}

export default withPageView(MessagesListPage);
