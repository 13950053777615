import React from 'react';
import TakeAwayIcon from '../icons/TakeAwayIcon.component';

import './Rule.scss';

class TakeAwayRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="enigmes-a-emporter">
        <div className="c-rule__card">
          <h2 className="c-rule__title">À emporter ou sur place</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <TakeAwayIcon size="60" />
            </div>
            <div className="c-rule__text">
              Les énigmes à emporter n'ont pas de rapport direct avec le lieu où
              elles se trouvent. Après avoir récupéré l'énoncé, il n'est pas
              nécessaire de rester sur place&nbsp;! Ces énigmes sont
              identifiables grâce au symbole "sac à dos".
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TakeAwayRule;
