import React from 'react';
import { Link } from 'react-router-dom';
import { to } from '../../../urls';
import CrossIcon from '../../icons/CrossIcon.component';
import colors from '~styles/variables/colors.scss';
import { notificationToString } from '../../trophies/Badges.function';
import withThemeFeatures from '../../theme/withThemeFeatures.hoc';

import './StatusBar.scss';

class NotificationBar extends React.PureComponent {
  closeBar() {
    this.props.readNotifications();
  }

  renderRankNotification() {
    const rank = this.props.newRankNotification.values.endRank;

    return (
      <p>
        <Link to={to('rank')} className="c-status-bar__link">
          Vous êtes désormais {rank}
          <sup>{rank === 1 ? 'er' : 'e'}</sup> au classement.
        </Link>
      </p>
    );
  }

  renderBadgeNotification() {
    const newBadges = this.props.newBadgeNotification.values.ids.map(
      badgeId => this.props.badges[badgeId]
    );

    return (
      <p>
        <Link to={to('trophies')} className="c-status-bar__link">
          {notificationToString(newBadges)}
        </Link>
      </p>
    );
  }

  render() {
    const hasBadgeNotification =
      this.props.themeFeatures.badgeNotification !== false &&
      this.props.newBadgeNotification &&
      !this.props.isBadgePage;
    const hasRankNotification =
      this.props.themeFeatures.rankNotification !== false &&
      this.props.newRankNotification &&
      !this.props.isRankPage;

    if (!hasBadgeNotification && !hasRankNotification) {
      return null;
    }

    return (
      <div className="c-status-bar">
        {hasBadgeNotification && this.renderBadgeNotification()}
        {hasRankNotification && this.renderRankNotification()}

        <button
          type="button"
          className="c-status-bar__cross"
          title="Fermer la fenêtre"
          onClick={this.closeBar.bind(this)}
        >
          <CrossIcon color={colors.alert} size="16" />
        </button>
      </div>
    );
  }
}

export default withThemeFeatures(NotificationBar);
