import React from 'react';
import AccountEdit from './account/AccountEdit.container';

class AccountEditPage extends React.PureComponent {
  render() {
    return <AccountEdit />;
  }
}

export default AccountEditPage;
