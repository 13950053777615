import { connect } from 'react-redux';
import FooterNav from './FooterNav.component';
import { isRoute, someRoute, getRouteParams } from '../../../urls';
import { getIsGeolocationApp } from '../../../modules/geolocation/Geolocation.selectors';

function mapStateToProps(state) {
  const isGeolocationApp = getIsGeolocationApp(state);
  const spotId = getRouteParams(state.router.location);
  const isHome = isRoute('home', state.router.location);
  const isMainNav =
    (isGeolocationApp && isHome) ||
    someRoute(['stories', 'trophies', 'rank'], state.router.location);
  const isSpotList =
    (!isGeolocationApp && isHome) ||
    someRoute(
      ['spotsToStart', 'spotsInProgress', 'spotsAnswered'],
      state.router.location
    );

  return {
    spotId,
    isRiddle: isRoute('spot', state.router.location),
    isMainNav,
    isSpotList
  };
}

export default connect(mapStateToProps)(FooterNav);
