import spots from '~data/spots';
import stories from '~data/stories';
import universes from '~data/universes';
import { getUser } from '../auth/Auth.selectors';
import { isSpotVisible } from '../story/Story.functions';
import { getCurrentStoryId } from '../story/Story.selectors';

export const getAllGames = state => state.game.all;

const emptyArray = [];
const emptyObject = {};

export const getGame = (state, spotId) => {
  const gameId = state.game.bySpotId[spotId];
  if (!gameId) {
    return null;
  }

  return state.game.all[gameId];
};

export const getVisibleSpots = state => {
  return Object.keys(spots).reduce((acc, spotId) => {
    if (isSpotCountable(state, spots[spotId])) {
      acc[spotId] = spots[spotId];
    }

    return acc;
  }, {});
};

export const getInProgressGameIds = state => state.game.byState.inProgress;

export const getAnsweredGameIds = state => state.game.byState.answered;

export const isGameInProgress = (state, spotId) =>
  typeof getGame(state, spotId) !== 'undefined';

export const isGameApiReady = state => state.game.isReady;

export const isGameStarted = (state, spotId) => Boolean(getGame(state, spotId));

export const isGameAnswered = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.isAnswered : false;
};

export const isGameBlocked = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.isBlocked : false;
};

export const isGameCompleted = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.isAnswered || game.isBlocked : false;
};

export const getStoriesState = state => {
  return state.game.storiesState;
};

export const getStoryState = (state, storyId) => {
  return state.game.storiesState[storyId] || emptyObject;
};

export const isStoryAnswered = (state, storyId) => {
  return getStoryState(state, storyId).isAnswered;
};

export const isStoryBlocked = (state, storyId) => {
  return getStoryState(state, storyId).isBlocked;
};

export const isStoryCompleted = (state, storyId) => {
  return getStoryState(state, storyId).isComplete;
};

export const getTries = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.tries : emptyArray;
};

export const getTryCount = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.tries.length : 0;
};

export const getGameEndDate = (state, spotId) => {
  const game = getGame(state, spotId);

  return game ? game.endDate : 0;
};

export const getNextClueIndex = (state, spotId) => {
  const game = getGame(state, spotId);

  return game && game.nextClueIndex ? game.nextClueIndex : 0;
};

export const getNextClue = (state, spotId) => {
  if (!spotId || !spots[spotId].clues) {
    return null;
  }
  const nextClueIndex = getNextClueIndex(state, spotId);

  return spots[spotId].clues[nextClueIndex];
};

export const getHasNextClue = (state, spotId) => {
  return Boolean(getNextClue(state, spotId));
};

export const getNextClueMalus = (state, spotId) => {
  return (getNextClue(state, spotId) || {}).malus;
};

export const getInitialCluesNumber = (state, spotId) => {
  if (!spotId || !spots[spotId].clues) {
    return 0;
  }

  return spots[spotId].clues.filter(clue => !clue.isAnswer).length;
};

export const getRemainingCluesNumber = (state, spotId) => {
  const nextClueIndex = getNextClueIndex(state, spotId);
  const initialCluesCount = getInitialCluesNumber(state, spotId);

  return Math.max(0, initialCluesCount - nextClueIndex);
};

export const getMalus = (state, spotId) => {
  const game = getGame(state, spotId);

  return game && game.malus ? game.malus : 0;
};

export const getStoryUsedClues = (state, storyId) => {
  // TODO: remove filter from selector
  let startedSpots = stories[storyId].spots.filter(function(spotId) {
    return isGameStarted(state, spotId);
  });

  let usedClues = 0;
  startedSpots.forEach(function(spotId) {
    usedClues += getNextClueIndex(state, spotId);
  });

  return usedClues;
};

export const getStoryProgress = (state, storyId) => {
  const user = getUser(state);

  // Filters is ok here since we return a number
  const visibleSpots = stories[storyId].spots.filter(function(spotId) {
    return isSpotVisible(spots[spotId], user);
  });
  const answeredSpots = stories[storyId].spots.filter(function(spotId) {
    return isGameCompleted(state, spotId);
  });

  return answeredSpots.length / visibleSpots.length;
};

const isSpotCountable = (state, spot) =>
  isSpotVisible(spot, getUser(state)) &&
  (!spot.isFinal || isFinalSpotVisible(state, spot.storyId));

const countSpots = (state, filter) => {
  return Object.values(spots)
    .filter(spot => isSpotCountable(state, spot))
    .filter(filter).length;
};

const countGame = (state, filter) => {
  return Object.values(state.game.all)
    .filter(
      game =>
        spots[game.spotId] && isSpotVisible(spots[game.spotId], getUser(state))
    )
    .filter(filter).length;
};

export const countGameToStart = state => {
  return countSpots(state, spot => !getGame(state, spot.id));
};

export const countGameInProgress = state => {
  return countGame(state, game => !isGameCompleted(state, game.spotId));
};

export const countGameAnswered = state => {
  return countGame(state, game => isGameCompleted(state, game.spotId));
};

export const countGameToStartForStory = (state, storyId) => {
  return countSpots(
    state,
    spot => spot.storyId === storyId && !getGame(state, spot.id)
  );
};

export const countGameInProgressForStory = (state, storyId) => {
  return countGame(
    state,
    game =>
      spots[game.spotId].storyId === storyId &&
      !isGameCompleted(state, game.spotId)
  );
};

export const countGameAnsweredForStory = (state, storyId) => {
  return countGame(
    state,
    game =>
      spots[game.spotId].storyId === storyId &&
      isGameCompleted(state, game.spotId)
  );
};

export const countGameByStatus = (state, forStory = true) => {
  const storyId = forStory && getCurrentStoryId(state);
  if (storyId) {
    return {
      storyId,
      gameToStartCount: countGameToStartForStory(state, storyId),
      gameInProgressCount: countGameInProgressForStory(state, storyId),
      gameAnsweredCount: countGameAnsweredForStory(state, storyId)
    };
  }

  return {
    gameToStartCount: countGameToStart(state),
    gameInProgressCount: countGameInProgress(state),
    gameAnsweredCount: countGameAnswered(state)
  };
};

export const getAnsweredSpotsForStory = (state, storyId) => {
  // TODO: remove filter from selector
  let answeredSpots = stories[storyId].spots.filter(function(spotId) {
    return isGameAnswered(state, spotId);
  });

  return answeredSpots;
};

const filterSpots = filterFn => Object.keys(spots).filter(filterFn);

export const getAnsweredSpots = state => {
  return filterSpots(spotId => isGameAnswered(state, spotId));
};

export const getCompletedSpots = state => {
  return filterSpots(spotId => isGameCompleted(state, spotId));
};

export const getCompletedSpotsForStory = (state, storyId) => {
  // TODO: remove filter from selector
  let completedSpots = stories[storyId].spots.filter(function(spotId) {
    return isGameCompleted(state, spotId);
  });

  return completedSpots;
};

export const getStartedSpotsForStory = (state, storyId) => {
  // TODO: remove filter from selector
  let startedSpots = stories[storyId].spots.filter(function(spotId) {
    return isGameStarted(state, spotId);
  });

  return startedSpots;
};

export const getStoryStartDate = (state, storyId) => {
  let startedSpots = getStartedSpotsForStory(state, storyId);
  if (startedSpots.length === 0) {
    return null;
  }
  let storyStartDate = getGame(state, startedSpots[0]).startDate;

  stories[storyId].spots.forEach(spot => {
    if (
      getGame(state, spot) &&
      getGame(state, spot).startDate < storyStartDate
    ) {
      storyStartDate = getGame(state, spot).startDate;
    }
  });
  return storyStartDate;
};

export const getStoryEndDate = (state, storyId) => {
  const story = stories[storyId];
  let answeredSpots = getCompletedSpotsForStory(state, storyId);
  if (answeredSpots.length < story.spots.length) {
    return null;
  }
  let storyEndDate = getGame(state, answeredSpots[0]).endDate;

  if (story.finalSpot) {
    storyEndDate = getGame(state, story.finalSpot)
      ? getGame(state, story.finalSpot).endDate
      : null;
  } else {
    answeredSpots.forEach(spot => {
      if (getGame(state, spot) && getGame(state, spot).endDate > storyEndDate) {
        storyEndDate = getGame(state, spot).endDate;
      }
    });
  }
  return storyEndDate;
};

export const getXpWon = (state, spotId) => {
  const game = getGame(state, spotId);
  if (game && game.isAnswered) {
    return game.XP;
  }

  return 0;
};

export const getStoryProgressXp = (state, storyId) => {
  let xp = 0;
  const story = stories[storyId];
  story.spots.forEach(function(spotId) {
    xp += getXpWon(state, spotId);
  });
  if (story.finalSpot) {
    xp += getXpWon(state, story.finalSpot);
  }

  return xp;
};

export const getUniverseProgressXp = (state, universeId) => {
  let xp = 0;
  universes[universeId].stories.forEach(function(storyId) {
    xp += getStoryProgressXp(state, storyId);
  });

  return xp;
};

export const isFinalSpotVisible = (state, storyId) => {
  let answeredSpots = getAnsweredSpotsForStory(state, storyId);
  let answeredSpotsCount = answeredSpots.length;
  let totalSpotsCount = stories[storyId].spots.length;

  return answeredSpotsCount >= totalSpotsCount;
};

export const isCompletedStory = (state, storyId) => {
  let answeredSpots = getCompletedSpotsForStory(state, storyId);
  let answeredSpotsCount = answeredSpots.length;
  let totalSpotsCount = stories[storyId].spots.length;
  if (answeredSpotsCount !== totalSpotsCount) {
    return false;
  }

  if (
    stories[storyId].finalSpot &&
    !isGameAnswered(state, stories[storyId].finalSpot)
  ) {
    return false;
  }

  return true;
};

export const getCompletedStories = state => {
  let storiesCompleted = Object.values(stories)
    .map(story => {
      if (isCompletedStory(state, story.id)) {
        return story.id;
      }
      return false;
    })
    .filter(Boolean);
  return storiesCompleted;
};

function getGameTime(game) {
  return (
    (game.endDate ? new Date(game.endDate) : new Date()) -
    new Date(game.startDate)
  );
}

export const getGameTimeBySpotId = (state, spotId) => {
  const game = getGame(state, spotId);
  if (!game) {
    return null;
  }

  return getGameTime(game);
};

export const getGameStartDateBySpotId = (state, spotId) => {
  const game = getGame(state, spotId);
  if (!game) {
    return null;
  }

  return new Date(game.startDate);
};

export const getGameMaxDateBySpotId = (state, spotId) => {
  if (!spots[spotId] || !spots[spotId].maxTime) {
    return null;
  }

  const startDate = getGameStartDateBySpotId(state, spotId);
  if (!startDate) {
    return null;
  }

  startDate.setSeconds(startDate.getSeconds() + spots[spotId].maxTime);

  return startDate;
};

export const getRecordTime = state => {
  const answeredGameIds = getAnsweredGameIds(state);
  if (answeredGameIds.length === 0) {
    return null;
  }
  const answerTimes = answeredGameIds.map(gameId =>
    getGameTime(state.game.all[gameId])
  );

  return Math.min(...answerTimes);
};
