import React from 'react';
import classNames from 'classnames';

class ModalButton extends React.PureComponent {
  onClick() {
    this.props.openModal(this.props.modalId);
  }

  render() {
    const classes = classNames(this.props.className, {
      [this.props.activeClassName]: this.props.isModalOpen
    });

    return (
      <button
        type="button"
        onClick={this.onClick.bind(this)}
        className={classes}
      >
        {this.props.children}
      </button>
    );
  }
}

export default ModalButton;
