import React from 'react';

class StoryPictogram extends React.PureComponent {
  render() {
    const color = this.props.color || 'currentColor';

    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <g fill={color} stroke={color} strokeWidth="2" strokeLinejoin="round">
          <circle cx="3" cy="3" r="2" />
          <circle cx="15" cy="7" r="2" />
          <circle cx="5" cy="13" r="2" />
          <circle cx="17" cy="17" r="2" />
          <path d="M3,3 L15,7 L5,13 L 17,17" fill="none" />
        </g>
      </svg>
    );
  }
}

export default StoryPictogram;
