import { connect } from 'react-redux';
import AwardModal from './AwardModal.component';
import { closeModal, openModal } from '../../../modules/ui/Ui.actions';
import { setHighlightedSpot } from '../../../modules/map/Map.actions';
import { isModalOpen } from '../../../modules/ui/Ui.selectors';
import {
  getStoryProgress,
  countGameAnsweredForStory,
  countGameAnswered,
  isGameCompleted
} from '../../../modules/game/Game.selectors';
import { ModalHoc } from './Modal.hoc';
import stories from '~data/stories';
import spots from '~data/spots';
import { isDefined } from '../../../../../utils/basis';
import { AWARD_MODAL } from './Modal.constants';
import { getUser } from '../../../modules/auth/Auth.selectors';

function mapStateToProps(state, ownProps) {
  let isEndOfStory = false;
  let finalSpot = null;
  let story = null;
  let nextIsFinal = false;
  const spot = ownProps.spot;

  if (spot) {
    story = stories[spot.storyId];

    if (isDefined(stories[spot.storyId].finalSpot)) {
      finalSpot = spots[stories[spot.storyId].finalSpot];
      if (isGameCompleted(state, finalSpot.id)) {
        isEndOfStory = true;
      }
    } else {
      isEndOfStory = getStoryProgress(state, spot.storyId) === 1;
    }
    const answeredGameCount = countGameAnsweredForStory(state, spot.storyId);
    nextIsFinal =
      answeredGameCount === story.spots.length && finalSpot && !spot.isFinal;
  }

  return {
    isEndOfStory,
    nextIsFinal,
    isOpen: isModalOpen(state, AWARD_MODAL),
    answeredGameCount: countGameAnswered(state),
    storyId: spot && spot.storyId,
    finalSpot,
    universeId: spot && spot.storyId && stories[spot.storyId].universeId,
    user: getUser(state)
  };
}

const mapDispatchToProps = {
  closeModal,
  openModal,
  setHighlightedSpot
};

export default ModalHoc(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AwardModal)
);
