import React from 'react';

class SpringTrophyIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 3 64 80"
        width={this.props.size || 30}
        height={this.props.size || 30}
        className={this.props.className}
      >
        <path
          fill={this.props.color || 'currentColor'}
          d="M64 27.375l-22.395-2.906L32 4.562 22.39 24.47 0 27.375l16.46 14.89-4.245 21.606L32 53.261l19.79 10.61-4.255-21.605zm0 0"
        />
        <path
          fill="white"
          transform="scale(0.12) translate(110 222)"
          d="M170.1 175.7s-56.2 1.8-108.8-43.4S.6 1.5 0 2.1 79.6-13.4 138.3 40s31.8 135.7 31.8 135.7zm109.1-56.9c16.8-33.5 15.4-76.3 15.4-76.3s-54.1-.4-84.1 36.7c-38.8 47.8-22.8 104.6-22.8 104.6s67.2-16.6 91.5-65z M241.6 91.4c-1.9-2-5.1-2-7.1-.1-31.7 31.2-47.3 68.3-54.9 106.1-8.4-42.8-31.9-107.8-97.8-142-2.5-1.3-5.5-.3-6.7 2.1-1.3 2.4-.3 5.5 2.1 6.7 92 47.8 96.8 159.9 97 173.4-1 12.7-1.3 25.2-1.4 37.4 0 2.8 2.2 5 5 5 2.7 0 5-2.2 5-5 .1-11.4.4-23 1.2-34.8.1-.4.2-.8.2-1.2v-1.1c3.8-48.9 16.7-99.7 57.3-139.6 2-1.8 2-4.9.1-6.9z"
        />
        <g fill="none" stroke={this.props.color || 'currentColor'}>
          <path strokeWidth="12" d="M20,67 H45" />
          <path strokeWidth="6" d="M15,78 H50" />
        </g>
      </svg>
    );
  }
}

export default SpringTrophyIcon;
