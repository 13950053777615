import React, { Fragment } from 'react';
import withPageView from '../modules/ui/hoc/withPageView.hoc';
import ThemeComponent from './theme/ThemeComponent.component';

class FooterMenu3 extends React.PureComponent {
  componentDidMount() {
    this.props.pageView(this.props.themeData.analytics.FooterMenu3);
  }

  render() {
    return (
      <Fragment>
        <ThemeComponent name="FooterMenu3" />
      </Fragment>
    );
  }
}

export default withPageView(FooterMenu3);
