import React from 'react';
import { AppContext } from '../../App';
import { deepGet } from '../../../../utils/basis';

class ThemeLabel extends React.PureComponent {
  getLabel(labels) {
    const { name } = this.props;
    const label = deepGet(labels, ...name.split('.'));
    if (label) {
      return label;
    }

    return this.props.default;
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ labels }) => {
          let label = this.getLabel(labels);
          if (this.props.capitalize) {
            label = label[0].toUpperCase() + label.slice(1);
          }

          if (this.props.children) {
            return this.props.children(label);
          }

          return label;
        }}
      </AppContext.Consumer>
    );
  }
}

export default ThemeLabel;
