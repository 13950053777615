import React from 'react';
import { getClickCoordinates } from './mechanisms.utils';

import './DrawingImage.scss';

class DrawingImage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.paths = [];
    this.canvas = null;
    this.context = null;

    this.onMouseDown = this.onMouseDown.bind(this);
  }

  componentDidMount() {
    this.canvas = document.createElement('canvas');
    this.canvas.className = 'c-drawing-image__canvas';
    this.canvas.setAttribute('width', this.props.width);
    this.canvas.setAttribute('height', this.props.height);
    this.canvas.addEventListener('mousedown', this.onMouseDown);
    this.initCanvas();
  }

  initCanvas() {
    this.root.current.appendChild(this.canvas);
    this.context = this.canvas.getContext('2d');
    this.context.strokeStyle = this.props.color;
    this.context.lineJoin = 'round';
    this.context.lineWidth = this.props.lineWidth;
  }

  componentDidUpdate() {
    this.initCanvas();
  }

  componentWillUnmount() {
    this.canvas.removeEventListener('mousedown', this.onMouseDown);
  }

  getMouseCoordinates(event) {
    const { x, y } = getClickCoordinates(event, this.canvas, this.props.width);
    const cellWidth = this.props.width / this.props.grid[0];
    const cellHeight = this.props.height / this.props.grid[1];
    const cellX = Math.round(x / cellWidth) * cellWidth;
    const cellY = Math.round(y / cellHeight) * cellHeight;
    const diffX = Math.abs(x - cellX);
    const diffY = Math.abs(y - cellY);
    const isHorizontal = diffX > diffY;

    if (isHorizontal) {
      const startCellX =
        Math.round((x - 0.5 * cellWidth) / cellWidth) * cellWidth;
      const endCellX =
        Math.round((x + 0.5 * cellWidth) / cellWidth) * cellWidth;

      return [startCellX, cellY, endCellX, cellY].join('-');
    }

    const startCellY =
      Math.round((y - 0.5 * cellHeight) / cellHeight) * cellHeight;
    const endCellY =
      Math.round((y + 0.5 * cellHeight) / cellHeight) * cellHeight;

    return [cellX, startCellY, cellX, endCellY].join('-');
  }

  onMouseDown(event) {
    const coordinates = this.getMouseCoordinates(event);
    const index = this.paths.indexOf(coordinates);
    if (index === -1) {
      this.paths.push(coordinates);
    } else {
      this.paths.splice(index, 1);
    }
    this.redraw();
  }

  clearCanvas() {
    this.context.clearRect(0, 0, this.props.width, this.props.height);
  }

  redraw() {
    this.clearCanvas();
    this.paths.forEach(path => {
      const [x1, y1, x2, y2] = path.split('-');
      this.context.beginPath();
      this.context.moveTo(x1, y1);
      this.context.lineTo(x2, y2);
      this.context.stroke();
    });
  }

  render() {
    return (
      <div ref={this.root} className="c-drawing-image">
        <img
          className="c-drawing-image__image"
          src={this.props.src}
          alt={this.props.alt}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}

export default DrawingImage;
