import React from 'react';
import { fetchApi } from '../../modules/api/Api.functions';
import { history } from '../../modules/createStore';

import '../subscribe/Subscribe.scss';
import './Gift.scss';

class Gift extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      invalidCode: false,
      errorReason: null
    };
  }

  checkGiftCode(e) {
    e.preventDefault();
    let userEmail = this.props.email;
    fetchApi('/api/gift/use', {
      activationCode: document.forms.gift.activationCode.value,
      userEmail
    })
      .then(([result]) => {
        if (!result) {
          this.setState(() => {
            return {
              invalidCode: true,
              errorReason:
                'Erreur de lors de la récupération du bon cadeau. Réessayez ou contactez-nous à contact@zupple.fr'
            };
          });
          return false;
        }
        if (result.status === 'ko') {
          this.setState(() => {
            return {
              invalidCode: true,
              errorReason: result.reason
            };
          });
          return false;
        } else {
          this.setState(() => {
            return {
              invalidCode: false
            };
          });
          history.push('/');
          return true;
        }
      })
      .catch(e => {
        this.setState(() => {
          return {
            invalidCode: true
          };
        });
      });
  }

  render() {
    return (
      <div className="c-subscribe__products-item">
        <div className="c-subscribe__card">
          <div className="c-subscribe__card-wrapper">
            <h1 className="c-subscribe__card-title">J'ai un bon cadeau</h1>
            <form name="gift" autoComplete="off">
              <div className="c-subscribe__card-content">
                {this.state.invalidCode && (
                  <div className="c-gift-error">{this.state.errorReason}</div>
                )}
                <input
                  id="activationCode"
                  type="text"
                  name="activationCode"
                  placeholder="Code d'activation"
                  className="c-gift-input"
                />
              </div>
              <button
                className="c-subscribe__card-button o-button"
                onClick={this.checkGiftCode.bind(this)}
              >
                C'est parti !
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Gift;
