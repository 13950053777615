import { connect } from 'react-redux';
import HomePage from './HomePage.component';
import { getCurrentStoryId } from '../modules/story/Story.selectors';
import { getXp } from '../modules/auth/Auth.selectors';
import { getIsGeolocationApp } from '../modules/geolocation/Geolocation.selectors';
import { isDefined } from '../../../utils/basis.js';
import { openModal } from '../modules/ui/Ui.actions';
import stories from '~data/stories';

function mapStateToProps(state) {
  let story = getCurrentStoryId(state);
  if (isDefined(story)) {
    story = stories[story];
  }

  return {
    isGeolocationApp: getIsGeolocationApp(state),
    story: story,
    userXp: getXp(state)
  };
}

const mapDispatchToProps = {
  openModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
