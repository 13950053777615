import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_EVENT_RAID_2019 } from '../../../../../../share/constants/Badges.constants';

class BadgeEventRaid2019 extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#C0C0C0"
        label={BADGE_LABEL_EVENT_RAID_2019}
        {...this.props}
      >
        <div style={{ fontSize: '15px', lineHeight: '18px' }}>RAID</div>
        <div style={{ fontSize: '10px', lineHeight: '10px' }}>2019</div>
      </BadgeIcon>
    );
  }
}

export default BadgeEventRaid2019;
