import { connect } from 'react-redux';
import SpotRiddle from './SpotRiddle.component';
import { openModal, sendMail } from '../../modules/ui/Ui.actions';
import { getTries } from '../../modules/game/Game.selectors';

function mapStateToProps(state, ownProps) {
  const spotId = ownProps.spot && ownProps.spot.id;
  const tries = getTries(state, spotId);

  return {
    tries
  };
}

const mapDispatchToProps = {
  openModal,
  sendMail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpotRiddle);
