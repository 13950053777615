import { connect } from 'react-redux';
import SpotIntroModal from './SpotIntroModal.component';
import { closeModal } from '../../../modules/ui/Ui.actions';
import { getCurrentModalParams } from '../../../modules/ui/Ui.selectors';

function mapStateToProps(state) {
  return {
    ...getCurrentModalParams(state)
  };
}

const mapDispatchToProps = {
  closeModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpotIntroModal);
