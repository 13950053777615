import { connect } from 'react-redux';
import MessagesListPage from './MessagesListPage.component';
import { getAllMessages } from '../../modules/app/App.selectors';
import { fetchMessages } from '../../modules/app/App.actions';

function mapStateToProps(state) {
  return {
    allMessages: getAllMessages(state)
  };
}

const mapDispatchToProps = {
  fetchMessages
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesListPage);
