import React from 'react';
import XpFlag from '../xp/XpFlag.component';

import './Rule.scss';

class LevelRule extends React.PureComponent {
  render() {
    const styles = {
      transform: 'scale(0.75, 0.75)',
      transformOrigin: 'center left'
    };

    return (
      <div className="c-rule" id="niveaux">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Facile ou Difficile</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <div style={styles}>
                <XpFlag xp={20} />
              </div>
              <div style={styles}>
                <XpFlag xp={60} />
              </div>
              <div style={styles}>
                <XpFlag xp={100} />
              </div>
            </div>
            <div className="c-rule__text">
              Attention avant de vous lancer dans une énigme&nbsp;! Le nombre de
              points, le nombre d'étoiles et la taille du point sur la carte
              vous indiquent son niveau de difficulté.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LevelRule;
