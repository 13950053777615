import { connect } from 'react-redux';
import StoryProgress from './StoryProgress.component';
import { getAnsweredSpotsForStory } from '../../../../../common/play/modules/game/Game.selectors';
import stories from '~data/stories';

function mapStateToProps(state, ownProps) {
  let answeredSpots = getAnsweredSpotsForStory(state, ownProps.storyId);
  let allSpots = stories[ownProps.storyId].spots;

  return {
    answeredSpots,
    allSpots
  };
}

export default connect(
  mapStateToProps,
  null
)(StoryProgress);
