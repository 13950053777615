import React from 'react';
import MapIcon from '../icons/MapIcon.component';

import './Rule.scss';

class SpotRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="au-fil-de-l-eau">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Au fil de l'eau</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <MapIcon size="60" />
            </div>
            <div className="c-rule__text">
              Les points peuvent se faire dans l'ordre que vous voulez.
              Commencez par un quartier que vous souhaitez visiter ou par ceux
              qui se trouvent sur le chemin vers votre lieu de travail.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SpotRule;
