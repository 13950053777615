function getLocalStorageKey(mechanismId, inputName) {
  return `mechanism__${mechanismId}__${inputName}`;
}

export function getInputDefaultValue(
  mechanismId,
  inputName,
  defaultValue = null
) {
  const storedValue = localStorage.getItem(
    getLocalStorageKey(mechanismId, inputName)
  );
  if (storedValue === null) {
    return defaultValue;
  }

  return storedValue;
}

export function updateValue(mechanismId, inputName, value) {
  localStorage.setItem(getLocalStorageKey(mechanismId, inputName), value);
}

export function updateInputValue(mechanismId, event) {
  updateValue(mechanismId, event.target.name, event.target.value);
}
