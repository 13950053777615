export function renderForEach(object, callback, elseCallback = () => null) {
  if (typeof object === 'undefined') {
    return elseCallback();
  }

  if (typeof object === 'string') {
    return callback(object, 0);
  }

  if (Array.isArray(object)) {
    if (object.length === 0) {
      return elseCallback();
    }
    return object.map(callback);
  }

  if (Object.keys(object).length === 0) {
    return elseCallback();
  }

  return Object.keys(object).map(function(key) {
    const item = object[key];
    return callback(item, key);
  });
}

export function renderFor(loop, callback) {
  const result = [];
  for (let i = 0; i < loop; i++) {
    result.push(callback(i));
  }

  return result;
}
