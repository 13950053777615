import React from 'react';
import { AMSynth } from 'tone';

import './MusicalSvg.scss';

class MusicalSvg extends React.PureComponent {
  constructor(props) {
    super(props);
    this.root = React.createRef();
    this.synth = new AMSynth().toDestination();
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.eventHandler.bind(this));
  }

  reset() {
    const clicked = this.root.current.querySelector('.c-musical-svg__clicked');
    if (clicked) {
      clicked.classList.remove('c-musical-svg__clicked');
    }
  }

  eventHandler(event) {
    this.reset();
    event.target.classList.add('c-musical-svg__clicked');

    const note = event.target.getAttribute('data-note');
    if (note) {
      this.synth.triggerAttackRelease(note + '4', '8n');
      return false;
    }

    const url = event.target.getAttribute('data-url');
    if (url) {
      const audio = new Audio(url);
      audio.play();
    }
  }

  render() {
    return (
      <div className="c-musical-svg">
        <div
          dangerouslySetInnerHTML={{ __html: this.props.content }}
          ref={this.root}
        />
      </div>
    );
  }
}

export default MusicalSvg;
