import React from 'react';

class SquircleIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 47 48"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <path
          d="M47,24 C47,39.518 38.5921323,48 23.2097591,48 C7.82778228,48 0,39.518 0,24 C0,8.482 7.82778228,0 23.2097591,0 C38.5921323,0 47,8.482 47,24"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default SquircleIcon;
