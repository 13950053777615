import React from 'react';

import colors from '~styles/variables/colors.scss';

class StoryFooterIcon extends React.PureComponent {
  render() {
    let prefix = 'icon';
    if (this.props.context) {
      prefix = this.props.context;
    }
    return (
      <svg
        viewBox="0 0 74 74"
        width={this.props.size || 74}
        height={this.props.size || 74}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.9976963%"
            x2="99.9944101%"
            y2="49.9976963%"
            id={`${prefix}-story-a`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.activeTo} offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M27.8395262,45.3026353 C27.9448528,45.8524517 28,46.4198946 28,47 C28,47.7625263 27.9046874,48.5032031 27.7253497,49.2107428 L54.0137792,57.8522203 C55.5160199,54.9710979 58.5320405,53 62,53 C66.96275,53 71,57.0365 71,62 C71,66.96275 66.96275,71 62,71 C57.3964978,71 53.5893536,67.526098 53.062287,63.0620137 L24.8887588,53.8008706 C23.3092958,55.1703259 21.2497452,56 19,56 C14.03725,56 10,51.96275 10,47 C10,42.0365 14.03725,38 19,38 C21.6351519,38 24.0093624,39.1380765 25.6567726,40.9487074 L45.6773177,30.4257063 C45.242431,29.3729911 45.0017034,28.2204102 45.000009,27.0128379 L18.4392552,18.2818426 C16.8037609,19.9579279 14.5213229,21 12,21 C7.03725,21 3,16.96275 3,12 C3,7.0365 7.03725,3 12,3 C16.96275,3 21,7.0365 21,12 C21,12.5307719 20.9538197,13.0509574 20.8652659,13.5567497 L46.5252742,21.9916539 C48.1424363,19.5857775 50.8894224,18 54,18 C58.96275,18 63,22.0365 63,27 C63,31.96275 58.96275,36 54,36 C52.0442921,36 50.2323116,35.373027 48.7544947,34.3095172 L27.8395262,45.3026353 Z"
          fill={`url(#${prefix}-story-a)`}
        />
      </svg>
    );
  }
}

export default StoryFooterIcon;
