import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { newPropValue } from '../../../../../utils/basis';
import universes from '~data/universes';
import { getTopLineStyles } from '../../../modules/story/Story.functions';
import StoryPreview from '../../story/StoryPreview.container';

import './Modal.scss';
import ModalLayout from './ModalLayout.component';

ReactModal.setAppElement('#root');

class Modal extends React.PureComponent {
  onOpen() {
    this.props.afterOpenModal && this.props.afterOpenModal();
  }

  onClose() {
    this.props.onClose && this.props.onClose();
  }

  componentDidUpdate(prevProps) {
    if (newPropValue(this.props, prevProps, 'isModalOpen', false)) {
      this.onClose();
    }
  }

  renderStoryPreview() {
    if (!this.props.showStoryPreview) {
      return null;
    }

    return (
      <div className="c-modal__story-preview">
        {' '}
        <StoryPreview
          inModal
          story={this.props.story}
          distance={this.props.distance}
        />
      </div>
    );
  }

  renderTopDecoration() {
    if (this.props.specificLogo) {
      return (
        <Fragment>
          <div className="c-modal__universe-logo">
            <img src={this.props.specificLogo} alt="" />
          </div>
        </Fragment>
      );
    }

    const universe = this.props.universeId && universes[this.props.universeId];
    if (universe) {
      return (
        <Fragment>
          <div
            className="c-modal__top-line"
            style={getTopLineStyles(universe)}
          />
          {universe.logo && (
            <div className="c-modal__universe-logo">
              <img src={universe.logo} alt="" />
            </div>
          )}

          {this.renderStoryPreview()}
        </Fragment>
      );
    }

    return null;
  }

  render() {
    const hasDecoration = !this.props.noDecorations;
    const isChildFunc = typeof this.props.children === 'function';
    const classes = classNames('c-modal', this.props.className, {
      'c-modal--content': hasDecoration
    });
    const child = isChildFunc
      ? this.props.children(this.props.isModalOpen, this.props.closeModal)
      : this.props.children;

    return (
      <ReactModal
        isOpen={this.props.isModalOpen}
        onRequestClose={this.props.onRequestClose || this.props.closeModal}
        onAfterOpen={this.onOpen.bind(this)}
        contentLabel={this.props.contentLabel}
        className={classes}
      >
        {this.renderTopDecoration()}
        {hasDecoration ? (
          <ModalLayout
            hideCross={this.props.hideCross}
            icon={this.props.icon}
            contentLabel={this.props.contentLabel}
            closeModal={this.props.onRequestClose || this.props.closeModal}
            title={this.props.title}
            renderFooter={this.props.renderFooter}
          >
            {child}
          </ModalLayout>
        ) : (
          child
        )}
      </ReactModal>
    );
  }
}

export default Modal;
