import React from 'react';
import { Link } from 'react-router-dom';
import { to } from '../../../urls';

import './StatusBar.scss';

class MessageBar extends React.PureComponent {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      newMessage: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.nextMessage) {
      if (
        !localStorage.getItem('app-readMessagesIds') ||
        !localStorage
          .getItem('app-readMessagesIds')
          .includes(this.props.nextMessage._id)
      ) {
        this.setState(() => ({
          newMessage: true
        }));
      }
    }
  }

  render() {
    if (!this.state.newMessage) {
      return null;
    }

    return (
      <Link
        to={to('messageDetail', this.props.nextMessage)}
        className="c-status-bar"
      >
        {this.props.nextMessage.subject}
      </Link>
    );
  }
}

export default MessageBar;
