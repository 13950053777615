import React from 'react';
import logoSrc from '../images/Zupple-logo.svg';
import textSrc from '../images/Zupple-text.svg';
import { renderForEach } from '../../../utils/render';
import stripeConf from '~data/stripe';
import { Link } from 'react-router-dom';
import { to } from '../urls';

import Product from './subscribe/Product.component';
import Gift from './subscribe/Gift.component';

import './subscribe/Subscribe.scss';

const products = stripeConf.products;

class PaymentPage extends React.PureComponent {
  render() {
    return (
      <div className="c-subscribe">
        <div className="c-subscribe__content">
          <div className="c-subscribe__header">
            <img src={logoSrc} alt="" className="c-subscribe__logo" />{' '}
            <img
              src={textSrc}
              alt="Zupple"
              className="c-subscribe__header-text"
            />{' '}
          </div>
          <div className="c-subscribe__intro">
            Vous pourrez activer votre accès quand vous le souhaitez depuis
            l'application ! Le temps de jeu ne sera décompté qu'à partir de ce
            moment-là.
          </div>
          <div className="c-subscribe__products">
            <Gift
              email={
                this.props.location.state && this.props.location.state.email
              }
            />

            {renderForEach(products, (product, index) => {
              if (product.isGift || product.hidden) {
                return '';
              }
              return (
                <Product
                  key={`product-${index}`}
                  color={product.toplineColor}
                  name={product.name}
                  desc={product.desc}
                  duration={product.duration}
                  durationUnit={product.durationUnit}
                  price={product.price}
                  sku={product.sku}
                  email={
                    this.props.location.state && this.props.location.state.email
                  }
                  fromApp={
                    this.props.location.state &&
                    this.props.location.state.fromApp
                  }
                />
              );
            })}

            <div className="c-subscribe__products-item">
              <div className="c-subscribe__card">
                <div className="c-subscribe__card-wrapper">
                  <h1 className="c-subscribe__card-title">Accès gratuit</h1>
                  <div className="c-subscribe__card-content">
                    Envie de voir à quoi ça ressemble avant de vous décider ?
                    Accèdez gratuitement à l'application pour consulter la carte
                    des énigmes, les aventures disponibles et les règles du jeu.
                  </div>
                  <Link
                    to={
                      this.props.location.state &&
                      this.props.location.state.fromApp
                        ? to('home')
                        : to('subscribeSuccess')
                    }
                  >
                    <button className="c-subscribe__card-button o-button">
                      C'est parti !
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentPage;
