import { combineReducers } from 'redux';
import AppReducer from './app/App.reducer';
import AuthReducer from './auth/Auth.reducer';
import BadgeReducer from './badge/Badge.reducer';
import GameReducer from './game/Game.reducer';
import GeolocationReducer from './geolocation/Geolocation.reducer';
import MapReducer from './map/Map.reducer';
import NotificationReducer from './notification/Notification.reducer';
import OptionsReducer from './options/Options.reducer';
import RankReducer from './rank/Rank.reducer';
import StoryReducer from './story/Story.reducer';
import UiReducer from './ui/Ui.reducer';

function createReducers(options) {
  return combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    badge: BadgeReducer,
    game: GameReducer,
    geolocation: GeolocationReducer(options),
    map: MapReducer,
    notification: NotificationReducer,
    options: OptionsReducer(options),
    rank: RankReducer,
    story: StoryReducer(options),
    ui: UiReducer
  });
}

export default createReducers;
