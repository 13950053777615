import React from 'react';
import classNames from 'classnames';
import { renderFor } from '../../../../utils/render';
import withPreFillAnswer from './withPrefillAnswer.hoc';
import { getInputDefaultValue, updateInputValue } from './localStorage.utils';
import { getName, getIsLight } from './ColorName.utils';

import './ColorName.scss';

class ColorName extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.state = {
      code: '#000000',
      name: 'Noir',
      isLight: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const name = this.computeColorName();
      this.props.prefillAnswer(name);
    }, 0);
  }

  onInput(event) {
    updateInputValue(this.props.mechanismId, event);
    const name = this.computeColorName();
    this.props.prefillAnswer(name);
  }

  computeColorName() {
    const code =
      '#' +
      Array.from(this.root.current.querySelectorAll('select'))
        .map(select => select.value)
        .join('');
    const name = this.props.colors[code] || getName(code);

    this.setState(() => ({
      code,
      name,
      isLight: getIsLight(code)
    }));

    return name;
  }

  render() {
    return (
      <div className="c-color-name">
        <form className="c-color-name__action" ref={this.root}>
          <div className="c-color-name__select">#</div>
          {renderFor(6, i => (
            <select
              defaultValue={getInputDefaultValue(
                this.props.mechanismId,
                `select${i}`
              )}
              onInput={this.onInput.bind(this)}
              name={`select${i}`}
              key={`select${i}`}
              className="c-color-name__select"
            >
              {renderFor(16, j => (
                <option
                  key={`option${j}`}
                  value={j.toString(16)}
                  className="c-color-name__option"
                >
                  {j.toString(16).toUpperCase()}
                </option>
              ))}
            </select>
          ))}
        </form>

        <div
          className={classNames('c-color-name__output', {
            'c-color-name__output--light': this.state.isLight
          })}
          style={{ background: this.state.code }}
        >
          {this.state.name}
        </div>
      </div>
    );
  }
}

export default withPreFillAnswer(ColorName);
