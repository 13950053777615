import {
  getDistanceTo,
  arrayToCoordinates
} from '../geolocation/Geolocation.functions';
import spots from '~data/spots';

export function getBackgroundStyles(universe) {
  if (!universe.background) {
    return {
      backgroundColor: 'white'
    };
  }

  return {
    backgroundImage: `url(${universe.background.image})`,
    backgroundColor: universe.background.color
  };
}

export function getTopLineStyles(universe) {
  if (universe.status === 'teaser') {
    return {
      background: '#DDDDDD'
    };
  }
  return {
    background: universe.topLineColor
  };
}

export function isStoryVisible(item, user) {
  return (
    item.status !== 'hidden' &&
    (item.status !== 'draft' || user.isAdmin) &&
    (item.status !== 'beta' || user.isBeta || user.isAdmin || item.teaser)
  );
}

export function isStoryTeaser(story, user) {
  if (!user) {
    return true;
  }

  return (
    story.teaser && !(story.status === 'beta' && user.isBeta) && !user.isAdmin
  );
}

export function isSpotVisible(item, user) {
  return (
    item.status !== 'hidden' &&
    (item.status !== 'draft' || user.isAdmin) &&
    (item.status !== 'beta' || user.isBeta || user.isAdmin) &&
    (!item.forGroups ||
      (
        user.groups &&
        item.forGroups.filter(value => user.groups.includes(value))
      ).length > 0 ||
      user.isAdmin)
  );
}

export function isSpotPlayable(item, user) {
  return (
    isSpotVisible(item, user) && (item.status !== 'teaser' || user.isAdmin)
  );
}

export function getDistanceToStory(story, position) {
  let distance = getDistanceTo(
    arrayToCoordinates(spots[story.spots[0]].coordinates),
    position
  );
  story.spots.forEach(spotId => {
    let spotDistance = getDistanceTo(
      arrayToCoordinates(spots[spotId].coordinates),
      position
    );
    if (spotDistance < distance) {
      distance = spotDistance;
    }
  });
  return distance;
}
