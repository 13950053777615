import React, { Fragment } from 'react';
import CrossIcon from '../../icons/CrossIcon.component';
import InfoIcon from '../../icons/InfoIcon.component';
import ClueIcon from '../../icons/ClueIcon.component';

import colors from '~styles/variables/colors.scss';
import './Modal.scss';

class ModalLayout extends React.PureComponent {
  icons = {
    info: InfoIcon,
    clue: ClueIcon
  };

  renderIcon() {
    const IconComponent = this.icons[this.props.icon];
    if (!IconComponent) {
      return '';
    }

    return <IconComponent className="c-modal__header-icon" />;
  }

  render() {
    return (
      <Fragment>
        <h2 className="c-modal__header">
          {this.props.icon && this.renderIcon()}
          {this.props.contentLabel}
        </h2>

        {!this.props.hideCross && (
          <button className="c-modal__cross" onClick={this.props.closeModal}>
            <CrossIcon color={colors.main} />
          </button>
        )}

        {this.props.title && (
          <div className="c-modal__title">{this.props.title}</div>
        )}

        <div className="c-modal__content">
          <div className="c-modal__scroll">
            <div className="c-modal__panel">{this.props.children}</div>
          </div>
        </div>

        {this.props.renderFooter && (
          <div className="c-modal__footer">{this.props.renderFooter()}</div>
        )}
      </Fragment>
    );
  }
}

export default ModalLayout;
