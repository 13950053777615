import React from 'react';

class TakeAwayPictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <g
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="2"
          strokeLinejoin="round"
        >
          <path d="M3,18 L17,18 L17,11 C17,6 15,3 10,3 C5,3 3,6 3,11z" />
          <path d="M6,18 L14,18 L14,15 C14,12 14,7 10,7 C6,7 6,12 6,15z" />
          <path d="M8,14 h4" />
          <path d="M4,0 v6 v-2 h2 v-4" />
          <path d="M16,0 v6 v-2 h-2 v-4" />
        </g>
      </svg>
    );
  }
}

export default TakeAwayPictogram;
