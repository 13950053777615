import React from 'react';

class PlanetIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 42 42"
        width={this.props.size || 42}
        height={this.props.size || 42}
        className={this.props.className}
      >
        <circle fill="white" cx="8" cy="8" r="2" />
        <circle fill="white" cx="21" cy="21" r="6" />
        <circle fill="white" cx="40" cy="10" r="2" />
        <circle fill="white" cx="35" cy="30" r="4" />
        <circle fill="white" cx="27" cy="5" r="4" />
        <circle fill="white" cx="6" cy="27" r="4" />
        <circle fill="white" cx="21" cy="40" r="2" />
      </svg>
    );
  }
}

export default PlanetIcon;
