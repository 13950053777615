import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_DATE_NEW_YEAR } from '../../../../../../share/constants/Badges.constants';

class BadgeDateNewYear extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#ffc247"
        label={BADGE_LABEL_DATE_NEW_YEAR}
        {...this.props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="30"
          height="30"
        >
          <path
            fill="#ffe733"
            d="M186.586 132.813l192.601 192.601c8.653 8.652 5.961 23.285-5.203 28.293L24.68 510.41c-14.653 6.574-29.664-8.437-23.09-23.09l156.7-349.304c5.01-11.164 19.644-13.856 28.296-5.203zm0 0"
          />
          <path
            fill="#ffe733"
            d="M379.188 325.414l-99.997-100-277.16 277.16c4.016 7.531 13.403 11.985 22.653 7.836l349.3-156.7c11.164-5.01 13.856-19.644 5.204-28.296zm0 0"
          />
          <path
            fill="#ffc247"
            d="M384.29 338.402c.175-4.613-1.446-9.332-5.103-12.988l-192.6-192.601c-8.652-8.653-23.285-5.961-28.293 5.203l-43.402 96.742zm0 0"
          />
          <path
            fill="#ffc247"
            d="M226.793 277.809l157.496 60.593c.176-4.613-1.445-9.332-5.101-12.988l-100-100zm0 0M136.035 460.457L31.18 421.359l41.855-93.3 192.793 74.171zm0 0"
          />
          <path
            fill="#ffc247"
            d="M69.105 435.5l66.93 24.957 129.793-58.227-118.039-45.414zm0 0M320.27 199.59a15.196 15.196 0 01-10.786-4.469c-5.96-5.957-5.96-15.617 0-21.574l19.45-19.453c5.96-5.957 15.62-5.961 21.578 0 5.957 5.957 5.957 15.617 0 21.574l-19.453 19.453a15.205 15.205 0 01-10.79 4.469zm0 0"
          />
          <path
            fill="#ffc247"
            d="M348.602 126.422a15.198 15.198 0 01-10.79-4.469c-5.957-5.957-5.957-15.617 0-21.578C345.349 92.84 349.5 82.816 349.5 72.16c0-10.66-4.152-20.68-11.688-28.219-5.957-5.957-5.957-15.613 0-21.574 5.958-5.957 15.618-5.96 21.575 0 13.3 13.301 20.625 30.985 20.625 49.793s-7.325 36.492-20.625 49.793a15.196 15.196 0 01-10.785 4.469zm0 0M473.578 173.39a15.196 15.196 0 01-10.785-4.468c-15.559-15.559-40.879-15.559-56.438 0-5.957 5.957-15.617 5.957-21.574 0s-5.957-15.617 0-21.578c27.457-27.453 72.13-27.453 99.586 0 5.957 5.957 5.957 15.617 0 21.578a15.221 15.221 0 01-10.789 4.469zm0 0"
          />
          <path
            fill="#ffc247"
            d="M281.652 145.785c-2.199 0-4.43-.476-6.547-1.484-7.609-3.621-10.84-12.723-7.218-20.332 15.629-32.84 8.84-72.192-16.89-97.926-5.962-5.957-5.962-15.617 0-21.574 5.956-5.957 15.616-5.957 21.573 0 34.848 34.843 44.035 88.136 22.868 132.613-2.614 5.492-8.086 8.703-13.786 8.703zm0 0M491.477 260.207a15.196 15.196 0 01-10.786-4.469c-25.734-25.73-65.086-32.52-97.921-16.89-7.606 3.62-16.711.39-20.332-7.22s-.391-16.714 7.218-20.331c44.473-21.172 97.766-11.977 132.61 22.867 5.96 5.957 5.96 15.617 0 21.574a15.205 15.205 0 01-10.79 4.469zm0 0M350.512 154.098v-.004l-41.028 41.027v.004a15.211 15.211 0 0010.79 4.469c3.906 0 7.808-1.489 10.788-4.469l19.454-19.453c5.957-5.957 5.957-15.617-.004-21.574zm0 0"
          />
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeDateNewYear;
