import React, { Fragment } from 'react';
import { renderForEach } from '../../../../utils/render';
import CheckPictogram from '../pictos/CheckPictogram.component';

import './FilterImage.scss';

class FilterImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: null
    };
  }

  onClick(filter) {
    this.setState(() => ({
      filter
    }));
  }

  renderButton(filter, key) {
    return (
      <button
        key={key}
        className="c-filter-image__input"
        title={filter && filter.name}
        style={{
          backgroundColor: filter && filter.color,
          color: filter && filter.contrast
        }}
        onClick={this.onClick.bind(this, filter)}
      >
        {this.state.filter === filter ? (
          <CheckPictogram />
        ) : (
          filter && filter.name
        )}
      </button>
    );
  }

  render() {
    return (
      <div className="c-filter-image">
        <div className="c-filter-image__wrapper">
          <img src={this.props.src} alt="" className="c-filter-image__image" />
          {this.state.filter && (
            <Fragment>
              <div
                className="c-filter-image__filter"
                style={{
                  backgroundColor: this.state.filter.color,
                  opacity: this.state.filter.opacity
                }}
              />
              <img
                src={this.state.filter.image}
                alt=""
                className="c-filter-image__appear"
              />
            </Fragment>
          )}
          {!this.state.filter && this.props.noFilterImage && (
            <Fragment>
              <img
                src={this.props.noFilterImage}
                alt=""
                className="c-filter-image__appear"
              />
            </Fragment>
          )}
        </div>

        <div className="c-filter-image__action">
          {this.renderButton(null)}
          {renderForEach(this.props.filters, this.renderButton.bind(this))}
        </div>
      </div>
    );
  }
}

export default FilterImage;
