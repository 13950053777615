import { connect } from 'react-redux';
import NotificationBar from './NotificationBar.component';
import { getLastUnreadNotificationByType } from '../../../modules/notification/Notification.selectors';
import {
  NOTIFICATION_NEW_BADGE,
  NOTIFICATION_NEW_RANK
} from '../../../modules/notification/Notification.constants';
import { readNotificationsByType } from '../../../modules/notification/Notification.actions';
import { getAllBadges } from '../../../modules/badge/Badge.selectors';
import {
  getIsBadgePage,
  getIsRankPage
} from '../../../modules/app/App.selectors';

function mapStateToProps(state) {
  return {
    isBadgePage: getIsBadgePage(state),
    isRankPage: getIsRankPage(state),
    badges: getAllBadges(state),
    newRankNotification: getLastUnreadNotificationByType(
      state,
      NOTIFICATION_NEW_RANK
    ),
    newBadgeNotification: getLastUnreadNotificationByType(
      state,
      NOTIFICATION_NEW_BADGE
    )
  };
}

const mapDispatchToProps = {
  readNotifications: () => {
    return function(dispatch) {
      dispatch(
        readNotificationsByType(NOTIFICATION_NEW_RANK, NOTIFICATION_NEW_BADGE)
      );
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBar);
