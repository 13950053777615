import React from 'react';
import { connect } from 'react-redux';
import { isGameApiReady } from '../../modules/game/Game.selectors';
import Error from '../layouts/Error/Error.component';
import WaitIcon from '../icons/WaitIcon.component';

function mapStateToProps(state) {
  return {
    isGameApiReady: isGameApiReady(state)
  };
}

export default function(Component) {
  class GameApiReady extends React.PureComponent {
    render() {
      if (!this.props.isGameApiReady) {
        return (
          <Error>
            <div>
              <WaitIcon disabled size={74} color="white" />
            </div>
            <p>En attente de connexion serveur...</p>
          </Error>
        );
      }

      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps)(GameApiReady);
}
