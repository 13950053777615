import React from 'react';
import { history } from '../modules/createStore';
import logoSrc from '../images/Zupple-logo.svg';
import textSrc from '../images/Zupple-text.svg';
import { to } from '../urls.js';
import { fetchApi } from '../modules/api/Api.functions';
import { encrypt } from '../../../utils/basis';
import { Link } from 'react-router-dom';
import * as EmailValidator from 'email-validator';

import './subscribe/Subscribe.scss';

class SubscribePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      invalidForm: true,
      showErrorMessage: false,
      email: null,
      pseudo: null,
      password: null,
      passwordConfirm: null,
      createError: false
    };
  }

  createUser(event) {
    event.preventDefault();
    if (this.state.invalidForm) {
      this.setState(() => {
        return {
          showErrorMessage: true
        };
      });
      return null;
    }
    const password = encrypt(this.state.password);
    const email = this.state.email;
    const pseudo = this.state.pseudo;
    fetchApi('/api/user/subscribe', {
      email,
      password,
      pseudo
    })
      .then(([{ error, user }]) => {
        if (error) {
          this.setState(() => {
            return {
              createError: error
            };
          });
          return null;
        }

        this.setState(() => {
          return {
            createError: false
          };
        });
        history.push(to('payment'), { email: user.email });
      })
      .catch(error => {
        console.error(error);
        this.setState(() => {
          return {
            createError: {
              label:
                "Erreur lors de la création du compte. Vous pouvez nous contacter à l'adresse contact@zupple.fr."
            }
          };
        });
      });
  }

  checkFormData() {
    this.setState(() => {
      return {
        email: document.forms.subscribe.email.value,
        pseudo: document.forms.subscribe.pseudo.value,
        password: document.forms.subscribe.password.value,
        passwordConfirm: document.forms.subscribe.passwordConfirm.value
      };
    });

    if (
      !document.forms.subscribe.email.value ||
      !document.forms.subscribe.password.value ||
      !document.forms.subscribe.passwordConfirm.value ||
      !document.forms.subscribe.pseudo.value
    ) {
      this.setState(() => {
        return {
          errorMessage:
            'Il manque des informations, merci de remplir tous les champs :)',
          invalidForm: true,
          showErrorMessage: false
        };
      });
      return false;
    }

    if (
      EmailValidator.validate(document.forms.subscribe.email.value) === false
    ) {
      this.setState(() => {
        return {
          errorMessage: 'Adresse email invalide',
          invalidForm: true,
          showErrorMessage: false
        };
      });
      return false;
    }

    if (
      document.forms.subscribe.password.value !==
      document.forms.subscribe.passwordConfirm.value
    ) {
      this.setState(() => {
        return {
          errorMessage: 'Les mots de passe ne correspondent pas :/',
          invalidForm: true,
          showErrorMessage: false
        };
      });
      return false;
    }

    this.setState(() => {
      return {
        invalidForm: false,
        showErrorMessage: false
      };
    });
    return true;
  }

  render() {
    return (
      <div className="c-subscribe">
        <div className="c-subscribe__content">
          <div className="c-subscribe__header">
            <img src={logoSrc} alt="" className="c-subscribe__logo" />{' '}
            <img
              src={textSrc}
              alt="Zupple"
              className="c-subscribe__header-text"
            />{' '}
          </div>
          <form name="subscribe" className="c-subscribe__form">
            {this.state.createError && (
              <div className="c-subscribe__error">
                {this.state.createError && this.state.createError.label}
              </div>
            )}
            {this.state.showErrorMessage && (
              <div className="c-subscribe__error">
                {this.state.errorMessage}
              </div>
            )}
            <label htmlFor="email" className="c-subscribe__input-label">
              Votre email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="E-mail"
              autoComplete="username"
              onInput={this.checkFormData.bind(this)}
              defaultValue={this.state.email}
              className={
                this.state.showErrorMessage && !this.state.email
                  ? 'c-subscribe__input-error'
                  : undefined
              }
            />
            <div className="c-subscribe__input-legend">
              Nous l'utiliserons pour vous confirmer votre inscription et vous
              renvoyer un mot de passe en cas d'oubli.
            </div>
            <label htmlFor="password" className="c-subscribe__input-label">
              Votre mot de passe
            </label>
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              defaultValue={this.state.password}
              autoComplete="current-password"
              onChange={this.checkFormData.bind(this)}
              className={
                (this.state.showErrorMessage && !this.state.password) ||
                this.state.password !== this.state.passwordConfirm
                  ? 'c-subscribe__input-error'
                  : undefined
              }
            />
            <label
              htmlFor="passwordConfirm"
              className="c-subscribe__input-label"
            >
              Confirmation de votre mot de passe
            </label>
            <input
              type="password"
              name="passwordConfirm"
              autoComplete="current-password"
              placeholder="Confirmation du mot de passe"
              defaultValue={this.state.passwordConfirm}
              onChange={this.checkFormData.bind(this)}
              className={
                (this.state.showErrorMessage && !this.state.passwordConfirm) ||
                this.state.password !== this.state.passwordConfirm
                  ? 'c-subscribe__input-error'
                  : undefined
              }
            />
            <label htmlFor="name" className="c-subscribe__input-label">
              Votre pseudo
            </label>
            <input
              id="pseudo"
              type="pseudo"
              name="pseudo"
              placeholder="Pseudo"
              defaultValue={this.state.pseudo}
              onInput={this.checkFormData.bind(this)}
              className={
                this.state.showErrorMessage && !this.state.pseudo
                  ? 'c-subscribe__input-error'
                  : undefined
              }
            />
            <div className="c-subscribe__input-legend">
              C'est le nom qui s'affichera sur votre interface de jeu.
            </div>

            <div className="o-action c-subscribe__button">
              <button
                className={
                  this.state.invalidForm
                    ? 'c-subscribe__button-disabled o-button o-button--alternate'
                    : 'o-button o-button--alternate'
                }
                type="submit"
                onClick={this.createUser.bind(this)}
              >
                Je crée mon compte
              </button>
            </div>
            <div className="o-action c-subscribe__button">
              <Link className="c-subscribe__connect-link" to={to('home')}>
                J'ai déjà un compte
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SubscribePage;
