import { connect } from 'react-redux';
import Map from './Map.component';
import {
  getGeolocationStatus,
  getPlayerPosition
} from '../../modules/geolocation/Geolocation.selectors';
import { getCurrentStoryId } from '../../modules/story/Story.selectors';
import {
  getHighlightedSpotId,
  getMapPosition,
  getMapZoom,
  isGlobalView,
  isSpotsView
} from '../../modules/map/Map.selectors';
import {
  moveMap,
  zoomMap,
  recenterMap,
  activeGlobalView,
  setHighlightedSpot
} from '../../modules/map/Map.actions';
import { getUser } from '../../modules/auth/Auth.selectors';
import stories from '~data/stories';

function mapStateToProps(state) {
  let storyId = getCurrentStoryId(state);

  return {
    geolocationStatus: getGeolocationStatus(state),
    playerPosition: getPlayerPosition(state),
    mapPosition: getMapPosition(state),
    mapZoom: getMapZoom(state),
    isGlobalView: isGlobalView(state),
    isSpotsView: isSpotsView(state),
    storyId,
    story: stories[storyId],
    user: getUser(state),
    highlightedSpotId: getHighlightedSpotId(state)
  };
}

const mapDispatchToProps = {
  moveMap,
  zoomMap,
  recenterMap,
  activeGlobalView,
  setHighlightedSpot
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
