import React from 'react';
import classNames from 'classnames';
import { renderFor, renderForEach } from '../../../../utils/render';

import './CrossWordsGrid.scss';

class CrossWordsGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lineValues: {}
    };
  }

  onChange(index, event) {
    let value = event.currentTarget.value;

    // Mobile browser do not respect input maxLength
    const maxLength = this.getLineWidth(index);
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
      event.currentTarget.value = value;
    }

    this.setState(state => ({
      lineValues: {
        ...state.lineValues,
        [index]: value
      }
    }));
  }

  renderLetterNumber(lineIndex, cellIndex, item, index) {
    if (item[0] !== lineIndex || item[1] !== cellIndex) {
      return '';
    }

    return (
      <div
        key={`cell-number-${lineIndex}-${cellIndex}`}
        className="c-cross-words-grid__number"
      >
        {index + 1}
      </div>
    );
  }

  renderCellContent(lineIndex, cellIndex) {
    let offset = this.props.content[lineIndex].indexOf('1');

    return (
      <div key={`cell-content-${lineIndex}-${cellIndex}`}>
        {this.state.lineValues[lineIndex] &&
          this.state.lineValues[lineIndex][cellIndex - offset]}
        {renderForEach(this.props.numbers, (item, index) =>
          this.renderLetterNumber(lineIndex, cellIndex, item, index)
        )}
      </div>
    );
  }

  getStyle(prop) {
    return this.props.style && this.props.style[prop];
  }

  renderCell(lineIndex, cellIndex) {
    const cellType = this.props.content[lineIndex].charAt(cellIndex);
    const isHidden = cellType === '0' || cellType === 'L';
    const isLegend = cellType === 'L';
    const isContent = cellType === '1';
    const isHighlight = this.props.colHighlight === cellIndex;
    const customStyles = !isHidden && !isHighlight;

    return (
      <div
        className={classNames('c-cross-words-grid__cell', {
          'c-cross-words-grid__cell--hidden': isHidden,
          'c-cross-words-grid__cell--highLight': isHighlight,
          'c-cross-words-grid__cell--legend': isLegend
        })}
        style={{
          color: customStyles && this.getStyle('color'),
          backgroundColor: customStyles && this.getStyle('backgroundColor'),
          borderColor: customStyles && this.getStyle('borderColor')
        }}
        key={`cell-${lineIndex}-${cellIndex}`}
      >
        {isContent && this.renderCellContent(lineIndex, cellIndex)}
        {isLegend && this.renderLegend(lineIndex)}
      </div>
    );
  }

  getLineWidth(index) {
    return this.props.content[index].replace(/^[0L]+|[0L]+$/g, '').length;
  }

  renderLine(index) {
    return (
      <div
        className="c-cross-words-grid__row"
        key={`row-${index}`}
        style={{
          height: this.getStyle('lineHeight'),
          marginBottom: this.getStyle('lineGap')
        }}
      >
        <input
          type="text"
          name={`row-${index}`}
          maxLength={this.getLineWidth(index)}
          size={this.getLineWidth(index)}
          className="c-cross-words-grid__input"
          onChange={this.onChange.bind(this, index)}
          autoComplete="new-password"
        />
        {renderFor(this.props.width, cellIndex =>
          this.renderCell(index, cellIndex)
        )}
      </div>
    );
  }

  renderLegend(index) {
    if (this.props.legends && this.props.legends[index]) {
      return <span>{this.props.legends[index]}</span>;
    }
    return '';
  }

  render() {
    return (
      <form className="c-cross-words-grid" autoComplete="off">
        {renderFor(this.props.height, index => this.renderLine(index))}
      </form>
    );
  }
}

export default CrossWordsGrid;
