import React from 'react';

class SignalPictogram extends React.PureComponent {
  render() {
    const color = this.props.color || 'currentColor';

    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <g
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
        >
          <g fill={color}>
            <path d="M3,18 V16 H17 V18z" />
          </g>
          <g fill="none">
            <path d="M5,16 L7.5,6.5 H12.5 L15,16z" />
            <path d="M1.5,8.5 H3" />
            <path d="M3.5,3 L4.5,4" />
            <path d="M10,1 V2.5" />
            <path d="M16.5,3 L15.5,4" />
            <path d="M18.5,8.5 H17" />
          </g>
        </g>
      </svg>
    );
  }
}

export default SignalPictogram;
