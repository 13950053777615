function initList(what) {
  const list = {};

  function add(item) {
    if (list[item.id]) {
      throw new Error(
        `Duplicate ${what} id ${item.id} between "${item.title}" and "${
          list[item.id].title
        }".`
      );
    }

    list[item.id] = item;
  }

  function getAll() {
    return list;
  }

  return {
    add,
    getAll
  };
}

module.exports = {
  initList
};
