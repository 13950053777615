import { connect } from 'react-redux';
import ClueModal from './ClueModal.component';
import {
  getNextClue,
  getRemainingCluesNumber,
  getInitialCluesNumber
} from '../../../modules/game/Game.selectors';
import { takeClue } from '../../../modules/game/Game.actions';
import { getRouteParams } from '../../../urls';
import { closeModal, pageView } from '../../../modules/ui/Ui.actions';

function mapStateToProps(state) {
  const spotId = getRouteParams(state.router.location);
  const remainingCluesNumber = getRemainingCluesNumber(state, spotId);
  const initialCluesCount = getInitialCluesNumber(state, spotId);
  const nextClue = getNextClue(state, spotId);

  return {
    spotId,
    remainingCluesNumber,
    nextClue,
    initialCluesCount
  };
}

const mapDispatchToProps = {
  takeClue,
  closeModal,
  pageView
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClueModal);
