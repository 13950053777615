import { connect } from 'react-redux';
import ModalButton from './ModalButton.component';
import { openModal } from '../../../modules/ui/Ui.actions';
import { isModalOpen } from '../../../modules/ui/Ui.selectors';

function mapStateToProps(state, ownProps) {
  return {
    isModalOpen: isModalOpen(state, ownProps.id)
  };
}

const mapDispatchToProps = {
  openModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalButton);
