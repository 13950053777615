import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import BackIcon from '../../icons/BackIcon.component';
import SpotIcon from '../../icons/SpotIcon.component';
import ThemeComponent from '../../theme/ThemeComponent.component';
import ThemeFeature from '../../theme/ThemeFeature.component';
import ThemeLabel from '../../theme/ThemeLabel.component';
import { ABOUT_MODAL } from '../Modal/Modal.constants';
import { to } from '../../../urls';
import withThemeFeatures from '../../theme/withThemeFeatures.hoc';

import colors from '~styles/variables/colors.scss';
import './Header.scss';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };

    this.rightLink = React.createRef();
    this.checkOutsideClick = this.checkOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkOutsideClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkOutsideClick, true);
  }

  checkOutsideClick(event) {
    if (
      this.state.isMenuOpen &&
      !this.rightLink.current.contains(event.target)
    ) {
      this.closeUserMenu();
    }
  }

  onMenuToggle(event) {
    event.preventDefault();
    this.setState(() => ({
      isMenuOpen: !this.state.isMenuOpen
    }));
  }

  closeUserMenu() {
    this.setState(() => ({
      isMenuOpen: false
    }));
  }

  onLogout() {
    this.props.disconnect();
  }

  openAboutModal() {
    this.closeUserMenu();
    this.props.openModal(ABOUT_MODAL);
  }

  renderLeftLink() {
    if (
      this.props.isHome ||
      (!this.props.isGeolocationApp && this.props.isSpotList)
    ) {
      let target;
      if (this.props.gameInProgressCount > 0) {
        target = 'spotsInProgress';
      } else if (this.props.gameToStartCount > 0) {
        target = 'spotsToStart';
      } else {
        target = 'spotsAnswered';
      }

      return (
        <div className="c-header__back">
          <Link to={to(target)} className="c-header__link" key="inProgress">
            <div className="c-header__text c-header__text--left">
              <div className="c-header__highlight">
                <ThemeLabel name="spot.plural" default="énigmes">
                  {label =>
                    this.props.storyId && !this.props.isSingleStory
                      ? 'Dans cette aventure'
                      : 'Mes ' + label
                  }
                </ThemeLabel>
              </div>
              <div className="c-header__small c-header__small--left">
                {this.props.gameToStartCount}
                <SpotIcon
                  size={12}
                  title="énigmes à commencer"
                  className="c-header__spot-icon"
                />
                {this.props.gameInProgressCount}
                <SpotIcon
                  inProgress
                  size={12}
                  title="énigmes à faire"
                  className="c-header__spot-icon"
                />
                {this.props.gameAnsweredCount}
                <SpotIcon
                  completed
                  size={12}
                  title="énigmes terminées"
                  className="c-header__spot-icon"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className="c-header__back">
        <Link
          to={this.props.lastPage}
          onClick={this.props.goBack}
          className="c-header__back-button"
          key="back"
        >
          <BackIcon
            color={this.props.isFullPage ? 'white' : colors.highlight2}
          />
        </Link>
      </div>
    );
  }

  renderRightLink() {
    return (
      <div key="user" className="c-header__user" ref={this.rightLink}>
        <button
          onClick={this.onMenuToggle.bind(this)}
          className="c-header__link"
        >
          <div key="user" className="c-header__text">
            <div className="c-header__highlight">
              {this.props.themeFeatures.hideFirstName
                ? 'Menu'
                : this.props.firstName}
            </div>
            <ThemeFeature
              name="xp"
              render={() => (
                <div className="c-header__small">{this.props.xp || 0} XP</div>
              )}
            />
          </div>
          <div className="c-header__menu-icon">
            <svg viewBox="0 0 20 20" width="10">
              <path
                d="M0,5 L10,15 L20,5"
                fill="none"
                stroke={this.props.isFullPage ? 'white' : colors.highlight2}
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </button>
        {this.renderMenu()}
      </div>
    );
  }

  renderMenu() {
    const classes = classNames('c-header__menu', {
      'c-header__menu--open': this.state.isMenuOpen,
      'c-header__menu--full-page': this.props.isFullPage
    });

    return (
      <div className={classes}>
        <div className="c-header__menu-list">
          <ThemeFeature
            name="profile"
            render={() => (
              <Link
                className="c-header__menu-link"
                to={to('accountEdit')}
                onClick={this.closeUserMenu.bind(this)}
              >
                Mon profil
              </Link>
            )}
          />
          <ThemeFeature
            name="nextMessage"
            render={() => (
              <Link
                className="c-header__menu-link"
                to={to('messagesList')}
                onClick={this.closeUserMenu.bind(this)}
              >
                <ThemeLabel name="messagesPageName" default="Mes Messages" />
              </Link>
            )}
          />
          <ThemeFeature
            name="rules"
            render={() => (
              <Link
                className="c-header__menu-link"
                to={to('rules')}
                onClick={this.closeUserMenu.bind(this)}
              >
                Les règles
              </Link>
            )}
          />
          <ThemeFeature
            name="about"
            render={() => (
              <Link
                className="c-header__menu-link"
                to={to('about')}
                onClick={this.closeUserMenu.bind(this)}
              >
                <ThemeLabel name="aboutPageName" default="À propos de Zupple" />
              </Link>
            )}
          />
          <ThemeFeature
            name="newsletter"
            render={() => (
              <a
                className="c-header__menu-link"
                href="https://zupple.fr/newsletter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Newsletter
              </a>
            )}
          />
          <ThemeFeature
            name="offrir"
            render={() => (
              <a
                className="c-header__menu-link"
                href="https://zupple.fr/bons-cadeaux/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Offrir
              </a>
            )}
          />
          <ThemeFeature
            name="disconnect"
            render={() => (
              <button
                className="c-header__menu-link"
                onClick={this.onLogout.bind(this)}
              >
                Me déconnecter
              </button>
            )}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="c-header">
        {this.renderLeftLink()}
        <ThemeComponent name="HeaderLogo" />
        {this.renderRightLink()}
      </div>
    );
  }
}

export default withThemeFeatures(Header);
