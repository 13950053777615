import React from 'react';
import Modal from './Modal.container';
import { FORGOTTEN_PASSWORD_MODAL } from './Modal.constants';
import { diffPropValue, newPropValue } from '../../../../../utils/basis';
import { fetchApi } from '../../../modules/api/Api.functions';

import './ForgottenPassword.scss';

class ForgottenPasswordModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      sendError: false,
      sendDone: false
    };
  }

  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'email')) {
      this.setState({ email: this.props.email });
    }
    if (newPropValue(this.props, prevProps, 'isModalOpen', false)) {
      this.setState({
        sendDone: false,
        email: this.props.email,
        sendError: false
      });
    }
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  onClick(event) {
    event.preventDefault();
    const email = this.state.email;
    fetchApi('/api/user/password/forgotten', {
      email
    })
      .then(([{ result }]) => {
        if (result.error) {
          this.setState(() => {
            return {
              sendError: result.error
            };
          });
          return false;
        } else {
          this.setState(() => {
            return {
              sendError: false,
              sendDone: true
            };
          });
          return true;
        }
      })
      .catch(e => {
        this.setState(() => {
          return {
            sendError: {
              label: 'Erreur lors de la réinitialisation du mot de passe.'
            }
          };
        });
      });
    return true;
  }

  renderButton() {
    return (
      <div className="o-action">
        <button
          type="submit"
          className="o-button"
          onClick={
            !this.state.sendDone
              ? this.onClick.bind(this)
              : this.props.closeModal
          }
        >
          {!this.state.sendDone && 'Valider'}
          {this.state.sendDone && 'Retour'}
        </button>
      </div>
    );
  }

  renderForm() {
    return (
      <form name="forgotten" className="c-forgotten-password__form">
        {this.state.sendError && (
          <div className="c-forgotten-password__error">
            {this.state.sendError && this.state.sendError.label}
          </div>
        )}
        Renseignez votre e-mail pour recevoir un nouveau mot de passe.
        <input
          type="email"
          name="email"
          placeholder="E-mail"
          autoComplete="username"
          value={this.state.email}
          onChange={this.handleChange.bind(this)}
          className={
            this.props.connectionError && this.props.connectionError.code === 1
              ? 'c-forgotten-password__input-error'
              : 'c-forgotten-password__input'
          }
        />
      </form>
    );
  }

  render() {
    return (
      <Modal
        modalId={FORGOTTEN_PASSWORD_MODAL}
        contentLabel="Mot de passe oublié"
        renderFooter={() => this.renderButton()}
      >
        {this.state.sendDone && (
          <p>
            {' '}
            Un nouveau mot de passe vient de vous être envoyé à l'adresse{' '}
            {this.state.email}.
          </p>
        )}
        {!this.state.sendDone && this.renderForm()}
      </Modal>
    );
  }
}

export default ForgottenPasswordModal;
