import React from 'react';
import CrossIcon from '../../../../../common/play/components/icons/CrossIcon.component';
import MessageSequence, {
  ANIMATION_STATUS
} from '../../../../../common/play/universes/default/answer/MessageSequence.component';
import { typography } from '../../../../../utils/basis';

import './WitchAnswer.scss';

const ANIMATION_TIMES = [
  { status: ANIMATION_STATUS.HIDDEN, delay: 0 },
  { status: ANIMATION_STATUS.SHOWN, delay: 500 },
  { status: ANIMATION_STATUS.HIDDEN, delay: 2000 }
];

const LAST_ANIMATION_TIMES = [
  { status: ANIMATION_STATUS.HIDDEN, delay: 0 },
  { status: ANIMATION_STATUS.SHOWN, delay: 1000 }
];

class WitchAnswer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      answer: false,
      hasCorrectAnswer: false,
      hasNextAction: false
    };
    this.input = React.createRef();
  }

  componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isOpen && prevProps.isOpen) {
      this.setState(() => ({
        answer: false,
        hasNextAction: false
      }));
    }
  }

  onAnimationEnd() {
    this.setState(() => ({
      hasNextAction: true
    }));
  }

  onAnswer(event) {
    event.preventDefault();
    const answer = document.forms.answer.answer.value;
    if (!answer.trim()) {
      return false;
    }

    const hasCorrectAnswer = this.props.checkAnswer(answer);
    this.setState(() => ({
      answer,
      hasCorrectAnswer
    }));
    return false;
  }

  renderStatusMessage() {
    if (!this.state.answer) {
      return null;
    }
    const sequence = (!this.props.spot.maxTry
      ? []
      : this.props.suspenseMessages
    ).map(message => ({
      message,
      animationTimes: ANIMATION_TIMES
    }));
    const lastMessage = this.props.getStatusMessage(
      this.state.hasCorrectAnswer,
      this.state.answer
    );
    sequence.push({
      message: typography(lastMessage),
      animationTimes: LAST_ANIMATION_TIMES
    });

    return (
      <div className="c-witch-answer__content">
        <p className="c-witch-answer__label">
          <MessageSequence
            sequence={sequence}
            onAnimationEnd={this.onAnimationEnd.bind(this)}
          />
        </p>
        {this.state.hasNextAction && (
          <div className="c-witch-answer__action">
            <button
              className="o-button o-button--fit"
              type="submit"
              onClick={this.props.closeModal}
            >
              Continuer
            </button>
          </div>
        )}
      </div>
    );
  }

  renderForm() {
    if (this.state.answer) {
      return null;
    }
    const questionMessage = this.props.getQuestionMessage();

    return (
      <form name="answer" className="c-witch-answer__content">
        <p className="c-witch-answer__label">{typography(questionMessage)}</p>
        <div className="c-witch-answer__player">
          <textarea
            name="answer"
            className="u-reset-textarea c-witch-answer__textarea"
            autoComplete="off"
            spellCheck="false"
            defaultValue={this.props.prefillAnswer}
            ref={this.input}
          />
        </div>
        <div className="c-witch-answer__action">
          <button
            className="o-button o-button--fit"
            type="submit"
            onClick={this.onAnswer.bind(this)}
          >
            Répondre
          </button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="c-witch-answer">
        <button
          className="c-witch-answer__close"
          type="button"
          onClick={this.props.closeModal}
        >
          <CrossIcon color="white" />
        </button>
        {this.renderStatusMessage()}
        {this.renderForm()}
      </div>
    );
  }
}

const CustomWitchAnswer = suspenseMessages => props => (
  <WitchAnswer suspenseMessages={suspenseMessages} {...props} />
);

export default CustomWitchAnswer;
