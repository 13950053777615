const colors = [
  {
    rgb: [253, 108, 158],
    name: 'Rose'
  },
  {
    rgb: [249, 66, 158],
    name: 'Rose bonbon'
  },
  {
    rgb: [253, 63, 146],
    name: 'Rose fuchsia'
  },
  {
    rgb: [255, 0, 255],
    name: 'Magenta'
  },
  {
    rgb: [219, 0, 115],
    name: 'Magenta fushia'
  },
  {
    rgb: [213, 132, 144],
    name: "Pelure d'oignon"
  },
  {
    rgb: [255, 134, 106],
    name: 'Rose thé'
  },
  {
    rgb: [255, 94, 77],
    name: 'Capucine'
  },
  {
    rgb: [252, 93, 93],
    name: 'Nacarat'
  },
  {
    rgb: [254, 195, 172],
    name: 'Chair'
  },
  {
    rgb: [253, 191, 183],
    name: 'Pêche'
  },
  {
    rgb: [255, 111, 125],
    name: 'Incarnat'
  },
  {
    rgb: [254, 231, 240],
    name: 'Cuisse de nymphe'
  },
  {
    rgb: [254, 191, 210],
    name: 'Rose dragée'
  },
  {
    rgb: [153, 122, 141],
    name: 'Rose Mountbatten'
  },
  {
    rgb: [199, 44, 72],
    name: 'Framboise'
  },
  {
    rgb: [172, 30, 68],
    name: 'Lie de vin'
  },
  {
    rgb: [145, 43, 59],
    name: 'Amarante'
  },
  {
    rgb: [109, 7, 26],
    name: 'Bordeaux'
  },
  {
    rgb: [150, 0, 24],
    name: 'Carmin'
  },
  {
    rgb: [187, 11, 11],
    name: 'Cerise'
  },
  {
    rgb: [219, 23, 2],
    name: 'Cinabre'
  },
  {
    rgb: [198, 8, 0],
    name: 'Coquelicot'
  },
  {
    rgb: [231, 62, 1],
    name: 'Corail'
  },
  {
    rgb: [237, 0, 0],
    name: 'Écarlate'
  },
  {
    rgb: [255, 73, 1],
    name: 'Feu vif'
  },
  {
    rgb: [191, 48, 48],
    name: 'Fraise'
  },
  {
    rgb: [164, 36, 36],
    name: 'Fraise écrasée'
  },
  {
    rgb: [238, 16, 16],
    name: 'Garance'
  },
  {
    rgb: [233, 56, 63],
    name: 'Grenadine'
  },
  {
    rgb: [207, 10, 29],
    name: 'Groseille'
  },
  {
    rgb: [226, 19, 19],
    name: 'Gueules'
  },
  {
    rgb: [198, 8, 0],
    name: 'Ponceau'
  },
  {
    rgb: [145, 40, 59],
    name: 'Queue-de-renard'
  },
  {
    rgb: [255, 0, 0],
    name: 'Rouge vif'
  },
  {
    rgb: [169, 17, 1],
    name: 'Andrinople'
  },
  {
    rgb: [247, 35, 12],
    name: 'Anglais'
  },
  {
    rgb: [165, 38, 10],
    name: 'Bismarck'
  },
  {
    rgb: [107, 13, 13],
    name: 'Bourgogne'
  },
  {
    rgb: [184, 32, 16],
    name: 'Cardinal'
  },
  {
    rgb: [217, 1, 21],
    name: 'Alizarine'
  },
  {
    rgb: [247, 35, 12],
    name: 'Rouge de Mars'
  },
  {
    rgb: [188, 32, 1],
    name: 'Ecrevisse'
  },
  {
    rgb: [254, 27, 0],
    name: 'Rouge feu'
  },
  {
    rgb: [110, 11, 20],
    name: 'Grenat'
  },
  {
    rgb: [133, 6, 6],
    name: 'Sang'
  },
  {
    rgb: [222, 41, 22],
    name: 'Tomate'
  },
  {
    rgb: [174, 74, 52],
    name: 'Tomette'
  },
  {
    rgb: [169, 17, 1],
    name: 'Turc'
  },
  {
    rgb: [219, 23, 2],
    name: 'Vermillon'
  },
  {
    rgb: [121, 248, 248],
    name: 'Aigue-marine'
  },
  {
    rgb: [116, 208, 241],
    name: 'Azur'
  },
  {
    rgb: [169, 234, 254],
    name: 'Azurin'
  },
  {
    rgb: [0, 0, 255],
    name: 'Cyan'
  },
  {
    rgb: [58, 142, 186],
    name: 'Acier'
  },
  {
    rgb: [104, 111, 140],
    name: 'Ardoise'
  },
  {
    rgb: [84, 114, 174],
    name: 'Bleuet'
  },
  {
    rgb: [4, 139, 154],
    name: 'Canard'
  },
  {
    rgb: [38, 196, 236],
    name: 'Céleste'
  },
  {
    rgb: [53, 122, 183],
    name: 'Cérule'
  },
  {
    rgb: [142, 162, 198],
    name: 'Charron'
  },
  {
    rgb: [119, 181, 254],
    name: 'Bleu ciel'
  },
  {
    rgb: [34, 66, 124],
    name: 'Cobalt'
  },
  {
    rgb: [36, 68, 92],
    name: 'Anvers'
  },
  {
    rgb: [49, 140, 231],
    name: 'Bleu de France'
  },
  {
    rgb: [0, 51, 102],
    name: 'Bleu de minuit'
  },
  {
    rgb: [36, 68, 92],
    name: 'Bleu de Prusse'
  },
  {
    rgb: [0, 204, 203],
    name: 'Bleu des mers du sud'
  },
  {
    rgb: [223, 242, 255],
    name: 'Bleu dragée'
  },
  {
    rgb: [44, 117, 255],
    name: 'Bleu électrique'
  },
  {
    rgb: [187, 210, 225],
    name: 'Fumée'
  },
  {
    rgb: [128, 208, 208],
    name: 'Givré'
  },
  {
    rgb: [86, 115, 154],
    name: 'Guède'
  },
  {
    rgb: [33, 23, 125],
    name: 'Klein'
  },
  {
    rgb: [96, 80, 220],
    name: 'Majorelle'
  },
  {
    rgb: [3, 34, 76],
    name: 'Marine'
  },
  {
    rgb: [15, 5, 107],
    name: 'Bleu nuit'
  },
  {
    rgb: [27, 1, 155],
    name: 'Outremer'
  },
  {
    rgb: [6, 119, 144],
    name: 'Paon'
  },
  {
    rgb: [102, 0, 255],
    name: 'Persan'
  },
  {
    rgb: [29, 72, 81],
    name: 'Pétrole'
  },
  {
    rgb: [49, 140, 231],
    name: 'Bleu roi'
  },
  {
    rgb: [1, 49, 180],
    name: 'Saphir'
  },
  {
    rgb: [0, 142, 142],
    name: 'Sarcelle'
  },
  {
    rgb: [66, 91, 138],
    name: 'Turquin'
  },
  {
    rgb: [37, 253, 233],
    name: 'Turquoise'
  },
  {
    rgb: [38, 196, 236],
    name: 'Caeruléum'
  },
  {
    rgb: [0, 255, 255],
    name: 'Cyan clair'
  },
  {
    rgb: [21, 96, 189],
    name: 'Denim'
  },
  {
    rgb: [187, 210, 225],
    name: 'Gris fumée'
  },
  {
    rgb: [38, 97, 156],
    name: 'Lapis-lazuli'
  },
  {
    rgb: [86, 115, 154],
    name: 'Pastel'
  },
  {
    rgb: [1, 49, 180],
    name: 'Safre'
  },
  {
    rgb: [150, 131, 236],
    name: 'lavande'
  },
  {
    rgb: [121, 28, 248],
    name: 'Indigo'
  },
  {
    rgb: [136, 77, 167],
    name: 'Améthyste'
  },
  {
    rgb: [106, 69, 93],
    name: 'Colombin'
  },
  {
    rgb: [201, 160, 220],
    name: 'Glycine'
  },
  {
    rgb: [210, 202, 236],
    name: 'Gris de lin'
  },
  {
    rgb: [223, 115, 255],
    name: 'Héliotrope'
  },
  {
    rgb: [182, 102, 210],
    name: 'Lilas'
  },
  {
    rgb: [212, 115, 212],
    name: 'Mauve'
  },
  {
    rgb: [128, 0, 128],
    name: 'Magenta foncé'
  },
  {
    rgb: [218, 112, 214],
    name: 'Orchidée'
  },
  {
    rgb: [207, 160, 233],
    name: 'Parme'
  },
  {
    rgb: [204, 204, 255],
    name: 'Pervenche'
  },
  {
    rgb: [129, 20, 83],
    name: 'Prune'
  },
  {
    rgb: [102, 0, 153],
    name: 'Violet'
  },
  {
    rgb: [114, 62, 100],
    name: "Violet d'évêque"
  },
  {
    rgb: [161, 6, 132],
    name: 'Violine'
  },
  {
    rgb: [108, 2, 119],
    name: 'Zinzolin'
  },
  {
    rgb: [244, 0, 161],
    name: 'Fuchsia'
  },
  {
    rgb: [240, 195, 0],
    name: 'Ambre jaune'
  },
  {
    rgb: [255, 203, 96],
    name: 'Aurore'
  },
  {
    rgb: [209, 182, 6],
    name: 'Banane'
  },
  {
    rgb: [240, 227, 107],
    name: 'Beurre'
  },
  {
    rgb: [255, 244, 141],
    name: 'Beurre frais'
  },
  {
    rgb: [254, 254, 226],
    name: 'Blanc cassé'
  },
  {
    rgb: [253, 241, 184],
    name: 'Blanc crème'
  },
  {
    rgb: [232, 214, 48],
    name: 'Blé'
  },
  {
    rgb: [252, 220, 18],
    name: "Bouton d'or"
  },
  {
    rgb: [251, 242, 183],
    name: 'Champagne'
  },
  {
    rgb: [247, 255, 60],
    name: 'Citron'
  },
  {
    rgb: [255, 255, 107],
    name: 'Fleur de soufre'
  },
  {
    rgb: [239, 210, 66],
    name: 'Auréolin'
  },
  {
    rgb: [231, 240, 13],
    name: 'Canari'
  },
  {
    rgb: [238, 209, 83],
    name: 'Jaune de Mars'
  },
  {
    rgb: [255, 240, 188],
    name: 'Naples'
  },
  {
    rgb: [255, 228, 54],
    name: 'Impérial'
  },
  {
    rgb: [255, 222, 117],
    name: 'Maïs'
  },
  {
    rgb: [218, 179, 10],
    name: 'Miel'
  },
  {
    rgb: [254, 248, 108],
    name: 'Mimosa'
  },
  {
    rgb: [199, 207, 0],
    name: 'Moutarde'
  },
  {
    rgb: [247, 226, 105],
    name: 'Nankin'
  },
  {
    rgb: [255, 255, 107],
    name: 'Soufre'
  },
  {
    rgb: [255, 215, 0],
    name: 'Or'
  },
  {
    rgb: [254, 227, 71],
    name: 'Paille'
  },
  {
    rgb: [252, 210, 28],
    name: 'Orpiment'
  },
  {
    rgb: [223, 175, 44],
    name: 'Ocre jaune'
  },
  {
    rgb: [252, 210, 28],
    name: 'Orpin de Perse'
  },
  {
    rgb: [250, 234, 115],
    name: 'Topaze'
  },
  {
    rgb: [243, 214, 23],
    name: 'Safran'
  },
  {
    rgb: [230, 126, 48],
    name: 'Abricot'
  },
  {
    rgb: [244, 102, 27],
    name: 'Carotte'
  },
  {
    rgb: [223, 109, 20],
    name: 'Citrouille'
  },
  {
    rgb: [231, 62, 1],
    name: 'Corail'
  },
  {
    rgb: [255, 73, 1],
    name: 'Feu vif'
  },
  {
    rgb: [237, 127, 16],
    name: 'Orange'
  },
  {
    rgb: [248, 142, 85],
    name: 'Saumon'
  },
  {
    rgb: [255, 127, 0],
    name: 'Tangerine'
  },
  {
    rgb: [127, 221, 76],
    name: 'Absinthe'
  },
  {
    rgb: [123, 160, 91],
    name: 'Asperge'
  },
  {
    rgb: [86, 130, 3],
    name: 'Avocat'
  },
  {
    rgb: [205, 205, 13],
    name: "Caca d'oie"
  },
  {
    rgb: [131, 166, 151],
    name: 'Céladon'
  },
  {
    rgb: [1, 215, 88],
    name: 'Émeraude'
  },
  {
    rgb: [100, 155, 136],
    name: 'Glauque'
  },
  {
    rgb: [135, 233, 144],
    name: 'Jade'
  },
  {
    rgb: [31, 160, 85],
    name: 'Malachite'
  },
  {
    rgb: [22, 184, 78],
    name: 'Menthe'
  },
  {
    rgb: [84, 249, 141],
    name: "Menthe à l'eau"
  },
  {
    rgb: [112, 141, 35],
    name: 'Olive'
  },
  {
    rgb: [190, 245, 116],
    name: 'Pistache'
  },
  {
    rgb: [76, 166, 107],
    name: 'Prasin'
  },
  {
    rgb: [20, 148, 20],
    name: 'Sinople'
  },
  {
    rgb: [1, 215, 88],
    name: 'Smaragdin'
  },
  {
    rgb: [0, 255, 0],
    name: 'Vert primaire'
  },
  {
    rgb: [130, 196, 108],
    name: 'amande'
  },
  {
    rgb: [159, 232, 85],
    name: 'anis'
  },
  {
    rgb: [9, 106, 9],
    name: 'bouteille'
  },
  {
    rgb: [194, 247, 50],
    name: 'chartreuse'
  },
  {
    rgb: [176, 242, 182],
    name: "Vert d'eau"
  },
  {
    rgb: [24, 57, 30],
    name: 'chrome'
  },
  {
    rgb: [149, 165, 149],
    name: 'Vert de gris'
  },
  {
    rgb: [27, 79, 8],
    name: 'Vert de Hooker'
  },
  {
    rgb: [34, 120, 15],
    name: 'Vert de vessie'
  },
  {
    rgb: [23, 87, 50],
    name: 'épinard'
  },
  {
    rgb: [58, 157, 35],
    name: 'gazon'
  },
  {
    rgb: [0, 86, 27],
    name: 'Vert impérial'
  },
  {
    rgb: [121, 137, 51],
    name: 'kaki'
  },
  {
    rgb: [133, 193, 126],
    name: 'lichen'
  },
  {
    rgb: [158, 253, 56],
    name: 'lime'
  },
  {
    rgb: [31, 160, 85],
    name: 'malachite'
  },
  {
    rgb: [56, 111, 72],
    name: 'mélèze'
  },
  {
    rgb: [89, 102, 67],
    name: 'militaire'
  },
  {
    rgb: [112, 141, 35],
    name: 'mousse'
  },
  {
    rgb: [151, 223, 198],
    name: 'opaline'
  },
  {
    rgb: [58, 242, 75],
    name: 'perroquet'
  },
  {
    rgb: [1, 121, 111],
    name: 'pin'
  },
  {
    rgb: [76, 166, 107],
    name: 'poireau'
  },
  {
    rgb: [52, 201, 36],
    name: 'pomme'
  },
  {
    rgb: [87, 213, 59],
    name: 'prairie'
  },
  {
    rgb: [0, 254, 126],
    name: 'printemps'
  },
  {
    rgb: [9, 82, 40],
    name: 'sapin'
  },
  {
    rgb: [104, 157, 113],
    name: 'sauge'
  },
  {
    rgb: [165, 209, 82],
    name: 'tilleul'
  },
  {
    rgb: [90, 101, 33],
    name: 'Véronèse'
  },
  {
    rgb: [64, 130, 109],
    name: 'Viride'
  },
  {
    rgb: [205, 133, 63],
    name: 'Brun clair'
  },
  {
    rgb: [136, 66, 29],
    name: 'Acajou'
  },
  {
    rgb: [167, 103, 38],
    name: 'Alezan'
  },
  {
    rgb: [173, 79, 9],
    name: 'Aquilain'
  },
  {
    rgb: [157, 62, 12],
    name: 'Auburn'
  },
  {
    rgb: [174, 100, 45],
    name: 'Baillet'
  },
  {
    rgb: [139, 108, 66],
    name: 'Basané'
  },
  {
    rgb: [118, 111, 100],
    name: 'Bis'
  },
  {
    rgb: [133, 109, 77],
    name: 'Bistre'
  },
  {
    rgb: [78, 61, 40],
    name: 'Bitume'
  },
  {
    rgb: [91, 60, 17],
    name: 'Blet'
  },
  {
    rgb: [91, 60, 17],
    name: 'Brun'
  },
  {
    rgb: [107, 87, 49],
    name: 'Bureau'
  },
  {
    rgb: [97, 78, 26],
    name: 'Bronze'
  },
  {
    rgb: [63, 34, 4],
    name: 'Brou de noix'
  },
  {
    rgb: [97, 75, 58],
    name: 'Cacao'
  },
  {
    rgb: [70, 46, 1],
    name: 'Café'
  },
  {
    rgb: [47, 27, 12],
    name: 'Cachou'
  },
  {
    rgb: [120, 94, 47],
    name: 'Café au lait'
  },
  {
    rgb: [126, 88, 53],
    name: 'Cannelle'
  },
  {
    rgb: [126, 51, 0],
    name: 'Caramel'
  },
  {
    rgb: [128, 109, 90],
    name: 'Châtaigne'
  },
  {
    rgb: [139, 108, 66],
    name: 'Châtain'
  },
  {
    rgb: [133, 83, 15],
    name: 'Chaudron'
  },
  {
    rgb: [90, 58, 34],
    name: 'Chocolat'
  },
  {
    rgb: [132, 90, 59],
    name: 'Claro'
  },
  {
    rgb: [112, 53, 22],
    name: 'Colorado'
  },
  {
    rgb: [106, 75, 33],
    name: 'Colorado claro'
  },
  {
    rgb: [179, 103, 0],
    name: 'Cuivre'
  },
  {
    rgb: [173, 79, 9],
    name: 'Fauve'
  },
  {
    rgb: [153, 81, 43],
    name: 'Feuille-morte'
  },
  {
    rgb: [148, 127, 96],
    name: 'Havane'
  },
  {
    rgb: [120, 94, 47],
    name: 'Isabelle'
  },
  {
    rgb: [148, 129, 43],
    name: 'Kaki'
  },
  {
    rgb: [143, 89, 34],
    name: 'Lavallière'
  },
  {
    rgb: [88, 41, 0],
    name: 'Marron'
  },
  {
    rgb: [135, 89, 26],
    name: 'Mordoré'
  },
  {
    rgb: [149, 86, 40],
    name: 'Noisette'
  },
  {
    rgb: [182, 120, 35],
    name: 'Poil de chameau'
  },
  {
    rgb: [152, 87, 23],
    name: 'Rouille'
  },
  {
    rgb: [173, 79, 9],
    name: 'Roux'
  },
  {
    rgb: [141, 64, 36],
    name: 'Senois'
  },
  {
    rgb: [159, 85, 30],
    name: 'Tabac'
  },
  {
    rgb: [167, 85, 2],
    name: 'Tanné'
  },
  {
    rgb: [70, 63, 50],
    name: 'Taupe'
  },
  {
    rgb: [146, 109, 39],
    name: "Terre d'ombre"
  },
  {
    rgb: [186, 155, 97],
    name: 'Claro claro'
  },
  {
    rgb: [142, 84, 52],
    name: 'Terre de Sienne'
  },
  {
    rgb: [150, 124, 92],
    name: 'Terre de Sienne brûlée'
  },
  {
    rgb: [200, 173, 127],
    name: 'Beige'
  },
  {
    rgb: [175, 167, 123],
    name: 'Beigeasse'
  },
  {
    rgb: [179, 177, 145],
    name: 'Mastic'
  },
  {
    rgb: [255, 228, 196],
    name: 'Bisque'
  },
  {
    rgb: [253, 241, 184],
    name: 'Blanc crème'
  },
  {
    rgb: [208, 192, 122],
    name: 'Chamois'
  },
  {
    rgb: [251, 242, 183],
    name: 'Champagne'
  },
  {
    rgb: [185, 178, 118],
    name: 'Clarissimo'
  },
  {
    rgb: [186, 155, 97],
    name: 'Double claro'
  },
  {
    rgb: [187, 174, 152],
    name: 'Grège'
  },
  {
    rgb: [221, 152, 92],
    name: 'Ocre rouge'
  },
  {
    rgb: [255, 239, 213],
    name: 'Papaye'
  },
  {
    rgb: [237, 211, 140],
    name: 'Papier bulle'
  },
  {
    rgb: [250, 240, 197],
    name: 'Platine'
  },
  {
    rgb: [168, 152, 116],
    name: 'Queue-de-vache foncé'
  },
  {
    rgb: [195, 180, 112],
    name: 'Queue-de-vache clair'
  },
  {
    rgb: [174, 137, 100],
    name: 'Sépia'
  },
  {
    rgb: [224, 205, 169],
    name: 'Sable'
  },
  {
    rgb: [225, 206, 154],
    name: 'Vanille'
  },
  {
    rgb: [233, 201, 177],
    name: 'Ventre de biche'
  },
  {
    rgb: [0, 0, 0],
    name: 'Noir'
  },
  {
    rgb: [48, 48, 48],
    name: 'Gris anthracite'
  },
  {
    rgb: [96, 96, 96],
    name: 'Gris'
  },
  {
    rgb: [90, 94, 107],
    name: 'Ardoise'
  },
  {
    rgb: [186, 186, 186],
    name: 'Étain oxydé'
  },
  {
    rgb: [175, 175, 175],
    name: 'Gris acier'
  },
  {
    rgb: [206, 206, 206],
    name: 'Argent'
  },
  {
    rgb: [239, 239, 239],
    name: 'Argile'
  },
  {
    rgb: [255, 255, 255],
    name: 'Blanc'
  },
  {
    rgb: [237, 237, 237],
    name: 'Étain pur'
  },
  {
    rgb: [103, 113, 121],
    name: 'Gris de Payne'
  },
  {
    rgb: [121, 128, 129],
    name: 'Plomb'
  },
  {
    rgb: [127, 127, 127],
    name: 'Gris fer'
  },
  {
    rgb: [206, 206, 206],
    name: 'Perle'
  },
  {
    rgb: [158, 158, 158],
    name: 'Souris'
  },
  {
    rgb: [187, 172, 172],
    name: 'Tourterelle'
  },
  {
    rgb: [193, 191, 177],
    name: 'Tourdille'
  },
  {
    rgb: [204, 204, 204],
    name: 'Pinchard'
  }
];

function getRGB(str) {
  return [
    parseInt(str.slice(1, 3), 16),
    parseInt(str.slice(3, 5), 16),
    parseInt(str.slice(5, 7), 16)
  ];
}

function getDistance(color1, color2) {
  return color1.reduce(function(acc, q, i) {
    return acc + Math.abs(q - color2[i]);
  }, 0);
}

export function getIsLight(code) {
  return getDistance([0, 0, 0], getRGB(code)) > 350;
}

export function getName(code) {
  const rgb = getRGB(code);
  let min = Infinity;
  let name = null;
  colors.forEach(function(color) {
    const distance = getDistance(rgb, color.rgb);
    if (min > distance) {
      name = color.name;
      min = distance;
    }
  });

  return name;
}
