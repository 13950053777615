import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { coordinatesToArray } from '../../modules/geolocation/Geolocation.functions';

import './PlayerMarker.scss';

class PlayerMarker extends React.Component {
  getIconHtml() {
    if (!this.props.hasOrientation) {
      return '';
    }

    const style = `transform: rotate(${315 -
      this.props.orientation}deg) skew(-15deg, -15deg) translate(1px, -5px);`;
    return `
      <div class="c-player-marker__orientation" style="${style}"></div>
    `;
  }

  render() {
    const className =
      'c-player-marker__icon' +
      (this.props.hasOrientation ? ' c-player-marker__icon--orientation' : '');
    const playerIcon = new L.DivIcon({
      popupAnchor: [0, -10],
      className,
      html: this.getIconHtml()
    });

    return (
      <Marker
        position={coordinatesToArray(this.props.position)}
        icon={playerIcon}
        zIndexOffset="-100"
        rotationOrigin="bottom left"
        rotationAngle="-135deg"
      >
        <Popup className="c-player-marker__popup">
          <div className="c-player-marker__content">Ma position</div>
        </Popup>
      </Marker>
    );
  }
}

export default PlayerMarker;
