import React from 'react';

import { typography } from '../../utils/basis';
import { renderForEach } from '../../utils/render';
import {
  TEXT,
  IMAGE,
  HTML
} from '../../common/play/constants/contentType.constants';

function renderText(content) {
  if (Array.isArray(content)) {
    return content
      .reduce(function(array, line, index) {
        return [
          ...array,
          <br key={index + 'b'} />,
          <span key={index}>{typography(line)}</span>
        ];
      }, [])
      .slice(1);
  }

  return typography(content);
}

class StructuredText extends React.PureComponent {
  render() {
    const baseUrl = this.props.baseUrl || '';

    return renderForEach(this.props.content, (item, index) => {
      if (!item.type) {
        return <p key={index}>{renderText(item.content || item)}</p>;
      }

      switch (item.type) {
        case TEXT:
          return (
            <p key={index} style={item.style || this.props.style}>
              {renderText(item.content)}
            </p>
          );

        case HTML:
          if (item.rootElement) {
            return React.createElement(item.rootElement.tag, {
              ...item.rootElement.props,
              key: index,
              dangerouslySetInnerHTML: { __html: item.content }
            });
          }

          return (
            <p
              key={index}
              dangerouslySetInnerHTML={{ __html: item.content }}
              style={item.style || this.props.style}
            />
          );

        case IMAGE:
          return (
            <figure key={index} style={item.rootStyle}>
              <img
                src={`${baseUrl}${item.content}`}
                alt={
                  item.alt ||
                  "Problème de chargement de l'image, merci de recharger la page"
                }
                height={item.height || 'auto'}
                width={item.width || 'auto'}
                style={{
                  display: 'block',
                  margin: '0 auto',
                  maxWidth: '100%',
                  ...(item.style || this.props.style)
                }}
              />
              {(item.caption ||
                item.source ||
                item.download ||
                item.downloadUrl) && (
                <figcaption className="c-rich-content__image-source">
                  {typography(item.caption)}
                  {item.caption && item.source ? ' ' : ''}
                  {item.source && (
                    <a
                      href={item.source}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: 'inline-block' }}
                    >
                      {item.sourceLabel || ''}
                    </a>
                  )}
                  {(item.download || item.downloadUrl) && (
                    <a
                      className="c-rich-content__download-link"
                      href={item.downloadUrl || item.content}
                      download
                    >
                      Télécharger l’image
                    </a>
                  )}
                </figcaption>
              )}
            </figure>
          );

        default:
          return null;
      }
    });
  }
}

export default StructuredText;
