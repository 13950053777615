import React from 'react';
import { renderFor } from '../../../../utils/render';

import './XpFlag.scss';

class XpFlag extends React.PureComponent {
  renderStar(index) {
    return (
      <svg
        viewBox="0 0 12 12"
        preserveAspectRatio="xMidYMin meet"
        className="c-xp-flag__star"
        key={`star-${index}`}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.9976963%"
            x2="99.9944101%"
            y2="49.9976963%"
            id="linearGradient-1"
          >
            <stop stopColor="#64CDF4" offset="0%" />
            <stop stopColor="#B8E986" offset="100%" />
          </linearGradient>
        </defs>

        <g id="icon-star" stroke="none" strokeWidth="1" fill="none">
          <path
            d="M11.9748708,4.18295265 C11.912302,4.01395313 11.7520231,3.899857 11.5711736,3.899857 L7.95332718,3.899857 L6.38567943,0.259933276 C6.31796802,0.102086006 6.17054571,0 5.99998152,0 C5.82941733,0 5.68199502,0.102086006 5.61428361,0.259933276 L4.04663586,3.899857 L0.42878943,3.899857 C0.247939964,3.899857 0.087661053,4.01395313 0.0250922803,4.18295265 C-0.0357622795,4.35281004 0.0165212155,4.5432562 0.155372465,4.65906805 L3.26409765,7.24724856 L1.69987833,11.4199068 C1.63388113,11.597485 1.68959306,11.7965098 1.83958669,11.9106059 C1.98958032,12.0264178 2.19528588,12.0298493 2.34956504,11.9200425 L6.00083863,9.31298892 L9.65211221,11.9200425 C9.80639138,12.0298493 10.0120969,12.0264178 10.1620906,11.9106059 C10.3120842,11.795652 10.3677961,11.597485 10.3017989,11.4199068 L8.7375796,7.24724856 L11.8463048,4.65906805 C11.9834418,4.5432562 12.0357253,4.35281004 11.9748708,4.18295265 Z"
            fill="url(#linearGradient-1)"
          />
        </g>
      </svg>
    );
  }

  renderMalus() {
    if (!this.props.malus) {
      return '';
    }

    return <span className="c-xp-flag__malus">&nbsp; -{this.props.malus}</span>;
  }

  computeLevel() {
    if (this.props.xp >= 70) {
      return 3;
    }
    if (this.props.xp >= 35) {
      return 2;
    }
    return 1;
  }

  renderStars() {
    const level = this.props.level || this.computeLevel();
    return <div>{renderFor(level, index => this.renderStar(index))}</div>;
  }

  render() {
    if (this.props.answered) {
      return (
        <div className="c-xp-flag">
          <div className="c-stories__answered-label">
            Terminé{this.props.isSpot ? 'e' : ''}&nbsp;!
          </div>
          <div className="c-stories__answered-xp">
            +{this.props.xp - (this.props.malus || 0)} XP
          </div>
        </div>
      );
    }

    if (this.props.blocked) {
      return (
        <div className="c-xp-flag">
          <div className="c-stories__answered-label">
            Perdu{this.props.isSpot ? 'e' : ''}&nbsp;!
          </div>
        </div>
      );
    }

    return (
      <div className="c-xp-flag">
        <div className="c-xp-flag__xp">
          {this.props.xp} XP
          {this.renderMalus()}
        </div>
        {this.renderStars()}
      </div>
    );
  }
}

export default XpFlag;
