import { connect } from 'react-redux';
import PlayerMarker from './PlayerMarker.component';
import {
  getPlayerOrientation,
  hasPlayerOrientation
} from '../../modules/geolocation/Geolocation.selectors';

function mapStateToProps(state, ownProps) {
  return {
    hasOrientation: hasPlayerOrientation(state),
    orientation: getPlayerOrientation(state)
  };
}

export default connect(mapStateToProps)(PlayerMarker);
