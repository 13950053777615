import {
  NOTIFICATION_NEW_BADGE,
  NOTIFICATION_NEW_RANK,
  NOTIFICATION_READ_STATUS,
  NOTIFICATION_UNREAD_STATUS,
  UPDATE_NOTIFICATIONS
} from './Notification.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';
import { createIndex } from '../../../../utils/basis';

const initialState = {
  all: {},
  byStatus: {
    [NOTIFICATION_READ_STATUS]: [],
    [NOTIFICATION_UNREAD_STATUS]: []
  },
  lastByType: {
    [NOTIFICATION_NEW_BADGE]: null,
    [NOTIFICATION_NEW_RANK]: null
  }
};

function first(array) {
  return array && !!array[0] ? array[0] : null;
}

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS:
      const all = {
        ...state.all,
        ...action.notifications
      };
      const byStatus = createIndex(Object.values(all), '_id', 'status');
      const byType = createIndex(
        Object.values(all).filter(
          notification => notification.status === NOTIFICATION_UNREAD_STATUS
        ),
        '_id',
        'type',
        function(notification) {
          return 0 - new Date(notification.date);
        }
      );

      return {
        ...state,
        all,
        byStatus: {
          [NOTIFICATION_READ_STATUS]: [],
          [NOTIFICATION_UNREAD_STATUS]: [],
          ...byStatus
        },
        lastByType: {
          [NOTIFICATION_NEW_BADGE]: first(byType[NOTIFICATION_NEW_BADGE]),
          [NOTIFICATION_NEW_RANK]: first(byType[NOTIFICATION_NEW_RANK])
        }
      };

    case LOGOUT_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default NotificationReducer;
