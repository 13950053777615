import React from 'react';
import { Link } from 'react-router-dom';
import { isRoute, to } from '../../../urls';
import classNames from 'classnames';

import StoryFooterIcon from '../../icons/StoryFooterIcon.component';
import TrophyIcon from '../../icons/TrophyIcon.component';
import MapIcon from '../../icons/MapIcon.component';
import ThemeFeature from '../../theme/ThemeFeature.component';
import ThemeLabel from '../../theme/ThemeLabel.component';
import ThemeData from '../../theme/ThemeData.component';

class FooterMainNav extends React.PureComponent {
  getLinkClasses(route) {
    let isActive;
    if (Array.isArray(route)) {
      const matchingRoutes = route.filter(item => {
        return !this.props.anyModalOpen && isRoute(item, this.props.location);
      });
      isActive = matchingRoutes.length > 0;
    } else {
      isActive =
        !this.props.anyModalOpen && isRoute(route, this.props.location);
    }

    return classNames('c-footer-nav__link', {
      'c-footer-nav__link--active': isActive
    });
  }

  render() {
    return (
      <ThemeFeature
        name="FooterMainNav"
        render={() => (
          <div className="c-footer-nav">
            <ul className="c-footer-nav__list">
              <li className="c-footer-nav__item">
                <Link to={to('home')} className={this.getLinkClasses('home')}>
                  <ThemeFeature
                    name="mainNavIcons"
                    render={() => <MapIcon size="21" context="footer" />}
                  />
                  <div className="c-footer-nav__name">
                    <ThemeLabel name="FooterMenu1" default="Carte" capitalize />
                  </div>
                </Link>
              </li>
              <ThemeData name="nav.FooterMenu2" default={{}}>
                {({ label, path, Icon }) => {
                  if (!path) {
                    return (
                      <ThemeFeature
                        name="universes"
                        render={() => (
                          <li className="c-footer-nav__item">
                            <Link
                              to={to('stories')}
                              className={this.getLinkClasses('stories')}
                            >
                              <ThemeFeature
                                name="mainNavIcons"
                                render={() => (
                                  <StoryFooterIcon size="21" context="footer" />
                                )}
                              />
                              <div className="c-footer-nav__name">
                                <ThemeLabel
                                  name="FooterMenu2"
                                  default="Aventures"
                                  capitalize
                                />
                              </div>
                            </Link>
                          </li>
                        )}
                      />
                    );
                  }

                  return (
                    <li className="c-footer-nav__item">
                      <Link to={path} className="c-footer-nav__link">
                        {Icon && <Icon size="21" context="footer" />}
                        <div className="c-footer-nav__name">{label}</div>
                      </Link>
                    </li>
                  );
                }}
              </ThemeData>
              <ThemeData name="nav.FooterMenu3" default={{}}>
                {({ label, path, Icon }) => {
                  if (!path) {
                    return (
                      <ThemeFeature
                        name="trophies"
                        render={() => (
                          <li className="c-footer-nav__item">
                            <Link
                              to={to('trophies')}
                              className={this.getLinkClasses([
                                'trophies',
                                'rank'
                              ])}
                            >
                              <ThemeFeature
                                name="mainNavIcons"
                                render={() => (
                                  <TrophyIcon size="21" context="footer" />
                                )}
                              />
                              <div className="c-footer-nav__name">
                                <ThemeLabel
                                  name="FooterMenu3"
                                  default="Trophées"
                                  capitalize
                                />
                              </div>
                              <ThemeFeature
                                name="menuNotification"
                                render={() =>
                                  this.props.hasNotification && (
                                    <div className="c-footer-nav__notif" />
                                  )
                                }
                              />
                            </Link>
                          </li>
                        )}
                      />
                    );
                  }

                  return (
                    <li className="c-footer-nav__item">
                      <Link to={path} className="c-footer-nav__link">
                        {Icon && <Icon size="21" context="footer" />}
                        <div className="c-footer-nav__name">{label}</div>
                      </Link>
                    </li>
                  );
                }}
              </ThemeData>
            </ul>
          </div>
        )}
      />
    );
  }
}

export default FooterMainNav;
