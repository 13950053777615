import React from 'react';

class CrossIcon extends React.PureComponent {
  render() {
    const size = this.props.size || 20;

    return (
      <svg
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        height={size}
        className={this.props.className}
      >
        <path
          d={`M2,2 L${size - 2},${size - 2} M${size - 2},2 L2,${size - 2}`}
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
}

export default CrossIcon;
