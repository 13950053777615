import {
  PROMPT_PERMISSION,
  UPDATE_ERROR,
  UPDATE_POSITION,
  UPDATE_CURRENT_ORIENTATION,
  UPDATE_RELATIVE_ORIENTATION,
  UPDATE_PERMISSION,
  UPDATE_STATUS,
  STOPPED_STATUS
} from './Geolocation.constants';

const initialState = {
  isGeolocationApp: true,
  error: null,
  permission: PROMPT_PERMISSION,
  status: STOPPED_STATUS,
  simulator: false,
  position: {
    latitude: 0,
    longitude: 0
  },
  orientation: {
    currentNorth: null,
    currentCount: 1,
    validatedNorth: null,
    isAbsoluteSensor: false,
    relative: null
  }
};

const GeolocationReducer = options => {
  initialState.isGeolocationApp = options.features.geolocation !== false;

  return (state = initialState, action) => {
    let orientation;

    switch (action.type) {
      case UPDATE_ERROR:
        return {
          ...state,
          error: action.error
        };

      case UPDATE_POSITION:
        return {
          ...state,
          position: action.position,
          error: null
        };

      case UPDATE_CURRENT_ORIENTATION:
        if (state.orientation.isAbsoluteSensor) {
          return state;
        }

        let currentCount = 1;
        const currentNorth =
          Math.round((action.orientation - state.orientation.relative) / 30) *
          30;
        if (currentNorth === state.orientation.currentNorth) {
          currentCount = state.orientation.currentCount + 1;
        }
        orientation = {
          ...state.orientation,
          currentNorth,
          currentCount
        };

        // You need 4 consistent values to activate the orientation sensor
        if (currentCount === 4) {
          orientation.validatedNorth = currentNorth;
        }

        return {
          ...state,
          orientation
        };

      case UPDATE_RELATIVE_ORIENTATION:
        orientation = {
          ...state.orientation
        };
        if (action.isAbsoluteSensor) {
          orientation.isAbsoluteSensor = true;
        }
        orientation.relative = action.orientation;

        return {
          ...state,
          orientation
        };

      case UPDATE_PERMISSION:
        return {
          ...state,
          permission: action.permission
        };

      case UPDATE_STATUS:
        return {
          ...state,
          status: action.status
        };

      default:
        return state;
    }
  };
};

export default GeolocationReducer;
