import { getBadgeLabel } from '../../../../share/constants/Badges.functions';

function getListSeparator(index, length) {
  if (index === length - 2) {
    return ' et ';
  }
  if (index === length - 1) {
    return '';
  }

  return ', ';
}

export function badgesToString(badges) {
  return badges
    .filter(Boolean)
    .map(badge => getBadgeLabel(badge))
    .reduce(function(acc, label, index) {
      const separator = getListSeparator(index, badges.length);

      return acc + '"' + label + '"' + separator;
    }, '');
}

export function notificationToString(badges) {
  const isPlural = badges.length > 1;

  return `Vous avez débloqué ${badges.length} nouveau${
    isPlural ? 'x' : ''
  } badge${isPlural ? 's' : ''} : ${badgesToString(badges)}`;
}
