import { initiate } from '../../../common/play/App';
import ConnectionBody from './components/connection/ConnectionBody.component';
import ConnectionHeader from './components/connection/ConnectionHeader.component';
import ConnectionFooter from './components/connection/ConnectionFooter.component';
import HeaderLogo from './components/header/HeaderLogo.component';
import AboutContent from './components/about/AboutContent.component';
import AutologinContent from './components/autologin/AutologinContent.component';
import AutologinFooter from './components/autologin/AutologinFooter.component';
import AutologinHeader from './components/autologin/AutologinHeader.component';
import SquircleIcon from '../../zupple/zupple-play/components/icons/SquircleIcon.component';
import AnswerIcon from '../../../common/play/components/icons/AnswerIcon.component';
import WitchAnswer from './components/answer/WitchAnswer.component';
import StoryEnd from './components/final/StoryEnd.component';
import FinalSpot from './components/final/FinalSpot.component';
import WitchAwardModal from './components/modal/WitchAwardModal.component';
import StoryProgress from './components/progress/StoryProgress.container';
import TimeMachine from './components/TimeMachine.container';

import {
  AWARD,
  STORY_END
} from '../../../common/play/components/layouts/Modal/AwardModal.constants';
import { REVIEW_MODAL } from '../../../common/play/components/layouts/Modal/Modal.constants';

import './styles/override.scss';

const MortissaAnswer = WitchAnswer([
  'Cette enquête est bien compliquée&nbsp;!',
  'Peu d’indice…',
  'Laissez-moi regarder…'
]);

initiate({
  features: {
    xp: false,
    universes: false,
    stories: false,
    trophies: false,
    subscribe: false,
    forgottenPassword: false,
    hideFirstName: true,
    profile: false,
    offrir: false,
    rules: false,
    newsletter: false,
    disconnect: false,
    problemAlerting: false,
    rankNotification: false,
    badgeNotification: false,
    menuNotification: false,
    mainNavIcons: false,
    geolocation: false,
    allowAutologin: true,
    nextMessage: false
  },
  labels: {
    answer: 'Répondre',
    messagesPageName: `Lettre de mission`,
    spot: {
      singular: 'piste',
      plural: 'pistes',
      the: 'la piste',
      thePlural: 'les pistes',
      this: 'cette piste',
      thisPlural: 'ces pistes',
      no: 'aucune piste'
    },
    identifier: 'Login',
    aboutPageName: 'À propos de ce jeu'
  },
  components: {
    TimeMachine,
    ConnectionHeader,
    ConnectionBody,
    ConnectionFooter,
    HeaderLogo,
    AutologinHeader,
    AutologinContent,
    AutologinFooter,
    About: AboutContent,
    Answer: MortissaAnswer,
    AnswerIcon: AnswerIcon,
    AwardModal: WitchAwardModal,
    StoryProgress: StoryProgress,
    StoryEndModal: StoryEnd,
    FinalSpotModal: FinalSpot,
    FormIcon: SquircleIcon
  },
  data: {
    singleStoryId: 1,
    map: {
      account: 'zupple',
      styles: 'cjz9uv8ee02ia1crw8miqemgo',
      token:
        'pk.eyJ1IjoienVwcGxlIiwiYSI6ImNqODFpdHpycDZlcGszM251OTBwc2szMHgifQ.ZNGfE6Jo_qZdNaYX0vv0Jg'
    },
    awardModalSequence: function(props) {
      return {
        [AWARD]: {
          display: true
        },
        [STORY_END]: {
          display: props.isEndOfStory,
          next: REVIEW_MODAL
        }
      };
    }
  }
});
