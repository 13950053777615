import {
  RUNNING_APP_STATUS,
  UPDATE_APP,
  UPDATE_ALL_MESSAGES,
  UPDATE_LAST_VERSION_AVAILABLE
} from './App.constants';
import version from '../../../../version';

const initialState = {
  status: RUNNING_APP_STATUS,
  lastVersionAvailable: version,
  allMessages: []
};

const AppReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case UPDATE_APP:
      return {
        ...state,
        status: action.app.status,
        allMessages: action.app.allMessages
      };

    case UPDATE_ALL_MESSAGES:
      return {
        ...state,
        allMessages: action.allMessages
      };

    case UPDATE_LAST_VERSION_AVAILABLE:
      newState = {
        ...state,
        lastVersionAvailable: action.version
      };

      return newState;

    default:
      return state;
  }
};

export default AppReducer;
