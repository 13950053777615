import {
  BROWSER_NOT_SUPPORTED_ERROR,
  GRANTED_PERMISSION,
  PERMISSION_DENIED_ERROR,
  POSITION_UNAVAILABLE_ERROR,
  STARTED_STATUS,
  STOPPED_STATUS,
  UPDATE_ERROR,
  UPDATE_CURRENT_ORIENTATION,
  UPDATE_RELATIVE_ORIENTATION,
  UPDATE_PERMISSION,
  UPDATE_POSITION,
  UPDATE_STATUS,
  WORKING_STATUS
} from './Geolocation.constants';
import {
  getGeolocationPermission,
  getGeolocationStatus,
  getPlayerPosition,
  getRelativeOrientation
} from './Geolocation.selectors';
import {
  getOrientationFromCoordinates,
  roundCoordinate
} from './Geolocation.functions';

let watchId;
const options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 15000
};

export function requestGeolocationPermission() {
  return function(dispatch) {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(permission => {
        dispatch(updateGeolocationPermission(permission.state));
        permission.onchange = () => {
          dispatch(updateGeolocationPermission(permission.state));
        };
      });
    }
  };
}

export function startGeolocation() {
  return function(dispatch, getState) {
    if (typeof watchId === 'number') {
      return true;
    }
    if (!('geolocation' in navigator)) {
      dispatch(updateGeolocationError(BROWSER_NOT_SUPPORTED_ERROR));
      return false;
    }

    // Reset status and error
    dispatch(updateGeolocationStatus(STARTED_STATUS));
    dispatch(clearGeolocationError());

    watchId = navigator.geolocation.watchPosition(
      position => {
        dispatch(updateLocation(position));
        const status = getGeolocationStatus(getState());
        if (status !== WORKING_STATUS) {
          dispatch(updateGeolocationStatus(WORKING_STATUS));
        }
        const permission = getGeolocationPermission(getState());
        if (permission !== GRANTED_PERMISSION) {
          dispatch(updateGeolocationPermission(GRANTED_PERMISSION));
        }
      },
      error => {
        if (
          error.code === PERMISSION_DENIED_ERROR ||
          error.code === POSITION_UNAVAILABLE_ERROR
        ) {
          dispatch(stopGeolocation());
        }
        dispatch(updateGeolocationError(error.code));
      },
      options
    );

    window.addEventListener('deviceorientation', event => {
      dispatch(updateRelativeOrientation(event));
    });
  };
}

export function stopGeolocation() {
  return function(dispatch) {
    if (typeof watchId === 'number') {
      navigator.geolocation.clearWatch(watchId);
      window.removeEventListener(
        'deviceorientation',
        updateRelativeOrientation
      );
      watchId = null;
      dispatch(updateGeolocationStatus(STOPPED_STATUS));
    }
  };
}

function getOrientationFromEvent(event) {
  if (event.hasOwnProperty('webkitCompassHeading')) {
    return {
      absolute: true,
      orientation: 360 - event.webkitCompassHeading
    };
  }
  if (event.alpha === null || typeof event.alpha === 'undefined') {
    return null;
  }
  if (event.absolute) {
    return {
      absolute: true,
      orientation: event.alpha
    };
  }
  return {
    absolute: false,
    orientation: event.alpha
  };
}

function updateRelativeOrientation(event) {
  return function(dispatch, getState) {
    const computed = getOrientationFromEvent(event);
    if (!computed) {
      return false;
    }
    const { absolute, orientation } = computed;
    const round = Math.round(orientation / 5) * 5;

    if (round === getRelativeOrientation(getState())) {
      return false;
    }

    dispatch({
      type: UPDATE_RELATIVE_ORIENTATION,
      absolute,
      orientation: round
    });
  };
}

export function updateLocation(newPosition) {
  return function(dispatch, getState) {
    const playerPosition = getPlayerPosition(getState());
    const position = {
      latitude: roundCoordinate(newPosition.coords.latitude),
      longitude: roundCoordinate(newPosition.coords.longitude)
    };

    if (
      playerPosition.latitude === position.latitude &&
      playerPosition.longitude === position.longitude
    ) {
      return false;
    }

    dispatch({
      type: UPDATE_POSITION,
      position
    });

    if (!playerPosition.latitude) {
      return false;
    }

    const orientation = getOrientationFromCoordinates(playerPosition, position);
    dispatch({
      type: UPDATE_CURRENT_ORIENTATION,
      orientation
    });
  };
}

export function updateGeolocationStatus(status) {
  return {
    type: UPDATE_STATUS,
    status: status
  };
}

export function updateGeolocationPermission(permissionState) {
  return {
    type: UPDATE_PERMISSION,
    permission: permissionState
  };
}

export function clearGeolocationError() {
  return {
    type: UPDATE_ERROR,
    error: null
  };
}

export function updateGeolocationError(errorCode) {
  return {
    type: UPDATE_ERROR,
    error: errorCode
  };
}
