import React, { Fragment } from 'react';
import UpdateBar from '../StatusBar/UpdateBar.component';
import ModalButton from '../Modal/ModalButton.container';
import ForgottenPasswordModal from '../Modal/ForgottenPasswordModal.container';
import { FORGOTTEN_PASSWORD_MODAL } from '../Modal/Modal.constants';
import ThemeComponent from '../../theme/ThemeComponent.component';
import ThemeLabel from '../../theme/ThemeLabel.component';
import ThemeFeature from '../../theme/ThemeFeature.component';
import { Link } from 'react-router-dom';
import { to } from '../../../urls';

import { encrypt } from '../../../../../utils/basis';

import conf from '~data/global';

import './ConnectionGate.scss';

class ConnectionGate extends React.PureComponent {
  constructor(props) {
    super(props);

    if (props.isConnected) {
      props.resetUserData();
    }
    this.state = {
      email: ''
    };
    this.props.checkAppStatus();
  }

  setEmail() {
    this.setState(() => {
      return {
        email: document.forms.connection.email.value
      };
    });
  }

  onClick(event) {
    event.preventDefault();
    this.props.authenticate(
      document.forms.connection.email.value.trim(),
      encrypt(document.forms.connection.password.value)
    );
  }

  render() {
    if (this.props.isConnected) {
      return this.props.children;
    }

    return (
      <Fragment>
        <UpdateBar />
        <div className="c-connexion-gate">
          <ThemeComponent
            name="ConnectionHeader"
            appStatus={this.props.appStatus}
          />

          <form name="connection" className="c-connexion-gate__form">
            <div className="c-connexion-gate__error">
              {this.props.connectionError && this.props.connectionError.label}{' '}
            </div>

            <ThemeLabel name="identifier" default="E-mail">
              {label => (
                <input
                  type={conf.loginType}
                  name="email"
                  placeholder={label}
                  autoComplete="username"
                  onChange={this.setEmail.bind(this)}
                  className={
                    this.props.connectionError &&
                    this.props.connectionError.code === 1
                      ? 'c-connexion-gate__input-error'
                      : undefined
                  }
                />
              )}
            </ThemeLabel>
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              autoComplete="current-password"
              className={
                this.props.connectionError &&
                this.props.connectionError.code === 2
                  ? 'c-connexion-gate__input-error'
                  : undefined
              }
            />
            <div className="o-action">
              <button
                type="submit"
                className="o-button o-button--alternate"
                onClick={this.onClick.bind(this)}
              >
                <ThemeLabel name="connection" default="Se connecter" />
              </button>
            </div>
          </form>
          <ThemeFeature
            name="subscribe"
            render={() => (
              <Link to={to('subscribe')} className="c-connexion-gate__link">
                Pas encore de compte ? Inscrivez-vous !
              </Link>
            )}
          />
          <ThemeFeature
            name="forgottenPassword"
            render={() => (
              <ModalButton
                className="c-connexion-gate__link"
                modalId={FORGOTTEN_PASSWORD_MODAL}
              >
                Mot de passe oublié ?
              </ModalButton>
            )}
          />
          <ThemeComponent
            name="ConnectionBody"
            appStatus={this.props.appStatus}
          />
          <ThemeComponent name="ConnectionFooter" />
        </div>
        <ForgottenPasswordModal email={this.state.email} />
      </Fragment>
    );
  }
}

export default ConnectionGate;
