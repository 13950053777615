import React from 'react';
import Modal from './Modal.container';
import { FINAL_SPOT_MODAL } from './Modal.constants';
import { ModalHoc } from './Modal.hoc';
import ThemeLabel from '../../theme/ThemeLabel.component';
import ThemeComponent from '../../theme/ThemeComponent.component';

class FinalSpotModal extends React.PureComponent {
  render() {
    const story = this.props.story;

    return (
      <ThemeLabel name="spot.plural" default="énigmes">
        {label => (
          <Modal
            modalId={FINAL_SPOT_MODAL}
            contentLabel={label + ' finale'}
            noDecorations
          >
            <ThemeComponent
              name="FinalSpotModal"
              proxyParams={story}
              closeModal={this.props.closeModal}
              storyId={story && story.id}
            />
          </Modal>
        )}
      </ThemeLabel>
    );
  }
}

export default ModalHoc(FinalSpotModal);
