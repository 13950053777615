import React from 'react';
import classNames from 'classnames';
import withPreFillAnswer from './withPrefillAnswer.hoc';
import { renderFor, renderForEach } from '../../../../utils/render';
import { getInputDefaultValue, updateInputValue } from './localStorage.utils';

import './SelectableGrid.scss';

class SelectableGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.root = React.createRef();
    this.state = {
      solved: false,
      validated: [],
      blocked: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkGlobalAnswer();
      this.checkEveryAnswers();
    }, 100);
  }

  onInput(event) {
    updateInputValue(this.props.mechanismId, event);
    const value = event.target.value;
    const cellId = event.target.getAttribute('data-id');
    if (cellId) {
      Array.from(
        this.root.current.querySelectorAll(`[data-id="${cellId}"]`)
      ).forEach(cell => {
        cell.value = value;
        updateInputValue(this.props.mechanismId, { target: cell });
      });
    }
    this.checkGlobalAnswer();
  }

  onSubmit(event) {
    event.preventDefault();
    this.checkEveryAnswers();
  }

  checkEveryAnswers() {
    const goodValues = this.props.hash.split(',');
    Array.from(this.root.current.querySelectorAll(`select`)).forEach(
      (cell, index) => {
        if (cell.value && cell.value === goodValues[index]) {
          this.setState(({ validated }) => ({
            validated: [...validated, cell.parentNode.id]
          }));
        }
      }
    );

    this.setState(() => ({
      blocked: true
    }));
    setTimeout(() => {
      this.setState(() => ({
        blocked: false
      }));
    }, 30000);
  }

  checkGlobalAnswer() {
    const hash = Array.from(this.root.current.querySelectorAll(`select`))
      .map(cell => {
        return cell.value || this.props.emptyChar;
      })
      .join(',');
    if (hash === this.props.hash) {
      this.props.prefillAnswer(this.props.answer);
      this.setState(() => ({
        solved: true
      }));
    }
  }

  getData(lineIndex, cellIndex) {
    const data =
      this.props.content &&
      this.props.content[lineIndex] &&
      this.props.content[lineIndex][cellIndex];
    if (!data) {
      return null;
    }

    if (Array.isArray(data)) {
      return { options: data };
    }

    return data;
  }

  renderCell(lineIndex, cellIndex) {
    const cell = this.getData(lineIndex, cellIndex);
    const id = `cell-${lineIndex}-${cellIndex}`;
    const isValidated = this.state.validated.includes(id);

    return (
      <td
        className={classNames('c-selectable-grid__cell', {
          'c-selectable-grid__cell--empty': !cell || !cell.options,
          'c-selectable-grid__cell--validated': isValidated
        })}
        key={`cell-${cellIndex}`}
        id={id}
      >
        {cell && (
          <select
            className="c-selectable-grid__select"
            name={`cell-${lineIndex}-${cellIndex}`}
            onInput={this.onInput.bind(this)}
            defaultValue={getInputDefaultValue(
              this.props.mechanismId,
              `cell-${lineIndex}-${cellIndex}`,
              ''
            )}
            data-id={cell.id}
            disabled={isValidated}
          >
            {cell.id && (
              <option value="" key="default">
                ({cell.id})
              </option>
            )}
            {renderForEach(cell.options, function(option) {
              return <option key={option}>{option}</option>;
            })}
          </select>
        )}
      </td>
    );
  }

  renderLine(index) {
    return (
      <tr key={`line-${index}`}>
        {renderFor(this.props.width, cellIndex =>
          this.renderCell(index, cellIndex)
        )}
      </tr>
    );
  }

  render() {
    if (this.state.solved) {
      return (
        <div className="c-selectable-grid" ref={this.root}>
          <div className="c-selectable-grid__success" ref={this.root}>
            {this.props.successMessage}
          </div>
        </div>
      );
    }

    return (
      <form className="c-selectable-grid" ref={this.root}>
        <table className="c-selectable-grid__table">
          <tbody>
            {renderFor(this.props.height, index => this.renderLine(index))}
          </tbody>
        </table>
        <div className="o-action">
          {this.state.blocked && (
            <em>Une nouvelle validation disponible dans 30s</em>
          )}
          {!this.state.blocked && (
            <button className="o-button" onClick={this.onSubmit.bind(this)}>
              Valider mes avancées
            </button>
          )}
        </div>
      </form>
    );
  }
}

export default withPreFillAnswer(SelectableGrid);
