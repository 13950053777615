import { connect } from 'react-redux';
import SpotList, { PAGE_NAME } from './SpotList.component';
import {
  countGameByStatus,
  getAllGames,
  getAnsweredGameIds,
  getInProgressGameIds,
  getVisibleSpots
} from '../../modules/game/Game.selectors';
import {
  getCurrentStoryId,
  getIsSingleStory
} from '../../modules/story/Story.selectors';
import {
  getIsGeolocationApp,
  getPlayerPosition,
  hasPlayerPosition
} from '../../modules/geolocation/Geolocation.selectors';
import { isRoute } from '../../urls.js';
import { getUser } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state) {
  const { gameToStartCount, gameInProgressCount } = countGameByStatus(state);

  let gameIds;
  let page;
  if (isRoute('spotsToStart', state.router.location)) {
    page = PAGE_NAME.TO_START;
  } else if (isRoute('spotsInProgress', state.router.location)) {
    page = PAGE_NAME.IN_PROGRESS;
    gameIds = getInProgressGameIds(state);
  } else if (isRoute('spotsAnswered', state.router.location)) {
    page = PAGE_NAME.ANSWERED;
    gameIds = getAnsweredGameIds(state);
  } else if (gameInProgressCount > 0) {
    page = PAGE_NAME.IN_PROGRESS;
    gameIds = getInProgressGameIds(state);
  } else if (gameToStartCount > 0) {
    page = PAGE_NAME.TO_START;
  } else {
    page = PAGE_NAME.ANSWERED;
    gameIds = getAnsweredGameIds(state);
  }

  return {
    page,
    isSingleStory: getIsSingleStory(state),
    isGeolocationApp: getIsGeolocationApp(state),
    hasPlayerPosition: hasPlayerPosition(state),
    playerPosition: getPlayerPosition(state),
    allGames: getAllGames(state),
    visibleSpots: getVisibleSpots(state),
    gameIds,
    storyId: getCurrentStoryId(state),
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(SpotList);
