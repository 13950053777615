import { connect } from 'react-redux';
import StatusBar from './StatusBar.component';
import {
  getGeolocationError,
  getGeolocationStatus
} from '../../../modules/geolocation/Geolocation.selectors';
import { WORKING_STATUS } from '../../../modules/geolocation/Geolocation.constants';

function mapStateToProps(state) {
  return {
    isMapDisplayed: getGeolocationStatus(state) === WORKING_STATUS,
    geolocationError: getGeolocationError(state),
    location: state.router.location
  };
}

export default connect(mapStateToProps)(StatusBar);
