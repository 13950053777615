import React from 'react';
import { AppContext } from '../../App';

class ThemeFeature extends React.PureComponent {
  render() {
    let { name, names, render } = this.props;
    if (name) {
      names = [name];
    }

    return (
      <AppContext.Consumer>
        {({ features }) => {
          if (names.every(name => features[name] === false)) {
            return null;
          }

          return render();
        }}
      </AppContext.Consumer>
    );
  }
}

export default ThemeFeature;
