import {
  MOVE_MAP,
  RECENTER_MAP,
  ACTIVE_GLOBAL_VIEW,
  ACTIVE_SPOTS_VIEW,
  SET_HIGHLIGHTED_SPOT
} from './Map.constants';

export function moveMap(position, zoom) {
  return {
    type: MOVE_MAP,
    position,
    zoom
  };
}

export function zoomMap(zoom) {
  return {
    type: MOVE_MAP,
    zoom
  };
}

export function recenterMap() {
  return {
    type: RECENTER_MAP
  };
}

export function activeGlobalView() {
  return {
    type: ACTIVE_GLOBAL_VIEW
  };
}

export function activeSpotsView() {
  return {
    type: ACTIVE_SPOTS_VIEW
  };
}

export function setHighlightedSpot(spot) {
  return {
    type: SET_HIGHLIGHTED_SPOT,
    spot
  };
}
