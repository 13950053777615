import React from 'react';

import colors from '~styles/variables/colors.scss';

class MapIcon extends React.PureComponent {
  render() {
    let prefix = 'icon';
    if (this.props.context) {
      prefix = this.props.context;
    }
    return (
      <svg
        viewBox="3.5 3.5 66 66"
        width={this.props.size || 74}
        height={this.props.size || 74}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.998%"
            x2="99.994%"
            y2="49.998%"
            id={`${prefix}-spot`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.activeTo} offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none">
          <path
            d="M25 0C11.193 0 0 11.011 0 24.595 0 38.178 11.607 56.216 25 65c14.286-9.662 25-26.822 25-40.405C50 11.01 38.807 0 25 0zM11 25c0-7.72 6.28-14 14-14s14 6.28 14 14-6.28 14-14 14-14-6.28-14-14z"
            fill={`url(#${prefix}-spot)`}
            transform="translate(12 4)"
          />
          <path
            d="M16 25c0 4.963 4.037 9 9 9s9-4.037 9-9-4.037-9-9-9-9 4.037-9 9"
            fill={`url(#${prefix}-spot)`}
            transform="translate(12 4)"
          />
        </g>
      </svg>
    );
  }
}

export default MapIcon;
