import React, { Fragment } from 'react';

import Map from './map/Map.container';
import FinalSpotModal from './layouts/Modal/FinalSpotModal.component';
import SpotList from './spots/SpotList.container';
import { ERROR_MODAL } from './layouts/Modal/Modal.constants';

class HomePage extends React.PureComponent {
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.error) {
      this.props.openModal(ERROR_MODAL, {
        error: this.props.location.state.error
      });
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.isGeolocationApp && <Map />}
        {!this.props.isGeolocationApp && <SpotList />}
        <FinalSpotModal />
      </Fragment>
    );
  }
}

export default HomePage;
