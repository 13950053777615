import React from 'react';
import ThemeComponent from '../theme/ThemeComponent.component';
import {
  getCheerMessage,
  getHasCorrectSpotAnswer
} from '../../../../utils/answer';

const genericMessages = {
  validation: 'Bravo !',
  fail: 'Perdu !',
  blocked: `Perdu ! C’était votre dernier essai.`
};

class Answer extends React.PureComponent {
  getTryCount(gap = 0) {
    return this.props.tryCount + gap;
  }

  checkAnswer(answer) {
    this.props.proposeAnswer(this.props.spot.id, answer, this.props.malus);
    const hasCorrectAnswer = getHasCorrectSpotAnswer(this.props.spot, answer);
    this.props.setAnswer({
      spot: this.props.spot,
      malus: this.props.malus,
      hasCorrectAnswer,
      tryCount: this.getTryCount(1)
    });

    return hasCorrectAnswer;
  }

  getQuestionMessage() {
    const dialog = this.props.spot.dialog;
    if (dialog && dialog.question) {
      return dialog.question;
    }

    return 'Quelle est votre réponse ?';
  }

  getStatusMessage(
    hasCorrectAnswer,
    answer,
    defaultMessages = genericMessages
  ) {
    const { dialog, maxTry } = this.props.spot;
    if (hasCorrectAnswer) {
      if (dialog && dialog.validation) {
        return dialog.validation;
      }
      return defaultMessages.validation;
    }

    // Blocked
    const tryCount = this.getTryCount();
    if (maxTry && tryCount >= maxTry) {
      if (dialog && dialog.blocked) {
        return dialog.blocked;
      }
      return defaultMessages.blocked;
    }

    // Cheers
    const cheerMessage = getCheerMessage(this.props.spot, answer);
    if (cheerMessage) {
      return cheerMessage;
    }

    // Fail
    if (!dialog || !dialog.fail) {
      return defaultMessages.fail;
    }
    if (Array.isArray(dialog.fail)) {
      const failIndex = tryCount - 1;
      if (!dialog.fail[failIndex]) {
        return dialog.fail[dialog.fail.length - 1];
      }
      return dialog.fail[failIndex];
    }

    return dialog.fail;
  }

  render() {
    const { spot, story } = this.props;
    if (!spot) {
      return null;
    }

    return (
      <ThemeComponent
        name="Answer"
        proxyParams={[story, spot]}
        spot={spot}
        story={story}
        isLast={this.props.isLast}
        isOpen={this.props.isOpen}
        tryCount={this.getTryCount()}
        checkAnswer={this.checkAnswer.bind(this)}
        getQuestionMessage={this.getQuestionMessage.bind(this)}
        getStatusMessage={this.getStatusMessage.bind(this)}
        spotName={spot.title}
        closeModal={this.props.closeModal}
        prefillAnswer={this.props.prefillAnswer}
      />
    );
  }
}

export default Answer;
