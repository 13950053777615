function getClickCoordinates(event, reference, referenceWidth) {
  const isTouch = event.changedTouches;
  const { width, top, left } = reference.getBoundingClientRect();
  const ratio = referenceWidth / width;
  const pageX = isTouch ? event.changedTouches[0].pageX : event.pageX;
  const pageY = isTouch ? event.changedTouches[0].pageY : event.pageY;
  const x = (pageX - left) * ratio;
  const y = (pageY - top) * ratio;

  return { x, y };
}

function findElementAt(elements, x, y) {
  if (!elements) {
    return null;
  }

  return elements.find(element => {
    return (
      element.x < x &&
      element.x + element.width > x &&
      element.y < y &&
      element.y + element.height > y
    );
  });
}

function debugElements(canvas, elements) {
  var ctx = canvas.getContext('2d');
  elements.forEach(function(element) {
    ctx.beginPath();
    ctx.rect(element.x, element.y, element.width, element.height);
    ctx.stroke();
  });
}

function playSound(sound, soundStates, currentAudio) {
  let url = sound.url;
  if (sound.urlList) {
    soundStates[sound.name] = soundStates[sound.name] || 0;
    url = sound.urlList[soundStates[sound.name]];
    soundStates[sound.name] =
      (soundStates[sound.name] + 1) % sound.urlList.length;
  }

  if (currentAudio) {
    currentAudio.pause();
    currentAudio = null;
  }

  if (url) {
    currentAudio = new Audio(url);
    currentAudio.play();
  }

  return {
    soundStates,
    currentAudio,
    audioUrl: url
  };
}

module.exports = {
  getClickCoordinates,
  findElementAt,
  debugElements,
  playSound
};
