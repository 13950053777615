import { connect } from 'react-redux';
import FooterRiddleNav from './FooterRiddleNav.component';
import {
  getNextClue,
  getTryCount,
  isGameAnswered,
  isGameBlocked
} from '../../../modules/game/Game.selectors';
import spots from '~data/spots';

function mapStateToProps(state, ownProps) {
  const spot = spots[ownProps.spotId];
  const nextClue = getNextClue(state, ownProps.spotId);

  return {
    spot,
    isGameAnswered: isGameAnswered(state, ownProps.spotId),
    isGameBlocked: isGameBlocked(state, ownProps.spotId),
    tryCount: getTryCount(state, ownProps.spotId),
    nextClue
  };
}

export default connect(mapStateToProps)(FooterRiddleNav);
