import React from 'react';

class BackIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="3 0 12 20"
        width={this.props.size || 74}
        height={this.props.size || 74}
        className={this.props.className}
      >
        <path
          d="M5.92893219,10 L13.7071068,17.7781746 C14.0976311,18.1686989 14.0976311,18.8018639 13.7071068,19.1923882 C13.3165825,19.5829124 12.6834175,19.5829124 12.2928932,19.1923882 L3.80761184,10.7071068 C3.41708755,10.3165825 3.41708755,9.68341751 3.80761184,9.29289322 L12.2928932,0.807611845 C12.6834175,0.417087553 13.3165825,0.417087553 13.7071068,0.807611845 C14.0976311,1.19813614 14.0976311,1.83130112 13.7071068,2.22182541 L5.92893219,10 Z"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default BackIcon;
