import { connect } from 'react-redux';
import StoryIntroPage from './StoryIntroPage.component';
import { openModal } from '../../modules/ui/Ui.actions';
import { playStory } from '../../modules/story/Story.actions';
import { getUser, isConnected } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state) {
  return {
    isConnected: isConnected(state),
    user: getUser(state)
  };
}

const mapDispatchToProps = {
  playStory,
  openModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryIntroPage);
