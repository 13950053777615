import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import classNames from 'classnames';
import L from 'leaflet';
import SpotPreview from '../spots/SpotPreview.container';
import {
  arrayToCoordinates,
  getDistanceTo
} from '../../modules/geolocation/Geolocation.functions';
import { diffPropValue } from '../../../../utils/basis';
import { hasFullAccess } from '../../modules/auth/Auth.functions';

// Datas
import stories from '~data/stories';
import universes from '~data/universes';
import stripeConf from '~data/stripe';

import './SpotMarker.scss';

class SpotMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHighlighted: this.props.highlightedSpotId === this.props.spotId
    };
  }

  getSpotColor() {
    const spot = this.props.spot;
    if (spot.topLineColor) {
      return spot.topLineColor;
    }

    if (!spot.storyId) {
      return {};
    }

    const story = stories[spot.storyId];
    const universe = universes[story.universeId];

    return story.topLineColor || universe.topLineColor;
  }

  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'highlightedSpotId')) {
      if (this.props.highlightedSpotId === this.props.spotId) {
        if (!this.state.isHighlighted) {
          this.setState(() => ({
            isHighlighted: true
          }));
        }
      } else {
        this.setState(() => ({
          isHighlighted: false
        }));
      }
    }
  }

  hasPulse(distance) {
    const maxDistance =
      this.props.reachDistance + (this.props.spot.overReachDistance || 0);

    return !this.props.isGameStarted && distance <= maxDistance;
  }

  isInCurrentStory() {
    return (
      !this.props.storyId || this.props.storyId === this.props.spot.storyId
    );
  }

  getIconClassName() {
    const {
      spot,
      isGameAnswered,
      isGameBlocked,
      isGameStarted,
      user
    } = this.props;

    let isLightSpot =
      spot.status === 'teaser' ||
      (stripeConf.products &&
        !isGameStarted &&
        !spot.isFree &&
        !hasFullAccess(user));

    return classNames(
      'c-spot-marker__icon',
      `c-spot-marker__icon--story-${spot.storyId}`,
      {
        'c-spot-marker__icon--highlighted': this.state.isHighlighted,
        'c-spot-marker__icon--draft': spot.status === 'draft',
        'c-spot-marker__icon--secondary': !this.isInCurrentStory(),
        'c-spot-marker__icon--completed': isGameAnswered || isGameBlocked,
        'c-spot-marker__icon--answered': isGameAnswered,
        'c-spot-marker__icon--blocked': !isGameAnswered && isGameBlocked,
        'c-spot-marker__icon--ongoing': isGameStarted,
        'c-spot-marker__icon--final': spot.isFinal,
        'c-spot-marker__icon--light': isLightSpot
      }
    );
  }

  getIconZoomRatio() {
    if (this.props.zoom < 14) {
      return 0.1;
    }
    if (this.props.zoom > 15) {
      return 1;
    }

    return (this.props.zoom - 13) / 6 + 0.5;
  }

  computeIconWidth() {
    if (this.props.isGameAnswered || this.props.isGameBlocked) {
      return 12;
    }

    if (this.props.spot.riddle.level) {
      return Math.round(this.props.spot.riddle.level * 5) + 10;
    }

    return Math.round(this.props.spot.xp / 10) + 12;
  }

  render() {
    const { spot } = this.props;
    if (spot.isFinal && !this.props.isStoryComplete) {
      return null;
    }

    const size = this.computeIconWidth();
    const ratio = this.getIconZoomRatio();
    const spotColor = this.getSpotColor();
    const position = arrayToCoordinates(spot.coordinates);
    const distance = getDistanceTo(position, this.props.playerPosition);
    let className = this.getIconClassName(distance);

    const BubbleStyle = `
      width: ${size * ratio}px;
      height: ${size * ratio}px;
      ${spot.markerStyles}
    `;
    const PulseStyle = `
      width: ${24 * ratio}px;
      height: ${24 * ratio}px;
    `;

    const html = `
      <div class="c-spot-marker__scale" style="color: ${spotColor}">
        ${
          this.hasPulse(distance)
            ? `<div class="c-spot-marker__icon-pulse" style="${PulseStyle}"></div>`
            : ''
        }
        <div class="c-spot-marker__icon-border" style="${
          spot.markerStyles
        }"></div>
        <div class="c-spot-marker__icon-bubble" style="${BubbleStyle}">
          <svg class="c-spot-marker__icon-dot" width="19" height="15" viewBox="0 0 19 15" style="${
            spot.iconStyles
          }">
            <circle cx="9.5" cy="7.5" r="3.5" fill="white" />
          </svg>
          <svg class="c-spot-marker__icon-check" width="19" height="15" viewBox="0 0 19 15" style="${
            spot.iconStyles
          }">
            <path fill="none" stroke="white" stroke-width="4" stroke-linecap="round" d="M2,7 L7,12 L17,2"/>
          </svg>
          <svg class="c-spot-marker__icon-cross" width="19" height="15" viewBox="0 0 19 19" style="${
            spot.iconStyles
          }">
            <path fill="none" stroke="white" stroke-width="4" stroke-linecap="round" d="M2,2 L17,17 M2,17 L17,2"/>
          </svg>
        </div>
      </div>
    `;

    const spotIcon = new L.DivIcon({
      popupAnchor: [0, -20],
      iconSize: [28, 28],
      className,
      html
    });

    return (
      <Marker position={spot.coordinates} icon={spotIcon}>
        <Popup>
          <SpotPreview spot={spot} distance={distance} />
        </Popup>
      </Marker>
    );
  }
}

export default SpotMarker;
