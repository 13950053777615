import React, { Fragment } from 'react';

// Components
import { Map as LMap } from 'react-leaflet';
import L from 'leaflet';
import PlanetIcon from '../icons/PlanetIcon.component';
import MapCacheLayer from './MapCacheLayer.component';
import PlayerMarker from './PlayerMarker.container';
import SpotMarker from './SpotMarker.container';
import TargetIcon from '../icons/TargetIcon.component';
import ThemeComponent from '../theme/ThemeComponent.component';
import ThemeData from '../theme/ThemeData.component';
import StoryProgressBox from '../story/StoryProgressBox.container';

// Functions
import {
  arrayToCoordinates,
  coordinatesToArray
} from '../../modules/geolocation/Geolocation.functions';
import { renderForEach } from '../../../../utils/render';
import { isSpotVisible } from '../../modules/story/Story.functions';

// Data
import spots from '~data/spots';

// Styles
import colors from '~styles/variables/colors.scss';
import './Map.scss';

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.map = React.createRef();
  }

  componentDidMount() {
    this.checkZoom();
  }

  componentDidUpdate() {
    this.checkZoom();
  }

  checkZoom() {
    const zoom = this.getLeafletElement().getZoom();
    if (zoom !== this.props.zoom) {
      this.props.zoomMap(zoom);
    }
  }

  onZoom() {
    // When users zoom in, they can change the center
    this.onMove();
  }

  onMove() {
    this.getLeafletElement().closePopup();
    let zoom = this.getLeafletElement().getZoom();
    let newCenter = this.getCenter();
    if (this.isNear(this.getCurrentCenter(), newCenter)) {
      newCenter = null;
    }
    this.props.moveMap(newCenter, zoom);
  }

  isNear(position1, position2) {
    return (
      Math.abs(position2.latitude - position1.latitude) < 0.0003 &&
      Math.abs(position2.longitude - position1.longitude) < 0.0003
    );
  }

  getCenter() {
    const center = this.getLeafletElement().getCenter();

    return {
      latitude: center.lat,
      longitude: center.lng
    };
  }

  getCurrentCenter() {
    if (this.props.isGlobalView || this.props.isSpotsView) {
      return {
        latitude: 0,
        longitude: 0
      };
    }

    if (this.props.highlightedSpotId) {
      return arrayToCoordinates(
        spots[this.props.highlightedSpotId].coordinates
      );
    }

    if (this.isPlayerCentered()) {
      return this.props.playerPosition;
    }

    return this.props.mapPosition;
  }

  isSpotInCurrentStory(spot) {
    return !this.props.storyId || spot.storyId === this.props.storyId;
  }

  isPlayerCentered() {
    return (
      !this.props.mapPosition &&
      !this.props.isGlobalView &&
      !this.props.isSpotsView &&
      !this.props.highlightedSpotId
    );
  }

  onRecenter() {
    this.props.recenterMap();
  }

  onViewSpots() {
    this.props.activeGlobalView();
  }

  handlePopupOpen(event) {
    if (event.popup.options.className === 'c-player-marker__popup') {
      return null;
    }
    this.props.setHighlightedSpot(null);
    event.popup._source.getElement().classList.add('c-spot-marker__icon--open');
  }

  handlePopupClose(event) {
    event.popup._source
      .getElement()
      .classList.remove('c-spot-marker__icon--open');
  }

  getLeafletElement() {
    return this.map.current && this.map.current.leafletElement;
  }

  getMapBounds() {
    if (!this.props.isGlobalView && !this.props.isSpotsView) {
      return null;
    }

    const visibleSpots = Object.values(spots)
      .filter(spot => isSpotVisible(spot, this.props.user))
      .filter(this.isSpotInCurrentStory.bind(this));
    if (!visibleSpots.length) {
      return null;
    }

    const positionArray = visibleSpots.map(spot => spot.coordinates);
    if (this.props.isGlobalView) {
      positionArray.push(coordinatesToArray(this.props.playerPosition));
    }

    return L.latLngBounds(positionArray);
  }

  render() {
    return (
      <Fragment>
        {this.props.story && <StoryProgressBox story={this.props.story} />}
        <LMap
          center={coordinatesToArray(this.getCurrentCenter())}
          zoom={this.props.mapZoom}
          zoomControl={false}
          attributionControl={false}
          style={{ height: '100%', width: '100%', position: 'relative' }}
          onZoom={this.onZoom.bind(this)}
          onDragEnd={this.onMove.bind(this)}
          onPopupOpen={this.handlePopupOpen.bind(this)}
          onPopupClose={this.handlePopupClose.bind(this)}
          bounds={this.getMapBounds()}
          ref={this.map}
        >
          <ThemeData name="map">
            {props => <MapCacheLayer {...props} />}
          </ThemeData>

          {renderForEach(spots, spot => {
            if (!isSpotVisible(spot, this.props.user)) {
              return null;
            }

            return (
              <SpotMarker
                key={spot.id}
                spotId={spot.id}
                storyId={this.props.storyId}
                playerPosition={this.props.playerPosition}
                zoom={this.props.mapZoom}
                center={this.getCurrentCenter()}
                id={`marker-${spot.id}`}
              />
            );
          })}

          <PlayerMarker position={this.props.playerPosition} />
          {!this.isPlayerCentered() && (
            <button
              className="c-map__recenter"
              onClick={this.onRecenter.bind(this)}
            >
              <ThemeComponent
                name="FormIcon"
                className="c-map__recenter-squircle"
                color={colors.mainDark}
              />
              <TargetIcon color="white" className="c-map__recenter-target" />
            </button>
          )}
          {this.isPlayerCentered() && (
            <button
              className="c-map__recenter"
              onClick={this.onViewSpots.bind(this)}
            >
              <ThemeComponent
                name="FormIcon"
                className="c-map__recenter-squircle"
                color={colors.mainDark}
              />
              <PlanetIcon color="white" className="c-map__recenter-planet" />
            </button>
          )}
        </LMap>
      </Fragment>
    );
  }
}

export default Map;
