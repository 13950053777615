const {
  IMAGE
} = require('../../../../../../common/play/constants/contentType.constants');

module.exports = {
  id: 3,
  storyId: 1,
  title: 'Le Fantôme',
  coordinates: [45.755353, 4.838681],
  xp: 30,
  riddle: {
    content: [
      `Quelle est la note préférée du fantôme ?`,
      {
        type: IMAGE,
        content: '/images/spots/fantome.png'
      }
    ]
  },
  placeName: 'Rayon Multimédia',
  clues: [
    {
      content: `Le fantôme parle d'une note qui dénote. Observez bien l'aspect des notes sur la partition, il y en a une différente des autres.`,
      malus: 5
    }
  ],
  hint:
    "Pour résoudre cette énigme, vous devez utliser l'affiche correspondante présente dans la médiathèque.",
  dialog: {
    question: 'Quelle est la note préférée du fantôme ?',
    fail: [`Non, ce n'est pas la bonne réponse.`],
    validation: 'Bravo ! Vous avez trouvé la bonne note !',
    cheersAuto: {
      'min-length(4)':
        "Non, ce n'est pas la bonne réponse. Vous cherchez une note de musique."
    }
  },
  answer: ['la'],
  button: 'fantome'
};
