const {
  IMAGE
} = require('../../../../../../common/play/constants/contentType.constants');

module.exports = {
  id: 1,
  storyId: 1,
  title: 'L’Araignée',
  coordinates: [45.755353, 4.838681],
  xp: 30,
  riddle: {
    content: [
      `Qui est l'intrus qui est venu déranger l'Araignée Géante ?`,
      {
        type: IMAGE,
        content: '/images/spots/araignee.png'
      }
    ]
  },
  placeName: 'Rayon Sciences',
  clues: [
    {
      content: [
        `En suivant les fils de la toile, vous pouvez reconstituer des mots présents dans le témoignage de l'araignée.
        Ci-dessous, par exemple, on voit le mot « Victime » dont le « V » a été remplacé par un « ? ».
        Procédez de la même façon pour trouver les 3 autres lettres cachées par les « ? ».`,
        {
          type: IMAGE,
          content: '/images/spots/araignee-clue.png'
        }
      ],
      malus: 5
    }
  ],
  hint:
    "Pour résoudre cette énigme, vous devez utliser l'affiche correspondante présente dans la médiathèque.",
  dialog: {
    question: "Qui est l'intrus qui est venu déranger l'Araignée Géante ?",
    fail: [`Non, ce n'est pas la bonne réponse.`],
    cheers: {
      '^ictime':
        "Non, ce n'est pas la réponse. Mais ces lettres apparaissent bien sur la toile. Elles forment presque un mot, non ?",
      victime:
        "Non, ce n'est pas la réponse. Mais ce mot est bien présent sur la toile ! Notez la lettre qui a été remplacée par un « ? » et trouvez les 3 autres de la même manière.",
      '^oueuse':
        "Non, ce n'est pas la réponse. Mais ces lettres apparaissent bien sur la toile. Elles forment presque un mot, non ?",
      noueuse:
        "Non, ce n'est pas la réponse. Mais ce mot est bien présent sur la toile ! Notez la lettre qui a été remplacée par un « ? » et trouvez les 3 autres de la même manière.",
      appetissan$:
        "Non, ce n'est pas la réponse. Mais ces lettres apparaissent bien sur la toile. Elles forment presque un mot, non ?",
      appetissant:
        "Non, ce n'est pas la réponse. Mais ce mot est bien présent sur la toile ! Notez la lettre qui a été remplacée par un « ? » et trouvez les 3 autres de la même manière.",
      proprietair$:
        "Non, ce n'est pas la réponse. Mais ces lettres apparaissent bien sur la toile. Elles forment presque un mot, non ?",
      proprietaire:
        "Non, ce n'est pas la réponse. Mais ce mot est bien présent sur la toile ! Notez la lettre qui a été remplacée par un « ? » et trouvez les 3 autres de la même manière."
    },
    cheersAuto: {
      wrongOrder: `Non, ce n’est pas la bonne réponse, mais vous avez trouvé les bonnes lettres. Remettez les dans l’ordre.`,
      '!length(4)':
        'Non, ce n’est pas la bonne réponse. Vous cherchez un mot de 4 lettres, une lettre par « ? ».'
    },
    validation: `Bravo ! Vous avez trouvé qui était l'intrus qui soufflait fort dans le couloir !`
  },
  answer: ['vent'],
  button: 'araignee'
};
