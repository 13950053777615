import React from 'react';

import colors from '~styles/variables/colors.scss';

class StoryFooterIcon extends React.PureComponent {
  render() {
    let prefix = 'icon';
    if (this.props.context) {
      prefix = this.props.context;
    }
    return (
      <svg
        viewBox="0 0 74 74"
        width={this.props.size || 74}
        height={this.props.size || 74}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.9976963%"
            x2="99.9944101%"
            y2="49.9976963%"
            id={`${prefix}-trophy`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.activeTo} offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M58.9078594,16.337493 C58.6784406,15.7178281 58.0907513,15.2994757 57.4276366,15.2994757 L44.1621996,15.2994757 L38.4141579,1.95308868 C38.1658827,1.37431536 37.6253343,1 36.9999322,1 C36.3745302,1 35.8339817,1.37431536 35.5857066,1.95308868 L29.8376648,15.2994757 L16.5722279,15.2994757 C15.9091132,15.2994757 15.3214239,15.7178281 15.092005,16.337493 C14.8688716,16.9603035 15.0605778,17.6586061 15.569699,18.0832495 L26.9683581,27.5732447 L21.2328872,42.8729917 C20.9908975,43.5241117 21.1951745,44.2538693 21.7451512,44.6722218 C22.2951278,45.0968653 23.0493815,45.1094473 23.6150718,44.7068224 L37.003075,35.147626 L50.3910781,44.7068224 C50.9567684,45.1094473 51.7110221,45.0968653 52.2609987,44.6722218 C52.8109754,44.2507238 53.0152524,43.5241117 52.7732627,42.8729917 L47.0377919,27.5732447 L58.4364509,18.0832495 C58.9392867,17.6586061 59.1309928,16.9603035 58.9078594,16.337493 Z"
          id="Path"
          fill={`url(#${prefix}-trophy)`}
          fillRule="nonzero"
        />
        <path
          d="M55.4615385,65 L18.5384615,65 C17.6892308,65 17,65.672 17,66.5 L17,72.5 C17,73.328 17.6892308,74 18.5384615,74 L55.4615385,74 C56.3107692,74 57,73.328 57,72.5 L57,66.5 C57,65.672 56.3107692,65 55.4615385,65 Z"
          id="Path"
          fill={`url(#${prefix}-trophy)`}
          fillRule="nonzero"
        />
        <path
          d="M51,62 L51,51 C51,50.1325714 50.3031111,49.4285714 49.4444444,49.4285714 L24.5555556,49.4285714 C23.6968889,49.4285714 23,50.1325714 23,51 L23,62 L51,62 Z"
          id="Path"
          fill={`url(#${prefix}-trophy)`}
          fillRule="nonzero"
        />
      </svg>
    );
  }
}

export default StoryFooterIcon;
