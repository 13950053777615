import React, { Fragment } from 'react';

class AboutContent extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <p style={{ marginTop: '40px' }}>
          Ce jeu vous est proposé par la Métropole du Grand Lyon.
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <img
              src="/images/LogoMetropole.png"
              height="80"
              alt="Métropole du Grand Lyon"
            />
          </div>
        </p>
        <p>
          Il a été créé spécialement pour ces Nuits de la lecture par&nbsp;
          <a
            href="https://zupple.fr"
            style={{ textDecoration: 'underline' }}
            target="__blank"
          >
            Zupple
          </a>
          , en partenariat avec les&nbsp;
          <a
            href="https://www.quaisdupolar.com/"
            style={{ textDecoration: 'underline' }}
            target="__blank"
          >
            Quais du Polar
          </a>
          .
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img src="/images/logoQDP.png" height="80" alt="Quais du Polar" />
            <img src="/images/Zupple.png" height="80" alt="Zupple" />
          </div>
        </p>
      </Fragment>
    );
  }
}

export default AboutContent;
