import React, { Fragment } from 'react';
import Modal from './Modal.container';
import ThemeLabel from '../../theme/ThemeLabel.component';
import ThemeFeature from '../../theme/ThemeFeature.component';
import RichContent from '../../spots/RichContent.container';

import { CLUE_MODAL } from './Modal.constants';
import spots from '~data/spots';

import './Clue.scss';

class ClueModal extends React.PureComponent {
  showNextClue() {
    this.props.closeModal();
    this.props.takeClue(this.props.spotId, this.props.nextClue.malus);
  }

  onOpen() {
    this.props.pageView('Indices', this.props.spotId);
  }

  renderNoClue() {
    const spot = spots[this.props.spotId];

    return (
      <>
        <p>
          Il n'y a pas d'indices disponibles pour{' '}
          <ThemeLabel name="spot.this" default="cette énigme" />.
        </p>
        {spot.noClueMessage && <RichContent content={spot.noClueMessage} />}
      </>
    );
  }

  renderSingleClue() {
    return (
      <Fragment>
        <p>
          Un indice est disponible pour vous aider à résoudre{' '}
          <ThemeLabel name="spot.this" default="cette énigme" />
          &nbsp;!
        </p>
        {this.props.nextClue.teaser && (
          <p>
            Le prochain indice devrait vous donner{' '}
            <span className="clue-strong">{this.props.nextClue.teaser}</span>
          </p>
        )}
        <ThemeFeature
          name="xp"
          render={() => (
            <p>
              Mais attention, cet indice enlèvera{' '}
              <span className="clue-strong">
                {this.props.nextClue.malus}&nbsp;XP
              </span>{' '}
              à la récompense de{' '}
              <ThemeLabel name="spot.the" default="l'énigme" />.
            </p>
          )}
        />
      </Fragment>
    );
  }

  renderNoMoreClue() {
    return (
      <p>
        Il n'y a plus d'indices disponibles pour{' '}
        <ThemeLabel name="spot.this" default="cette énigme" />.
      </p>
    );
  }

  render1MoreClue() {
    return (
      <Fragment>
        <p>
          Il reste <span className="clue-strong">1 indice</span> disponible pour
          vous aider à résoudre{' '}
          <ThemeLabel name="spot.this" default="cette énigme" />
          &nbsp;!
        </p>
        {this.props.nextClue.teaser && (
          <p>
            Le prochain indice devrait vous donner{' '}
            <span className="clue-strong">{this.props.nextClue.teaser}</span>
          </p>
        )}
        <ThemeFeature
          name="xp"
          render={() => (
            <p>
              Mais attention, cet indice enlèvera{' '}
              <span className="clue-strong">
                {this.props.nextClue.malus}&nbsp;XP
              </span>{' '}
              à la récompense de{' '}
              <ThemeLabel name="spot.the" default="l'énigme" />.
            </p>
          )}
        />
      </Fragment>
    );
  }

  renderSeveralMoreClues() {
    return (
      <Fragment>
        <p>
          Il reste{' '}
          <span className="clue-strong">
            {this.props.remainingCluesNumber} indices{' '}
          </span>
          disponibles pour vous aider à résoudre{' '}
          <ThemeLabel name="spot.this" default="cette énigme" />
          &nbsp;!
        </p>
        {this.props.nextClue.teaser && (
          <p>
            Le prochain indice vous donnera{' '}
            <span className="clue-strong">{this.props.nextClue.teaser}</span>
          </p>
        )}
        <ThemeFeature
          name="xp"
          render={() => (
            <p>
              Mais attention, chaque indice enlèvera{' '}
              <span className="clue-strong">
                {this.props.nextClue.malus}&nbsp;XP
              </span>{' '}
              à la récompense de{' '}
              <ThemeLabel name="spot.the" default="l'énigme" />.
            </p>
          )}
        />
      </Fragment>
    );
  }

  renderButton() {
    if (this.props.remainingCluesNumber === 0 && !this.props.nextClue) {
      return '';
    }

    return (
      <button
        type="button"
        className="o-button"
        onClick={this.showNextClue.bind(this)}
      >
        {this.props.remainingCluesNumber > 1 && <span>Voir un indice</span>}
        {this.props.remainingCluesNumber === 1 &&
          this.props.initialCluesCount !== 1 && (
            <span>Voir le dernier indice</span>
          )}
        {this.props.remainingCluesNumber === 1 &&
          this.props.initialCluesCount === 1 && <span>Voir l'indice</span>}
        {this.props.remainingCluesNumber === 0 && <span>Voir la réponse</span>}
      </button>
    );
  }

  renderClue() {
    if (!this.props.spotId) {
      return null;
    }

    if (this.props.initialCluesCount === 0) {
      return this.renderNoClue();
    }
    if (this.props.remainingCluesNumber === 0) {
      return this.renderNoMoreClue();
    }
    if (this.props.initialCluesCount === 1) {
      return this.renderSingleClue();
    }
    if (this.props.remainingCluesNumber === 1) {
      return this.render1MoreClue();
    }

    return this.renderSeveralMoreClues();
  }

  render() {
    const title =
      this.props.nextClue && this.props.nextClue.isAnswer
        ? 'Réponse'
        : 'Indices';

    return (
      <Modal
        modalId={CLUE_MODAL}
        afterOpenModal={this.onOpen.bind(this)}
        contentLabel={title}
        icon="clue"
        renderFooter={() => this.renderButton()}
      >
        {this.renderClue()}
      </Modal>
    );
  }
}

export default ClueModal;
