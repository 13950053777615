import React from 'react';
import TakeAwayRule from './TakeAwayRule.component';
import LevelRule from './LevelRule.component';
import LifeCycleRule from './LifeCycleRule.component';
import PlaceRule from './PlaceRule.component';
import RankRule from './RankRule.component';
import SignalRule from './SignalRule.component';
import SpotRule from './SpotRule.component';
import StoryRule from './StoryRule.component';
import BadgeRule from './BadgeRule.component';

import './Rules.scss';

class Rules extends React.PureComponent {
  componentDidMount() {
    if (window.location.hash) {
      const universe = document.getElementById(window.location.hash.slice(1));
      if (universe) {
        universe.scrollIntoView();
      }
    }
  }

  render() {
    return (
      <div className="c-rules">
        <h1 className="c-rules__title">Les règles</h1>
        <h2 className="c-rules__chapter">Comment jouer</h2>
        <PlaceRule />
        <SpotRule />
        <StoryRule />

        <h2 className="c-rules__chapter">Types d'énigmes</h2>
        <LevelRule />
        <TakeAwayRule />

        <h2 className="c-rules__chapter">Objectifs</h2>
        <LifeCycleRule />
        <BadgeRule />
        <RankRule />

        <h2 className="c-rules__chapter">Un problème ?</h2>
        <SignalRule />
      </div>
    );
  }
}

export default Rules;
