export function getDistanceTo(reference, position) {
  const R = 6371000; // Radius of the earth in m
  const dLatitude = toRadians(reference.latitude - position.latitude);
  const dLongitude = toRadians(reference.longitude - position.longitude);
  const a =
    Math.pow(Math.sin(dLatitude / 2), 2) +
    Math.pow(Math.sin(dLongitude / 2), 2) *
      Math.cos(toRadians(position.latitude)) *
      Math.cos(toRadians(reference.latitude));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.floor(R * c); // Distance in m
}

function toRadians(number) {
  return (number * Math.PI) / 180;
}

function toDegrees(number) {
  return (360 - (number / Math.PI) * 180) % 360;
}

// https://gis.stackexchange.com/a/8674
export function roundCoordinate(coord, precision = 5) {
  return parseFloat(coord.toFixed(precision));
}

// https://gis.stackexchange.com/a/228663
export function getOrientationFromCoordinates(from, to, precision = 10) {
  const dLongitude = toRadians(to.longitude - from.longitude);
  const a =
    Math.cos(toRadians(from.latitude)) * Math.sin(toRadians(to.latitude));
  const b =
    Math.sin(toRadians(from.latitude)) *
    Math.cos(toRadians(to.latitude)) *
    Math.cos(dLongitude);
  const radians = Math.atan2(
    Math.sin(dLongitude) * Math.cos(toRadians(to.latitude)),
    a - b
  );

  return Math.round(toDegrees(radians) / precision) * precision;
}

export function coordinatesToArray(coords) {
  return [coords.latitude, coords.longitude];
}

export function arrayToCoordinates(coords) {
  return {
    latitude: coords[0],
    longitude: coords[1]
  };
}

export function getDistanceLabel(distance) {
  if (typeof distance === 'undefined' || distance === null) {
    return null;
  }
  if (distance > 800000) {
    return 'Trèèès loin';
  }
  if (distance > 100000) {
    return 'Très loin';
  }
  if (distance >= 1000) {
    return (Math.round(distance / 100) / 10).toLocaleString() + ' km';
  }
  if (distance > 100) {
    return Math.round(distance / 10) * 10 + ' m';
  }

  return 'Proche';
}
