import { connect } from 'react-redux';
import Modal from './Modal.component';
import { closeModal } from '../../../modules/ui/Ui.actions';
import { isModalOpen } from '../../../modules/ui/Ui.selectors';

function mapStateToProps(state, ownProps) {
  return {
    isModalOpen: isModalOpen(state, ownProps.modalId)
  };
}

const mapDispatchToProps = {
  closeModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
