import {
  BACK_HISTORY,
  CLOSE_MODAL,
  OPEN_MODAL,
  PREFILL_ANSWER,
  CLOSE_FREE_BAR
} from './Ui.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';

const emptyModal = {
  app: null,
  id: null,
  params: {}
};

const initialState = {
  currentModal: emptyModal,
  history: [],
  prefillAnswer: null,
  showFreeBar: true
};

function getDirectory(path) {
  if (!path) {
    return null;
  }

  return path.slice(0, path.slice(1).indexOf('/') + 2);
}

const UiReducer = (state = initialState, action) => {
  let history;

  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        currentModal: {
          id: action.modalId,
          params: action.modalParams
        }
      };

    case CLOSE_MODAL:
    case LOGOUT_USER:
      return {
        ...state,
        currentModal: emptyModal,
        showFreeBar: true
      };

    case CLOSE_FREE_BAR:
      return {
        ...state,
        showFreeBar: false
      };

    case PREFILL_ANSWER:
      return {
        ...state,
        prefillAnswer: action.prefillAnswer
      };

    case BACK_HISTORY:
      history = state.history.slice(0, -1);

      return {
        ...state,
        history
      };

    case '@@router/LOCATION_CHANGE':
      history = state.history;
      if (action.action === 'POP') {
        history = state.history.slice(0, -1);
      } else {
        const path = window.location.pathname;
        if (getDirectory(history[history.length - 1]) !== getDirectory(path)) {
          history = [...history, path];
        }
      }

      return {
        ...state,
        currentModal: emptyModal,
        history
      };

    default:
      return state;
  }
};

export default UiReducer;
