import React, { Fragment } from 'react';

class DetectyvesStoryEnd extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <p>
          Vous avez mené cette enquête de mains de maitres ! Merci et bravo à
          vous !
        </p>
      </Fragment>
    );
  }
}

export default DetectyvesStoryEnd;
