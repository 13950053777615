import React from 'react';
import stripeConf from '~data/stripe';
import { Link } from 'react-router-dom';
import { to, isRoute } from '../../../urls';
import {
  hasExpiredPass,
  hasFullAccess
} from '../../../modules/auth/Auth.functions';

import CrossIcon from '../../icons/CrossIcon.component';
import stories from '~data/stories';

import colors from '~styles/variables/colors.scss';
import './StatusBar.scss';

class FreeBar extends React.PureComponent {
  onClick(product) {
    this.props.activateProduct(product.durationId);
  }

  closeFreeBar() {
    this.props.closeFreeBar();
  }

  playFree() {
    this.closeFreeBar();
  }

  renderPlayFreeButton() {
    const freeStories = Object.values(stories).filter(story => story.isFree);
    if (!freeStories.length) {
      return null;
    }

    return (
      <Link
        className="o-button o-button--tiny c-status-bar__button"
        to={{
          pathname: to('stories'),
          state: {
            openStory: freeStories[0].id
          }
        }}
        onClick={this.playFree.bind(this)}
      >
        Jouer l'aventure gratuite
      </Link>
    );
  }

  renderContent() {
    if (this.props.pendingProduct) {
      const product = stripeConf.products[this.props.pendingProduct];

      return (
        <div>
          Pour jouer, activez votre accès.
          <br />
          <button
            className="o-button o-button--alert o-button--tiny c-status-bar__button"
            onClick={this.onClick.bind(this, product)}
          >
            Activer mon accès {product.duration} {product.durationUnit}
          </button>
          {this.renderPlayFreeButton()}
        </div>
      );
    }

    if (hasExpiredPass(this.props.user)) {
      return (
        <div>
          Votre accès a expiré le{' '}
          {new Date(this.props.expirationDate).toLocaleDateString()}. Pour
          continuer à jouer, choisissez votre nouveau temps d'accès.
          <br />
          <Link
            to={{
              pathname: to('payment'),
              state: {
                email: this.props.email,
                fromApp: true
              }
            }}
          >
            <button className="o-button o-button--alert o-button--tiny c-status-bar__button">
              Choisir mon nouveau temps d'accès
            </button>
          </Link>
          {this.renderPlayFreeButton()}
        </div>
      );
    }

    return (
      <div>
        Pour jouer, choisissez votre temps d'accès.
        <br />
        <Link
          className="o-button o-button--alert o-button--tiny c-status-bar__button"
          to={{
            pathname: to('payment'),
            state: {
              email: this.props.email,
              fromApp: true
            }
          }}
        >
          Choisir mon temps d'accès
        </Link>
        {this.renderPlayFreeButton()}
      </div>
    );
  }

  render() {
    if (
      !stripeConf.products ||
      !this.props.showFreeBar ||
      !isRoute('home', this.props.location) ||
      hasFullAccess(this.props.user)
    ) {
      return '';
    }

    return (
      <div className="c-status-bar c-status-bar--free">
        {this.renderContent()}

        <button
          type="button"
          className="c-status-bar__cross"
          title="Fermer la fenêtre"
          onClick={this.closeFreeBar.bind(this)}
        >
          <CrossIcon color={colors.alert} size="16" />
        </button>
      </div>
    );
  }
}

export default FreeBar;
