import React from 'react';

class ConnectionHeader extends React.PureComponent {
  render() {
    return (
      <div className="c-autologin__header">
        <div className="c-autologin__header-subtitle">
          «&nbsp;La confrérie des ténèbres&nbsp;»
        </div>
      </div>
    );
  }
}

export default ConnectionHeader;
