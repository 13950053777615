import React, { Fragment } from 'react';

// Components
import SpotPreview from './SpotPreview.container';
import StoryProgressBox from '../story/StoryProgressBox.container';
import ThemeLabel from '../theme/ThemeLabel.component';

// Functions
import { renderForEach } from '../../../../utils/render';
import {
  arrayToCoordinates,
  getDistanceTo
} from '../../modules/geolocation/Geolocation.functions';
import { isSpotVisible } from '../../modules/story/Story.functions';

// Data
import spots from '~data/spots';
import stories from '~data/stories';

// Styles
import './SpotList.css';

export const PAGE_NAME = {
  TO_START: 'toStart',
  IN_PROGRESS: 'inProgress',
  ANSWERED: 'answered'
};

class SpotList extends React.PureComponent {
  getDistance(spot) {
    if (!this.props.isGeolocationApp) {
      return 0;
    }

    return getDistanceTo(
      arrayToCoordinates(spot.coordinates),
      this.props.playerPosition
    );
  }

  isSpotVisible(spot) {
    return (
      spot &&
      isSpotVisible(spot, this.props.user) &&
      (!this.props.storyId || spot.storyId === this.props.storyId)
    );
  }

  sortSpotsByGame(mapper, sorter) {
    return this.props.gameIds
      .map(gameId => {
        const game = this.props.allGames[gameId];
        const spot = spots[game.spotId];

        return {
          game,
          spot
        };
      })
      .filter(({ spot }) => this.isSpotVisible(spot))
      .sort((a, b) => {
        return sorter(mapper(a), mapper(b));
      })
      .map(({ spot }) => spot);
  }

  getGameIdsByLastActivity() {
    return this.sortSpotsByGame(
      ({ game }) => new Date(game.lastActivity || 0),
      (dateA, dateB) => dateB - dateA
    );
  }

  getGameIdsByAnswerDate() {
    return this.sortSpotsByGame(
      ({ game }) => new Date(game.endDate || 0),
      (dateA, dateB) => dateB - dateA
    );
  }

  getSpotsToStart() {
    const startedSpotIds = Object.values(this.props.allGames).map(
      game => game.spotId
    );

    return Object.values(this.props.visibleSpots)
      .filter(spot => this.isSpotVisible(spot))
      .filter(spot => !startedSpotIds.includes(spot.id))
      .sort((a, b) => {
        const distanceA = this.getDistance(a);
        const distanceB = this.getDistance(b);

        return distanceA - distanceB;
      });
  }

  getTitle(spotList) {
    const count = spotList.length;
    const plural = count > 1;
    let suffix;
    if (this.props.page === PAGE_NAME.TO_START) {
      suffix = 'à commencer';
    } else if (this.props.page === PAGE_NAME.IN_PROGRESS) {
      suffix = 'en cours';
    } else {
      suffix = plural ? 'terminées' : 'terminée';
    }

    return (
      <span>
        <ThemeLabel
          name={plural ? 'spot.plural' : 'spot.singular'}
          default={plural ? 'énigmes' : 'énigme'}
          capitalize
        />{' '}
        {suffix} ( {count} )
      </span>
    );
  }

  renderList() {
    let spotList;
    if (this.props.page === PAGE_NAME.TO_START) {
      spotList = this.getSpotsToStart();
    } else if (this.props.page === PAGE_NAME.IN_PROGRESS) {
      spotList = this.getGameIdsByLastActivity();
    } else {
      spotList = this.getGameIdsByAnswerDate();
    }

    if (!spotList.length) {
      const suffix =
        this.props.storyId && this.props.isGeolocationApp
          ? ' dans cette aventure'
          : '';
      if (this.props.page === PAGE_NAME.TO_START) {
        return (
          <p>
            <ThemeLabel name="spot.no" default="Aucune énigme" capitalize /> à
            commencer{suffix}.
          </p>
        );
      } else if (this.props.page === PAGE_NAME.IN_PROGRESS) {
        return (
          <p>
            <ThemeLabel name="spot.no" default="Aucune énigme" capitalize /> en
            cours
            {suffix}.
          </p>
        );
      } else {
        return (
          <p>
            Aucune <ThemeLabel name="spot.singular" default="énigme" /> terminée
            {suffix}.
          </p>
        );
      }
    }

    return (
      <Fragment>
        <h1 className="c-spot-list__title">{this.getTitle(spotList)}</h1>
        {renderForEach(spotList, spot => {
          const distance = this.getDistance(spot);

          return (
            <div className="c-spot-list__item" key={spot.id}>
              <SpotPreview spot={spot} distance={distance} small withMapLink />
            </div>
          );
        })}
      </Fragment>
    );
  }

  render() {
    return (
      <div className="c-spot-list">
        {this.props.storyId && (
          <StoryProgressBox story={stories[this.props.storyId]} />
        )}
        <div className="c-spot-list__list">{this.renderList()}</div>
      </div>
    );
  }
}

export default SpotList;
