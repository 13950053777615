export function compareDate(a, b) {
  if (a.getFullYear() > b.getFullYear()) {
    return 1;
  }
  if (a.getFullYear() < b.getFullYear()) {
    return -1;
  }
  if (a.getMonth() > b.getMonth()) {
    return 1;
  }
  if (a.getMonth() < b.getMonth()) {
    return -1;
  }
  if (a.getDate() > b.getDate()) {
    return 1;
  }
  if (a.getDate() < b.getDate()) {
    return -1;
  }

  return 0;
}

export function isToday(date) {
  return compareDate(date, new Date()) === 0;
}

export function isYesterday(date) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return compareDate(date, yesterday) === 0;
}

export function isIn7days(date) {
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 6);

  return compareDate(date, lastWeek) > 0 && compareDate(date, new Date()) < 0;
}

function padWithZero(number) {
  const width = 2;
  number = number + '';
  if (number.length >= width) {
    return number;
  }
  const pad = new Array(width - number.length + 1).join('0');

  return pad + number;
}

export function displayDuration(durationInMilli) {
  let duration = durationInMilli / 1000;
  if (duration >= 86400) {
    return '> 1 jour';
  }

  const hours = Math.floor(duration / 3600);
  const min = Math.floor(duration / 60 - hours * 60);
  const withMinutes = hours || min;
  const withSeconds = !hours;
  const seconds = withSeconds
    ? Math.round(duration - hours * 3600 - min * 60)
    : 0;

  return (
    (hours ? hours + ' h ' : '') +
    (withMinutes ? (hours ? padWithZero(min) : min) + ' min ' : '') +
    (withSeconds ? (min ? padWithZero(seconds) : seconds) + ' s ' : '')
  ).trim();
}

const days = [
  'dimanche',
  'lundi',
  'mardi',
  'mercredi',
  'jeudi',
  'vendredi',
  'samedi'
];

export function displayDate(date, withPrefix = false) {
  if (!date) {
    return null;
  }

  if (isToday(date)) {
    return (
      (withPrefix ? 'à ' : '') +
      date.getHours() +
      ' h ' +
      padWithZero(date.getMinutes())
    );
  }

  if (isYesterday(date)) {
    return 'hier';
  }

  if (isIn7days(date)) {
    return days[date.getDay()];
  }

  return (withPrefix ? 'le ' : '') + date.toLocaleDateString();
}

export function displayHourMinuteDate(date) {
  if (!date) {
    return null;
  }
  return (
    date.toLocaleDateString() +
    ' à ' +
    date.getHours() +
    ' h ' +
    padWithZero(date.getMinutes())
  );
}
