const sha256 = require('js-sha256');

function isDefined(object) {
  return object != null && typeof object !== 'undefined';
}

// Function used to check if an answer is the right answer
function cleanString(string) {
  if (!string) {
    return '';
  }

  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[-\s;.']/g, '')
    .toLowerCase();
}

function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

function capitalizeEachWord(string) {
  return string.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
}

function getStateFromLocalStorage(reducerName, emptyState) {
  let initialState = Object.assign({}, emptyState);
  try {
    const cache = JSON.parse(localStorage.getItem('state-' + reducerName));
    if (cache) {
      Object.keys(cache).forEach(function(key) {
        initialState[key] = cache[key];
      });
    }
  } catch (error) {}

  return initialState;
}

function saveStateInLocalStorage(reducerName, value) {
  localStorage.setItem('state-' + reducerName, JSON.stringify(value));
}

function typography(text) {
  if (!text) {
    return '';
  }

  return text.replace(/ ([!:?»])/g, '\u00A0$1').replace(/([«]) /g, '$1\u00A0');
}

function keyBy(collection, key) {
  return collection.reduce((object, item) => {
    object[item[key]] = item;

    return object;
  }, {});
}

function groupBy(collection, key) {
  return collection.reduce((object, item) => {
    const value = typeof key === 'function' ? key(item) : item[key];
    if (!object[value]) {
      object[value] = [];
    }
    object[value].push(item);

    return object;
  }, {});
}

function indexBy(collection, key) {
  return collection.reduce((object, item) => {
    object[item[key]] = item;

    return object;
  }, {});
}

function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFn(object[key], key);

    return result;
  }, {});
}

function uniqueArrayFilter(value, index, self) {
  return self.indexOf(value) === index;
}

function pick(object, ...keys) {
  const picked = {};
  keys.forEach(key => (picked[key] = object[key]));

  return picked;
}

function booleanToFactor(bool) {
  return 2 * Number(bool) - 1;
}

function mapper(object, mapper) {
  if (typeof mapper === 'function') {
    return mapper(object);
  }

  return object[mapper];
}

function mapSorter(mapper) {
  return (a, b) => mapper(b) - mapper(a);
}

function createIndex(collection, id, index, sortAttribute, isAsc = true) {
  return objectMap(groupBy(collection, index), function(list) {
    if (sortAttribute) {
      list.sort(function(a, b) {
        return (
          (mapper(a, sortAttribute) - mapper(b, sortAttribute)) *
          booleanToFactor(isAsc)
        );
      });
    }

    return list.map(item => item[id]);
  });
}

function deepGet(scope, ...fields) {
  fields.find(field => {
    if (!scope) {
      return true;
    }
    scope = scope[field];

    return false;
  });

  return scope;
}

function flatten(array) {
  return [].concat.apply([], array);
}

function diffPropValue(props, prevProps, key) {
  return props[key] !== prevProps[key];
}

function newPropValue(props, prevProps, key, value) {
  return props[key] === value && prevProps[key] !== value;
}

function encrypt(string) {
  return sha256(string);
}

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

function numberToLetter(index) {
  return alphabet[index];
}

const DEVICE_OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OTHER: 'inconnu'
};

function getDeviceOs() {
  if (/Android/i.test(navigator.userAgent)) {
    return DEVICE_OS.ANDROID;
  }
  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return DEVICE_OS.IOS;
  }

  return DEVICE_OS.OTHER;
}

function getDeviceBrowser() {
  if (typeof InstallTrigger !== 'undefined') {
    return 'Firefox';
  }

  if (!!window.chrome) {
    return 'Chrome';
  }

  if (typeof window.safari !== 'undefined') {
    return 'Safari';
  }

  if (!!window.StyleMedia) {
    return 'Edge';
  }

  return 'inconnu';
}

function getIsPwa() {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone
  );
}

module.exports = {
  isDefined,
  cleanString,
  capitalize,
  capitalizeEachWord,
  getStateFromLocalStorage,
  saveStateInLocalStorage,
  typography,
  encrypt,
  keyBy,
  groupBy,
  indexBy,
  objectMap,
  uniqueArrayFilter,
  pick,
  createIndex,
  deepGet,
  flatten,
  diffPropValue,
  newPropValue,
  numberToLetter,
  getDeviceOs,
  DEVICE_OS,
  getDeviceBrowser,
  getIsPwa,
  mapSorter
};
