export const ANSWER_MODAL = 'answer';

export const AWARD_MODAL = 'award';

export const FINAL_SPOT_MODAL = 'finalSpot';

export const CLUE_MODAL = 'clue';

export const ERROR_MODAL = 'error';

export const INFO_MODAL = 'info';

export const ABOUT_MODAL = 'about';

export const SPOT_INTRO_MODAL = 'spotIntro';

export const STORY_INTRO_MODAL = 'storyIntro';

export const FORGOTTEN_PASSWORD_MODAL = 'forgottenPassword';

export const BADGE_MODAL = 'badge';

export const REVIEW_MODAL = 'review';

export const EVENT_PARTICIPATION_MODAL = 'event';

export const ZOOM_CONTENT_MODAL = 'zoomContent';
