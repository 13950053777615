import React from 'react';

class AutologinContent extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="c-autologin__text">
          <p className="c-autologin__text-header">Bienvenue,</p>
          <p>
            Si vous êtes ici c'est que vous avez accepté la mission de retrouver
            le voleur parmi les membres de la confrérie&nbsp;! Bravo et merci
            pour votre courage&nbsp;! <br />
            Vous trouverez dans la médiathèque, 4 affiches-énigmes vous
            permettant de récolter des informations. Une fois que vous les aurez
            résolues, allez consulter la liste des membres de la confrérie et
            identifiez le voleur avant qu'il ne soit trop tard&nbsp;! Grâce à
            votre smartphone, vous pourrez valider vos réponses et demander un
            indice si besoin.
          </p>
        </div>
      </div>
    );
  }
}

export default AutologinContent;
