import React from 'react';
import classNames from 'classnames';
import Header from '../Header/Header.container';
import FooterNav from '../FooterNav/FooterNav.container';
import UpdateBar from '../StatusBar/UpdateBar.component';
import StatusBar from '../StatusBar/StatusBar.container';
import MessageBar from '../StatusBar/MessageBar.container';
import FreeBar from '../StatusBar/FreeBar.container';
import NotificationBar from '../StatusBar/NotificationBar.container';
import ThemeFeature from '../../theme/ThemeFeature.component';

import './GameLayout.scss';

class GameLayout extends React.PureComponent {
  componentDidMount() {
    this.timer = setInterval(() => {
      this.props.checkAppStatus();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const classes = classNames('c-game-layout', {
      'c-game-layout--full': this.props.isFullPage
    });

    return (
      <div className={classes}>
        <div className="c-game-layout__header">
          <Header />
        </div>
        <nav className="c-game-layout__status-bar">
          <FreeBar />
          <UpdateBar />
          <ThemeFeature name="nextMessage" render={() => <MessageBar />} />
          <StatusBar />
          <NotificationBar />
        </nav>
        <div className="c-game-layout__main">{this.props.children}</div>
        <nav className="c-game-layout__nav">
          <FooterNav />
        </nav>
      </div>
    );
  }
}

export default GameLayout;
