import React from 'react';

class SummerTrophyIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 3 64 80"
        width={this.props.size || 34}
        height={this.props.size || 34}
        className={this.props.className}
      >
        <path
          fill={this.props.color || 'currentColor'}
          d="M64 27.375l-22.395-2.906L32 4.562 22.39 24.47 0 27.375l16.46 14.89-4.245 21.606L32 53.261l19.79 10.61-4.255-21.605zm0 0"
        />
        <path
          d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"
          transform="scale(1.2) translate(14.5 20)"
          fill="white"
        />
        <g fill="none" stroke={this.props.color || 'currentColor'}>
          <path strokeWidth="12" d="M20,67 H45" />
          <path strokeWidth="6" d="M15,78 H50" />
        </g>
      </svg>
    );
  }
}

export default SummerTrophyIcon;
