import { connect } from 'react-redux';
import TimeMachine from './TimeMachine.component';
import { isModalOpen } from '../../../../common/play/modules/ui/Ui.selectors';
import { ANSWER_MODAL } from '../../../../common/play/components/layouts/Modal/Modal.constants';

function mapStateToProps(state) {
  return {
    isAnswerModalOpen: isModalOpen(state, ANSWER_MODAL)
  };
}

export default connect(mapStateToProps)(TimeMachine);
