import React from 'react';
import { Redirect } from 'react-router-dom';
import { fetchApi } from '../../modules/api/Api.functions';
import withThemeFeatures from '../theme/withThemeFeatures.hoc';
import ThemeComponent from '../theme/ThemeComponent.component';

import './Autologin.scss';

class AutologinPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.riddle = new URLSearchParams(window.location.search).get('riddle');
  }

  createUser(event) {
    event.preventDefault();
    fetchApi('/api/user/autologin')
      .then(([{ error, user }]) => {
        if (error) {
          this.setState(() => {
            return {
              createError: error
            };
          });
          return null;
        }

        this.setState(() => {
          return {
            createError: false
          };
        });
        this.props.authenticate(user.email, user.password);
      })
      .catch(error => {
        console.error(error);
        this.setState(() => {
          return {
            createError: {
              label:
                "Erreur lors de la création du compte. Vous pouvez nous contacter à l'adresse contact@zupple.fr."
            }
          };
        });
      });
  }

  render() {
    if (this.props.isConnected || !this.props.themeFeatures.allowAutologin) {
      if (this.riddle) {
        return <Redirect to={'/enigme/' + this.riddle + '/'} />;
      }
      return <Redirect to="/" />;
    }
    return (
      <div className="c-autologin">
        <ThemeComponent name="AutologinHeader" />
        <div>
          <ThemeComponent name="AutologinContent" />
          <button
            className="o-button o-button--alternate"
            type="submit"
            onClick={this.createUser.bind(this)}
          >
            C'est parti !
          </button>
        </div>
        <ThemeComponent name="AutologinFooter" />
      </div>
    );
  }
}

export default withThemeFeatures(AutologinPage);
