import { fetchApi } from './Api.functions';
import { getAuthToken, getUserId } from '../auth/Auth.selectors';
import { keyBy, objectMap } from '../../../../utils/basis';
import { openModal } from '../ui/Ui.actions';
import version from '../../../../version';

// Constants
import { UPDATE_GAMES, UPDATE_RANKS } from '../game/Game.constants';
import { LOGOUT_USER, UPDATE_USERS } from '../auth/Auth.constants';
import { UPDATE_BADGES } from '../badge/Badge.constants';
import { UPDATE_NOTIFICATIONS } from '../notification/Notification.constants';
import { ERROR_MODAL } from '../../components/layouts/Modal/Modal.constants';
import { DISCONNECT_EFFECT } from './Api.contants';
import { UPDATE_ALL_MESSAGES } from '../app/App.constants';
import { updateApp } from '../app/App.actions';
import { getLastVersionAvailable } from '../app/App.selectors';
import { UPDATE_LAST_VERSION_AVAILABLE } from '../app/App.constants';

export function fetchAndUpdate(path, params = {}, callback = null) {
  // TODO: local update first to handle offline

  // TODO: offline

  return function(dispatch, getState) {
    const userId = getUserId(getState());
    const knownServerVersion = getLastVersionAvailable(getState());

    fetchApi(path, {
      version,
      userId,
      token: getAuthToken(getState()),
      ...params
    })
      .then(function([{ result, update, error, version: newServerVersion }]) {
        if (callback) {
          update = callback(update, dispatch, result);
        }
        const {
          app,
          games,
          users,
          ranks,
          badges,
          notifications,
          allMessages
        } = update;
        if (app) {
          dispatch(updateApp(app));
        }

        if (error) {
          if (error.effect === DISCONNECT_EFFECT) {
            dispatch({
              type: LOGOUT_USER,
              label: error.label
            });
          }

          return null;
        }

        if (games) {
          const myGames = Object.values(games).filter(
            game => game.userId === userId
          );
          const bySpotId = objectMap(
            keyBy(myGames, 'spotId'),
            game => game._id
          );

          dispatch({
            type: UPDATE_GAMES,
            games,
            bySpotId
          });
        }

        if (users) {
          dispatch({
            type: UPDATE_USERS,
            users
          });
        }

        if (ranks) {
          dispatch({
            type: UPDATE_RANKS,
            ranks
          });
        }

        if (badges) {
          dispatch({
            type: UPDATE_BADGES,
            badges
          });
        }

        if (notifications) {
          dispatch({
            type: UPDATE_NOTIFICATIONS,
            notifications
          });
        }

        if (allMessages) {
          dispatch({
            type: UPDATE_ALL_MESSAGES,
            allMessages
          });
        }

        if (knownServerVersion !== newServerVersion) {
          dispatch({
            type: UPDATE_LAST_VERSION_AVAILABLE,
            version: newServerVersion
          });
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(
          openModal(ERROR_MODAL, {
            error: `Problème de connexion internet :'(`
          })
        );
      });
  };
}
