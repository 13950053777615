const {
  IMAGE
} = require('../../../../../../common/play/constants/contentType.constants');

module.exports = {
  id: 2,
  storyId: 1,
  title: 'Le Vampire',
  coordinates: [45.755353, 4.838681],
  xp: 30,
  riddle: {
    content: [
      `Quel est le message de Dracula ?`,
      {
        type: IMAGE,
        content: '/images/spots/vampire.png'
      }
    ]
  },
  placeName: 'Rayon Roman',
  clues: [
    {
      content: `Le nom de l'auteur semble avoir été déchiré par les canines de Dracula. Trouvez un exemplaire de ce livre dans la médiathèque pour identifier les morceaux qui ont disparu. Réunis, ils forment un mot qui apparait sur la quatrième de couverture présentée sur l'affiche.`,
      malus: 5
    }
  ],
  hint:
    "Pour résoudre cette énigme, vous devez utliser l'affiche correspondante présente dans la médiathèque.",
  dialog: {
    question: 'Quel est le message de Dracula ?',
    fail: [`Non, ce n'est pas la bonne réponse.`],
    cheers: {
      bramer:
        "Non, ce n'est pas la réponse. Mais vous avez bien identifié les 2 parties arrachées. D'après l'éditeur, il faut regarder juste en-dessous sur la page."
    },
    cheersAuto: {
      '!length(4)':
        "Non, ce n'est pas la bonne réponse. Vous cherchez un message en 4 lettres."
    },
    validation: `Bravo ! Vous avez trouvé que voir le gâchis de nourriture, c’était ça le PIRE pour le Vampire !`
  },
  answer: ['pire'],
  button: 'vampire'
};
