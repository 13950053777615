import { connect } from 'react-redux';
import RichContent from './RichContent.component';
import { openModal, pageView } from '../../modules/ui/Ui.actions';

const mapDispatchToProps = {
  pageView,
  openModal
};

export default connect(
  null,
  mapDispatchToProps
)(RichContent);
