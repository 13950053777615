import React, { Fragment } from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';
import { renderForEach } from '../../utils/render';

// Polyfill
import '../../utils/polyfill-flat';

import createStore, { history } from './modules/createStore';
import { getPath } from './urls';

// Styles
import '~styles/main.scss';

// Components
import AboutModal from './components/layouts/Modal/AboutModal.component';
import ConnectionGate from './components/layouts/ConnectionGate/ConnectionGate.container';
import ErrorModal from './components/layouts/Modal/ErrorModal.component';
import GameLayout from './components/layouts/GameLayout/GameLayout.container';
import GeolocationGate from './components/layouts/GeolocationGate/GeolocationGate.container';
import HomePage from './components/HomePage.container';
import RankPage from './components/RankPage.component';
import AccountEditPage from './components/AccountEditPage.component';
import Spot from './components/spots/Spot.container';
import SpotList from './components/spots/SpotList.container';
import StoryIntroPage from './components/story/StoryIntroPage.container';
import FooterMenu2 from './components/FooterMenu2.component';
import FooterMenu3 from './components/FooterMenu3.component';
import RulesPage from './components/RulesPage.component';
import SubscribePage from './components/SubscribePage.component';
import WelcomePage from './components/WelcomePage.component';
import PaymentPage from './components/PaymentPage.component';
import AutologinPage from './components/autologin/AutologinPage.container';
import AboutPage from './components/AboutPage.container';
import MessagesListPage from './components/messages/MessagesListPage.container';
import MessageDetailPage from './components/messages/MessageDetailPage.container';

serviceWorker.register();

const AuthenticatedRoutes = withRouter(({ location, options }) => {
  const pages = options.pages || {};

  return (
    <ConnectionGate>
      <GeolocationGate>
        <GameLayout key={location.pathname}>
          <Switch>
            <Route path={getPath('home')} exact={true} component={HomePage} />
            <Route path="/enigmes/" component={SpotList} />
            <Route path={getPath('spot')} exact={true} component={Spot} />
            <Route
              path={getPath('stories')}
              exact={true}
              component={FooterMenu2}
            />
            <Route
              path={getPath('trophies')}
              exact={true}
              component={FooterMenu3}
            />
            <Route
              path={getPath('messageDetail')}
              exact={true}
              component={MessageDetailPage}
            />
            <Route
              path={getPath('messagesList')}
              exact={true}
              component={MessagesListPage}
            />
            <Route path={getPath('rank')} exact={true} component={RankPage} />
            <Route
              path={getPath('accountEdit')}
              exact={true}
              component={AccountEditPage}
            />
            <Route path={getPath('rules')} exact={true} component={RulesPage} />
            <Route path={getPath('about')} exact={true} component={AboutPage} />
            {renderForEach(pages, (component, path) => (
              <Route
                key={path}
                path={path}
                exact={true}
                component={component}
              />
            ))}
          </Switch>
        </GameLayout>
      </GeolocationGate>
    </ConnectionGate>
  );
});

const Content = ({ options }) => (
  <Fragment>
    <Switch>
      <Route
        path={getPath('subscribe')}
        exact={true}
        component={SubscribePage}
      />
      <Route
        path={getPath('subscribeSuccess')}
        exact={true}
        component={WelcomePage}
      />
      <Route path={getPath('payment')} exact={true} component={PaymentPage} />
      <Route
        path={getPath('storyIntro')}
        exact={true}
        component={StoryIntroPage}
      />
      <Route
        path={getPath('autologin')}
        exact={true}
        component={AutologinPage}
      />
      <Route component={() => <AuthenticatedRoutes options={options} />} />
    </Switch>
    <ErrorModal />
    <AboutModal />
  </Fragment>
);

export const AppContext = React.createContext({});

class App extends React.PureComponent {
  render() {
    const store = createStore(this.props.options);

    return (
      <Provider store={store}>
        <Router history={history}>
          <AppContext.Provider value={this.props.options}>
            <Content options={this.props.options} />
          </AppContext.Provider>
        </Router>
      </Provider>
    );
  }
}

export default App;

export function initiate(options = {}) {
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(<App options={options} />, rootElement);
  } else {
    render(<App options={options} />, rootElement);
  }
}
