import React from 'react';

import colors from '~styles/variables/colors.scss';

class InfoIcon extends React.PureComponent {
  render() {
    let prefix = 'icon';
    if (this.props.context) {
      prefix = this.props.context;
    }
    return (
      <svg
        viewBox="0 0 24 24"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <defs>
          <linearGradient
            x1="5.732%"
            y1="98.482%"
            x2="87.831%"
            y2="8.564%"
            id={`${prefix}-info-a`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.iconGradient} offset="100%" />
          </linearGradient>
        </defs>
        <g transform="translate(1 0)" fill="none">
          <circle fill={colors.main} cx="11" cy="11" r="11" />
          <path
            d="M0 8a8 8 0 0 0 16 0A8 8 0 1 0 0 8"
            transform="translate(3 3)"
            fill={`url(#${prefix}-info-a)`}
          />
          <g transform="translate(10 6)">
            <circle fill={colors.main} cx="1" cy="1" r="1" />
            <rect fill={colors.main} y="4" width="2" height="6" rx="1" />
          </g>
        </g>
      </svg>
    );
  }
}

export default InfoIcon;
