import React from 'react';

import {
  BADGE_ID_ADVENTURE_13,
  BADGE_ID_AUTUMN,
  BADGE_ID_BETA,
  BADGE_ID_DATE_CHRISTMAS,
  BADGE_ID_DATE_HALLOWEEN,
  BADGE_ID_DATE_NEW_YEAR,
  BADGE_ID_RAID_2019,
  BADGE_ID_RAID_2020,
  BADGE_ID_RAID_2021,
  BADGE_ID_SPRING,
  BADGE_ID_STORY_CASH,
  BADGE_ID_STORY_CASSANDRE,
  BADGE_ID_STORY_DETECTYVES,
  BADGE_ID_STORY_COMMUNE,
  BADGE_ID_STORY_PAGAILLE,
  BADGE_ID_STORY_PAX_DEORUM,
  BADGE_ID_STORY_RESISTANCE,
  BADGE_ID_SUMMER,
  BADGE_ID_WINTER,
  BADGE_TYPE_ACHIEVEMENT,
  BADGE_TYPE_DATE,
  BADGE_TYPE_EVENT,
  BADGE_TYPE_SEASON,
  BADGE_TYPE_STORY,
  BADGE_TYPE_XP
} from '../../../../../share/constants/Badges.constants';
import BadgeEventBeta from './event/BadgeEventBeta.component';
import BadgeEventRaid2019 from './event/BadgeEventRaid2019.component';
import BadgeEventRaid2020 from './event/BadgeEventRaid2020.component';
import BadgeEventRaid2021 from './event/BadgeEventRaid2021.component';
import BadgeSeasonWinter from './season/BadgeSeasonWinter.component';
import BadgeSeasonSpring from './season/BadgeSeasonSpring.component';
import BadgeSeasonSummer from './season/BadgeSeasonSummer.component';
import BadgeSeasonAutumn from './season/BadgeSeasonAutumn.component';
import BadgeAchievementWinter from './achievement/BadgeAchievementWinter.component';
import BadgeAchievementSpring from './achievement/BadgeAchievementSpring.component';
import BadgeAchievementSummer from './achievement/BadgeAchievementSummer.component';
import BadgeAchievementAutumn from './achievement/BadgeAchievementAutumn.component';
import BadgeXP500 from './xp/BadgeXP500.component';
import BadgeXP1000 from './xp/BadgeXP1000.component';
import BadgeXP2000 from './xp/BadgeXP2000.component';
import BadgeXP5000 from './xp/BadgeXP5000.component';
import BadgeStoryCash from './story/BadgeStoryCash.component';
import BadgeStoryCassandre from './story/BadgeStoryCassandre.component';
import BadgeStoryDetectyves from './story/BadgeStoryDetectyves.component';
import BadgeStoryPagaille from './story/BadgeStoryPagaille.component';
import BadgeStoryPaxDeorum from './story/BadgeStoryPaxDeorum.component';
import BadgeStoryResistance from './story/BadgeStoryResistance.component';
import BadgeStoryCommune from './story/BadgeStoryCommune.component';

import '../Badges.scss';
import { getBadgeId } from '../../../../../share/constants/Badges.functions';
import BadgeDateHalloween from './date/BadgeDateHalloween.component';
import BadgeDateChristmas from './date/BadgeDateChristmas.component';
import BadgeDateNewYear from './date/BadgeDateNewYear.component';
import BadgeEventAdventure13 from './event/BadgeEventAdventure13.component';

const storyBadgesMap = {
  [BADGE_ID_STORY_CASH]: BadgeStoryCash,
  [BADGE_ID_STORY_CASSANDRE]: BadgeStoryCassandre,
  [BADGE_ID_STORY_DETECTYVES]: BadgeStoryDetectyves,
  [BADGE_ID_STORY_PAGAILLE]: BadgeStoryPagaille,
  [BADGE_ID_STORY_PAX_DEORUM]: BadgeStoryPaxDeorum,
  [BADGE_ID_STORY_RESISTANCE]: BadgeStoryResistance,
  [BADGE_ID_STORY_COMMUNE]: BadgeStoryCommune
};

const seasonBadgesMap = {
  [BADGE_ID_WINTER]: BadgeSeasonWinter,
  [BADGE_ID_SPRING]: BadgeSeasonSpring,
  [BADGE_ID_SUMMER]: BadgeSeasonSummer,
  [BADGE_ID_AUTUMN]: BadgeSeasonAutumn
};

const xpBadgesMap = {
  500: BadgeXP500,
  1000: BadgeXP1000,
  2000: BadgeXP2000,
  5000: BadgeXP5000
};

const dateBadgesMap = {
  [BADGE_ID_DATE_HALLOWEEN]: BadgeDateHalloween,
  [BADGE_ID_DATE_CHRISTMAS]: BadgeDateChristmas,
  [BADGE_ID_DATE_NEW_YEAR]: BadgeDateNewYear
};

const achievementBadgesMap = {
  [BADGE_ID_WINTER]: BadgeAchievementWinter,
  [BADGE_ID_SPRING]: BadgeAchievementSpring,
  [BADGE_ID_SUMMER]: BadgeAchievementSummer,
  [BADGE_ID_AUTUMN]: BadgeAchievementAutumn
};

const eventBadgesMap = {
  [BADGE_ID_BETA]: BadgeEventBeta,
  [BADGE_ID_RAID_2019]: BadgeEventRaid2019,
  [BADGE_ID_RAID_2020]: BadgeEventRaid2020,
  [BADGE_ID_RAID_2021]: BadgeEventRaid2021,
  [BADGE_ID_ADVENTURE_13]: BadgeEventAdventure13
};

function getBadgeComponent(badge) {
  const id = getBadgeId(badge);
  if (badge.type === BADGE_TYPE_STORY) {
    return storyBadgesMap[id];
  }
  if (badge.type === BADGE_TYPE_SEASON) {
    return seasonBadgesMap[id];
  }
  if (badge.type === BADGE_TYPE_XP) {
    return xpBadgesMap[id];
  }
  if (badge.type === BADGE_TYPE_DATE) {
    return dateBadgesMap[id];
  }
  if (badge.type === BADGE_TYPE_ACHIEVEMENT) {
    return achievementBadgesMap[id];
  }
  if (badge.type === BADGE_TYPE_EVENT) {
    return eventBadgesMap[id];
  }
}

class BadgeProxy extends React.PureComponent {
  render() {
    const { badge, ...rest } = this.props;
    const Component = getBadgeComponent(this.props.badge);
    if (!Component) {
      return null;
    }

    return <Component {...rest} />;
  }
}

export default BadgeProxy;
