import React from 'react';
import { renderForEach } from '../../../../../utils/render';

import spots from '~data/spots';

class StoryProgress extends React.PureComponent {
  renderSpotObject(spotId) {
    const spot = spots[spotId];
    const button = spot.button;
    let colorClass = '';

    if (
      !this.props.answeredSpots ||
      !this.props.answeredSpots.includes(spotId)
    ) {
      colorClass = 'c-story-progress-box__icon--inactive';
    }
    return (
      <img
        src={`/images/spots/${button}.png`}
        height="20"
        className={`c-story-progress-box__icon ${colorClass}`}
        alt=""
        key={`object-${button}`}
      />
    );
  }

  render() {
    const storySpots = this.props.allSpots;
    return (
      <div>{renderForEach(storySpots, this.renderSpotObject.bind(this))}</div>
    );
  }
}

export default StoryProgress;
