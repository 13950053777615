import React from 'react';
import { AppContext } from '../../App';
import { deepGet } from '../../../../utils/basis';

class ThemeData extends React.PureComponent {
  getSubData(data) {
    const subData = deepGet(data, ...this.props.name.split('.'));
    if (typeof subData === 'undefined') {
      return this.props.default;
    }

    return subData;
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ data }) => this.props.children(this.getSubData(data))}
      </AppContext.Consumer>
    );
  }
}

export default ThemeData;
