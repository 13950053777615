import { BADGE_GROUPS_BY_TYPE, BADGE_LABELS_BY_TYPE } from './Badges.constants';
import universes from '~data/universes';

export function getBadgeId(badge) {
  if (typeof badge.value !== 'number') {
    return badge.value;
  }
  if (!BADGE_GROUPS_BY_TYPE[badge.type]) {
    return badge.value;
  }

  const group = Object.keys(BADGE_GROUPS_BY_TYPE[badge.type]).find(item => {
    return BADGE_GROUPS_BY_TYPE[badge.type][item].includes(badge.value);
  });

  return group || badge.value;
}

export function getBadgeStoryName(badge) {
  const slug = getBadgeId(badge);
  const univers = Object.values(universes).find(function(item) {
    return item.slug === slug;
  });

  return univers ? univers.title : slug;
}

export function getBadgeLabel(badge) {
  const id = getBadgeId(badge);

  if (
    badge &&
    BADGE_LABELS_BY_TYPE[badge.type] &&
    BADGE_LABELS_BY_TYPE[badge.type][id]
  ) {
    return BADGE_LABELS_BY_TYPE[badge.type][id];
  }
}
