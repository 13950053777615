export const BADGE_TYPE_STORY = 'STORY';
export const BADGE_TYPE_SEASON = 'SEASON';
export const BADGE_TYPE_XP = 'XP';
export const BADGE_TYPE_DATE = 'DATE';
export const BADGE_TYPE_ACHIEVEMENT = 'ACHIEVEMENT';
export const BADGE_TYPE_EVENT = 'EVENT';

export const BADGE_ID_STORY_CASH = 'cash';
export const BADGE_ID_STORY_CASSANDRE = 'cassandre';
export const BADGE_ID_STORY_DETECTYVES = 'detectyves';
export const BADGE_ID_STORY_RESISTANCE = 'resistance';
export const BADGE_ID_STORY_PAX_DEORUM = 'pax-deorum';
export const BADGE_ID_STORY_PAGAILLE = 'pagaille';
export const BADGE_ID_STORY_COMMUNE = 'commune';

export const BADGE_LABEL_STORY_CASH = 'Hacker';
export const BADGE_LABEL_STORY_CASSANDRE = 'Désamorceur·se';
export const BADGE_LABEL_STORY_DETECTYVES = 'Détective';
export const BADGE_LABEL_STORY_PAX_DEORUM = 'Agent Secret';
export const BADGE_LABEL_STORY_RESISTANCE = 'Résistant·e';
export const BADGE_LABEL_STORY_PAGAILLE = 'Ami·e des Dieux';
export const BADGE_LABEL_STORY_COMMUNE = 'Révolutionnaire';

export const BADGE_ID_DATE_HALLOWEEN = 305;
export const BADGE_ID_DATE_CHRISTMAS = 359;
export const BADGE_ID_DATE_NEW_YEAR = 366;

export const BADGE_LABEL_DATE_HALLOWEEN = 'Même pas peur';
export const BADGE_LABEL_DATE_CHRISTMAS = 'Magie de Noël';
export const BADGE_LABEL_DATE_NEW_YEAR = 'Bonnes Résolutions';

export const BADGE_ID_WINTER = 0;
export const BADGE_ID_SPRING = 1;
export const BADGE_ID_SUMMER = 2;
export const BADGE_ID_AUTUMN = 3;

export const BADGE_LABEL_SEASON_WINTER = 'En hiver';
export const BADGE_LABEL_SEASON_SPRING = 'Au printemps';
export const BADGE_LABEL_SEASON_SUMMER = 'En été';
export const BADGE_LABEL_SEASON_AUTUMN = 'En automne';

export const BADGE_LABEL_ACHIEVEMENT_SPRING = 'Champion·ne de printemps';
export const BADGE_LABEL_ACHIEVEMENT_SUMMER = "Champion·ne d'été";
export const BADGE_LABEL_ACHIEVEMENT_AUTUMN = "Champion·ne d'automne";
export const BADGE_LABEL_ACHIEVEMENT_WINTER = "Champion·ne d'hiver";

export const BADGE_LABEL_XP_500 = 'Perspicace';
export const BADGE_LABEL_XP_1000 = 'Astucieux·se';
export const BADGE_LABEL_XP_2000 = 'Clairvoyant·e';
export const BADGE_LABEL_XP_5000 = 'Ingénieux·se';

export const BADGE_ID_BETA = 1;
export const BADGE_ID_RAID_2019 = 2;
export const BADGE_ID_RAID_2020 = 3;
export const BADGE_ID_RAID_2021 = 4;
export const BADGE_ID_ADVENTURE_13 = 13;

export const BADGE_LABEL_EVENT_BETA = 'Bêta testeur';
export const BADGE_LABEL_EVENT_RAID_2019 = 'RAIDinLyon 2019';
export const BADGE_LABEL_EVENT_RAID_2020 = 'RAIDinLyon 2020';
export const BADGE_LABEL_EVENT_RAID_2021 = 'RAIDinLyon 2021';
export const BADGE_LABEL_EVENT_ADVENTURE_13 = '13e aventure';

export const BADGE_TYPES = [
  BADGE_TYPE_STORY,
  BADGE_TYPE_SEASON,
  BADGE_TYPE_XP,
  BADGE_TYPE_DATE,
  BADGE_TYPE_EVENT,
  BADGE_TYPE_ACHIEVEMENT
];

export const BADGE_IDS_BY_TYPE = {
  [BADGE_TYPE_STORY]: [
    1,
    2,
    3,
    4,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19
  ],
  [BADGE_TYPE_SEASON]: [
    BADGE_ID_WINTER,
    BADGE_ID_SPRING,
    BADGE_ID_SUMMER,
    BADGE_ID_AUTUMN
  ],
  [BADGE_TYPE_XP]: [500, 1000, 2000, 5000],
  [BADGE_TYPE_DATE]: [
    BADGE_ID_DATE_HALLOWEEN,
    BADGE_ID_DATE_CHRISTMAS,
    BADGE_ID_DATE_NEW_YEAR
  ],
  [BADGE_TYPE_ACHIEVEMENT]: [
    BADGE_ID_WINTER,
    BADGE_ID_SPRING,
    BADGE_ID_SUMMER,
    BADGE_ID_AUTUMN
  ],
  [BADGE_TYPE_EVENT]: [
    BADGE_ID_BETA,
    BADGE_ID_RAID_2019,
    BADGE_ID_RAID_2020,
    BADGE_ID_RAID_2021,
    BADGE_ID_ADVENTURE_13
  ]
};

export const BADGE_GROUPS_BY_TYPE = {
  [BADGE_TYPE_STORY]: {
    [BADGE_ID_STORY_CASH]: [1, 2],
    [BADGE_ID_STORY_CASSANDRE]: [3, 4],
    [BADGE_ID_STORY_DETECTYVES]: [6, 16],
    [BADGE_ID_STORY_RESISTANCE]: [7, 8, 9],
    [BADGE_ID_STORY_PAX_DEORUM]: [10, 11],
    [BADGE_ID_STORY_PAGAILLE]: [12, 13, 14, 15],
    [BADGE_ID_STORY_COMMUNE]: [17, 18, 19]
  }
};

export const BADGE_LABELS_BY_TYPE = {
  [BADGE_TYPE_STORY]: {
    [BADGE_ID_STORY_CASH]: BADGE_LABEL_STORY_CASH,
    [BADGE_ID_STORY_CASSANDRE]: BADGE_LABEL_STORY_CASSANDRE,
    [BADGE_ID_STORY_DETECTYVES]: BADGE_LABEL_STORY_DETECTYVES,
    [BADGE_ID_STORY_RESISTANCE]: BADGE_LABEL_STORY_RESISTANCE,
    [BADGE_ID_STORY_PAX_DEORUM]: BADGE_LABEL_STORY_PAX_DEORUM,
    [BADGE_ID_STORY_PAGAILLE]: BADGE_LABEL_STORY_PAGAILLE,
    [BADGE_ID_STORY_COMMUNE]: BADGE_LABEL_STORY_COMMUNE
  },
  [BADGE_TYPE_SEASON]: {
    [BADGE_ID_WINTER]: BADGE_LABEL_SEASON_WINTER,
    [BADGE_ID_SPRING]: BADGE_LABEL_SEASON_SPRING,
    [BADGE_ID_SUMMER]: BADGE_LABEL_SEASON_SUMMER,
    [BADGE_ID_AUTUMN]: BADGE_LABEL_SEASON_AUTUMN
  },
  [BADGE_TYPE_XP]: {
    500: BADGE_LABEL_XP_500,
    1000: BADGE_LABEL_XP_1000,
    2000: BADGE_LABEL_XP_2000,
    5000: BADGE_LABEL_XP_5000
  },
  [BADGE_TYPE_DATE]: {
    [BADGE_ID_DATE_HALLOWEEN]: BADGE_LABEL_DATE_HALLOWEEN,
    [BADGE_ID_DATE_CHRISTMAS]: BADGE_LABEL_DATE_CHRISTMAS,
    [BADGE_ID_DATE_NEW_YEAR]: BADGE_LABEL_DATE_NEW_YEAR
  },
  [BADGE_TYPE_ACHIEVEMENT]: {
    [BADGE_ID_WINTER]: BADGE_LABEL_ACHIEVEMENT_WINTER,
    [BADGE_ID_SPRING]: BADGE_LABEL_ACHIEVEMENT_SPRING,
    [BADGE_ID_SUMMER]: BADGE_LABEL_ACHIEVEMENT_SUMMER,
    [BADGE_ID_AUTUMN]: BADGE_LABEL_ACHIEVEMENT_AUTUMN
  },
  [BADGE_TYPE_EVENT]: {
    [BADGE_ID_BETA]: BADGE_LABEL_EVENT_BETA,
    [BADGE_ID_RAID_2019]: BADGE_LABEL_EVENT_RAID_2019,
    [BADGE_ID_RAID_2020]: BADGE_LABEL_EVENT_RAID_2020,
    [BADGE_ID_RAID_2021]: BADGE_LABEL_EVENT_RAID_2021,
    [BADGE_ID_ADVENTURE_13]: BADGE_LABEL_EVENT_ADVENTURE_13
  }
};
