import React from 'react';
import logoSrc from '../images/Zupple-logo.svg';
import textSrc from '../images/Zupple-text.svg';
import global from '~data/global';

import './subscribe/Welcome.scss';

class WelcomePage extends React.PureComponent {
  render() {
    return (
      <div className="c-welcome">
        <div className="c-welcome__header">
          <img src={logoSrc} alt="" className="c-welcome__logo" />{' '}
          <img src={textSrc} alt="Zupple" className="c-welcome__header-text" />{' '}
          <div className="c-welcome__text">
            <div className="c-welcome__text-header">
              Bienvenue sur Zupple !<br />
              <br />
            </div>
            Vous pouvez dès à présent vous connecter au jeu. Vous pourrez
            ensuite activer votre accès depuis l'application quand vous le
            souhaiterez.
            <br />
            <br />
            Pour vous connecter, rendez-vous sur :{' '}
            <a href={global.domain} className="c-welcome__link">
              {global.domain}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomePage;
