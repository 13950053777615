module.exports = {
  id: 1,
  slug: 'Confrerie',
  title: `La confrérie des ténèbres`,
  preview: 'La confrérie des ténèbres',
  topLineColor: '#0f613b',
  stories: [1],
  logo: '/images/stories/DetectYves.png',
  xp: 260
};
