import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_STORY_DETECTYVES } from '../../../../../../share/constants/Badges.constants';

class BadgeStoryDetectyves extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#4A90E2"
        label={BADGE_LABEL_STORY_DETECTYVES}
        {...this.props}
      >
        <svg viewBox="20 -25 110 110" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M102.3 39L91 11c-1.4-3.4-5.7-4.2-8.2-1.7L78.1 14c-1.5 1.5-3.8 1.5-5.3 0l-4.6-4.7C65.7 6.7 61.3 7.6 60 11L48.7 39h53.6z"
            fill="#4A90E2"
          />
          <path
            d="M113.6 38.3H37.4c-2.1 0-3.9 1.7-3.9 3.9 0 2.1 1.7 3.9 3.9 3.9h76.3c2.1 0 3.9-1.7 3.9-3.9-.1-2.2-1.8-3.9-4-3.9z"
            fill="#4A90E2"
          />
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeStoryDetectyves;
