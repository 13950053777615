import React from 'react';
import SpotIcon from '../icons/SpotIcon.component';

import './Rule.scss';

class LifeCycleRule extends React.PureComponent {
  render() {
    const styles = {
      verticalAlign: 'middle'
    };

    return (
      <div className="c-rule" id="lifecyle">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Réussir plein d'énigmes</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon c-rule__icon--fit">
              <SpotIcon size="20" style={styles} />
            </div>
            <div className="c-rule__text">
              <strong>Énigme à commencer</strong>
              <br />
              Approchez-vous pour y accéder.
            </div>
          </div>
          <div className="c-rule__content">
            <div className="c-rule__icon c-rule__icon--fit">
              <SpotIcon size="20" style={styles} inProgress />
            </div>
            <div className="c-rule__text">
              <strong>Énigme en cours</strong>
              <br />
              Répondez d'où vous voulez.
            </div>
          </div>
          <div className="c-rule__content">
            <div className="c-rule__icon c-rule__icon--fit">
              <SpotIcon size="20" style={styles} completed />
            </div>
            <div className="c-rule__text">
              <strong>Énigme terminée</strong>
              <br />
              Bravo&nbsp;!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LifeCycleRule;
