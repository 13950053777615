const TEXT = 'text';

const HTML = 'html';

const IMAGE = 'image';

const IMAGE_WALL = 'image_wall';

const IMAGE_PANORAMA = 'IMAGE_PANORAMA';

const PAPER = 'paper';

const CLICK_TO_SEE = 'click_to_see';

const CAROUSEL = 'carousel';

const FILE = 'file';

const AUDIO = 'audio';

const VIDEO = 'video';

const LIST = 'list';

const LIST_SELECTION = 'LIST_SELECTION';

const ORDERED_LIST = 'ordered_list';

const HINT_BOX = 'hint_box';

// Mechanisms

const DRAWING_IMAGE = 'drawing_image';

const HIDDEN_WORDS_GRID = 'hidden_words_grid';

const SELECTABLE_GRID = 'SELECTABLE_GRID';

const CROSS_WORDS_GRID = 'cross_words_grid';

const IMAGE_GRID = 'image_grid';

const FILTER_IMAGE = 'FILTER_IMAGE';

const CONNECTED_LINKS = 'CONNECTED_LINKS';

const MUSICAL_BUTTON = 'MUSICAL_BUTTON';

const MUSICAL_IMAGE = 'MUSICAL_IMAGE';

const MUSICAL_SVG = 'MUSICAL_SVG';

const TEXTUAL_IMAGE = 'TEXTUAL_IMAGE';

const ZOOMABLE_IMAGE = 'ZOOMABLE_IMAGE';

const UNPIXELATE_IMAGE = 'UNPIXELATE_IMAGE';

const TOGGLE_IN_IMAGE = 'TOGGLE_IN_IMAGE';

const PASSWORD_PROTECTION = 'PASSWORD_PROTECTION';

const CHAT = 'CHAT';

const COLOR_NAME = 'COLOR_NAME';

const CUSTOM_MECHANISM = 'CUSTOM_MECHANISM';

const TABLE = 'TABLE';

const ZOOM = 'ZOOM';

// LOGICAL

const FOR_GROUPS = 'FOR_GROUPS';

module.exports = {
  TEXT,
  HTML,
  IMAGE,
  IMAGE_WALL,
  IMAGE_PANORAMA,
  PAPER,
  CLICK_TO_SEE,
  CAROUSEL,
  FILE,
  VIDEO,
  AUDIO,
  LIST,
  LIST_SELECTION,
  ORDERED_LIST,
  HINT_BOX,
  DRAWING_IMAGE,
  HIDDEN_WORDS_GRID,
  SELECTABLE_GRID,
  CROSS_WORDS_GRID,
  IMAGE_GRID,
  FILTER_IMAGE,
  CONNECTED_LINKS,
  MUSICAL_BUTTON,
  MUSICAL_IMAGE,
  MUSICAL_SVG,
  TEXTUAL_IMAGE,
  ZOOMABLE_IMAGE,
  UNPIXELATE_IMAGE,
  TOGGLE_IN_IMAGE,
  PASSWORD_PROTECTION,
  CHAT,
  COLOR_NAME,
  CUSTOM_MECHANISM,
  TABLE,
  ZOOM,
  FOR_GROUPS
};
