import React from 'react';
import stripeConf from '~data/stripe';
import { absoluteTo } from '../../urls.js';

import './Product.scss';

class Product extends React.PureComponent {
  onCheckout() {
    const stripe = window.Stripe(stripeConf.token);
    stripe.redirectToCheckout({
      items: [{ sku: this.props.sku, quantity: 1 }],
      customerEmail: this.props.email,
      successUrl: this.props.fromApp
        ? absoluteTo('home')
        : absoluteTo('subscribeSuccess'),
      cancelUrl: absoluteTo('payment')
    });
  }

  render() {
    return (
      <div className="c-product">
        <div className="c-product-card">
          <div
            className="c-product__top-line"
            style={{ backgroundColor: this.props.color }}
          />
          <div className="c-product__wrapper">
            <h1 className="c-product__title">{this.props.name}</h1>
            <div className="c-product__content">
              <div className="c-product__left">
                <div className="c-product__left-big">{this.props.duration}</div>
                <div>{this.props.durationUnit}</div>
              </div>
              <div>{this.props.desc}</div>
            </div>
            <button
              className="c-product__button o-button o-button--fit"
              role="link"
              onClick={this.onCheckout.bind(this)}
            >
              C'est parti pour {this.props.price} !
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
