import React from 'react';
import Modal from './Modal.container';
import { ERROR_MODAL } from './Modal.constants';
import { ModalHoc } from './Modal.hoc';

class ErrorModal extends React.PureComponent {
  render() {
    return (
      <Modal modalId={ERROR_MODAL} contentLabel="Erreur">
        <p>{this.props.error}</p>
      </Modal>
    );
  }
}

export default ModalHoc(ErrorModal);
