import React from 'react';

class CheckPictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <path
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="3"
          strokeLinecap="round"
          d="M2,11 L7,16 L17,6"
        />
      </svg>
    );
  }
}

export default CheckPictogram;
