import React from 'react';
import { findElementAt, getClickCoordinates } from './mechanisms.utils';
import RichContent from '../spots/RichContent.container';

import './TextualImage.scss';

class TextualImage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.state = {
      currentPageName: props.firstPageName
    };
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.eventHandler);
  }

  componentWillUnmount() {
    this.root.current.removeEventListener('click', this.eventHandler);
  }

  eventHandler(event) {
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.props.width
    );
    const page = findElementAt(this.props.pages, x, y);
    if (!page) {
      return null;
    }

    this.container = document.querySelector('.c-page__scroll');
    if (this.container) {
      this.container.scrollTo(0, 0);
    }
    this.setState(() => ({
      currentPageName: page.name
    }));
  }

  render() {
    const page = this.props.pages.find(
      pageItem => pageItem.name === this.state.currentPageName
    );

    return (
      <div className="c-textual-image">
        {page && (
          <div className="c-textual-image__text">
            <p>
              <strong>{page.name}</strong>
            </p>
            <RichContent content={page.content} />
          </div>
        )}

        <img
          className="c-textual-image__image"
          src={this.props.image}
          alt={this.props.alt}
          ref={this.root}
        />
      </div>
    );
  }
}

export default TextualImage;
