import React from 'react';
import classNames from 'classnames';
import withPreFillAnswer from './withPrefillAnswer.hoc';
import { renderForEach } from '../../../../utils/render';

import './ConnectedLinks.scss';

class ConnectedLinks extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      lastClicked: false
    };
  }

  onClick(index) {
    if (this.state.lastClicked === false) {
      if (this.hasLink(index)) {
        const links = this.removeLink(index);
        this.setState(() => ({
          links
        }));
      } else {
        this.setState(() => ({
          lastClicked: index
        }));
      }
    } else if (this.state.lastClicked === index) {
      this.setState(() => ({
        lastClicked: false
      }));
    } else {
      const links = this.addLink(this.state.lastClicked, index);
      this.setState(() => ({
        lastClicked: false,
        links
      }));
    }
  }

  hasLink(...indexes) {
    return this.state.links.some(
      link => indexes.includes(link[0]) || indexes.includes(link[1])
    );
  }

  removeLink(...indexes) {
    if (!this.hasLink(...indexes)) {
      return this.state.links;
    }

    return this.state.links.filter(
      link => !indexes.includes(link[0]) && !indexes.includes(link[1])
    );
  }

  addLink(a, b) {
    const links = this.removeLink(a, b);
    links.push([a, b].sort());

    return links.sort((a, b) => a[0] - b[0]);
  }

  getCurve(index) {
    const { x, y } = this.props.dots[index];
    const curveIndex = this.props.curveIndex || 0;
    const cx = this.props.viewBox[2] - this.props.viewBox[0];
    const cy = this.props.viewBox[3] - this.props.viewBox[1];

    return {
      x: x + (cx - x) * curveIndex,
      y: y + (cy - y) * curveIndex
    };
  }

  toString(coords) {
    if (typeof coords === 'number') {
      coords = this.props.dots[coords];
    }

    return coords.x + ',' + coords.y;
  }

  getMessage() {
    const key = this.state.links.flat().join('');
    if (this.props.messages[key]) {
      return this.props.messages[key];
    }
    const index = parseInt(key) % this.props.messageBucket.length;

    return this.props.messageBucket[index];
  }

  render() {
    const classes = classNames('c-connected-links', {
      'c-connected-links--border': this.props.border
    });
    const isComplete = this.state.links.length === this.props.dots.length / 2;
    const message = isComplete && this.getMessage();
    if (isComplete) {
      this.props.prefillAnswer(message);
    }

    return (
      <div
        className={classes}
        style={{
          backgroundImage: `url(${this.props.backgroundImage})`
        }}
      >
        <svg
          className="c-connected-links__svg"
          viewBox={this.props.viewBox.join(' ')}
        >
          {this.state.lastClicked !== false && (
            <circle
              key="last-dot"
              className="c-connected-links__last-dot"
              cx={this.props.dots[this.state.lastClicked].x}
              cy={this.props.dots[this.state.lastClicked].y}
              r={this.props.dotSize + 2 || 5}
              fill="rgba(0, 0, 0, 0.2)"
            />
          )}
          {renderForEach(this.props.dots, (dot, index) => (
            <circle
              key={'dot-' + index}
              className="c-connected-links__dot"
              cx={dot.x}
              cy={dot.y}
              r={dot.size || this.props.dotSize || 3}
              onClick={this.onClick.bind(this, index)}
              fill={dot.color || this.props.dotColor}
              stroke={dot.color || this.props.dotColor}
            />
          ))}
          {renderForEach(this.state.links, (link, index) => {
            const c0 = this.getCurve(link[0]);
            const c1 = this.getCurve(link[1]);
            const d = `M${this.toString(link[0])} C${this.toString(
              c0
            )} ${this.toString(c1)} ${this.toString(link[1])}`;

            return (
              <g
                key={'link-' + link.join('-')}
                fill="none"
                stroke={this.props.curveColor || 'black'}
                strokeLinecap="round"
                strokeWidth="1"
                className="c-connected-links__link"
              >
                <path d={d} />
              </g>
            );
          })}
        </svg>
        {isComplete && (
          <div className="c-connected-links__overlay">{message}</div>
        )}
      </div>
    );
  }
}

export default withPreFillAnswer(ConnectedLinks);
