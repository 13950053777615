import React from 'react';
import {
  getClickCoordinates,
  findElementAt,
  playSound
} from './mechanisms.utils';

import './MusicalImage.scss';

class MusicalImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.root = React.createRef();
    this.currentAudio = null;
    this.soundStates = {};
    this.state = {
      audioUrl: false,
      text: ''
    };
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.eventHandler);
  }

  componentWillUnmount() {
    if (this.currentAudio) {
      this.currentAudio.pause();
    }
    this.root.current.removeEventListener('click', this.eventHandler);
  }

  eventHandler(event) {
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.props.width
    );
    this.getSound(x, y);
  }

  getSound(x, y) {
    const sound = findElementAt(this.props.sounds, x, y);
    if (!sound) {
      return null;
    }

    const { url, soundStates, currentAudio } = playSound(
      sound,
      this.soundStates,
      this.currentAudio
    );
    this.soundStates = soundStates;
    this.currentAudio = currentAudio;
    this.setState(() => ({
      audioUrl: url
    }));

    const action = sound.action;
    if (action) {
      if (action.clearText) {
        this.setState({
          text: ''
        });
      }
      if (action.addText) {
        this.setState(({ text }) => ({
          text: text + action.addText
        }));
      }
    }
  }

  render() {
    return (
      <div className="c-musical-image" style={this.props.style}>
        {this.state.text && (
          <div
            className="c-musical-image__text"
            style={this.props.textContainer}
          >
            {this.state.text}
          </div>
        )}

        <img
          className="c-musical-image__image"
          src={this.props.image}
          alt={this.props.alt}
          ref={this.root}
        />
        {this.props.download && (
          <div className="c-musical-image__audio-link">
            {this.state.audioUrl && (
              <a href={this.state.audioUrl} download>
                Télécharger le dernier son joué
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default MusicalImage;
