import React from 'react';
import withPageView from '../../modules/ui/hoc/withPageView.hoc';
import { displayDate } from '../../utils/date';
import { getRouteParams } from '../../urls';
import Page from '../Page.component';

class MessageDetailPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.messageId = getRouteParams(this.props.location);

    // Initial state
    this.state = {
      message: null
    };
  }

  componentDidMount() {
    this.props.fetchMessages();
    if (localStorage.getItem('app-readMessagesIds')) {
      let previousLocalStorage = JSON.parse(
        localStorage.getItem('app-readMessagesIds')
      );
      previousLocalStorage.push(this.messageId);
      localStorage.setItem(
        'app-readMessagesIds',
        JSON.stringify(previousLocalStorage)
      );
    } else {
      localStorage.setItem(
        'app-readMessagesIds',
        JSON.stringify([this.messageId])
      );
    }
  }

  componentDidUpdate() {
    if (!this.state.message && this.props.allMessages) {
      const message = this.props.allMessages.find(
        message => message._id === this.messageId
      );
      if (message) {
        this.setState({
          message
        });
        this.props.pageView(`Message ${message.subject}`);
      }
    }
  }

  render() {
    const message = this.state.message;

    if (!message) {
      return '';
    }

    return (
      <Page>
        <h1 className="c-spot__title">{message.subject}</h1>
        <p className="c-spot__subtitle">
          {displayDate(new Date(message.date))}
        </p>
        {message.image && (
          <p>
            <img src={message.image} alt="" />
          </p>
        )}
        {message.content
          .split('\n')
          .filter(Boolean)
          .map((paragraph, index) => (
            <p key={`content-${index}`}>{paragraph}</p>
          ))}
      </Page>
    );
  }
}

export default withPageView(MessageDetailPage);
