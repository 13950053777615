import { connect } from 'react-redux';
import ReviewModal from './ReviewModal.component';
import { review, closeModal } from '../../../modules/ui/Ui.actions';

const mapDispatchToProps = {
  review,
  closeModal
};

export default connect(
  null,
  mapDispatchToProps
)(ReviewModal);
