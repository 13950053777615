import { connect } from 'react-redux';
import SpotMarker from './SpotMarker.component';
import spots from '~data/spots';
import {
  isGameAnswered,
  isGameStarted,
  getStoryProgress,
  isGameBlocked
} from '../../modules/game/Game.selectors';
import { getUser } from '../../modules/auth/Auth.selectors';
import { getReachDistance } from '../../modules/options/Options.selectors';
import { getHighlightedSpotId } from '../../modules/map/Map.selectors';

function mapStateToProps(state, ownProps) {
  return {
    spot: spots[ownProps.spotId],
    isGameAnswered: isGameAnswered(state, ownProps.spotId),
    isGameBlocked: isGameBlocked(state, ownProps.spotId),
    isGameStarted: isGameStarted(state, ownProps.spotId),
    reachDistance: getReachDistance(state),
    isStoryComplete:
      getStoryProgress(state, spots[ownProps.spotId].storyId) === 1,
    highlightedSpotId: getHighlightedSpotId(state),
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(SpotMarker);
