import { connect } from 'react-redux';
import ForgottenPasswordModal from './ForgottenPasswordModal.component';
import { FORGOTTEN_PASSWORD_MODAL } from './Modal.constants';
import { closeModal } from '../../../modules/ui/Ui.actions.js';
import { isModalOpen } from '../../../modules/ui/Ui.selectors.js';

function mapStateToProps(state) {
  return {
    isModalOpen: isModalOpen(state, FORGOTTEN_PASSWORD_MODAL)
  };
}

const mapDispatchToProps = {
  closeModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgottenPasswordModal);
