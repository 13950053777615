import React from 'react';
import Rules from './rules/Rules.component';
import withPageView from '../modules/ui/hoc/withPageView.hoc';

class RulesPage extends React.PureComponent {
  componentDidMount() {
    this.props.pageView('Règles');
  }

  render() {
    return <Rules />;
  }
}

export default withPageView(RulesPage);
