import React from 'react';
import TargetIcon from '../../icons/TargetIcon.component';
import WaitIcon from '../../icons/WaitIcon.component';
import { Switch, Case } from '../../utils/Switch.component';
import Error from '../Error/Error.component';
import ThemeLabel from '../../theme/ThemeLabel.component';
import {
  diffPropValue,
  getDeviceOs,
  DEVICE_OS
} from '../../../../../utils/basis';

// Constants
import {
  BROWSER_NOT_SUPPORTED_ERROR,
  GRANTED_PERMISSION,
  PERMISSION_DENIED_ERROR,
  POSITION_UNAVAILABLE_ERROR,
  TIMEOUT_ERROR
} from '../../../modules/geolocation/Geolocation.constants';

const deviceOs = getDeviceOs();

class NoGps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      asked: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      diffPropValue(this.props, prevProps, 'geolocationError') ||
      diffPropValue(this.props, prevProps, 'geolocationPermission')
    ) {
      this.setState(() => ({
        asked: false
      }));
    }
  }

  askForGeolocation() {
    this.setState(() => ({
      asked: true
    }));
    const willAPopupShowUp =
      this.props.geolocationPermission !== GRANTED_PERMISSION;
    const delay = willAPopupShowUp ? 2000 : 0;
    setTimeout(this.props.startGeolocation, delay);
  }

  render() {
    if (this.props.geolocationError) {
      return (
        <Error>
          <div>
            <TargetIcon disabled size={74} color="white" />
          </div>
          <Switch value={this.props.geolocationError}>
            <Case value={BROWSER_NOT_SUPPORTED_ERROR}>
              Cet appareil ne supporte pas la géolocalisation.
              <br />
              Mettez à jour votre navigateur pour pouvoir jouer.
            </Case>
            <Case value={POSITION_UNAVAILABLE_ERROR}>
              Vous devez activer le GPS de votre téléphone
              <br />
              pour pouvoir jouer.
              {!this.state.asked && (
                <div className="o-action">
                  <button
                    className="o-button o-button--alternate o-button--fit"
                    onClick={this.askForGeolocation.bind(this)}
                  >
                    C'est bon, mon GPS est activé&nbsp;!
                  </button>
                </div>
              )}
            </Case>
            <Case value={TIMEOUT_ERROR}>
              Le GPS capte mal&nbsp;!
              <br />
              Pouvez-vous vous déplacer un petit peu&nbsp;?
            </Case>
            <Case value={PERMISSION_DENIED_ERROR}>
              Vous avez refusé de partager votre position.
              <br />
              Nous ne pouvons pas vous afficher le jeu.
              {deviceOs === DEVICE_OS.ANDROID && (
                <div className="o-action">
                  <a
                    href="https://zupple.fr/faq/activer-la-geolocalisation-sous-Android/"
                    className="o-button o-button--alternate o-button--fit"
                  >
                    Comment activer mon GPS sous Android ?
                  </a>
                </div>
              )}
              {deviceOs === DEVICE_OS.IOS && (
                <div className="o-action">
                  <a
                    href="https://zupple.fr/faq/activer-la-geolocalisation-sous-iOS/"
                    className="o-button o-button--alternate o-button--fit"
                  >
                    Comment activer mon GPS sous iOS ?
                  </a>
                </div>
              )}
            </Case>
          </Switch>
        </Error>
      );
    }

    // Position not yet available
    if (this.props.geolocationPermission === GRANTED_PERMISSION) {
      return (
        <Error>
          <div>
            <WaitIcon disabled size={74} color="white" />
          </div>
          <p>Géolocalisation en cours...</p>
        </Error>
      );
    }

    // Permission not given yet
    return (
      <Error>
        <Switch value={this.state.asked}>
          <Case value={true}>
            <div>
              <WaitIcon size={74} color="white" />
            </div>
            <p>
              Une fenêtre va s'ouvrir pour <br />
              confirmer le partage de votre position.
            </p>
          </Case>
          <Case default>
            <div>
              <TargetIcon disabled size={74} color="white" />
            </div>
            <p>
              Vous devez autoriser la géolocalisation
              <br />
              pour accéder aux{' '}
              <ThemeLabel name="spot.plural" default="énigmes" />.
            </p>
            <div className="o-action">
              <button
                className="o-button o-button--alternate o-button--fit"
                onClick={this.askForGeolocation.bind(this)}
              >
                Partager ma position
              </button>
            </div>
          </Case>
        </Switch>
      </Error>
    );
  }
}

export default NoGps;
