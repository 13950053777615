import React from 'react';
import BadgeIcon from '../Badge.component';
import AutumnIcon from '../../../icons/AutumnIcon.component';
import { BADGE_LABEL_SEASON_AUTUMN } from '../../../../../../share/constants/Badges.constants';

class BadgeSeasonAutumn extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#F9D71C"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #FFFE6F)"
        label={BADGE_LABEL_SEASON_AUTUMN}
        {...this.props}
      >
        <AutumnIcon color="#cc6633" />
      </BadgeIcon>
    );
  }
}

export default BadgeSeasonAutumn;
