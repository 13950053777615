import React from 'react';
import { CLOSED_AFTER_APP_STATUS } from '../../../../../common/play/modules/app/App.constants';

import './ConnectionBody.scss';

class ConnectionBody extends React.PureComponent {
  render() {
    if (this.props.appStatus === CLOSED_AFTER_APP_STATUS) {
      return (
        <div className="c-connexion-body">
          <p>
            <strong>Le jeu est fini !</strong>
          </p>
          <p>On se retrouve à 18h45 sur …</p>
        </div>
      );
    }

    return <div className="c-connexion-body" />;
  }
}

export default ConnectionBody;
