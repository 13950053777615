import React from 'react';

class DistancePictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 14}
        height={this.props.size || 14}
        className={this.props.className}
      >
        <g
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="3"
        >
          <circle
            cx="10"
            cy="8"
            r="1"
            fill={this.props.color || 'currentColor'}
          />
          <path d="M10,18 L4,11 C2,7 5,2 10,2 C15,2 18,7 16,11z" />
        </g>
      </svg>
    );
  }
}

export default DistancePictogram;
