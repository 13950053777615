import React from 'react';
import CrossIcon from '../../../../../common/play/components/icons/CrossIcon.component';

class FinalSpot extends React.PureComponent {
  render() {
    return (
      <div className="c-witch-answer">
        <button
          className="c-witch-answer__close"
          onClick={this.props.closeModal}
        >
          <CrossIcon color="#EEEEEE" />
        </button>
        <div className="c-witch-answer__label">
          <p>Bien joué, vous avez réussi à récolter les 4 témoignages&nbsp;!</p>
          <p>
            {' '}
            Rendez-vous maintenant à l'accueil pour consulter le tableau des
            suspects. Utilisez les 4 informations obtenues pour identifier le
            coupable.
          </p>
        </div>
      </div>
    );
  }
}

export default FinalSpot;
