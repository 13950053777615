import { connect } from 'react-redux';
import FreeBar from './FreeBar.component';
import {
  isAdmin,
  isOurGuest,
  isExpired,
  getPendingProduct,
  getExpirationDate,
  getEmail,
  getUser
} from '../../../modules/auth/Auth.selectors';
import { closeFreeBar } from '../../../modules/ui/Ui.actions';
import { activateProduct } from '../../../modules/auth/Auth.actions';
import { showFreeBar } from '../../../modules/ui/Ui.selectors';

function mapStateToProps(state) {
  return {
    isAdmin: isAdmin(state),
    isOurGuest: isOurGuest(state),
    pendingProduct: getPendingProduct(state),
    email: getEmail(state),
    isExpired: isExpired(state),
    expirationDate: getExpirationDate(state),
    showFreeBar: showFreeBar(state),
    location: state.router.location,
    user: getUser(state)
  };
}

const mapDispatchToProps = {
  activateProduct,
  closeFreeBar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreeBar);
