import { to, someRoute } from '../../urls';

export const getCurrentModalId = state => state.ui.currentModal.id;

export const getCurrentModalParams = state => state.ui.currentModal.params;

export const anyModalOpen = state => Boolean(getCurrentModalId(state));

export const isModalOpen = (state, modalId) =>
  getCurrentModalId(state) === modalId;

const FullPageNames = ['accountEdit', 'rules', 'about'];

export const isFullPage = state =>
  someRoute(FullPageNames, state.router.location);

export const getHistory = state => state.ui.history;

export const getLastHistoryEntry = state => {
  if (state.ui.history.length < 2) {
    return to('home');
  }
  return state.ui.history[state.ui.history.length - 2];
};

export const getPrefillAnswer = state => state.ui.prefillAnswer;

export const showFreeBar = state => state.ui.showFreeBar;
