import React from 'react';
import BadgeIcon from '../Badge.component';
import SpringTrophyIcon from '../../../icons/SpringTrophyIcon.component';
import { BADGE_LABEL_ACHIEVEMENT_SPRING } from '../../../../../../share/constants/Badges.constants';

class BadgeAchievementSpring extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#80d629"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #FFFE6F)"
        label={BADGE_LABEL_ACHIEVEMENT_SPRING}
        {...this.props}
      >
        <SpringTrophyIcon color="#80d629" size="45" />
      </BadgeIcon>
    );
  }
}

export default BadgeAchievementSpring;
