import React from 'react';
import version from '../../../../version';
import ThemeComponent from '../theme/ThemeComponent.component';
import ThemeLabel from '../theme/ThemeLabel.component';
import withThemeFeatures from '../theme/withThemeFeatures.hoc';

import './About.scss';

class About extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasLocalStorage: localStorage.getItem('state-auth') !== null
    };
  }

  onClearCache(event) {
    event.preventDefault();
    localStorage.clear();
    this.setState(() => ({
      hasLocalStorage: localStorage.getItem('state-auth') !== null
    }));
  }

  onUpdate(event) {
    event.preventDefault();
    window.location.reload();
  }

  render() {
    const versionStr = version + '';
    const dotVersion =
      versionStr.substr(0, 1) +
      '.' +
      versionStr.substr(1, 1) +
      '.' +
      versionStr.substr(2);
    const groups = this.props.user.groups.join('.');

    return (
      <div className="c-about">
        <h1 className="c-about__title">
          <ThemeLabel name="aboutPageName" default="À propos de Zupple" />
        </h1>
        <div className="c-about__sub-title">
          Zupple v{dotVersion}
          {groups ? ` - ${groups}` : ''}
        </div>
        <div className="o-action">
          {!this.props.isAppUpToDate && (
            <span className="c-about__button">
              <button
                className="o-button o-button--fit"
                onClick={this.onUpdate.bind(this)}
              >
                Mettre à jour
              </button>
            </span>
          )}
          {this.state.hasLocalStorage &&
            this.props.themeFeatures.disconnect !== false && (
              <span className="c-about__button">
                <button
                  className="o-button o-button--fit"
                  onClick={this.onClearCache.bind(this)}
                >
                  Vider le cache
                </button>
              </span>
            )}
        </div>
        <ThemeComponent name="About" />
      </div>
    );
  }
}

export default withThemeFeatures(About);
