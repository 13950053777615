import React from 'react';

class WitchAwardModal extends React.PureComponent {
  render() {
    if (this.props.isFinal) {
      return this.renderEnd();
    } else {
      return this.renderClassic();
    }
  }

  renderClassic() {
    return (
      <div>
        <p>
          Génial ! Vous avez récupéré un témoignage. Notez l'information
          obtenue, elle vous sera certainement utile plus tard pour trouver le
          coupable.
        </p>
      </div>
    );
  }

  renderEnd() {
    return (
      <p>
        {' '}
        Bravo ! Vous avez démasqué la coupable. C'est bien la vampiresse qui a
        dérobé le sang de licorne.
      </p>
    );
  }
}

export default WitchAwardModal;
