import React, { Fragment } from 'react';
import {
  GRANTED_PERMISSION,
  STOPPED_STATUS,
  WORKING_STATUS
} from '../../../modules/geolocation/Geolocation.constants';
import { newPropValue } from '../../../../../utils/basis';
import NoGps from './NoGps.container';
import UpdateBar from '../StatusBar/UpdateBar.component';

class GeolocationGate extends React.PureComponent {
  componentDidMount() {
    this.props.requestGeolocationPermission();
  }

  componentDidUpdate(prevProps) {
    if (
      newPropValue(
        this.props,
        prevProps,
        'geolocationPermission',
        GRANTED_PERMISSION
      ) &&
      this.props.geolocationStatus === STOPPED_STATUS
    ) {
      this.props.startGeolocation();
    }
  }

  render() {
    if (
      !this.props.isGeolocationApp ||
      this.props.geolocationStatus === WORKING_STATUS
    ) {
      return this.props.children;
    }

    return (
      <Fragment>
        <UpdateBar />
        <NoGps />
      </Fragment>
    );
  }
}

export default GeolocationGate;
