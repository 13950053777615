import React from 'react';

class AutologinFooter extends React.PureComponent {
  render() {
    return (
      <div>
        <div className="c-autologin__notice">
          <img
            src="/images/LogoMetropole.png"
            height="80"
            alt="Métropole du Grand Lyon"
          />
        </div>
      </div>
    );
  }
}

export default AutologinFooter;
