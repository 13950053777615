import {
  createStore as createReduxStore,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';

import createReducers from './createReducers';

export const history = createHistory();

function createStore(options) {
  const reducers = createReducers(options);
  const middlewares = [thunk, routerMiddleware(history)];
  const enhancers = [];

  // Redux Access to the Browser DevTools
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers
  );

  return createReduxStore(
    connectRouter(history)(reducers),
    {},
    composedEnhancers
  );
}

export default createStore;
