import { connect } from 'react-redux';
import { pageView } from '../Ui.actions';
import withThemeData from '../../../components/theme/withThemeData.hoc';

const mapDispatchToProps = {
  pageView
};

const withPageView = Component =>
  connect(
    null,
    mapDispatchToProps
  )(withThemeData(Component));

export default withPageView;
