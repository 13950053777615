import React from 'react';

class CluePictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 19 19"
        width={this.props.size || 19}
        height={this.props.size || 19}
        className={this.props.className}
      >
        <g
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="3"
          strokeLinecap="round"
        >
          <circle cx="7.5" cy="7.5" r="6" />
          <path d="M12,12 L17,17" />
        </g>
      </svg>
    );
  }
}

export default CluePictogram;
