import React from 'react';
import { displayDuration } from '../../utils/date';

import './StoryProgressBox.scss';

class Timer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      now: new Date()
    };
  }

  componentDidMount() {
    this.updateTime();
  }

  updateTime() {
    this.timer = setTimeout(() => {
      if (!this.props.end) {
        this.setState(() => ({
          now: new Date()
        }));
        this.updateTime();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const start = new Date(this.props.start);
    const end = this.props.end ? new Date(this.props.end) : this.state.now;
    const time = displayDuration(end - start);
    if (!this.props.withTag) {
      return time;
    }

    return <div className="o-tag">{time}</div>;
  }
}

export default Timer;
