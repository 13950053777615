import { connect } from 'react-redux';
import StoryPreview from './StoryPreview.component';
import { openModal } from '../../modules/ui/Ui.actions';
import { playStory } from '../../modules/story/Story.actions';
import {
  getCompletedSpotsForStory,
  getStoryEndDate,
  getStoryStartDate,
  isGameAnswered,
  getStoryProgressXp,
  isCompletedStory
} from '../../modules/game/Game.selectors';
import { getUser } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state, ownProps) {
  let answeredSpots = getCompletedSpotsForStory(state, ownProps.story.id);
  let answeredSpotsCount = answeredSpots.length;
  let totalSpotsCount = ownProps.story.spots.length;
  let isCompleted = totalSpotsCount === answeredSpotsCount;

  if (ownProps.story.finalSpot && isCompleted) {
    totalSpotsCount = totalSpotsCount + 1;
    if (isGameAnswered(state, ownProps.story.finalSpot)) {
      answeredSpotsCount = answeredSpotsCount + 1;
    }
  }
  return {
    answeredSpotsCount: answeredSpotsCount,
    totalSpotsCount,
    user: getUser(state),
    isCompleted: isCompletedStory(state, ownProps.story.id),
    storyStartDate: getStoryStartDate(state, ownProps.story.id),
    storyEndDate: getStoryEndDate(state, ownProps.story.id),
    currentProgressXp: getStoryProgressXp(state, ownProps.story.id)
  };
}

const mapDispatchToProps = {
  openModal,
  playStory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryPreview);
