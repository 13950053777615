module.exports = {
  id: 1,
  title: 'Récolte des témoignages',
  pitch: `TODO`,
  spots: [3, 1, 4, 2],
  finalSpot: 5,
  duration: '2 h',
  xp: 240,
  level: 2,
  zone: 'TODO',
  logo: '/images/spots/Detectyves_GratteCiel/intro.svg',
  introImage: '/images/spots/Detectyves_GratteCiel/intro.svg',
  universeId: 1,
  dialog: {
    databaseName: `Base BlendWebMix 2021`,
    statusComplete: `RDV au stand Zupple !`,
    statusFinal: `RDV au stand Zupple !`,
    suspectsCount: 800
  }
};
