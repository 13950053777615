import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_STORY_PAGAILLE } from '../../../../../../share/constants/Badges.constants';

class BadgeStoryPagaille extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#1AA5BE"
        label={BADGE_LABEL_STORY_PAGAILLE}
        {...this.props}
      >
        <svg viewBox="0 0 24 24" width="34" height="34">
          <path
            d="M20,3 L13,11 L16,11 L4,23 L10,13 L6,13 L13,3 z"
            fill="#1AA5BE"
          />
        </svg>
      </BadgeIcon>
    );
  }
}

export default BadgeStoryPagaille;
