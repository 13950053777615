import React, { Fragment } from 'react';
import classNames from 'classnames';
import { STORY_INTRO_MODAL } from '../layouts/Modal/Modal.constants';
import { renderFor } from '../../../../utils/render';
import { displayDuration } from '../../utils/date';
import { getDistanceLabel } from '../../modules/geolocation/Geolocation.functions';
import { isStoryTeaser } from '../../modules/story/Story.functions';

import './StoryPreview.scss';

class StoryPreview extends React.PureComponent {
  onClick() {
    if (
      isStoryTeaser(this.props.story, this.props.user) ||
      this.props.inModal
    ) {
      return null;
    }

    this.props.openModal(STORY_INTRO_MODAL, {
      user: this.props.user,
      storyId: this.props.story.id,
      playStory: this.props.playStory,
      distance: this.props.distance,
      isCompleted: this.props.isCompleted,
      isConnected: true
    });
  }

  renderStar(index) {
    return (
      <svg
        viewBox="0 0 12 12"
        preserveAspectRatio="xMidYMin meet"
        className="c-story-preview__star"
        key={`star-${index}`}
      >
        <defs>
          <linearGradient
            x1="0%"
            y1="49.9976963%"
            x2="99.9944101%"
            y2="49.9976963%"
            id="linearGradient-1"
          >
            <stop stopColor="#64CDF4" offset="0%" />
            <stop stopColor="#B8E986" offset="100%" />
          </linearGradient>
        </defs>

        <g id="icon-star" stroke="none" strokeWidth="1" fill="none">
          <path
            d="M11.9748708,4.18295265 C11.912302,4.01395313 11.7520231,3.899857 11.5711736,3.899857 L7.95332718,3.899857 L6.38567943,0.259933276 C6.31796802,0.102086006 6.17054571,0 5.99998152,0 C5.82941733,0 5.68199502,0.102086006 5.61428361,0.259933276 L4.04663586,3.899857 L0.42878943,3.899857 C0.247939964,3.899857 0.087661053,4.01395313 0.0250922803,4.18295265 C-0.0357622795,4.35281004 0.0165212155,4.5432562 0.155372465,4.65906805 L3.26409765,7.24724856 L1.69987833,11.4199068 C1.63388113,11.597485 1.68959306,11.7965098 1.83958669,11.9106059 C1.98958032,12.0264178 2.19528588,12.0298493 2.34956504,11.9200425 L6.00083863,9.31298892 L9.65211221,11.9200425 C9.80639138,12.0298493 10.0120969,12.0264178 10.1620906,11.9106059 C10.3120842,11.795652 10.3677961,11.597485 10.3017989,11.4199068 L8.7375796,7.24724856 L11.8463048,4.65906805 C11.9834418,4.5432562 12.0357253,4.35281004 11.9748708,4.18295265 Z"
            fill="url(#linearGradient-1)"
          />
        </g>
      </svg>
    );
  }

  renderStars() {
    if (isStoryTeaser(this.props.story, this.props.user)) {
      return null;
    }

    const level = this.props.story.level;
    return (
      <span className="c-story-preview__level">
        {renderFor(level, index => this.renderStar(index))}
      </span>
    );
  }

  renderPrice() {
    if (!this.props.story.isFree) {
      return null;
    }

    return (
      <Fragment>
        &nbsp;
        <span className="o-tag o-tag--small o-tag--upper">Gratuit</span>
      </Fragment>
    );
  }

  renderXp() {
    if (isStoryTeaser(this.props.story, this.props.user)) {
      return this.props.story.teaser;
    }

    if (this.props.isCompleted) {
      return (
        <div className="c-story-preview__xp">
          +{this.props.currentProgressXp} XP
        </div>
      );
    } else {
      if (
        this.props.storyStartDate &&
        !this.props.storyEndDate &&
        this.props.currentProgressXp > 0
      ) {
        return (
          <div className="c-story-preview__xp">
            <span className="c-story-preview__highlight">
              +{this.props.currentProgressXp}
            </span>
            /{this.props.story.xp} XP
          </div>
        );
      } else {
        return (
          <div className="c-story-preview__xp">{this.props.story.xp} XP</div>
        );
      }
    }
  }

  renderSpotCount() {
    if (isStoryTeaser(this.props.story, this.props.user)) {
      return null;
    }

    let className = '';
    if (this.props.isCompleted) {
      return (
        <span>
          <span className="c-story-preview__highlight">
            {this.props.answeredSpotsCount}
          </span>
          /{this.props.totalSpotsCount}
        </span>
      );
    }

    if (this.props.answeredSpotsCount > 0) {
      className = 'c-story-preview__highlight';
    }
    return (
      <span>
        <span className={className}>{this.props.answeredSpotsCount}</span>/
        {this.props.totalSpotsCount}
      </span>
    );
  }

  renderDuration() {
    if (isStoryTeaser(this.props.story, this.props.user)) {
      return null;
    }

    if (this.props.isCompleted) {
      let duration =
        new Date(this.props.storyEndDate) - new Date(this.props.storyStartDate);
      return displayDuration(duration);
    }

    if (this.props.storyStartDate) {
      return <span className="o-tag o-tag--small o-tag--upper">En cours</span>;
    }

    return <span>~{this.props.story.duration}</span>;
  }

  render() {
    const story = this.props.story;
    let classModifier = '';
    if (isStoryTeaser(story, this.props.user)) {
      classModifier = `c-story-preview--teaser`;
    } else if (this.props.isCompleted) {
      classModifier = `c-story-preview--completed`;
    } else if (this.props.storyStartDate && !this.props.storyEndDate) {
      classModifier = `c-story-preview--ongoing`;
    }

    let classForRank = '';
    if (this.props.inModal) {
      classForRank = 'c-story-preview--display';
    } else if (this.props.isFirst && this.props.isLast) {
      classForRank = 'c-story-preview--only';
    } else if (this.props.isFirst) {
      classForRank = 'c-story-preview--first';
    } else if (this.props.isLast) {
      classForRank = 'c-story-preview--last';
    }
    const classes = classNames('c-story-preview', classModifier, classForRank);

    return (
      <button className={classes} onClick={this.onClick.bind(this)}>
        <div className="c-story-preview__line">
          <div className="c-story-preview__title">
            {this.props.story.shortTitle || this.props.story.title}
            {this.renderStars()}
            {this.renderPrice()}
          </div>
          {this.renderXp()}
        </div>
        <div className="c-story-preview__line">
          <div className="c-story-preview__infos">
            <span className="c-story-preview__zone">{story.zone} </span>
            {!this.props.isCompleted &&
              !isStoryTeaser(story, this.props.user) &&
              getDistanceLabel(this.props.distance)}
          </div>
          <div className="c-story-preview__stats">
            <span className="c-story-preview__spots">
              {this.renderSpotCount()}
            </span>
            <div>{this.renderDuration()}</div>
          </div>
        </div>
      </button>
    );
  }
}

export default StoryPreview;
