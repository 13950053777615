import React from 'react';

import './Badge.scss';

class BadgeIcon extends React.PureComponent {
  render() {
    const { color, active, count, label, withLabel } = this.props;
    const background = this.props.background || color;

    return (
      <div className="c-badge">
        <div
          className="c-badge__icon"
          style={{
            color,
            background,
            opacity: active ? '1' : '0.2'
          }}
        >
          <div className="c-badge__circle">{this.props.children}</div>
          {Boolean(count) && (
            <div
              className="c-badge__bubble"
              style={{ borderColor: color, color }}
            >
              {count}
            </div>
          )}
        </div>
        {withLabel && (
          <div className="c-badge__label">
            {label}
            {count === 1 && ' Débutant·e'}
            {count === 2 && ' Confirmé·e'}
            {count === 3 && ' Expert·e'}
            {count === 4 && ' Héros'}
          </div>
        )}
      </div>
    );
  }
}

export default BadgeIcon;
