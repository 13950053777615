import React from 'react';
import BadgeIcon from '../Badge.component';
import SpringIcon from '../../../icons/SpringIcon.component';
import { BADGE_LABEL_SEASON_SPRING } from '../../../../../../share/constants/Badges.constants';

class BadgeSeasonSpring extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#80d629"
        background="linear-gradient(to top, #cc6633, #F8F8F8, #b8e986, #FFFE6F)"
        label={BADGE_LABEL_SEASON_SPRING}
        {...this.props}
      >
        <SpringIcon color="#80d629" />
      </BadgeIcon>
    );
  }
}

export default BadgeSeasonSpring;
