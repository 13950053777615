import React from 'react';
import PlaceIcon from '../icons/PlaceIcon.component';

import './Rule.scss';

class PlaceRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="partout-dans-lyon">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Se balader dans Lyon</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <PlaceIcon size="60" />
            </div>
            <div className="c-rule__text">
              Nous avons placé des énigmes dans votre ville préférée. Elles sont
              dans des lieux touristiques, historiques ou insolites.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlaceRule;
