import { connect } from 'react-redux';
import Rank from './Rank.component';
import { updateGlobalRank } from '../../modules/rank/Rank.actions';
import {
  getAllRanks,
  getRankIndexesByValue,
  getTopRankValues
} from '../../modules/rank/Rank.selectors';
import { getUser, getUserRank } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state) {
  const userRank = getUserRank(state);
  const rankIndexesByValue = getRankIndexesByValue(state);
  const userRankIndex = rankIndexesByValue[userRank]
    ? Math.min.apply(null, rankIndexesByValue[userRank])
    : false;
  const topRankValues = getTopRankValues(state);

  return {
    user: getUser(state),
    ranks: getAllRanks(state),
    topRankValues,
    rankIndexesByValue,
    userRank,
    userRankIndex
  };
}

const mapDispatchToProps = {
  updateGlobalRank
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rank);
