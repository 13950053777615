import {
  CLOSE_MODAL,
  OPEN_MODAL,
  BACK_HISTORY,
  PREFILL_ANSWER,
  CLOSE_FREE_BAR
} from './Ui.constants';
import { anyModalOpen } from './Ui.selectors';
import { fetchAndUpdate } from '../api/Api.actions';

export function checkAppStatus() {
  return fetchAndUpdate('/api/app/get');
}

export function openModal(modalId, modalParams = {}) {
  window.history.pushState(null, '', window.location.href);

  return {
    type: OPEN_MODAL,
    modalId,
    modalParams
  };
}

export function closeModal() {
  return function(dispatch, getState) {
    if (!anyModalOpen(getState())) {
      return null;
    }

    dispatch({
      type: CLOSE_MODAL
    });
  };
}

export function closeFreeBar() {
  return function(dispatch, getState) {
    dispatch({
      type: CLOSE_FREE_BAR
    });
  };
}

export function goBack() {
  return {
    type: BACK_HISTORY
  };
}

export function pageView(pageName, spotId = null) {
  return fetchAndUpdate('/api/page/view', {
    pageName,
    spotId
  });
}

export function sendMail(message, user, userId, spotId, spotTitle) {
  return fetchAndUpdate('/api/mail', {
    message,
    user,
    userId,
    spotId,
    spotTitle
  });
}

export function eventParticipation(universeId, team) {
  return fetchAndUpdate('/api/event/participated', {
    universeId,
    team
  });
}

export function review(storyId, like = false, message = null) {
  return fetchAndUpdate('/api/review', {
    storyId,
    like,
    message
  });
}

export function prefillAnswer(answer) {
  return {
    type: PREFILL_ANSWER,
    prefillAnswer: answer
  };
}
