import { connect } from 'react-redux';
import ForGroups from './ForGroups.component';
import { isUserInOneGroup } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state, ownProps) {
  return {
    isInOneGroup: isUserInOneGroup(state, ownProps.groups)
  };
}

export default connect(mapStateToProps)(ForGroups);
