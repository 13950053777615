import { connect } from 'react-redux';
import { getCurrentModalParams } from '../../../modules/ui/Ui.selectors';

function mapStateToProps(state) {
  return {
    ...getCurrentModalParams(state)
  };
}

export function ModalHoc(component) {
  return connect(mapStateToProps)(component);
}
