import { connect } from 'react-redux';
import Answer from './Answer.component';
import spots from '~data/spots';
import stories from '~data/stories';
import { isDefined } from '../../../../utils/basis';
import { getRouteParams } from '../../urls';
import {
  getMalus,
  getAnsweredSpotsForStory,
  getTryCount
} from '../../modules/game/Game.selectors';
import { proposeAnswer } from '../../modules/game/Game.actions';
import { openModal, closeModal } from '../../modules/ui/Ui.actions';
import { getPrefillAnswer } from '../../modules/ui/Ui.selectors';

function mapStateToProps(state) {
  const spotId = getRouteParams(state.router.location);
  let story = null;
  let spot = null;
  let malus = 0;
  let isLast = false;
  let prefillAnswer = getPrefillAnswer(state);
  if (isDefined(spotId)) {
    spot = spots[spotId];
    story = stories[spot.storyId];
    malus = getMalus(state, spotId);
    isLast =
      story.spots.length ===
      getAnsweredSpotsForStory(state, spot.storyId).length;
  }
  return {
    story,
    spot,
    malus,
    isLast,
    prefillAnswer,
    tryCount: getTryCount(state, spotId)
  };
}

const mapDispatchToProps = {
  openModal,
  proposeAnswer,
  closeModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Answer);
