import {
  MOVE_MAP,
  RECENTER_MAP,
  ACTIVE_GLOBAL_VIEW,
  MANUAL_VIEW_MODE,
  GLOBAL_VIEW_MODE,
  SPOTS_VIEW_MODE,
  ACTIVE_SPOTS_VIEW,
  SET_HIGHLIGHTED_SPOT,
  HIGHLIGHT_SPOT_VIEW_MODE
} from './Map.constants';

const initialState = {
  playerZoom: 15,
  zoom: 15,
  position: null,
  highlightedSpotId: null,
  viewMode: SPOTS_VIEW_MODE
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOVE_MAP:
      let playerZoom;
      let viewMode = action.position ? MANUAL_VIEW_MODE : state.viewMode;
      let position = action.position || state.position;

      // If we change the zoom when following the player, we update playerZoom
      if (action.zoom && !position && viewMode === MANUAL_VIEW_MODE) {
        playerZoom = action.zoom;
      }

      return {
        ...state,
        position,
        playerZoom: playerZoom || state.playerZoom,
        zoom: action.zoom || state.zoom,
        viewMode
      };

    case RECENTER_MAP:
      return {
        ...state,
        zoom: state.playerZoom,
        position: null,
        highlightedSpotId: null,
        viewMode: MANUAL_VIEW_MODE
      };

    case ACTIVE_GLOBAL_VIEW:
      return {
        ...state,
        position: null,
        highlightedSpotId: null,
        viewMode: GLOBAL_VIEW_MODE
      };

    case ACTIVE_SPOTS_VIEW:
      return {
        ...state,
        position: null,
        highlightedSpotId: null,
        viewMode: SPOTS_VIEW_MODE
      };

    case SET_HIGHLIGHTED_SPOT:
      if (action.spot === null) {
        return {
          ...state,
          highlightedSpotId: null
        };
      }
      return {
        ...state,
        zoom: 16,
        highlightedSpotId: action.spot.id,
        viewMode: HIGHLIGHT_SPOT_VIEW_MODE
      };

    default:
      return state;
  }
};

export default MapReducer;
