import React from 'react';
import { getRouteParams, to } from '../../urls';
import { history } from '../../modules/createStore';
import StoryIntroModal from '../layouts/Modal/StoryIntroModal.component';
import { STORY_INTRO_MODAL } from '../layouts/Modal/Modal.constants';

import stories from '~data/stories';

import './StoryIntroPage.scss';

class StoryIntroPage extends React.PureComponent {
  componentDidMount() {
    const storyId = getRouteParams(this.props.location);
    const story = stories[storyId];
    if (!story) {
      return null;
    }

    this.props.openModal(STORY_INTRO_MODAL, {
      user: this.props.user,
      storyId: story.id,
      playStory: this.props.playStory,
      isConnected: this.props.isConnected,
      onClose: this.onClose.bind(this)
    });
  }

  onClose() {
    history.push(to('home'));
  }

  render() {
    return (
      <div className="c-story-intro-page">
        <StoryIntroModal />
      </div>
    );
  }
}

export default StoryIntroPage;
