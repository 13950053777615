import { connect } from 'react-redux';
import Spot from './Spot.component';
import {
  isGameStarted,
  isGameInProgress,
  isGameAnswered,
  getNextClueIndex,
  getMalus,
  getGameTimeBySpotId,
  isGameBlocked,
  getGameMaxDateBySpotId
} from '../../modules/game/Game.selectors';
import { getRouteParams } from '../../urls';
import spots from '~data/spots';
import { findGame } from '../../modules/game/Game.actions';
import { openModal, prefillAnswer } from '../../modules/ui/Ui.actions';
import { getCurrentStoryId } from '../../modules/story/Story.selectors';
import { getReachDistance } from '../../modules/options/Options.selectors';
import {
  getIsGeolocationApp,
  getPlayerPosition,
  hasPlayerPosition
} from '../../modules/geolocation/Geolocation.selectors';
import { getUser } from '../../modules/auth/Auth.selectors';

function mapStateToProps(state) {
  const spotId = getRouteParams(state.router.location);
  const story = getCurrentStoryId(state);
  const spot = spots[spotId];

  return {
    spot,
    isGeolocationApp: getIsGeolocationApp(state),
    isGameStarted: isGameStarted(state, spotId),
    hasPlayerPosition: hasPlayerPosition(state),
    playerPosition: getPlayerPosition(state),
    isGameInProgress: isGameInProgress(state, spotId),
    isGameAnswered: isGameAnswered(state, spotId),
    isGameBlocked: isGameBlocked(state, spotId),
    nextClueIndex: getNextClueIndex(state, spotId),
    clues: spots[spotId].clues,
    malus: getMalus(state, spotId),
    story,
    reachDistance: getReachDistance(state),
    user: getUser(state),
    gameTime: getGameTimeBySpotId(state, spotId),
    maxDate: getGameMaxDateBySpotId(state, spotId)
  };
}

const mapDispatchToProps = {
  findGame,
  openModal,
  prefillAnswer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Spot);
