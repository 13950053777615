const {
  IMAGE
} = require('../../../../../../common/play/constants/contentType.constants');

module.exports = {
  id: 4,
  storyId: 1,
  title: 'Le Serpent',
  coordinates: [45.755353, 4.838681],
  xp: 30,
  riddle: {
    content: [
      `Quelle est la lettre sur laquelle était la joueuse au début de son tour ?`,
      {
        type: IMAGE,
        content: '/images/spots/serpent.png'
      }
    ]
  },
  placeName: 'Rayon Jeunesse',
  clues: [
    {
      content: `Après avoir fait son premier 6, la joueuse a grimpé l'échelle rouge.`,
      malus: 5
    }
  ],
  hint:
    "Pour résoudre cette énigme, vous devez utliser l'affiche correspondante présente dans la médiathèque.",
  dialog: {
    question:
      'Quelle est la lettre sur laquelle était la joueuse au début de son tour ?',
    fail: [`Non, ce n'est pas la bonne réponse.`],
    cheers: {
      '^p$':
        'Non, ce n’est pas la réponse. Les échelles permettent de monter vers la mort, pas de redescendre vers le départ.'
    },
    cheersAuto: {
      'min-length(2)':
        "Non, ce n'est pas la bonne réponse. Vous cherchez une lettre."
    },
    validation: `Sssssssuper ! Vous avez trouvé la bonne lettre ! `
  },
  answer: ['s'],
  button: 'serpent'
};
