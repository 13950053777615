import React from 'react';

class CrossPictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <path
          fill="none"
          stroke={this.props.color || 'currentColor'}
          strokeWidth="3"
          strokeLinecap="round"
          d="M4,4 L16,16 M4,16 L16,4"
        />
      </svg>
    );
  }
}

export default CrossPictogram;
