import React from 'react';

import './StatusBar.scss';

class UpdateBar extends React.PureComponent {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      newVersion: false
    };

    // Update newVersion state
    window.isUpdateAvailable.then(() => {
      this.setState(() => ({
        newVersion: true
      }));
    });
  }

  reload() {
    window.location.reload();
  }

  render() {
    if (this.state.newVersion) {
      return (
        <div className="c-status-bar">
          Une nouvelle version est disponible
          <br />
          <button
            className="o-button o-button--alert o-button--tiny c-status-bar__button"
            onClick={this.reload.bind(this)}
          >
            Mettre à jour
          </button>
        </div>
      );
    }

    return null;
  }
}

export default UpdateBar;
