import React, { Fragment } from 'react';
import classNames from 'classnames';
import { capitalize } from '../../../../utils/basis';

import './Stats.scss';
import { renderFor, renderForEach } from '../../../../utils/render';

const TABLE_MAX_BEFORE = 5;
const TABLE_MAX_AFTER = 5;

class Rank extends React.PureComponent {
  componentDidMount() {
    this.props.updateGlobalRank();
  }

  renderPodiumStep(rankPosition) {
    const rankValue = this.props.topRankValues[rankPosition - 1];
    if (!rankValue) {
      return null;
    }
    const rankIndexes = this.props.rankIndexesByValue[rankValue];
    const classes = classNames('c-stats__stats-value', 'o-laurel', {
      'o-laurel--smaller': rankPosition !== 1
    });
    const xp = this.props.ranks[rankIndexes[0]].xp;

    return (
      <div className="c-stats__stats-item">
        <div className={classes}>{rankValue}</div>
        <div className="c-stats__stats-label">
          {this.props.user.rank === rankValue && (
            <strong key="user" className="c-stats__user">
              {capitalize(this.props.user.firstName)}
            </strong>
          )}
          {renderForEach(rankIndexes, rankIndex => {
            const user = this.props.ranks[rankIndex];
            if (user._id === this.props.user._id) {
              return null;
            }

            return (
              <strong key={rankIndex}>
                {capitalize(this.props.ranks[rankIndex].firstName)}
              </strong>
            );
          })}
          {xp} XP
        </div>
      </div>
    );
  }

  renderUserRankTable() {
    const {
      ranks,
      rankIndexesByValue,
      topRankValues,
      userRankIndex
    } = this.props;
    if (!Object.values(ranks).length) {
      return null;
    }

    const afterPodiumIndex = Math.min.apply(
      null,
      rankIndexesByValue[topRankValues[3]]
    );
    const isUserOnTop = userRankIndex < afterPodiumIndex;
    const center = isUserOnTop
      ? afterPodiumIndex + TABLE_MAX_BEFORE
      : userRankIndex;
    const isGapAfterPodium = center > afterPodiumIndex + TABLE_MAX_BEFORE;
    const ranksTableWithoutUser = Object.values(ranks).filter(
      user => isUserOnTop || this.props.user._id !== user._id
    );
    const startIndex = Math.max(center, afterPodiumIndex);

    return (
      <Fragment>
        {isGapAfterPodium && (
          <h2 className="c-stats__title-2">Autour de moi</h2>
        )}
        <table className="c-stats__rank-table">
          <tbody>
            {renderFor(TABLE_MAX_BEFORE, gap =>
              this.renderUserRankLineByIndex(
                ranksTableWithoutUser,
                afterPodiumIndex,
                startIndex - TABLE_MAX_BEFORE + gap
              )
            )}
            {!isUserOnTop && this.renderUserRankLine(this.props.user, 'user')}
            {renderFor(TABLE_MAX_AFTER, gap =>
              this.renderUserRankLineByIndex(
                ranksTableWithoutUser,
                afterPodiumIndex,
                startIndex + gap
              )
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }

  renderUserRankLineByIndex(ranks, afterPodiumIndex, index) {
    if (index < afterPodiumIndex) {
      return null;
    }
    return this.renderUserRankLine(ranks[index], index);
  }

  renderUserRankLine(user, index) {
    if (!user) {
      return null;
    }

    return (
      <tr key={index} className={index === 'user' ? 'c-stats__user' : ''}>
        <td className="c-stats__rank-cell">{user.rank}</td>
        <td className="c-stats__rank-cell">{capitalize(user.firstName)}</td>
        <td className="c-stats__rank-cell">{user.xp} XP</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="c-stats">
        <div className="c-stats__header">
          <div className="c-stats__title">Classement</div>
          <div className="c-stats__section">
            <div className="c-stats__stats-grid">
              {this.renderPodiumStep(2)}
              {this.renderPodiumStep(1)}
              {this.renderPodiumStep(3)}
            </div>
          </div>
        </div>
        {this.renderUserRankTable()}
      </div>
    );
  }
}

export default Rank;
