module.exports = {
  id: 5,
  storyId: 1,
  title: 'Le coupable',
  coordinates: [45.755353, 4.838681],
  xp: 30,
  riddle: {
    content: [
      `Grâce aux 4 informations récoltées et au tableau des membres de la Confrérie, identifiez qui a dérobé le sang de licorne et tous les autres ingrédients.`
    ]
  },
  placeName: 'Accueil',
  clues: [
    {
      content: `Lisez à haute voix les 4 réponses trouvées. Essayez dans différents ordres. Cela devrait vous dire quelque chose.`,
      malus: 5
    }
  ],
  hint:
    "Pour résoudre cette énigme, vous devez utliser l'affiche des membres de la confrérie présente dans la médiathèque.",
  dialog: {
    question: 'Qui a volé le sang de licorne ?',
    fail: [`Non, ce n'est pas la bonne réponse.`],
    validation: `Bravo ! Vous avez démasqué la coupable !`
  },
  answer: ['lavampiresse', 'vampiresse'],
  isFinal: true
};
