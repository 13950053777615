import { GLOBAL_VIEW_MODE, SPOTS_VIEW_MODE } from './Map.constants';

export const getMapPosition = state => state.map.position;

export const getMapZoom = state => state.map.zoom;

export const getViewMode = state => state.map.viewMode;

export const isGlobalView = state => state.map.viewMode === GLOBAL_VIEW_MODE;

export const isSpotsView = state => state.map.viewMode === SPOTS_VIEW_MODE;

export const getHighlightedSpotId = state => state.map.highlightedSpotId;
