import React from 'react';

class TargetIcon extends React.PureComponent {
  render() {
    if (this.props.disabled) {
      return (
        <svg
          viewBox="0 0 74 74"
          width={this.props.size || 24}
          height={this.props.size || 24}
          className={this.props.className}
        >
          <path
            d="M37.088 5.002A3 3 0 0 0 34 8v3.172a25.76 25.76 0 0 0-10.586 3.683l-8.037-8.103a3 3 0 0 0-2.15-.918 3 3 0 0 0-2.112 5.143l51.805 52.24a3 3 0 1 0 4.26-4.225l-8.149-8.217A25.797 25.797 0 0 0 62.828 40H66a3 3 0 0 0 0-6h-3.172C61.453 22.038 51.962 12.547 40 11.172V8a3 3 0 0 0-2.912-2.998zM37 22c8.274 0 15 6.726 15 15 0 1.975-.39 3.858-1.088 5.586l-4.941-4.982c.013-.2.029-.4.029-.604 0-4.964-4.037-9-9-9-.178 0-.352.015-.527.025l-4.94-4.98A14.863 14.863 0 0 1 37 22zm-21.947 1.092A25.816 25.816 0 0 0 11.172 34H8a3 3 0 0 0 0 6h3.172C12.547 51.962 22.038 61.453 34 62.828V66a3 3 0 0 0 6 0v-3.172a25.819 25.819 0 0 0 10.727-3.762l-8.102-8.17A14.911 14.911 0 0 1 37 52c-8.274 0-15-6.726-15-15 0-2.035.41-3.975 1.146-5.746l-8.093-8.162zM28.04 36.189A9.06 9.06 0 0 0 28 37c0 4.963 4.037 9 9 9 .247 0 .49-.018.732-.037l-9.691-9.774z"
            fill={this.props.color || 'currentColor'}
          />
        </svg>
      );
    }

    return (
      <svg
        viewBox="0 0 74 74"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <path
          d="M40 62.829V66a3 3 0 0 1-6 0v-3.171C22.038 61.454 12.546 51.962 11.171 40H8a3 3 0 0 1 0-6h3.171C12.546 22.038 22.038 12.546 34 11.171V8a3 3 0 0 1 6 0v3.171C51.962 12.546 61.454 22.038 62.829 34H66a3 3 0 0 1 0 6h-3.171C61.454 51.962 51.962 61.454 40 62.829zM37 52c8.274 0 15-6.726 15-15 0-8.274-6.726-15-15-15-8.274 0-15 6.726-15 15 0 8.274 6.726 15 15 15z"
          fill="#FFF"
        />
        <path
          d="M28 37c0 4.963 4.037 9 9 9s9-4.037 9-9c0-4.964-4.037-9-9-9s-9 4.036-9 9"
          fill="#FFF"
        />
      </svg>
    );
  }
}

export default TargetIcon;
