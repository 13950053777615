import React from 'react';
import withPreFillAnswer from './withPrefillAnswer.hoc';
import { renderFor } from '../../../../utils/render';
import { numberToLetter } from '../../../../utils/basis';

import './ImageGrid.scss';

class ImageGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputNumber: 0,
      answer: []
    };
  }

  componentDidMount() {
    this.updateGridHeight();
  }

  componentDidUpdate() {
    this.updateGridHeight();
  }

  updateGridHeight() {
    const grid = document.querySelector('.c-image-grid');
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        const height = Math.ceil(grid.clientHeight);
        if (height > 0) {
          grid.style.height = height + 'px';
        }
      });
    }, 100);
  }

  getGridStyles() {
    let rowsTemplate = `repeat(${this.props.linesCount}, minmax(0, 1fr))`;
    let columnsTemplate = `repeat(${this.props.columnsCount}, minmax(0, 1fr))`;

    if (this.props.linesTemplate) {
      rowsTemplate = this.props.linesTemplate;
    }

    if (this.props.columnsTemplate) {
      columnsTemplate = this.props.columnsTemplate;
    }

    return {
      gridTemplateColumns: columnsTemplate,
      gridTemplateRows: rowsTemplate
    };
  }

  onChange(event) {
    let isMeaningfulChange = true;
    if (this.props.maxInput) {
      isMeaningfulChange = this.checkInputNumber(event);
    }
    if (isMeaningfulChange) {
      this.prefillAnswer(event);
    }
  }

  toCoordinate(index, list) {
    if (!list) {
      return numberToLetter(index);
    }

    return list[index];
  }

  prefillAnswer(event) {
    const coords = event.target.name.split('-');
    const rowIndex = parseInt(coords[0]);
    const rowCoordinate = this.toCoordinate(rowIndex, this.props.rowNames);
    const columnIndex = parseInt(coords[1]);
    const columnCoordinate = this.toCoordinate(
      columnIndex,
      this.props.columnNames
    );
    let newInput = rowCoordinate + columnCoordinate;
    let newAnswer = this.state.answer;

    if (event.target.checked) {
      newAnswer.push(newInput);
    } else {
      let index = newAnswer.indexOf(newInput);
      if (index > -1) {
        newAnswer.splice(index, 1);
      }
    }
    newAnswer = newAnswer.sort();
    this.setState(() => {
      return {
        answer: newAnswer
      };
    });
    this.props.prefillAnswer(newAnswer.join(''));
  }

  checkInputNumber(event) {
    if (!event.target.checked) {
      this.setState(() => {
        return {
          inputNumber: this.state.inputNumber - 1
        };
      });
      return true;
    }
    if (this.state.inputNumber > this.props.maxInput - 1) {
      event.target.checked = false;
      return false;
    }
    this.setState(() => {
      return {
        inputNumber: this.state.inputNumber + 1
      };
    });
    return true;
  }

  renderCell(lineIndex, cellIndex) {
    let isActiveCell = true;
    if (
      this.props.inactiveLines &&
      this.props.inactiveLines.includes(lineIndex)
    ) {
      isActiveCell = false;
    }
    return (
      <div
        className="c-image-grid__cell"
        key={`cell-${lineIndex}-${cellIndex}`}
      >
        <input
          className="c-image-grid__input"
          type="checkbox"
          name={`${lineIndex}-${cellIndex}`}
          onChange={this.onChange.bind(this)}
          disabled={!isActiveCell}
        />
        <div
          className="c-image-grid__check"
          style={this.props.color && { backgroundColor: this.props.color }}
        />
      </div>
    );
  }

  renderLine(index) {
    return renderFor(this.props.columnsCount, cellIndex =>
      this.renderCell(index, cellIndex)
    );
  }

  render() {
    const style = this.getGridStyles();
    return (
      <div className="c-image-grid">
        <img
          src={this.props.image}
          alt={this.props.alt}
          className="c-image-grid__image"
        />
        <div className="c-image-grid__grid" style={style}>
          {renderFor(this.props.linesCount, index => this.renderLine(index))}
        </div>
      </div>
    );
  }
}

export default withPreFillAnswer(ImageGrid);
