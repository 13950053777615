import React from 'react';

class DurationPictogram extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width={this.props.size || 20}
        height={this.props.size || 20}
        className={this.props.className}
      >
        <g fill="none" stroke={this.props.color || 'currentColor'}>
          <circle cx="9" cy="10" r="7.5" strokeWidth="3" />
          <path d="M9,6 v4 L12,13" strokeWidth="2" />
        </g>
      </svg>
    );
  }
}

export default DurationPictogram;
