import React, { Fragment } from 'react';
import withPageView from '../modules/ui/hoc/withPageView.hoc';
import ThemeComponent from './theme/ThemeComponent.component';

class FooterMenu2 extends React.PureComponent {
  componentDidMount() {
    this.props.pageView(this.props.themeData.analytics.FooterMenu2);
  }

  render() {
    return (
      <Fragment>
        <ThemeComponent name="FooterMenu2" location={this.props.location} />
      </Fragment>
    );
  }
}

export default withPageView(FooterMenu2);
