import React from 'react';
import withPreFillAnswer from './withPrefillAnswer.hoc';
import { renderForEach } from '../../../../utils/render';

import './ListSelection.scss';

class ListSelection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      maxReached: false
    };
  }

  onInput() {
    const form = document.forms['list-selection-' + this.props.id];
    const checkboxes = Array.from(form.querySelectorAll('input'));
    const count = checkboxes.filter(item => item.checked).length;

    if (!this.props.max || count === this.props.max) {
      checkboxes
        .filter(item => !item.checked)
        .forEach(item => (item.disabled = true));
      this.prefillAnswer();
      this.setState(() => ({
        maxReached: true
      }));
    } else {
      checkboxes.forEach(item => (item.disabled = false));
      this.props.prefillAnswer('');
      this.setState(() => ({
        maxReached: false
      }));
    }
  }

  prefillAnswer() {
    const form = document.forms['list-selection-' + this.props.id];
    const checked = Array.from(form.querySelectorAll(':checked'));
    const answer = checked.map(item => item.value).join(' - ');
    this.props.prefillAnswer(answer);
  }

  render() {
    return (
      <form
        className="c-list-selection"
        name={'list-selection-' + this.props.id}
      >
        <ul className="c-list-selection__list">
          {renderForEach(this.props.content, item => (
            <li className="c-list-selection__list-item" key={item.value}>
              <label className="c-list-selection__control">
                <input
                  type="checkbox"
                  value={item.value}
                  className="c-list-selection__checkbox"
                  name={'list-selection-' + this.props.id}
                  onInput={this.onInput.bind(this)}
                />
                <span className="c-list-selection__label">{item.label}</span>
              </label>
            </li>
          ))}
        </ul>
        {this.state.maxReached && (
          <span>{this.props.maxLabel || 'Maximum atteint'}</span>
        )}
      </form>
    );
  }
}

export default withPreFillAnswer(ListSelection);
