import React from 'react';
import classNames from 'classnames';
import ThemeComponent from '../theme/ThemeComponent.component';
import CrossIcon from '../icons/CrossIcon.component';
import Timer from './Timer.component';

import universes from '~data/universes';

import colors from '~styles/variables/colors.scss';
import './StoryProgressBox.scss';

class StoryProgressBox extends React.PureComponent {
  stopStory() {
    this.props.stopStory(this.props.story.id);
  }

  render() {
    const story = this.props.story;
    const { universeId } = story;
    const classes = classNames('c-story-progress-box', {
      'c-story-progress-box--completed': this.props.storyEndDate
    });

    return (
      <div className={classes}>
        <div className="c-story-progress-box__logo">
          <img src={universes[universeId].logo} alt="" />
        </div>
        <div className="c-story-progress-box__text">
          <div>
            <h1 className="c-story-progress-box__title">
              {universes[universeId].title}
            </h1>
            <div className="c-story-progress-box__subtitle">
              {story.shortTitle || story.title}
              <span className="c-story-progress-box__answered">
                {this.props.completedSpotsCount}
              </span>
              /{this.props.totalSpotsCount}
            </div>
          </div>
          <div className="c-story-progress-box__info">
            <ThemeComponent
              name="StoryProgress"
              proxyParams={{ universeId }}
              storyId={this.props.story.id}
            />
            {this.props.storyStartDate && (
              <Timer
                start={this.props.storyStartDate}
                end={this.props.storyEndDate}
                withTag={!this.props.storyEndDate}
              />
            )}
          </div>
        </div>
        {!this.props.isSingleStory && (
          <button
            type="button"
            className="c-story-progress-box__cross"
            title="Fermer la fenêtre"
            onClick={this.stopStory.bind(this)}
          >
            <CrossIcon color={colors.main} size="16" />
          </button>
        )}
      </div>
    );
  }
}

export default StoryProgressBox;
