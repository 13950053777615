import React from 'react';
import classNames from 'classnames';

// Components
import SpotRiddle from './SpotRiddle.container';
import SpotIntroModal from '../layouts/Modal/SpotIntroModal.container';
import ReviewModal from '../layouts/Modal/ReviewModal.container';
import GameApiReady from './GameApiReady.hoc';
import Page from '../Page.component';

// Functions
import { isDefined } from '../../../../utils/basis.js';
import {
  arrayToCoordinates,
  getDistanceTo
} from '../../modules/geolocation/Geolocation.functions';
import { history } from '../../modules/createStore';
import {
  hasFullAccess,
  hasActivePass
} from '../../modules/auth/Auth.functions';
import { isSpotPlayable } from '../../modules/story/Story.functions';

// Constants
import conf from '~data/global.js';
import stories from '~data/stories.js';
import universes from '~data/universes.js';
import { SPOT_INTRO_MODAL } from '../layouts/Modal/Modal.constants';

import './Spot.scss';

class Spot extends React.PureComponent {
  componentDidMount() {
    // Before starting the game check user rights
    if (!this.checkAccess()) {
      return this.goOut();
    }

    // Starts the game
    this.props.findGame(this.props.spot.id);
    const showStoryModal = this.showIntroModal();
    if (showStoryModal || this.props.spot.popup) {
      this.props.openModal(SPOT_INTRO_MODAL, {
        showStoryModal
      });
    }

    // Empty previous prefill answer
    this.props.prefillAnswer(null);
  }

  checkAccess() {
    const { spot, user } = this.props;
    if (!isSpotPlayable(spot, user)) {
      return false;
    }
    if (this.props.isGameStarted) {
      return true;
    }
    if (!hasFullAccess(user) && !spot.isFree) {
      return false;
    }

    return this.checkDistance();
  }

  checkDistance() {
    if (!this.props.isGeolocationApp) {
      return true;
    }
    const distance = getDistanceTo(
      arrayToCoordinates(this.props.spot.coordinates),
      this.props.playerPosition
    );
    const maxDistance =
      this.props.reachDistance + (this.props.spot.overReachDistance || 0);

    return this.props.user.isAdmin || distance <= maxDistance;
  }

  goOut() {
    let error = 'Rapprochez-vous du lieu pour y accéder';
    if (this.props.spot.status === 'teaser') {
      error =
        "Cette énigme n'est pas encore disponible. Encore un peu de patience ;)";
    }
    if (!hasActivePass(this.props.user)) {
      if (this.props.user.pendingProduct) {
        error = 'Activez votre accès pour pouvoir jouer.';
      } else {
        error = "Choisissez un temps d'accès pour pouvoir jouer.";
      }
    }
    history.push('/', { error });
  }

  isInStoryMode() {
    return isDefined(this.props.story);
  }

  showIntroModal() {
    // If spot already started, no modal
    if (this.props.isGameStarted) {
      return false;
    }

    // Story mode
    if (this.isInStoryMode()) {
      return this.props.spot.item && this.props.spot.item.description;
    }

    // Individual spot modal
    return (
      conf.spotIntroModal && !this.isInStoryMode() && this.props.spot.storyId
    );
  }

  renderContent() {
    if (!this.props.isGameInProgress) {
      return null;
    }

    return (
      <SpotRiddle
        spot={this.props.spot}
        clues={this.props.clues}
        nextClueIndex={this.props.nextClueIndex}
        malus={this.props.malus}
        isGameAnswered={this.props.isGameAnswered}
        isGameBlocked={this.props.isGameBlocked}
        user={this.props.user}
        gameTime={this.props.gameTime}
        maxDate={this.props.maxDate}
      />
    );
  }

  render() {
    if (!this.props.spot) {
      return 'Error';
    }

    const { spot, isGameAnswered, isGameBlocked } = this.props;
    const story = stories[spot.storyId];
    const universe = universes[story.universeId];
    const classes = classNames({
      'o-stamp': isGameAnswered || isGameBlocked,
      'o-stamp--blocked': !isGameAnswered && isGameBlocked
    });

    return (
      <Page className={classes}>
        <div className={`c-spot c-spot--${universe.slug}`}>
          <div>{this.renderContent()}</div>
          <SpotIntroModal spot={spot} storyMode={this.isInStoryMode()} />
          <ReviewModal />
        </div>
      </Page>
    );
  }
}

export default GameApiReady(Spot);
