import React from 'react';
import { renderForEach } from '../../../../utils/render';
import RichContent from '../spots/RichContent.container';

import './Chat.scss';

class Chat extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.state = {
      currentStepId: props.start
    };
  }

  onClick(id) {
    this.container = document.querySelector('.c-page__scroll');
    if (this.container) {
      this.container.scrollTo(0, 0);
    }
    this.setState(() => ({
      currentStepId: id
    }));
  }

  render() {
    const step = this.props.content[this.state.currentStepId];

    return (
      <div className="c-chat">
        {step && step.content && <RichContent content={step.content} />}

        <ul className="c-chat__action-list">
          {step &&
            step.actions &&
            renderForEach(step.actions, (label, id) => (
              <li className="c-chat__action" key={id}>
                <button
                  className="o-button o-button--block"
                  type="button"
                  onClick={this.onClick.bind(this, id)}
                >
                  {label}
                </button>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export default Chat;
