import React from 'react';
import StoryFooterIcon from '../icons/StoryFooterIcon.component';

import './Rule.scss';

class StoryRule extends React.PureComponent {
  render() {
    return (
      <div className="c-rule" id="parcours">
        <div className="c-rule__card">
          <h2 className="c-rule__title">Suivre une aventure</h2>
          <div className="c-rule__content">
            <div className="c-rule__icon">
              <StoryFooterIcon size="60" />
            </div>
            <div className="c-rule__text">
              Vous avez toujours rêvé de sauver le monde&nbsp;? Nous
              aussi&nbsp;! Sur Zupple vous devrez désamorcer des bombes, voler
              les membres de la mafia, combattre les nazis, etc. Chaque aventure
              est découpée en histoires d'environ 2h30 de jeu.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoryRule;
