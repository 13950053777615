export const MOVE_MAP = 'map/MOVE_MAP';

export const RECENTER_MAP = 'map/RECENTER_MAP';

export const ACTIVE_SPOTS_VIEW = 'map/ACTIVE_SPOTS_VIEW';

export const ACTIVE_GLOBAL_VIEW = 'map/ACTIVE_GLOBAL_VIEW';

export const SET_HIGHLIGHTED_SPOT = 'map/SET_HIGHLIGHTED_SPOT';

export const MANUAL_VIEW_MODE = 1;

export const GLOBAL_VIEW_MODE = 2;

export const SPOTS_VIEW_MODE = 3;

export const HIGHLIGHT_SPOT_VIEW_MODE = 4;
