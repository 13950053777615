import React from 'react';
import { displayDuration } from '../../utils/date';

class CountDown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      render: 0
    };
    this.timer = null;
  }

  componentDidMount() {
    this.tick();
  }

  componentDidUpdate() {
    this.tick();
  }

  tick() {
    if (this.timer !== null) {
      return null;
    }

    this.timer = setTimeout(() => {
      this.timer = null;
      this.setState(state => ({
        render: state.render + 1
      }));
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  render() {
    const duration = this.props.target - new Date();

    return <span>{displayDuration(duration)}</span>;
  }
}

export default CountDown;
