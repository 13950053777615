import React from 'react';
import PropTypes from 'prop-types';
import {
  debugElements,
  findElementAt,
  getClickCoordinates
} from './mechanisms.utils';

import './ToggleInImage.scss';
import { getInputDefaultValue, updateValue } from './localStorage.utils';

class ToggleInImage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.state = {
      loaded: false,
      toggleStates: props.toggle.reduce((acc, toggle) => {
        const isActive =
          this.props.mechanismId &&
          getInputDefaultValue(this.props.mechanismId, toggle.id) === '1';
        acc[toggle.id] = isActive ? 1 : 0;

        return acc;
      }, {})
    };
    this.image = new Image(props.width, props.height);
    this.image.onload = this.loadHandler.bind(this);
    this.image.src = props.content;
    this.clickHandler = this.clickHandler.bind(this);
  }

  loadHandler() {
    this.setState(() => ({
      loaded: true
    }));
  }

  componentDidMount() {
    this.root.current.addEventListener('click', this.clickHandler);
  }

  componentWillUnmount() {
    this.root.current.removeEventListener('click', this.clickHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    const canvas = this.root.current;
    canvas.width = this.image.naturalWidth;
    canvas.height = this.image.naturalHeight;
    const ctx = this.root.current.getContext('2d');
    ctx.drawImage(this.image, 0, 0, canvas.width, canvas.height);

    this.props.toggle.forEach(toggle => {
      if (this.state.toggleStates[toggle.id]) {
        ctx.fillStyle = toggle.color;
        ctx.fillRect(toggle.x, toggle.y, toggle.width, toggle.height);
      }
    });

    if (this.props.debug) {
      debugElements(canvas, this.props.toggle);
    }
  }

  clickHandler(event) {
    const { x, y } = getClickCoordinates(
      event,
      this.root.current,
      this.image.width
    );
    const toggle = findElementAt(this.props.toggle, x, y);
    if (!toggle) {
      return null;
    }
    this.setState(state => {
      const toggleStates = Object.assign({}, state.toggleStates);
      toggleStates[toggle.id] = toggleStates[toggle.id] ? 0 : 1;
      if (this.props.mechanismId) {
        updateValue(this.props.mechanismId, toggle.id, toggleStates[toggle.id]);
      }

      return {
        toggleStates
      };
    });
  }

  render() {
    return (
      <div className="c-toggle-in-image">
        <canvas className="c-toggle-in-image__canvas" ref={this.root} />
      </div>
    );
  }
}

ToggleInImage.propTypes = {
  props: PropTypes.shape({
    mechanismId: PropTypes.string,
    content: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    debug: PropTypes.bool,
    toggle: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        color: PropTypes.string
      })
    )
  })
};

export default ToggleInImage;
