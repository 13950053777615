import React from 'react';
import { cleanString } from '../../../../utils/basis';
import { renderForEach } from '../../../../utils/render';

import './UnpixelateImage.scss';

class UnpixelateImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      previousAnswers: this.getPreviousAnswers(props)
    };
    this.root = React.createRef();
    this.answers = props.answers.map(answer => {
      if (typeof answer === 'string') {
        return [cleanString(answer)];
      }

      return answer.map(item => cleanString(item));
    });
    this.image = new Image(props.width, props.height);
    this.image.onload = this.loadHandler.bind(this);
    this.image.src = props.content;
    this.submitHandler = this.submitHandler.bind(this);
  }

  getPreviousAnswers(props) {
    try {
      const previousAnswers = JSON.parse(
        localStorage.getItem('mechanism-unpixelate-' + props.id)
      );
      if (Array.isArray(previousAnswers)) {
        return previousAnswers;
      }
    } catch (error) {}

    return [];
  }

  loadHandler() {
    this.setState(() => ({
      loaded: true
    }));
  }

  componentDidUpdate() {
    if (this.state.loaded) {
      const remaining =
        this.props.answers.length - this.state.previousAnswers.length;
      const ratio = (remaining + this.props.pixelGap) * this.props.pixelStep;
      this.pixelateImage(this.root.current, this.image, ratio > 0 ? ratio : 1);
    }
  }

  drawSmallImage(image, ratio) {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth / ratio;
    canvas.height = image.naturalHeight / ratio;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvas;
  }

  pixelateImage(canvas, image, ratio) {
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    const smallCanvas = this.drawSmallImage(image, ratio);
    ctx.drawImage(
      smallCanvas,
      0,
      0,
      smallCanvas.width,
      smallCanvas.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
  }

  checkAnswer(answer) {
    const cleanAnswer = cleanString(answer);
    const correctAnswer = this.answers.findIndex(item =>
      item.includes(cleanAnswer)
    );
    if (correctAnswer === -1) {
      return false;
    }

    return correctAnswer;
  }

  getCheerMessage(answer) {
    if (!this.props.cheers) {
      return null;
    }

    const cleanedAnswer = cleanString(answer);
    const cheerMessage = Object.keys(this.props.cheers).find(regex => {
      return new RegExp(regex, 'i').test(cleanedAnswer);
    });
    if (!cheerMessage) {
      return null;
    }

    return this.props.cheers[cheerMessage];
  }

  submitHandler(event) {
    event.preventDefault();
    const input = document.forms.unpixelate.guess;
    const cheer = this.getCheerMessage(input.value);
    if (cheer) {
      this.setState(() => ({
        messageError: cheer,
        messageValidation: null
      }));

      return false;
    }

    const answer = this.checkAnswer(input.value);
    input.value = '';

    if (answer === false) {
      this.setState(() => ({
        messageError: 'Mauvaise réponse.',
        messageValidation: null
      }));

      return false;
    }

    const { previousAnswers } = this.state;
    if (previousAnswers.includes(answer)) {
      this.setState(() => ({
        messageError: 'Réponse déjà validée.',
        messageValidation: null
      }));

      return false;
    }

    previousAnswers.push(answer);
    localStorage.setItem(
      'mechanism-unpixelate-' + this.props.id,
      JSON.stringify(previousAnswers)
    );
    this.setState(() => ({
      previousAnswers: [...previousAnswers],
      messageError: null,
      messageValidation: 'Bonne réponse !'
    }));
  }

  render() {
    return (
      <form
        name="unpixelate"
        className="c-unpixelate-image"
        onSubmit={this.submitHandler}
      >
        <label className="c-unpixelate-image__fieldset">
          <span className="c-unpixelate-image__label">{this.props.label}</span>
          <span className="c-unpixelate-image__input-group">
            <input
              name="guess"
              type="text"
              className="c-unpixelate-image__input"
            />
            <span className="c-unpixelate-image__button">
              <button className="o-button o-button--tiny">OK</button>
            </span>
          </span>
          {this.state.messageError && (
            <span className="c-unpixelate-image__message c-unpixelate-image__message--error">
              {this.state.messageError}
            </span>
          )}
          {this.state.messageValidation && (
            <span className="c-unpixelate-image__message c-unpixelate-image__message--validation">
              {this.state.messageValidation}
            </span>
          )}
        </label>
        <div
          className={`c-unpixelate-image__image ${
            this.props.large ? 'c-unpixelate-image__image--large' : ''
          }`}
        >
          {this.props.counter && (
            <span
              className={`c-unpixelate-image__counter ${
                this.props.counterBottom
                  ? 'c-unpixelate-image__counter--bottom'
                  : ''
              }`}
            >
              {this.state.previousAnswers.length} / {this.props.answers.length}
            </span>
          )}
          <canvas className="c-unpixelate-image__canvas" ref={this.root} />
        </div>
        {this.state.previousAnswers.length > 0 && (
          <ul className="c-unpixelate-image__validated">
            {renderForEach(this.state.previousAnswers, (answerIndex, index) => {
              const answers = this.props.answers[answerIndex];
              const label = Array.isArray(answers) ? answers[0] : answers;

              return (
                <li
                  className="c-unpixelate-image__answer-item"
                  key={`answer-${index}`}
                >
                  ✓ {label}
                </li>
              );
            })}
          </ul>
        )}
      </form>
    );
  }
}

export default UnpixelateImage;
