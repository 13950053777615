import React from 'react';

import './FooterRiddleNav.scss';
import AnswerModal from '../Modal/AnswerModal.container';
import ClueModal from '../Modal/ClueModal.container';
import InfoModal from '../Modal/InfoModal.component';
import ModalButton from '../Modal/ModalButton.container';
import { ANSWER_MODAL, CLUE_MODAL, INFO_MODAL } from '../Modal/Modal.constants';
import InfoIcon from '../../icons/InfoIcon.component';
import ClueIcon from '../../icons/ClueIcon.component';
import ThemeComponent from '../../theme/ThemeComponent.component';
import ThemeLabel from '../../theme/ThemeLabel.component';

import colors from '~styles/variables/colors.scss';
import KidsIcon from '../../icons/KidsIcon.component';
import { KIDS_INFO_TYPE } from '../../../constants/spot.constants';

class FooterRiddleNav extends React.PureComponent {
  render() {
    const { isGameAnswered, isGameBlocked, tryCount, spot } = this.props;
    const isKidsInfo = spot.infoType === KIDS_INFO_TYPE;

    return (
      <div className="c-footer-riddle-nav">
        <ul className="c-footer-riddle-nav__list">
          {!isGameAnswered && !isGameBlocked && (
            <li
              className={
                (!this.props.nextClue &&
                  'c-footer-riddle-nav__item-light c-footer-riddle-nav__item') ||
                'c-footer-riddle-nav__item'
              }
            >
              <ModalButton
                className="c-footer-riddle-nav__link"
                activeClassName="c-footer-riddle-nav__link--active"
                modalId={CLUE_MODAL}
              >
                <ClueIcon
                  className="c-footer-riddle-nav__item-icon"
                  context="footer"
                />
                {this.props.nextClue && this.props.nextClue.isAnswer
                  ? 'Réponse'
                  : 'Indice'}
              </ModalButton>
            </li>
          )}
          {(spot.place || spot.info) && (
            <li className="c-footer-riddle-nav__item">
              <ModalButton
                className="c-footer-riddle-nav__link"
                activeClassName="c-footer-riddle-nav__link--active"
                modalId={INFO_MODAL}
              >
                {isKidsInfo && (
                  <KidsIcon
                    className="c-footer-riddle-nav__item-icon"
                    context="footer"
                  />
                )}
                {!isKidsInfo && (
                  <InfoIcon
                    className="c-footer-riddle-nav__item-icon"
                    context="footer"
                  />
                )}
                {(spot.info && spot.info.name) ||
                  spot.infoName ||
                  spot.placeName}
              </ModalButton>
            </li>
          )}
          {!(spot.place || spot.info) && <li />}
        </ul>
        {!isGameAnswered && !isGameBlocked && (
          <div className="c-footer-riddle-nav__answer">
            <ModalButton
              className="c-footer-riddle-nav__answer-button"
              modalId={ANSWER_MODAL}
            >
              <ThemeComponent
                name="FormIcon"
                className="c-footer-riddle-nav__answer-background"
                color={colors.mainDark}
              />
              <ThemeComponent
                name="AnswerIcon"
                className="c-footer-riddle-nav__answer-icon"
                color={colors.highlight2}
              />
              <span className="c-footer-riddle-nav__answer-legend">
                <ThemeLabel name="answer" default="Répondre" />
                {spot.maxTry && (
                  <div>
                    {tryCount + 1} / {spot.maxTry}
                  </div>
                )}
              </span>
            </ModalButton>
          </div>
        )}
        <ClueModal />
        <InfoModal spotId={spot.id} />
        <AnswerModal />
      </div>
    );
  }
}

export default FooterRiddleNav;
