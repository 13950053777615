import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { to } from '../../urls';

// Components
import XpFlag from '../xp/XpFlag.component';
import RichContent from './RichContent.container';
import ClueIcon from '../icons/ClueIcon.component';
import HistoryIcon from '../icons/HistoryIcon.component';
import InfoIcon from '../icons/InfoIcon.component';
import TakeAwayPictogram from '../pictos/TakeAwayPictogram.component';
import AwardModal from '../layouts/Modal/AwardModal.container';
import SignalPictogram from '../pictos/SignalPictogram.component';
import ThemeFeature from '../theme/ThemeFeature.component';
import ThemeLabel from '../theme/ThemeLabel.component';
import WarningIcon from '../icons/WarningIcon.component';
import CountDown from './CountDown.component';

// Functions
import { renderFor, renderForEach } from '../../../../utils/render';
import { diffPropValue, typography } from '../../../../utils/basis';
import { getCheerMessage } from '../../../../utils/answer';
import { displayDuration } from '../../utils/date';
import withThemeFeatures from '../theme/withThemeFeatures.hoc';

class SpotRiddle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      problemSent: false,
      showContactInvite: false
    };
  }

  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'nextClueIndex')) {
      const element = document.querySelector('.c-page__scroll');
      element.scrollTo(0, element.scrollHeight);
    }
    if (diffPropValue(this.props, prevProps, 'isGameAnswered')) {
      const element = document.querySelector('.c-page__scroll');
      element.scrollTo(0, 0);
    }
  }

  getIsDebug() {
    return (
      this.props.user.isAdmin &&
      (this.props.isGameAnswered || this.props.isGameBlocked)
    );
  }

  contact() {
    this.setState(() => ({
      problemSent: false,
      showContactInvite: !this.state.showContactInvite
    }));
  }

  send() {
    this.setState(() => ({
      showContactInvite: false,
      problemSent: true
    }));
    let message = document.forms.contact.message.value;
    this.props.sendMail(
      message,
      this.props.user.email,
      this.props.user._id,
      this.props.spot.id,
      this.props.spot.title
    );
  }

  cancel() {
    this.setState(() => ({
      problemSent: false,
      showContactInvite: false
    }));
  }

  renderClues() {
    let count = Math.min(this.props.nextClueIndex, this.props.clues.length);
    const isDebug = this.getIsDebug();
    if (isDebug) {
      count = this.props.clues.length;
    }
    if (count === 0) {
      return null;
    }

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <ClueIcon context="riddle" />
          </div>
          Indices
        </h3>
        <ul className="c-spot__clues-list">
          {renderFor(count, index => (
            <li className="c-spot__clues-item" key={`clue-${index}`}>
              <div className="c-spot__clues-separator" />
              <div className="c-spot__clues-text">
                <RichContent content={this.props.clues[index].content} />
              </div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  debugCheers() {
    const isDebug = this.getIsDebug();
    const cheers = this.props.spot.dialog && this.props.spot.dialog.cheers;
    if (!isDebug) {
      return '';
    }

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <InfoIcon context="riddle" />
          </div>
          Les encouragements
        </h3>
        <ul className="c-spot__answer-list">
          {renderForEach(
            cheers,
            (cheerMessage, pattern) => (
              <li className="c-spot__answer-item" key={`cheer-${pattern}`}>
                <div className="c-spot__answer-separator" />
                <div className="c-spot__answer-text">
                  {pattern}
                  <div className="c-spot__answer-cheer">{cheerMessage}</div>
                </div>
              </li>
            ),
            () => (
              <li className="c-spot__answer-item" key={`no-cheer`}>
                <div className="c-spot__answer-cheer">Pas d’encouragement</div>
              </li>
            )
          )}
        </ul>
      </Fragment>
    );
  }

  debugDifficulty() {
    const isDebug = this.getIsDebug();
    const riddle = this.props.spot.riddle;
    if (!isDebug) {
      return '';
    }

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <InfoIcon context="riddle" />
          </div>
          Difficulté:
        </h3>
        <p>Niveau : {riddle.level || this.props.spot.xp + 'xp'}</p>
        {riddle.levelDetails && (
          <ul className="c-spot__answer-list">
            <li className="c-spot__answer-item">
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Complexité : {riddle.levelDetails[0]}
              </div>
            </li>
            <li className="c-spot__answer-item">
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Originalité : {riddle.levelDetails[1]}
              </div>
            </li>
            <li className="c-spot__answer-item">
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Longueur : {riddle.levelDetails[2]}
              </div>
            </li>
          </ul>
        )}
      </Fragment>
    );
  }

  debugAcceptedAnswers() {
    const isDebug = this.getIsDebug();
    if (!isDebug) {
      return '';
    }

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <InfoIcon context="riddle" />
          </div>
          Réponses acceptées
        </h3>
        <ul className="c-spot__answer-list">
          {renderForEach(this.props.spot.answer, (answer, index) => (
            <li className="c-spot__answer-item" key={`answer-${index}`}>
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">{answer}</div>
            </li>
          ))}
        </ul>
      </Fragment>
    );
  }

  debugFeedbackLink() {
    if (
      !this.props.themeFeatures.feedbackLink ||
      (!this.props.isGameAnswered && !this.props.isGameBlocked)
    ) {
      return '';
    }

    return (
      <a
        href="https://framaforms.org/beta-retour-sur-les-enigmes-du-raid-2022-1652297611"
        target="_blank"
        rel="noopener noreferrer"
        className="o-button"
      >
        Évaluer cette énigme
      </a>
    );
  }

  debugFail() {
    const isDebug = this.getIsDebug();
    if (!isDebug) {
      return '';
    }

    const dialog = this.props.spot.dialog;
    if (!dialog) {
      return null;
    }

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <InfoIcon context="riddle" />
          </div>
          Les messages
        </h3>
        <ul className="c-spot__answer-list">
          {renderForEach(dialog.fail, (message, index) => (
            <li className="c-spot__answer-item" key={`fail-${index + 1}`}>
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Erreur n°{index + 1}
                <div className="c-spot__answer-cheer">{message}</div>
              </div>
            </li>
          ))}
          {dialog.blocked && (
            <li className="c-spot__answer-item" key={`fail-${3}`}>
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Erreur n°3
                <div className="c-spot__answer-cheer">{dialog.blocked}</div>
              </div>
            </li>
          )}
          {dialog.validation && (
            <li className="c-spot__answer-item" key={`success`}>
              <div className="c-spot__answer-separator" />
              <div className="c-spot__answer-text">
                Réussite
                <div className="c-spot__answer-cheer">{dialog.validation}</div>
              </div>
            </li>
          )}
        </ul>
      </Fragment>
    );
  }

  renderPreviousAnswers() {
    const { isGameAnswered, isGameBlocked } = this.props;
    if (isGameAnswered || isGameBlocked) {
      return '';
    }

    if (this.props.tries.length === 0) {
      return null;
    }
    const tries = this.props.tries.slice().reverse();

    return (
      <Fragment>
        <h3 className="c-spot__section-title">
          <div className="c-spot__section-title-icon">
            <HistoryIcon context="riddle" />
          </div>
          Vos essais
        </h3>
        <ul className="c-spot__answer-list">
          {renderForEach(tries, (answer, index) => {
            const cheerMessage = getCheerMessage(this.props.spot, answer);

            return (
              <li className="c-spot__answer-item" key={`answer-${index}`}>
                <div className="c-spot__answer-separator" />
                <div className="c-spot__answer-text">
                  {answer}
                  {cheerMessage && (
                    <div className="c-spot__answer-cheer">{cheerMessage}</div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </Fragment>
    );
  }

  renderQuestion() {
    const question = this.props.spot.riddle.question;
    if (!question) {
      return '';
    }

    return <div className="c-spot__question">{question}</div>;
  }

  renderHint() {
    const hint = this.props.spot.hint;
    if (!hint || this.props.isGameAnswered) {
      return '';
    }
    const isPlural = Array.isArray(hint) && hint.length > 1;

    return (
      <div className="c-spot__hint">
        <div className="c-spot__hint-title">
          <span role="img" aria-label="">
            ℹ️
          </span>
          ️ Précision{isPlural ? 's' : ''}{' '}
        </div>
        <div className="c-spot__hint-content">
          <RichContent content={hint} />
        </div>
      </div>
    );
  }

  getWarningMessage(spot, isGameStarted, isGameAnswered, isGameBlocked) {
    if (
      isGameAnswered ||
      isGameBlocked ||
      !spot.warning ||
      !spot.warning.during
    ) {
      return '';
    }

    return spot.warning.during;
  }

  renderWarning() {
    const { spot, isGameStarted, isGameAnswered, isGameBlocked } = this.props;

    const warning = this.getWarningMessage(
      spot,
      isGameStarted,
      isGameAnswered,
      isGameBlocked
    );
    if (!warning) {
      return '';
    }

    return (
      <div className="c-spot__warning u-txt-center">
        <WarningIcon className="c-spot__warning-icon" />
        {warning}
      </div>
    );
  }

  renderCongrats() {
    if (!this.props.isGameAnswered) {
      return '';
    }

    return (
      <div className="c-spot__hint">
        <div className="c-spot__hint-title">Bravo&nbsp;!</div>
        <div className="c-spot__hint-content">
          Vous avez réussi{' '}
          <ThemeLabel name="spot.this" default="cette énigme" /> en{' '}
          {displayDuration(this.props.gameTime)}.
          <div>
            {typography(
              (this.props.spot.award && this.props.spot.award.desc) ||
                (this.props.spot.dialog && this.props.spot.dialog.validation)
            )}
          </div>
        </div>
      </div>
    );
  }

  getFailMessage() {
    const dialog = this.props.spot.dialog;
    if (!dialog) {
      return null;
    }

    if (dialog.blocked) {
      return dialog.blocked;
    }

    if (!dialog.fail) {
      return null;
    }

    if (Array.isArray(dialog.fail)) {
      return dialog.fail[dialog.fail.length - 1];
    }

    return dialog.fail;
  }

  renderFailStatus() {
    const { isGameAnswered, isGameBlocked } = this.props;
    if (isGameAnswered || !isGameBlocked) {
      return '';
    }
    const failMessage = this.getFailMessage();

    return (
      <div className="c-spot__hint">
        <div className="c-spot__hint-title">Dommage&nbsp;!</div>
        <div className="c-spot__hint-content">
          Vous avez loupé <ThemeLabel name="spot.this" default="cette énigme" />
          .{failMessage && <div>{failMessage}</div>}
        </div>
      </div>
    );
  }

  renderCountDown() {
    const { isGameAnswered, isGameBlocked, maxDate } = this.props;
    if (!maxDate || isGameAnswered || isGameBlocked) {
      return null;
    }

    return (
      <div className="c-spot__hint">
        <div className="c-spot__hint-title">Le chrono tourne&nbsp;!</div>
        <div className="c-spot__hint-content">
          Il ne vous reste plus que <CountDown target={maxDate} /> pour résoudre
          <ThemeLabel name="spot.this" default="cette énigme" />.
        </div>
      </div>
    );
  }

  renderContact() {
    if (this.state.problemSent) {
      return (
        <div className="c-spot__hint">
          <div className="c-spot__hint-title">Merci ! </div>
          <div className="c-spot__hint-content">
            Vous nous avez envoyé un message concernant{' '}
            <ThemeLabel name="spot.this" default="cette énigme" />. Nous allons
            prendre en compte ces informations rapidement.
          </div>
        </div>
      );
    } else if (this.state.showContactInvite) {
      return (
        <div>
          <div>
            <div className="c-spot__problem-title">
              Quelque chose ne va pas&nbsp;?
            </div>
            <p className="c-spot__problem-text">
              La ville évolue sans cesse ! Il est possible que certaines{' '}
              <ThemeLabel name="spot.plural" default="énigmes" /> soient
              perturbées par des travaux. Vous pensez que c'est le cas pour
              <ThemeLabel name="spot.this" default="cette énigme" />
              &nbsp;?&nbsp; Dites-le-nous&nbsp;!
            </p>
          </div>

          <form name="contact">
            <textarea
              placeholder="Votre message"
              name="message"
              className="c-spot__problem-message"
            />
            <div className="c-spot__problem-buttons">
              <button
                type="button"
                className="c-spot__problem-cancel-button"
                onClick={this.cancel.bind(this)}
              >
                Annuler
              </button>
              <button
                type="button"
                className="o-button o-button--tiny o-button--alert c-spot__problem-send-button"
                onClick={this.send.bind(this)}
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      );
    } else {
      return '';
    }
  }

  render() {
    const spot = this.props.spot;

    return (
      <Fragment>
        <ThemeFeature
          name="xp"
          render={() => (
            <div className="c-spot__flag">
              <XpFlag
                isSpot
                xp={spot.xp}
                malus={this.props.malus}
                answered={this.props.isGameAnswered}
                blocked={this.props.isGameBlocked}
              />
            </div>
          )}
        />
        <h1 className="c-spot__title">
          {spot.displayId && (
            <span>
              {spot.displayId}
              {' - '}
            </span>
          )}
          {spot.title}
          {spot.riddle.isTakeAway && (
            <Link
              to={to('rules') + '#enigmes-a-emporter'}
              className="c-spot__title-icon"
            >
              <TakeAwayPictogram />
            </Link>
          )}

          <ThemeFeature
            name="problemAlerting"
            render={() => (
              <button
                onClick={this.contact.bind(this)}
                className="c-spot__problem-button c-spot__title-icon"
              >
                <SignalPictogram color="#FC388C" />
              </button>
            )}
          />
        </h1>
        {spot.riddle.subtitle && (
          <h2 className="c-spot__subtitle">{spot.riddle.subtitle}</h2>
        )}
        {this.renderContact()}

        {!this.state.showContactInvite && (
          <div className="c-spot__content">
            {this.renderCongrats()}
            {this.renderFailStatus()}
            {this.renderCountDown()}
            <RichContent content={spot.riddle.content} />
            {this.renderQuestion()}
            {this.renderHint()}
            {this.renderWarning()}
            {this.renderClues()}
            {this.renderPreviousAnswers()}
            {this.debugFail()}
            {this.debugCheers()}
            {this.debugDifficulty()}
            {this.debugAcceptedAnswers()}
            {this.debugFeedbackLink()}
          </div>
        )}

        <AwardModal userXp={this.props.user.xp} spot={spot} />
      </Fragment>
    );
  }
}

export default withThemeFeatures(SpotRiddle);
