import React from 'react';

class SpringIcon extends React.PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 294.7 280.2"
        width={this.props.size || 30}
        height={this.props.size || 30}
        className={this.props.className}
      >
        <path
          d="m170.1 175.7s-56.2 1.8-108.8-43.4-60.7-130.8-61.3-130.2 79.6-15.5 138.3 37.9 31.8 135.7 31.8 135.7zm109.1-56.9c16.8-33.5 15.4-76.3 15.4-76.3s-54.1-0.4-84.1 36.7c-38.8 47.8-22.8 104.6-22.8 104.6s67.2-16.6 91.5-65z"
          fill={this.props.color || 'currentColor'}
        />
        <path
          d="m241.6 91.4c-1.9-2-5.1-2-7.1-0.1-31.7 31.2-47.3 68.3-54.9 106.1-8.4-42.8-31.9-107.8-97.8-142-2.5-1.3-5.5-0.3-6.7 2.1-1.3 2.4-0.3 5.5 2.1 6.7 92 47.8 96.8 159.9 97 173.4-1 12.7-1.3 25.2-1.4 37.4 0 2.8 2.2 5 5 5 2.7 0 5-2.2 5-5 0.1-11.4 0.4-23 1.2-34.8 0.1-0.4 0.2-0.8 0.2-1.2v-1.1c3.8-48.9 16.7-99.7 57.3-139.6 2-1.8 2-4.9 0.1-6.9z"
          fill={this.props.color || 'currentColor'}
        />
      </svg>
    );
  }
}

export default SpringIcon;
