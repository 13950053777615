import { BLOCK_GAME, UPDATE_GAMES } from './Game.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';
import { objectMap } from '../../../../utils/basis';
import spots from '~data/spots';
import stories from '~data/stories';

const initialState = {
  all: {},
  bySpotId: {},
  byStory: {},
  storiesState: {},
  byState: {
    inProgress: [],
    answered: []
  },
  isReady: false
};

function getGamesByStatus(all) {
  const inProgress = Object.values(all)
    .filter(game => !game.isAnswered && !game.isBlocked)
    .map(game => game._id);
  const answered = Object.values(all)
    .filter(game => game.isAnswered || game.isBlocked)
    .map(game => game._id);

  return {
    inProgress,
    answered
  };
}

function getGamesByStory(all) {
  return objectMap(stories, story =>
    Object.values(all)
      .filter(
        game => spots[game.spotId] && spots[game.spotId].storyId === story.id
      )
      .map(game => game._id)
  );
}

function getStoriesState(all, byStory) {
  return objectMap(byStory, (storyGameIds, storyId) => {
    const story = stories[storyId];
    const games = Object.values(all).filter(game =>
      storyGameIds.includes(game._id)
    );
    const isComplete =
      games.length === story.spots.length + (story.finalSpot ? 1 : 0);

    return {
      isComplete,
      isAnswered: isComplete && games.every(game => game.isAnswered),
      isBlocked: games.some(game => game.isBlocked && !game.isAnswered)
    };
  });
}

const GameReducer = (state = initialState, action) => {
  let all;
  let byState;
  let byStory;
  let storiesState;

  switch (action.type) {
    case UPDATE_GAMES:
      all = {
        ...state.all,
        ...action.games
      };
      byState = getGamesByStatus(all);
      byStory = getGamesByStory(all);
      storiesState = getStoriesState(all, byStory);

      return {
        ...state,
        all,
        bySpotId: {
          ...state.bySpotId,
          ...action.bySpotId
        },
        byState,
        byStory,
        storiesState,
        isReady: true
      };

    case BLOCK_GAME:
      const game = state.all[action.gameId];
      if (game.isAnswered || game.isBlocked) {
        return state;
      }

      all = {
        ...state.all,
        [action.gameId]: {
          ...game,
          isBlocked: true,
          endDate: action.endDate || new Date()
        }
      };
      byState = getGamesByStatus(all);
      byStory = getGamesByStory(all);
      storiesState = getStoriesState(all, byStory);

      return {
        ...state,
        all,
        byState,
        byStory,
        storiesState
      };

    case LOGOUT_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default GameReducer;
