import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_STORY_CASH } from '../../../../../../share/constants/Badges.constants';

class BadgeStoryCash extends React.PureComponent {
  render() {
    return (
      <BadgeIcon color="#F5A623" label={BADGE_LABEL_STORY_CASH} {...this.props}>
        $
      </BadgeIcon>
    );
  }
}

export default BadgeStoryCash;
