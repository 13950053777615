import { connect } from 'react-redux';
import GameLayout from './GameLayout.component';
import { isFullPage } from '../../../modules/ui/Ui.selectors';
import { checkAppStatus } from '../../../modules/ui/Ui.actions';

function mapStateToProps(state) {
  return {
    isFullPage: isFullPage(state)
  };
}

const mapDispatchToProps = {
  checkAppStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameLayout);
