import React from 'react';

import {
  TEXT,
  HTML,
  IMAGE,
  IMAGE_WALL,
  IMAGE_PANORAMA,
  PAPER,
  CLICK_TO_SEE,
  CAROUSEL,
  LIST,
  LIST_SELECTION,
  AUDIO,
  VIDEO,
  ORDERED_LIST,
  DRAWING_IMAGE,
  HIDDEN_WORDS_GRID,
  SELECTABLE_GRID,
  CROSS_WORDS_GRID,
  FILE,
  IMAGE_GRID,
  FILTER_IMAGE,
  CONNECTED_LINKS,
  MUSICAL_IMAGE,
  MUSICAL_SVG,
  TEXTUAL_IMAGE,
  ZOOMABLE_IMAGE,
  UNPIXELATE_IMAGE,
  PASSWORD_PROTECTION,
  CUSTOM_MECHANISM,
  TABLE,
  ZOOM,
  CHAT,
  COLOR_NAME,
  TOGGLE_IN_IMAGE,
  HINT_BOX,
  MUSICAL_BUTTON,
  FOR_GROUPS
} from '../../constants/contentType.constants';
import { ZOOM_CONTENT_MODAL } from '../layouts/Modal/Modal.constants';
import Chat from '../mechanisms/Chat.component';
import ColorName from '../mechanisms/ColorName.component';
import ConnectedLinks from '../mechanisms/ConnectedLinks.component';
import CrossWordsGrid from '../mechanisms/CrossWordsGrid.component';
import DrawingImage from '../mechanisms/DrawingImage.component';
import FilterImage from '../mechanisms/FilterImage.component';
import ForGroups from '../mechanisms/ForGroups.container';
import HiddenWordsGrid from '../mechanisms/HiddenWordsGrid.component';
import ImageGrid from '../mechanisms/ImageGrid.component';
import ListSelection from '../mechanisms/ListSelection.component';
import MixtCarousel from '../mechanisms/MixtCarousel.component';
import MusicalButton from '../mechanisms/MusicalButton.component';
import MusicalImage from '../mechanisms/MusicalImage.component';
import MusicalSvg from '../mechanisms/MusicalSvg.component';
import PasswordProtection from '../mechanisms/PasswordProtection.component';
import SelectableGrid from '../mechanisms/SelectableGrid.component';
import StructuredText from '../../../../share/components/StructuredText.component';
import TextualImage from '../mechanisms/TextualImage.component';
import ToggleInImage from '../mechanisms/ToggleInImage.component';
import UnpixelateImage from '../mechanisms/UnpixelateImage.component';
import ZoomableImage from '../mechanisms/ZoomableImage.component';
import ThemeComponent from '../theme/ThemeComponent.component';
import Modal from '../layouts/Modal/Modal.container';
import { renderForEach } from '../../../../utils/render';

import './RichContent.scss';

class RichContent extends React.PureComponent {
  render() {
    if (!Array.isArray(this.props.content)) {
      return this.renderContent(this.props.content);
    }

    return renderForEach(
      this.props.content.filter(Boolean),
      this.renderContent.bind(this)
    );
  }

  renderContent(item, index = 0) {
    if (typeof item === 'string' || Array.isArray(item)) {
      item = {
        type: TEXT,
        content: item
      };
    }

    switch (item.type) {
      case TEXT:
      case HTML:
      case IMAGE:
        return (
          <StructuredText
            key={index}
            content={[item]}
            style={this.props.style}
          />
        );

      case HINT_BOX:
        return (
          <div className="c-spot__hint" key={index}>
            <div className="c-spot__hint-title">
              {item.title || 'Précision'}
            </div>
            <div className="c-spot__hint-content">
              <RichContent content={item.content} />
            </div>
          </div>
        );

      case IMAGE_PANORAMA:
        return (
          <div className="c-rich-content__panorama" key={index}>
            <div className="c-rich-content__panorama-caret" key={index}>
              <img
                className="c-rich-content__panorama-image"
                alt="Problème de chargement de l'image, merci de recharger la page"
                src={item.content}
                height={item.height || 'auto'}
              />
              {item.surprises &&
                item.surprises.map((surprise, index) => (
                  <div
                    className="c-rich-content__panorama-surprise"
                    style={{ marginLeft: surprise.left }}
                    key={index}
                  >
                    <RichContent content={surprise.content} />
                  </div>
                ))}
            </div>
          </div>
        );

      case IMAGE_WALL:
        return (
          <div className="c-rich-content__image-wall" key={index}>
            {item.content.map((el, itemIndex) => (
              <img
                className="c-rich-content__image-wall-item"
                key={itemIndex}
                alt="Problème de chargement de l'image, merci de recharger la page"
                src={el}
                height={item.rowHeight || 'auto'}
              />
            ))}
          </div>
        );

      case FILE:
        return (
          <a
            key={index}
            href={item.src}
            target="_blank"
            rel="noopener noreferrer"
            className="c-rich-content__file"
          >
            {item.icon === 'text' && (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="#31115B"
                  d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
                />
              </svg>
            )}
            {item.title}
          </a>
        );

      case AUDIO:
        return (
          <div key={index}>
            {item.background && (
              <img
                src={item.background}
                alt="Écoutez ce fichier son"
                className="c-rich-content__audio-image"
              />
            )}
            <audio
              controls
              src={item.content}
              className="c-rich-content__audio-player"
            >
              Votre navigateur ne supporte pas les éléments
              <code>audio</code>.
            </audio>
            <div className="c-rich-content__audio-link">
              <a href={item.content} download>
                Télécharger le fichier audio
              </a>
            </div>
          </div>
        );

      case VIDEO:
        return (
          <div key={index}>
            <video
              controls
              src={item.content}
              className="c-rich-content__video-player"
            >
              Votre navigateur ne supporte pas les éléments
              <code>video</code>.
            </video>
          </div>
        );

      case LIST:
        return (
          <ul key={index}>
            {(item.content || item.items).map((el, itemIndex) => (
              <li key={itemIndex}>
                <RichContent {...this.props} content={el} />
              </li>
            ))}
          </ul>
        );

      case LIST_SELECTION:
        return <ListSelection key={index} {...item} />;

      case ORDERED_LIST:
        return (
          <ol key={index}>
            {(item.content || item.items).map((el, itemIndex) => (
              <li key={itemIndex}>
                <RichContent {...this.props} content={el} />
              </li>
            ))}
          </ol>
        );

      case DRAWING_IMAGE:
        return (
          <DrawingImage
            color={item.color}
            lineWidth={item.lineWidth}
            width={item.width}
            height={item.height}
            grid={item.grid}
            src={item.src}
            alt={
              item.alt ||
              "Problème de chargement de l'image, merci de recharger la page"
            }
            key={index}
          />
        );

      case HIDDEN_WORDS_GRID:
        return <HiddenWordsGrid {...item} key={index} />;

      case SELECTABLE_GRID:
        return <SelectableGrid {...item} key={index} />;

      case CROSS_WORDS_GRID:
        return <CrossWordsGrid key={index} {...item} />;

      case IMAGE_GRID:
        return (
          <ImageGrid
            key={index}
            image={item.image}
            alt={
              item.alt ||
              "Problème de chargement de l'image, merci de recharger la page"
            }
            columnsTemplate={item.columnsTemplate}
            linesTemplate={item.linesTemplate}
            linesCount={item.linesCount}
            columnsCount={item.columnsCount}
            rowNames={item.rowNames}
            columnNames={item.columnNames}
            maxInput={item.maxInput}
            inactiveLines={item.inactiveLines}
            color={item.color}
          />
        );

      case PAPER:
        return (
          <div
            key={index}
            className="c-rich-content__paper"
            style={{
              textAlign: item.align || 'center',
              fontSize: item.fontSize || '',
              fontFamily: item.fontFamily || '',
              fontStyle: item.fontStyle || ''
            }}
          >
            <RichContent {...this.props} content={item.content} />
          </div>
        );

      case CLICK_TO_SEE:
        const onClick = event => {
          event.target.parentElement.style.display = 'none';
          event.target.parentElement.nextSibling.style.display = 'block';
          item.pageView &&
            this.props.pageView &&
            this.props.pageView(item.pageView);
        };

        return (
          <div className="c-rich-content__click-to-see" key={index}>
            <div className="c-rich-content__click-to-see-action">
              <button onClick={onClick} className="o-button o-button--fit">
                {item.label}
              </button>
            </div>
            <div className="c-rich-content__click-to-see-content">
              <RichContent {...this.props} content={item.content} />
            </div>
          </div>
        );

      case CAROUSEL:
        return <MixtCarousel key={index} {...item} />;

      case FILTER_IMAGE:
        return <FilterImage key={index} {...item} />;

      case CONNECTED_LINKS:
        return <ConnectedLinks key={index} {...item} />;

      case MUSICAL_BUTTON:
        return <MusicalButton key={index} {...item} />;

      case MUSICAL_IMAGE:
        return <MusicalImage key={index} {...item} />;

      case MUSICAL_SVG:
        return <MusicalSvg key={index} {...item} />;

      case TEXTUAL_IMAGE:
        return <TextualImage key={index} {...item} />;

      case ZOOMABLE_IMAGE:
        return <ZoomableImage key={index} {...item} />;

      case UNPIXELATE_IMAGE:
        return <UnpixelateImage key={index} {...item} />;

      case TOGGLE_IN_IMAGE:
        return <ToggleInImage key={index} {...item} />;

      case PASSWORD_PROTECTION:
        return <PasswordProtection key={index} {...item} />;

      case CHAT:
        return <Chat key={index} {...item} />;

      case COLOR_NAME:
        return <ColorName key={index} {...item} />;

      case CUSTOM_MECHANISM:
        return <ThemeComponent name={item.name} key={index} {...item.props} />;

      case FOR_GROUPS:
        return (
          <ForGroups key={index} {...item}>
            {() => <RichContent content={item.content} />}
          </ForGroups>
        );

      case ZOOM:
        return (
          <div key={index} className="c-zoom">
            <div key={index} className="c-zoom__anchor">
              <RichContent content={item.content} />
              <button
                className="c-zoom__button"
                type="button"
                onClick={() => this.props.openModal(ZOOM_CONTENT_MODAL)}
              >
                {item.label || 'Agrandir'}
              </button>
            </div>
            <Modal
              modalId={ZOOM_CONTENT_MODAL}
              contentLabel={item.label || 'Zoom'}
            >
              <div key={index} className="c-zoom__modal">
                <RichContent content={item.zoom} />
              </div>
            </Modal>
          </div>
        );

      case TABLE:
        return (
          <table
            className="c-rich-content__table"
            style={item.style}
            key={index}
          >
            <tbody>
              {renderForEach(item.content, (row, rowIndex) => (
                <tr
                  className="c-rich-content__table-row"
                  key={`row-${rowIndex}`}
                >
                  {renderForEach(row, (cell, colIndex) => (
                    <td
                      className="c-rich-content__table-cell"
                      key={`cell-${colIndex}`}
                      style={{
                        border: item.border,
                        verticalAlign: item.verticalAlign,
                        textAlign: item.horizontalAlign,
                        paddingLeft: colIndex ? item.horizontalGap : 0,
                        paddingTop: rowIndex ? item.verticalGap : 0
                      }}
                    >
                      <RichContent content={cell} style={item.subStyle} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );

      default:
        return null;
    }
  }
}

export default RichContent;
