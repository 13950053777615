import React from 'react';
import BadgeIcon from '../Badge.component';
import { BADGE_LABEL_STORY_PAX_DEORUM } from '../../../../../../share/constants/Badges.constants';

class BadgeStoryPaxDeorum extends React.PureComponent {
  render() {
    return (
      <BadgeIcon
        color="#574240"
        label={BADGE_LABEL_STORY_PAX_DEORUM}
        {...this.props}
      >
        <img src="/images/stories/Pax-Deorum-badge.png" alt="" />
      </BadgeIcon>
    );
  }
}

export default BadgeStoryPaxDeorum;
