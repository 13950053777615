import React, { Fragment } from 'react';
import Modal from './Modal.container';
import { AWARD_MODAL, FINAL_SPOT_MODAL, REVIEW_MODAL } from './Modal.constants';
import ThemeComponent from '../../theme/ThemeComponent.component';
import { history } from '../../../modules/createStore';
import { diffPropValue } from '../../../../../utils/basis';
import stories from '~data/stories';
import { AWARD, NEWSLETTER, STORY_END } from './AwardModal.constants';
import withThemeData from '../../theme/withThemeData.hoc';

function buildSequence(props) {
  const modalConditions = props.themeData.awardModalSequence(props);

  return Object.entries(modalConditions)
    .filter(function([, step]) {
      return step.display;
    })
    .map(function([name, step]) {
      return { ...step, name };
    });
}

class AwardModal extends React.PureComponent {
  constructor(props) {
    super(props);
    const sequence = buildSequence(props);
    this.state = {
      sequence,
      stepIndex: 0,
      step: sequence[0],
      isEndOfStory: this.props.isEndOfStory
    };
    this.nextIsFinal = this.props.nextIsFinal;
    this.isEndOfStory = this.props.isEndOfStory;
    this.storyId = this.props.storyId;
    this.universeId = this.props.universeId;
    this.finalSpot = this.props.finalSpot;
  }

  static getDerivedStateFromProps(props, state) {
    const sequence = buildSequence(props);

    return {
      sequence,
      step: sequence[state.stepIndex]
    };
  }

  componentDidUpdate(prevProps) {
    if (diffPropValue(this.props, prevProps, 'nextIsFinal')) {
      this.nextIsFinal = this.props.nextIsFinal;
    }
    if (diffPropValue(this.props, prevProps, 'isEndOfStory')) {
      this.setState(() => ({
        isEndOfStory: this.props.isEndOfStory
      }));
    }
    if (diffPropValue(this.props, prevProps, 'storyId')) {
      this.storyId = this.props.storyId;
    }
    if (diffPropValue(this.props, prevProps, 'universeId')) {
      this.universeId = this.props.universeId;
    }
    if (diffPropValue(this.props, prevProps, 'finalSpot')) {
      this.finalSpot = this.props.finalSpot;
    }
  }

  onNext() {
    this.setState(state => {
      const stepIndex = state.stepIndex + 1;

      return {
        stepIndex,
        step: state.sequence[stepIndex]
      };
    });
  }

  isLastStep() {
    return this.state.stepIndex === this.state.sequence.length - 1;
  }

  displayFinalSpot(storyId, finalSpot) {
    this.goToHome();
    this.props.openModal(FINAL_SPOT_MODAL, {
      story: stories[storyId],
      closeModal: this.props.closeModal
    });
    this.props.setHighlightedSpot(finalSpot);
  }

  goToHome() {
    history.push('/');
  }

  onRequestClose() {
    if (this.isLastStep()) {
      this.onClose();
    } else {
      this.onNext();
    }
  }

  onClose() {
    if (!this.state.step) {
      return null;
    }
    if (this.state.step.next === REVIEW_MODAL) {
      return this.props.openModal(REVIEW_MODAL, {
        storyId: this.storyId,
        closeModal: this.props.closeModal
      });
    }

    if (this.nextIsFinal) {
      return this.displayFinalSpot(this.storyId, this.finalSpot);
    }

    this.props.closeModal();
  }

  getTitle() {
    if (!this.state.step) {
      return null;
    }
    if (this.state.step.title) {
      return this.state.step.title;
    }
    if (this.props.isGameAnswered || this.state.step.name === STORY_END) {
      return 'Félicitations !';
    }
    return "C'est perdu…";
  }

  getNextStepAction() {
    const nextStep = this.state.sequence[this.state.stepIndex + 1];
    if (nextStep && nextStep.action) {
      return nextStep.action;
    }

    return 'Suivant';
  }

  renderButton() {
    if (this.state.step && this.state.step.noNext) {
      return null;
    }

    if (this.isLastStep()) {
      return (
        <button className="o-button" onClick={this.onClose.bind(this)}>
          Revenir au jeu
        </button>
      );
    }

    return (
      <button className="o-button" onClick={this.onNext.bind(this)}>
        {this.getNextStepAction()}
      </button>
    );
  }

  renderContent() {
    const { spot, universeId } = this.props;
    if (!spot || !this.state.step) {
      return null;
    }

    if (this.state.step.name === AWARD) {
      return (
        <ThemeComponent
          name="AwardModal"
          proxyParams={{ universeId }}
          spotId={spot.id}
          award={spot.award}
          storyId={spot.storyId}
          isFinal={spot.isFinal}
          isOpen={this.props.isOpen}
          isGameAnswered={this.props.isGameAnswered}
          nextModal={this.onRequestClose.bind(this)}
        />
      );
    }

    if (this.state.step.name === STORY_END) {
      return (
        <ThemeComponent
          name="StoryEndModal"
          proxyParams={{ universeId }}
          storyId={spot.storyId}
        />
      );
    }

    if (this.state.step.name === NEWSLETTER) {
      return (
        <Fragment>
          <p className="u-txt-center">
            Maintenant qu'on se connaît un peu, on reste en contact ?
          </p>
          <p className="u-m-3">
            <a
              className="o-button o-button--alternate o-button--no-shadow"
              href="https://zupple.fr/newsletter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              S'inscrire à notre newsletter
            </a>
          </p>
        </Fragment>
      );
    }

    return (
      <ThemeComponent
        name={this.state.step.name}
        proxyParams={{ universeId }}
        spotId={spot.id}
        isOpen={this.props.isOpen}
        isGameAnswered={this.props.isGameAnswered}
        nextModal={this.onRequestClose.bind(this)}
      />
    );
  }

  render() {
    const noDecorations = this.state.step && this.state.step.noDecorations;

    return (
      <Modal
        modalId={AWARD_MODAL}
        universeId={noDecorations ? undefined : this.props.universeId}
        className="c-modal-award"
        title={this.getTitle()}
        renderFooter={this.renderButton.bind(this)}
        onClose={this.onClose.bind(this)}
        hideCross={this.state.step && this.state.step.noClose}
        onRequestClose={this.onRequestClose.bind(this)}
        specificLogo={this.state.step && this.state.step.logo}
        noDecorations={noDecorations}
      >
        {this.renderContent()}
      </Modal>
    );
  }
}

export default withThemeData(AwardModal);
