import { PLAY_STORY, STOP_STORY } from './Story.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  getStateFromLocalStorage,
  saveStateInLocalStorage
} from '../../../../utils/basis';

const reducerName = 'story';
const emptyState = {
  singleStoryId: null,
  currentStoryId: null
};
const initialState = getStateFromLocalStorage(reducerName, emptyState);

function saveState(state) {
  saveStateInLocalStorage(reducerName, state);
}

const StoryReducer = options => {
  initialState.singleStoryId = options.data && options.data.singleStoryId;
  const defaultStoryId = initialState.singleStoryId;
  initialState.currentStoryId = initialState.currentStoryId || defaultStoryId;

  return (state = initialState, action) => {
    let newState;

    switch (action.type) {
      case PLAY_STORY:
        newState = {
          ...state,
          currentStoryId: state.singleStoryId || action.storyId
        };
        saveState(newState);

        return newState;

      case STOP_STORY:
      case LOGOUT_USER:
        newState = {
          ...state,
          currentStoryId: defaultStoryId
        };
        saveState(newState);

        return newState;

      default:
        return state;
    }
  };
};

export default StoryReducer;
