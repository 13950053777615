import React from 'react';

import colors from '~styles/variables/colors.scss';

class ClueIcon extends React.PureComponent {
  render() {
    let prefix = 'icon';
    if (this.props.context) {
      prefix = this.props.context;
    }
    return (
      <svg
        viewBox="0 0 24 24"
        width={this.props.size || 24}
        height={this.props.size || 24}
        className={this.props.className}
      >
        <defs>
          <filter
            x="-841.7%"
            y="-808%"
            width="1783.3%"
            height="1716%"
            filterUnits="objectBoundingBox"
            id={`${prefix}-clue-a`}
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="56"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <linearGradient
            x1="99.226%"
            y1="100%"
            x2="26.881%"
            y2="26.849%"
            id={`${prefix}-clue-b`}
          >
            <stop stopColor={colors.recentFrom} offset="0%" />
            <stop stopColor={colors.recentTo} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="5.732%"
            y1="97.871%"
            x2="87.831%"
            y2="9.087%"
            id={`${prefix}-clue-c`}
          >
            <stop stopColor={colors.activeFrom} offset="0%" />
            <stop stopColor={colors.iconGradient} offset="100%" />
          </linearGradient>
        </defs>
        <g filter={`url(#${prefix}-clue-a)`} fill="none">
          <path
            d="M.778.711a1.811 1.811 0 0 0 0 2.548l8.667 8.735c.698.704 1.83.704 2.528 0 .35-.352.524-.813.524-1.274 0-.461-.174-.922-.524-1.274L3.306.711a1.775 1.775 0 0 0-2.528 0"
            transform="translate(10.79 11.019)"
            fill={`url(#${prefix}-clue-b)`}
          />
          <path
            d="M18.636 9.516c0 5.256-4.172 9.517-9.318 9.517S0 14.773 0 9.516C0 4.261 4.172 0 9.318 0s9.318 4.26 9.318 9.516"
            fill={colors.main}
          />
          <path
            d="M0 5.854c0 3.23 2.635 5.848 5.885 5.848s5.885-2.618 5.885-5.848S9.135.006 5.885.006 0 2.624 0 5.854"
            transform="translate(3.433 3.506)"
            fill={`url(#${prefix}-clue-c)`}
          />
        </g>
      </svg>
    );
  }
}

export default ClueIcon;
