import { connect } from 'react-redux';
import NoGps from './NoGps.component';
import {
  getGeolocationError,
  getGeolocationPermission
} from '../../../modules/geolocation/Geolocation.selectors';
import { startGeolocation } from '../../../modules/geolocation/Geolocation.actions';

function mapStateToProps(state) {
  return {
    geolocationError: getGeolocationError(state),
    geolocationPermission: getGeolocationPermission(state)
  };
}

const mapDispatchToProps = {
  startGeolocation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoGps);
