import { connect } from 'react-redux';
import StoryProgressBox from './StoryProgressBox.component';
import { stopStory } from '../../modules/story/Story.actions';
import {
  getCompletedSpotsForStory,
  getStoryEndDate,
  getStoryStartDate,
  isGameAnswered
} from '../../modules/game/Game.selectors';
import { getIsSingleStory } from '../../modules/story/Story.selectors';

function mapStateToProps(state, ownProps) {
  let answeredSpots = getCompletedSpotsForStory(state, ownProps.story.id);
  let answeredSpotsCount = answeredSpots.length;
  let totalSpotsCount = ownProps.story.spots.length;
  let isCompleted = totalSpotsCount === answeredSpotsCount;

  if (ownProps.story.finalSpot && isCompleted) {
    totalSpotsCount = totalSpotsCount + 1;
    if (isGameAnswered(state, ownProps.story.finalSpot)) {
      answeredSpotsCount = answeredSpotsCount + 1;
    }
  }
  return {
    isSingleStory: getIsSingleStory(state),
    completedSpotsCount: answeredSpotsCount,
    totalSpotsCount,
    storyStartDate: getStoryStartDate(state, ownProps.story.id),
    storyEndDate: getStoryEndDate(state, ownProps.story.id),
    universeId: ownProps.story.universeId
  };
}

const mapDispatchToProps = {
  stopStory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryProgressBox);
