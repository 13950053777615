import React from 'react';
import Modal from './Modal.container';
import { ABOUT_MODAL } from './Modal.constants';
import version from '../../../../../version';

class AboutModal extends React.PureComponent {
  render() {
    const versionStr = version + '';
    const dotVersion =
      versionStr.substr(0, 1) +
      '.' +
      versionStr.substr(1, 1) +
      '.' +
      versionStr.substr(2);

    return (
      <Modal modalId={ABOUT_MODAL} contentLabel="À propos">
        <div>
          <em>Zupple v{dotVersion}</em>
        </div>
      </Modal>
    );
  }
}

export default AboutModal;
