import { connect } from 'react-redux';
import GeolocationGate from './GeolocationGate.component';
import {
  getGeolocationPermission,
  getGeolocationStatus,
  getIsGeolocationApp
} from '../../../modules/geolocation/Geolocation.selectors';
import {
  requestGeolocationPermission,
  startGeolocation
} from '../../../modules/geolocation/Geolocation.actions';

function mapStateToProps(state) {
  return {
    isGeolocationApp: getIsGeolocationApp(state),
    geolocationPermission: getGeolocationPermission(state),
    geolocationStatus: getGeolocationStatus(state)
  };
}

const mapDispatchToProps = {
  requestGeolocationPermission,
  startGeolocation
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeolocationGate);
