import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MessageSequence.css';

export const ANIMATION_STATUS = {
  SHOWN: 'shown',
  HIDDEN: 'hidden'
};

class MessageSequence extends React.PureComponent {
  constructor(props) {
    super(props);
    this.counterTime = 0;
    this.state = {
      stepIndex: 0,
      animationStatus: ANIMATION_STATUS.HIDDEN
    };
  }

  componentDidMount() {
    this.props.sequence.forEach(({ message, animationTimes }, index) => {
      this.addAnimationCycle(index, animationTimes);
    });
    if (this.props.onAnimationEnd) {
      setTimeout(this.props.onAnimationEnd, this.counterTime);
    }
  }

  getMaxStepIndex() {
    return this.props.sequence.length;
  }

  addAnimation(state, delay) {
    this.counterTime += delay;
    setTimeout(() => this.setState(() => state), this.counterTime);
  }

  addAnimationCycle(stepIndex, animationTimes) {
    animationTimes.forEach(({ status, delay }) => {
      this.addAnimation(
        {
          stepIndex,
          animationStatus: status
        },
        delay
      );
    });
  }

  render() {
    const className = classNames(`c-message-sequence`, {
      'c-message-sequence--hide':
        ANIMATION_STATUS.HIDDEN === this.state.animationStatus
    });
    let message = this.props.sequence[this.state.stepIndex].message;

    return (
      <span
        className={className}
        key={`message-${this.state.stepIndex}`}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    );
  }
}

MessageSequence.propTypes = {
  sequence: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.node,
      animationsTimes: PropTypes.shape({
        status: PropTypes.oneOf(Object.values(ANIMATION_STATUS)),
        delay: PropTypes.number
      })
    })
  ),
  onAnimationEnd: PropTypes.func
};

export default MessageSequence;
